import { Theme as NavTheme } from '@react-navigation/native';
import _ from 'lodash';

import { AppTheme } from '../const';

export const screenTheme = (theme: AppTheme, options: any = {}): any => {
    return _.merge({}, options, {
        headerStyle: {
            backgroundColor: theme.colors.surface,
        },
        headerTintColor: theme.colors.accent,
        headerTitleStyle: {},
        transparentCard: true,
        cardStyle: {
            backgroundColor: 'transparent',
            opacity: 1,
        },
        transitionConfig: () => ({
            containerStyle: {
                backgroundColor: 'transparent',
            },
        }),
    });
};

export const navTheme = (theme: AppTheme): NavTheme => {
    const { colors } = theme;
    return {
        dark: theme.dark,
        colors: {
            /**
             * The primary color of the app used to tint various elements.
             * Usually you'll want to use your brand color for this.
             */
            primary: colors.primary,
            /**
             * The color of various backgrounds, such as background
             * color for the screens.
             */
            background: colors.background,
            /**
             * The background color of card-like elements, such as
             * headers, tab bars etc.
             */
            card: colors.surface,
            /** The text color of various elements. */
            text: colors.accent,
            /** The color of borders, e.g. header border, tab bar border etc. */
            border: colors.placeholder,
            /** The color of Tab Navigator badge. */
            notification: colors.primary,
        },
    };
};
