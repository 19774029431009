import {
    formatGlucoseUnit,
    formatGlucoseValue,
    lz,
} from '@byterium/glucose-diary-client';
import React from 'react';
import { StyleSheet, View, ViewProps } from 'react-native';
import { Button, Divider } from 'react-native-form-model';
import { Text, useTheme } from 'react-native-paper';

import { AppTheme } from '../../const';
import UserSettings from '../../services/UserSettings';
import { GlucoseIcon } from '../assets/glucoseAssets';

export interface GlucoseRangeWidgetProps extends ViewProps {}

interface GlucoseRangeWidgetInternalProps extends GlucoseRangeWidgetProps {
    onPressChange: () => void;
}

const GlucoseRangeWidget: React.FC<GlucoseRangeWidgetInternalProps> = ({
    onPressChange,
    ...otherProps
}) => {
    const theme = useTheme() as AppTheme;

    const upperLimit = UserSettings.useGlucoseTargetUpperLimit();
    const lowerLimit = UserSettings.useGlucoseTargetLowerLimit();
    const { glucoseUnit } = UserSettings.useUnits();

    const header = (
        <View style={styles.header}>
            <GlucoseIcon />
            <Text
                style={[
                    styles.headerTitle,
                    {
                        color: theme.colors.glucose,
                        paddingLeft: hPadding,
                    },
                ]}
                onPressIn={undefined}
                onPressOut={undefined}
            >
                {lz('targetLimits')}
            </Text>
            <FlexibleSpace />
            <Button
                mode='text'
                title={lz('change')}
                compact
                onPress={onPressChange}
                style={styles.headerButton}
                textStyle={styles.headerButtonText}
            />
        </View>
    );

    const body = (
        <View style={styles.body}>
            <Text
                style={styles.description}
                onPressIn={undefined}
                onPressOut={undefined}
            >
                {lz('changeGlucoseLimitsConsultDoctor')}
            </Text>

            <Divider style={styles.divider} />

            <View style={styles.row}>
                <Text
                    style={[
                        styles.rowTitle,
                        { color: theme.colors.glucoseHigh },
                    ]}
                    onPressIn={undefined}
                    onPressOut={undefined}
                >
                    {lz('upperTargetLimit')}
                </Text>
                <FlexibleSpace />
                <Text
                    style={styles.value}
                    onPressIn={undefined}
                    onPressOut={undefined}
                >
                    {formatGlucoseValue(upperLimit, glucoseUnit)}
                </Text>
                <Text
                    style={[
                        styles.unit,
                        { color: theme.colors.textStronglyFaded },
                    ]}
                    onPressIn={undefined}
                    onPressOut={undefined}
                >
                    {formatGlucoseUnit(glucoseUnit)}
                </Text>
            </View>
            <View style={styles.rowPadding} />
            <View style={styles.row}>
                <Text
                    style={[
                        styles.rowTitle,
                        { color: theme.colors.glucoseLow },
                    ]}
                    onPressIn={undefined}
                    onPressOut={undefined}
                >
                    {lz('lowerTargetLimit')}
                </Text>
                <FlexibleSpace />
                <Text
                    style={styles.value}
                    onPressIn={undefined}
                    onPressOut={undefined}
                >
                    {formatGlucoseValue(lowerLimit, glucoseUnit)}
                </Text>
                <Text
                    style={[
                        styles.unit,
                        { color: theme.colors.textStronglyFaded },
                    ]}
                    onPressIn={undefined}
                    onPressOut={undefined}
                >
                    {formatGlucoseUnit(glucoseUnit)}
                </Text>
            </View>
        </View>
    );

    const containerStyle = [
        styles.container,
        {
            backgroundColor: theme.colors.surface,
            borderRadius: theme.roundness,
        },
        otherProps.style,
    ];

    return (
        <View style={containerStyle}>
            <View style={{ flex: 1 }}>
                {header}
                {body}
            </View>
        </View>
    );
};

const FlexibleSpace = React.memo(() => <View style={{ flex: 1 }} />);

const hPadding = 10;
const vPadding = 10;

const styles = StyleSheet.create({
    container: {
        paddingHorizontal: 12,
        paddingVertical: vPadding,
    },
    header: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    headerTitle: {
        flexShrink: 1,
        paddingRight: hPadding,
        fontSize: 14,
        fontWeight: 'bold',
    },
    headerButton: {
        marginVertical: -5,
    },
    headerButtonText: {
        fontSize: 14,
    },
    description: {
        fontSize: 14,
    },
    divider: {
        marginVertical: 12,
    },
    row: {
        flexDirection: 'row',
        alignItems: 'baseline',
    },
    rowPadding: {
        height: 5,
    },
    body: {
        paddingTop: 8,
        flex: 1,
    },
    rowTitle: {
        fontSize: 14,
        flexShrink: 1,
        marginRight: 8,
    },
    value: {
        paddingRight: hPadding,
        fontWeight: 'bold',
        fontSize: 16,
    },
    unit: {
        paddingRight: hPadding,
        fontSize: 16,
    },
});

export default GlucoseRangeWidget;
