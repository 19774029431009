import { AppDelegate, User, lz } from '@byterium/glucose-diary-client';
import { NavigationContainer } from '@react-navigation/native';
import { StackNavigationOptions } from '@react-navigation/stack';
import React from 'react';
import { useTheme } from 'react-native-paper';

import { AppTheme } from '../const';
import { navTheme, screenTheme } from '../services/style';
import AddSamplePage from './AddSamplePage/AddSamplePage';
import HomePage from './HomePage';
import insulinScreens from './Insulin/insulinScreens';
import OnboardPage from './OnboardPage';
import OverviewChartPage from './OverviewChartPage';
import settingsScreens from './Settings/settingsScreens';
import loginScreens from './login/loginScreens';
import { HomeStack, getHeaderTitleStyle, navigationRef } from './navigate';
import recordScreens from './records/recordScreens';
import userProfileScreens from './userProfile/userProfileScreens';

let didSignalReady = false;

AppDelegate.shared().addUserTask(() => {
    didSignalReady = false;
});

const Home: React.FC = () => {
    const theme = useTheme() as AppTheme;
    const defaultScreenOptions: StackNavigationOptions = {
        headerTitleAlign: 'center',
        headerTitleStyle: getHeaderTitleStyle(),
        headerTruncatedBackTitle: '',
        cardStyle: {
            flex: 1,
            backgroundColor: theme.colors.background,
        },
        headerTitleAllowFontScaling: true,
        headerBackAllowFontScaling: true,
    };
    const user = User.current();
    const initialRouteName = React.useMemo(
        () =>
            user ? (!user.needsOnboarding() ? 'Home' : 'Onboard') : 'LoginMain',
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [user?.id]
    );

    return (
        <HomeStack.Navigator
            initialRouteName={initialRouteName}
            screenOptions={screenTheme(theme)}
        >
            {loginScreens({ defaultScreenOptions })}

            <HomeStack.Screen
                key='Onboard'
                name='Onboard'
                component={OnboardPage}
                options={{
                    title: lz('welcome'),
                    ...defaultScreenOptions,
                    headerTitleAlign: 'center',
                }}
            />

            <HomeStack.Screen
                name='Home'
                component={HomePage}
                options={{
                    title: lz('home'),
                    ...defaultScreenOptions,
                }}
                listeners={{
                    focus: event => {
                        // Signal ready
                        if (!didSignalReady) {
                            didSignalReady = true;
                            AppDelegate.shared().setReady();
                        }
                    },
                }}
            />
            <HomeStack.Screen
                name='AddSample'
                component={AddSamplePage}
                options={{
                    title: lz('addRecord'),
                    ...defaultScreenOptions,
                }}
            />
            <HomeStack.Screen
                name='OverviewChart'
                component={OverviewChartPage}
                options={{
                    title: lz('overview'),
                    ...defaultScreenOptions,
                }}
            />
            {insulinScreens({ defaultScreenOptions })}
            {recordScreens({ defaultScreenOptions })}
            {userProfileScreens({ defaultScreenOptions })}
            {settingsScreens({ defaultScreenOptions })}
        </HomeStack.Navigator>
    );
};

const Pages: React.FC = () => {
    // TODO: Set app background color. See [task](https://trello.com/c/UelT9QvK)
    const theme = useTheme() as AppTheme;
    return (
        <NavigationContainer ref={navigationRef as any} theme={navTheme(theme)}>
            {/* <DrawerWrapper /> */}
            <Home />
        </NavigationContainer>
    );
};

export default Pages;
