import * as Carb from './carbUnitForms';
import * as Energy from './energyUnitForms';
import * as Glucose from './glucoseUnitForms';
import * as Insulin from './insulinUnitForms';
import { UnitSettingFormOptions } from './unitSettingTypes';

export function addUnitSectionToForm(options: UnitSettingFormOptions) {
    const { form } = options;
    const section = form.addSection();

    Glucose.addGlucoseUnitRowsToSection({ ...options, section });
    Insulin.addInsulinUnitRowsToSection({ ...options, section });
    Carb.addCarbUnitRowsToSection({ ...options, section });
    Energy.addEnergyUnitRowsToSection({ ...options, section });
}
