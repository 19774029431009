import {
    InsulinDoses,
    InsulinProducts,
    formatInsulinUnit,
    formatInsulinValue,
} from '@byterium/glucose-diary-client';
import i18n from 'i18n-js';
import React from 'react';

import { navigate } from '../../pages/navigate';
import UserSettings from '../../services/UserSettings';
import { InsulinIcon } from '../assets/insulinAssets';
import RecordSummaryCell, {
    RecordSummaryBuildOptions,
    RecordSummaryCellProps,
} from '../records/RecordSummaryCell';

export const useInsulinDoseSummaryCell = (
    options: RecordSummaryBuildOptions
): React.ReactNode => {
    const { record, error, loading } = InsulinDoses.useLatest();
    const { insulinUnit } = UserSettings.useUnits();
    const props: Partial<RecordSummaryCellProps> = {};

    if (record) {
        props.value = formatInsulinValue(record.doseAmount, insulinUnit);
        props.unit = formatInsulinUnit(insulinUnit);
        props.date = record.date.format('LLL');
        props.description = InsulinProducts.formatProduct(record.product, {
            concentration: true,
        });
    }

    return (
        <RecordSummaryCell
            {...options}
            {...props}
            title={i18n.t('insulin')}
            titleIcon={() => <InsulinIcon />}
            error={error ? error.message || String(error) : ''}
            placeholder={loading ? i18n.t('loading') : i18n.t('noData')}
            primaryColor={options.theme.colors.insulin}
            onPress={() =>
                navigate('RecordSummary', { recordType: 'InsulinDose' })
            }
        />
    );
};
