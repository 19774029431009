import {
    RecordType,
    getCurrentLocale,
    kRecordTypes,
    lzn,
} from '@byterium/glucose-diary-client';
import {
    AutoScaleController,
    Axis,
    ChartLayout,
    ChartLayoutProps,
    DataSource,
    DateAxis,
    DateScale,
    DiscreteScale,
    FixedScaleController,
    Hysteresis,
    LabelDataSource,
    LineDataSource,
    LinearScale,
    PlotLayout,
    RectDataSource,
    ScaleLayout,
} from '@byterium/librechart';
import _ from 'lodash';
import moment, { Duration, Moment } from 'moment';
import React from 'react';
import { Animated, Dimensions } from 'react-native';
import { BehaviorSubject, Subject } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

import { animatedObservable, useObservable } from '../../reactUtil';
import { recordTypeColor } from '../assets/recordAssets';
import SecondaryChartAxisLabel from './SecondaryChartAxisLabel';
import {
    DateScaleInfo,
    RecordChartDataPoint,
    RecordChartLayout,
    RecordChartLayoutOptions,
    SecondaryDataSourceInfo,
    SecondaryDataSourceOptions,
    UpdateChartScrollOptions,
    kBarCornerRadius,
    kBaseDateUnit,
    kBottomAxisHeight,
    kDataDisplayDebounceInterval,
    kMainLabelOffset,
    kMajorXGridLineDistanceMin,
    kRightMainAxisThickness,
    kRightSecondaryAxisThickness,
    kSecondaryDataSourcePosition,
    kSecondaryPointRadiusBase,
    kSecondaryValueLabelOffset,
    periods,
} from './chartConst';
import {
    chartValueFormatter,
    createPeriodState,
    getOriginDate,
    labelTextStyle,
    rectDataTransForm,
} from './chartUtil';

const kNumSecondaryRecordTypes = kRecordTypes.length - 1;

export function createChartLayout({
    recordType,
    valueTransformer,
    dataAverages,
    mode = 'line',
    units,
    color,
    highColor,
    lowColor,
    highValue,
    lowValue,
    isOverview = false,
    theme,
}: RecordChartLayoutOptions): RecordChartLayout {
    const dataSources: DataSource<any, Moment, number>[] = [];

    const visibleDataRange$ = new Subject<[Moment, Moment]>();

    const originDate = getOriginDate();
    const defaultScaleX = Dimensions.get('window').width;
    const mainScale$ = new Animated.ValueXY({
        x: defaultScaleX,
        y: -20,
    });
    const dateScale = new DateScale({
        originDate,
        baseUnit: kBaseDateUnit,
    });
    const dateScaleInfo: DateScaleInfo = {
        majorInterval: 0,
        minorCount: 0,
        recenteringOffset: 0,
        scale: dateScale,
    };
    const periodState = createPeriodState(0, dateScaleInfo);
    const minorPeriod$ = new BehaviorSubject(periodState.minorPeriod);

    // Main data
    let dataLayout: DataSource<RecordChartDataPoint, Moment, number>;
    if (mode === 'line') {
        dataLayout = new LineDataSource<RecordChartDataPoint, Moment, number>({
            transform: data => ({ x: data.x, y: valueTransformer(data.y) }),
            style: {
                strokeColor: color || undefined,
                pointInnerRadius: 2.5,
                pointOuterRadius: 4.5,
                strokeWidth: 2,
                pointInnerColor: theme.chart.backgroundColor,
            },
        });
    } else if (mode === 'bar') {
        dataLayout = new RectDataSource<RecordChartDataPoint, Moment, number>({
            transform: data =>
                rectDataTransForm(
                    data,
                    periodState.minorPeriodMs,
                    valueTransformer
                ),
            style: {
                fillColor: color || undefined,
                topLeftCornerRadius: kBarCornerRadius,
                topRightCornerRadius: kBarCornerRadius,
            },
        });
    } else {
        throw new Error('Invalid chart mode');
    }

    // Main label data
    const valueFormatter = chartValueFormatter(recordType, units);
    const labelDataLayout = new LabelDataSource<
        RecordChartDataPoint,
        Moment,
        number
    >({
        transform: data => ({ x: data.x, y: valueTransformer(data.y) }),
        style: {
            textStyle: labelTextStyle({ color, theme }),
        },
        itemStyle: (data, index, style) => {
            style = style || {
                align: { y: undefined },
                viewLayout: {
                    offset: { y: new Animated.Value(0) },
                    anchor: { y: new Animated.Value(0) },
                },
                textStyle: { color: '' },
            };
            const { isTop = true } = data;
            if (isTop) {
                if (style.align!.y !== 'bottom') {
                    style.align!.y = 'bottom';
                    (style.viewLayout!.offset!.y as Animated.Value).setValue(
                        -kMainLabelOffset
                    );
                    (style.viewLayout!.anchor!.y as Animated.Value).setValue(1);
                }
            } else {
                if (style.align!.y !== 'top') {
                    style.align!.y = 'top';
                    (style.viewLayout!.offset!.y as Animated.Value).setValue(
                        kMainLabelOffset
                    );
                    (style.viewLayout!.anchor!.y as Animated.Value).setValue(0);
                }
            }

            if (highColor && !_.isNaN(highValue) && data.y > highValue!) {
                // @ts-ignore
                style.textStyle.color = highColor;
            } else if (lowColor && !_.isNaN(lowValue) && data.y < lowValue!) {
                // @ts-ignore
                style.textStyle.color = lowColor;
            } else {
                // @ts-ignore
                style.textStyle.color = color;
            }
            return style;
        },
        getLabel: data => (data.showLabel ? valueFormatter(data.y) : ''),
    });

    // Main data limits
    if (highColor && !_.isNaN(highValue)) {
        dataSources.push(
            new LineDataSource<number, Moment, number>({
                data: [0, 1],
                transform: data => {
                    return {
                        x: data
                            ? moment().startOf('year').add(10, 'year')
                            : moment(0),
                        y: highValue || 0,
                    };
                },
                style: {
                    strokeColor: highColor,
                    strokeWidth: 2,
                    strokeDashArray: [2, 4],
                },
            })
        );
    }

    if (lowColor && !_.isNaN(lowValue)) {
        dataSources.push(
            new LineDataSource<number, Moment, number>({
                data: [0, 1],
                transform: data => {
                    return {
                        x: data
                            ? moment().startOf('year').add(10, 'year')
                            : moment(0),
                        y: lowValue || 0,
                    };
                },
                style: {
                    strokeColor: lowColor,
                    strokeWidth: 2,
                    strokeDashArray: [2, 4],
                },
            })
        );
    }

    dataSources.push(dataLayout);
    dataSources.push(labelDataLayout);

    const xLayout = new ScaleLayout({
        scale: dateScale,
        style: {
            majorGridLineDistanceMin: kMajorXGridLineDistanceMin,
        },
    });
    const bottomAxis = new DateAxis({
        axisType: 'bottomAxis',
        locale: getCurrentLocale(),
        theme: theme.chart,
        style: {
            labelStyle: { align: { x: 'left' } },
            axisThickness: kBottomAxisHeight,
        },
        layoutSourceDefaults: {
            willShowItem: (item, options) => {
                if (options.created || options.dequeued) {
                    updateVisibleDataRegion();
                }
            },
        },
    });
    const rightTopAxis = new Axis({
        axisType: 'rightAxis',
        getTickLabel: isOverview
            ? tick => {
                  return tick.value === 0 ? '' : lzn(tick.value);
              }
            : tick => lzn(tick.value),
        // onThicknessChange: (thickness, previousThickness) => {
        //     // TODO: Prevent modifying visible region if not at boundary. See [issue](https://trello.com/c/2xUWOmeQ)
        //     updateVisibleRegion();
        // },
        theme: theme.chart,
        style: {
            axisThickness: kRightMainAxisThickness,
        },
    });

    const updateVisibleDataRegion = _.debounce(() => {
        if (!bottomAxis.contentLayout) {
            return;
        }
        // TODO: getVisibleRange() returns grid indexes. Convert to content location.
        const { itemSize, itemOrigin } = bottomAxis.contentLayout;
        const gridRange = bottomAxis.contentLayout.getVisibleRange();
        gridRange[0] -= 1;
        gridRange[1] += 1;
        const dateRange = gridRange.map(x => {
            x = (x - itemOrigin.x * itemSize.x) * itemSize.x;
            return xLayout.scale.valueAtLocation(x);
        }) as [Moment, Moment];
        visibleDataRange$.next(dateRange);
    }, kDataDisplayDebounceInterval);

    let rowHeights: ChartLayoutProps['rowHeights'] = undefined;

    const plot = new PlotLayout<Moment, number, Duration, number>({
        offset: {
            x: -moment.duration(moment().diff(originDate)).as(kBaseDateUnit),
        },
        scale: mainScale$,
        anchor: { x: 0.5, y: 0 },
        xLayout,
        yLayout: new ScaleLayout({
            controller: new AutoScaleController({
                // viewPaddingAbs: 5,
                contentPaddingRel: 0.3,
                anchor: 0,
                min: 0,
                defaultMax: 20,
                hysteresis: Hysteresis.withScale(
                    new LinearScale({
                        constraints: { maxCount: 5 },
                    })
                ),
            }),
        }),
        dataSources,
        axes: {
            rightAxis: rightTopAxis,
            bottomAxis: isOverview ? false : bottomAxis,
        },
        grid: {
            horizontal: true,
            vertical: mode === 'line',
        },
        verticalPanEnabled: false,
        theme: theme.chart,
        onViewportSizeChanged: () => updateVisibleRegion(),
    });
    const plots = [plot];

    // Secondary data
    let secondaryPlot: PlotLayout<Moment, number, Duration, number> | undefined;
    const secondaryDataLayoutInfos: {
        [T in RecordType]?: SecondaryDataSourceInfo;
    } = {};
    const secondaryDataLayouts: DataSource<
        RecordChartDataPoint,
        Moment,
        number
    >[] = [];
    if (isOverview) {
        // Add secondary data
        for (const secondaryRecordType of kRecordTypes) {
            if (secondaryRecordType === recordType) {
                continue;
            }

            const info = createSecondaryDataLayout({
                recordType: secondaryRecordType,
                dataAverages,
                units,
                theme,
            });
            secondaryDataLayouts.push(info.valueDataLayout);
            secondaryDataLayouts.push(info.labelDataLayout);
            secondaryDataLayoutInfos[secondaryRecordType] = info;
        }

        const secondaryScale$ = new Animated.ValueXY({
            x: mainScale$.x,
            y: new Animated.Value(-20),
        });

        const isCompactAxisLabel$ = animatedObservable(secondaryScale$.y).pipe(
            map(scale => Math.abs(scale) <= 80),
            distinctUntilChanged()
        );

        const rightBottomAxis = new Axis({
            axisType: 'rightAxis',
            getTickLabel: tick => props =>
                (
                    <SecondaryChartAxisLabel
                        {...props}
                        mainRecordType={recordType}
                        tick={tick}
                        units={units}
                        theme={theme}
                        isCompact={useObservable(isCompactAxisLabel$).value}
                    />
                ),
            style: {
                axisThickness: kRightSecondaryAxisThickness,
            },
            theme: theme.chart,
        });
        rightTopAxis.syncThickness(rightBottomAxis);

        secondaryPlot = new PlotLayout({
            linkedLayouts: [{ layout: plot, axis: 'x' }],
            scale: secondaryScale$,
            anchor: { x: 0.5, y: 0 },
            xLayout,
            yLayout: new ScaleLayout({
                scale: new DiscreteScale(),
                controller: new FixedScaleController({
                    min: 1,
                    max: kNumSecondaryRecordTypes,
                    contentPaddingAbs: 0.5,
                }),
            }),
            verticalPanEnabled: false,
            axes: {
                bottomAxis,
                rightAxis: rightBottomAxis,
            },
            grid: {
                vertical: true,
            },
            dataSources: secondaryDataLayouts,
            theme: theme.chart,
        });
        plots.push(secondaryPlot);
        rowHeights = [{ flex: 1 }, { flex: 0.5 }];
    }

    const layout = new ChartLayout({
        rowHeights,
        plots,
        theme: theme.chart,
    });

    const xLayoutOwnerPlot = secondaryPlot || plot;

    const updateVisibleRegion = (options?: UpdateChartScrollOptions) => {
        const period = periods[periodState.periodIndex];
        const visibleDate =
            options?.date ||
            (() => {
                const visibleXRange =
                    xLayoutOwnerPlot.xLayout.getVisibleLocationRange();
                if (options?.snapToCurrentDate) {
                    const now = moment();
                    const nowLocation =
                        xLayoutOwnerPlot.xLayout.scale.locationOfValue(now);
                    const visibleRangeLen = visibleXRange[1] - visibleXRange[0];
                    const visibleLowTh =
                        visibleXRange[0] + visibleRangeLen * 0.3;
                    const visibleHighTh =
                        visibleXRange[0] + visibleRangeLen * 0.7;
                    if (
                        nowLocation >= visibleLowTh &&
                        nowLocation <= visibleHighTh
                    ) {
                        // Current moment is centered in view, zoom in to it
                        return now;
                    }
                }
                // Zoom based on the central date
                const visibleMid = (visibleXRange[1] + visibleXRange[0]) / 2;
                return xLayoutOwnerPlot.xLayout.scale.valueAtLocation(
                    visibleMid
                );
            })();
        const startDate = visibleDate.startOf(period);
        const endDate = startDate.clone().add(1, period);

        xLayoutOwnerPlot.scrollToValueRange(
            { x: startDate },
            { x: endDate },
            {
                animated: true,
                timing: {
                    duration: 600,
                },
                onEnd: ({ finished }) => {
                    if (finished) {
                        if (dateScaleInfo.recenteringOffset) {
                            dateScaleInfo.recenteringOffset = 0;
                            updateVisibleRegion({ date: visibleDate });
                        }
                    }
                },
            }
        );
    };

    const updatePeriodState = () => {
        Object.assign(
            periodState,
            createPeriodState(periodState.periodIndex, dateScaleInfo)
        );
        if (!_.isEqual(minorPeriod$.value, periodState.minorPeriod)) {
            // Minor period changed
            minorPeriod$.next(periodState.minorPeriod);
        }
    };

    return {
        plot,
        layout,
        dataLayout,
        labelDataLayout,
        secondaryDataLayouts: secondaryDataLayoutInfos,
        periodState,
        minorPeriod$,
        dateScaleInfo,
        visibleDataRange$,
        updateVisibleRegion,
        updatePeriodState,
    };
}

export function getSecondaryPointDiameter(
    value: number,
    average: number
): number {
    const mag = kSecondaryPointRadiusBase + Math.log(value / average) * 6;
    return Math.max(1, mag);
}

export const createSecondaryDataLayout = ({
    recordType,
    dataAverages,
    units,
    theme,
}: SecondaryDataSourceOptions): SecondaryDataSourceInfo => {
    const color = recordTypeColor(recordType, theme);
    const y = kSecondaryDataSourcePosition[recordType];
    const valueDataLayout = new LineDataSource<
        RecordChartDataPoint,
        Moment,
        number
    >({
        transform: data => ({
            x: data.x,
            y,
        }),
        style: {
            strokeWidth: 0,
            pointInnerColor: color,
            pointInnerRadius: kSecondaryPointRadiusBase,
        },
        itemStyle: p => ({
            pointInnerRadius: getSecondaryPointDiameter(
                p.y,
                dataAverages[recordType]
            ),
        }),
    });

    // Secondary label data
    const valueFormatter = chartValueFormatter(recordType, units);
    const labelDataLayout = new LabelDataSource<
        RecordChartDataPoint,
        Moment,
        number
    >({
        transform: data => ({ x: data.x, y }),
        style: {
            textStyle: labelTextStyle({ color, theme }),
            align: { x: 'left' },
            viewLayout: {
                anchor: { x: 0 },
            },
        },
        itemStyle: (data, index, style) => {
            style = style || {
                viewLayout: {
                    offset: {
                        x: new Animated.Value(kSecondaryValueLabelOffset),
                    },
                },
            };
            const diameter = getSecondaryPointDiameter(
                data.y,
                dataAverages[recordType]
            );

            (style.viewLayout!.offset!.x as Animated.Value).setValue(
                diameter / 2 + kSecondaryValueLabelOffset
            );
            return style;
        },
        getLabel: data => (data ? valueFormatter(data.y) : ''),
    });

    return {
        valueDataLayout,
        labelDataLayout,
    };
};

export function updateChartDataLayout(
    dataLayout: DataSource<RecordChartDataPoint, Moment, number>,
    data: RecordChartDataPoint[]
) {
    dataLayout.data = data;
    dataLayout.update({
        visible: true,
        forceRender: true,
    });
}
