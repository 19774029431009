import React from 'react';
import { StyleSheet, TouchableHighlightProps } from 'react-native';
import { Button } from 'react-native-form-model';
import { useTheme } from 'react-native-paper';

import { AppTheme } from '../const';
import { RefreshAction } from '../reactUtil';
import { RefreshIcon } from './assets/commonAssets';

export interface RefreshButtonProps
    extends Omit<TouchableHighlightProps, 'onPress'>,
        RefreshAction {
    diameter?: number;
    color?: string;
    rippleColor?: string;
}

export default function RefreshButton({
    coolingDown,
    refreshing,
    refreshComplete,
    onRefresh,
    diameter = 26,
    style,
    disabled,
    color,
    rippleColor,
    underlayColor,
    ...props
}: RefreshButtonProps) {
    const theme = useTheme() as AppTheme;
    color = disabled ? theme.colors.disabled : color || theme.colors.primary;

    const stateColor =
        refreshComplete && coolingDown ? theme.colors.textStronglyFaded : color;

    return (
        <Button
            {...props}
            onPress={() => onRefresh()}
            style={[
                styles.container,
                style,
                {
                    width: diameter,
                    height: diameter,
                    borderRadius: diameter / 2,
                    overflow: 'hidden',
                },
            ]}
            contentContainerStyle={{ width: diameter, height: diameter }}
            compact
            color={stateColor}
            title={props => <RefreshIcon color={stateColor} size={diameter} />}
            activityIndicatorSize={diameter}
            loading={refreshing}
            disabled={disabled || refreshing || coolingDown}
        />
    );
}

const styles = StyleSheet.create({
    container: {
        alignItems: 'center',
        justifyContent: 'center',
    },
});
