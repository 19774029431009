import { ENV } from '@byterium/glucose-diary-client';
import React from 'react';
import { TextProps } from 'react-native';
import { Caption } from 'react-native-paper';

import { navigate } from '../pages/navigate';
import { allowDevMenu } from '../services/env';

export interface VersionInfoProps extends TextProps {}

export default function VersionInfo({ onPress, ...props }: VersionInfoProps) {
    const infoBits = [
        ENV.EXPO_APP_VERSION,
        (ENV.VERCEL_GIT_COMMIT_SHA || '').slice(0, 7),
        ENV.VERCEL_GIT_COMMIT_REF,
    ].filter(x => !!x);
    return (
        <Caption
            {...props}
            onPress={event => {
                // Show dev page in dev and during beta
                if (allowDevMenu()) {
                    navigate('DeveloperSettings');
                }
                onPress?.(event);
            }}
        >
            {infoBits.join(' • ') || 'v?'}
        </Caption>
    );
}
