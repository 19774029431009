import { lz } from '@byterium/glucose-diary-client';
import React from 'react';
import { ScrollView, StyleSheet, View, ViewProps } from 'react-native';
import { Button } from 'react-native-form-model';
import {
    ActivityIndicator,
    Caption,
    Colors,
    Headline,
    Text,
    useTheme,
} from 'react-native-paper';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import AppBackground from '../components/AppBackground';
import AppFooter from '../components/AppFooter';
import { AppName } from '../components/AppName';
import CenteredContent from '../components/CenteredContent';
import { AppIcon } from '../components/assets/brandAssets';
import { AppTheme, kMaxPageWidth } from '../const';

export interface LoadingPageProps extends ViewProps {
    onRetry: () => void;
    error?: Error | string;
}

export default function LoadingPage({ error, onRetry }: LoadingPageProps) {
    const theme = useTheme() as AppTheme;
    const insets = useSafeAreaInsets();
    const errorMsg =
        (typeof error === 'object' &&
            error instanceof Error &&
            error?.message) ||
        error ||
        '';

    return (
        <View style={styles.container}>
            <AppBackground />
            <ScrollView
                style={styles.container}
                contentContainerStyle={[
                    styles.scrollContent,
                    {
                        paddingTop: insets.top,
                        paddingLeft: insets.left,
                        paddingRight: insets.right,
                        paddingBottom: insets.bottom,
                    },
                ]}
            >
                <CenteredContent
                    maxWidth={kMaxPageWidth}
                    style={styles.contentContainer}
                >
                    <AppIcon style={styles.appIcon} />
                    <AppName style={styles.appName} />

                    {errorMsg ? (
                        <View>
                            <Headline
                                style={[
                                    styles.message,
                                    { color: Colors.red800 },
                                ]}
                            >
                                {lz('somethingWentWrong')}
                            </Headline>
                            <Text
                                style={styles.message}
                                onPressIn={undefined}
                                onPressOut={undefined}
                            >
                                {errorMsg}
                            </Text>
                            <Text
                                style={styles.message}
                                onPressIn={undefined}
                                onPressOut={undefined}
                            >
                                {lz('tryAgainMessage') +
                                    ' ' +
                                    lz('contactUsIfIssuePersistsMessage')}
                            </Text>

                            <Button
                                compact
                                title={lz('retry')}
                                onPress={onRetry}
                                mode='contained'
                                style={styles.retryButton}
                            />
                        </View>
                    ) : (
                        <View>
                            <ActivityIndicator
                                size='large'
                                animating
                                color={theme.colors.accent}
                            />
                            <Caption style={styles.message}>
                                {lz('loadingPleaseWait', {
                                    default: 'Loading...',
                                })}
                            </Caption>
                        </View>
                    )}
                </CenteredContent>
                <AppFooter showContactButton style={styles.footer} />
            </ScrollView>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        maxHeight: '100%',
    },
    scrollContent: {
        flexGrow: 1,
        flexDirection: 'column',
        paddingVertical: 16,
        alignItems: 'center',
    },
    contentContainer: {
        flex: 1,
        alignItems: 'center',
    },
    appIcon: {
        marginTop: 0,
        marginRight: -20,
        marginBottom: -20,
        marginLeft: -20,
        height: 200,
        width: 200,
        alignSelf: 'center',
    },
    appName: {
        textAlign: 'center',
        marginBottom: 48,
    },
    message: {
        paddingVertical: 10,
        paddingHorizontal: 20,
        textAlign: 'center',
    },
    retryButton: {
        marginTop: 20,
        alignSelf: 'center',
        height: 40,
    },
    flex: {
        flex: 1,
    },
    footer: {
        margin: 16,
    },
});
