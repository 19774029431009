import {
    getAllSharedFitnessKits,
    isMockFitnessKit,
} from '@byterium/glucose-diary-client';
import React from 'react';
import { ImageStyle, StyleProp } from 'react-native';
import { FormModel, SectionModelOptions } from 'react-native-form-model';

import AppDelegate from '../../../AppDelegate';
import { ChevronIcon } from '../../../components/assets/commonAssets';
import { AppTheme } from '../../../const';
import { DeveloperSettingsNavigationProp } from '../../../pages/Settings/developer/DeveloperSettingsTypes';
import UserSettings from '../../UserSettings';
import { createRedirectURI } from '../../login/loginUtil';
import { addDataManagementSectionToForm } from './dataManagementForm';

const app = AppDelegate.shared();

export function addDeveloperSettingsSections(
    form: FormModel,
    {
        styles,
        theme,
        navigation,
        ...sectionOptions
    }: {
        theme: AppTheme;
        styles: { icon: StyleProp<ImageStyle> };
        navigation: DeveloperSettingsNavigationProp;
    } & Partial<SectionModelOptions>
) {
    form.addSection({ title: 'Info' }).modify(section => {
        section
            .addRow()
            .addLabel({
                title: 'Server URL',
                style: {
                    colors: { label: theme.colors.textSlightlyFaded },
                },
            })
            .addFlex()
            .addLabel(String(app.serverURI));

        section
            .addRow()
            .addLabel({
                title: 'Redirect URI',
                style: {
                    colors: { label: theme.colors.textSlightlyFaded },
                },
            })
            .addFlex()
            .addLabel(createRedirectURI());

        section
            .addRow({ onPress: () => navigation.push('DeveloperEnv') })
            .addLabel('Env')
            .addFlex()
            .addCustom(() => <ChevronIcon />);
    });

    addDataManagementSectionToForm({ form, navigation, theme });

    form.addSection({ title: 'Debug Login' }).modify(section => {
        section
            .addRow()
            .addLabel({ title: 'Email Dry Run', flex: 1 })
            .addSwitchInput(UserSettings._debugEmailLoginDryRun$);
    });

    form.addSection({
        ...sectionOptions,
        title: 'Debug Fitness',
        footer: 'Reload required for changes to take effect. Press on row to dump data to console.',
    }).modify(section => {
        for (const fitness of getAllSharedFitnessKits()) {
            section
                .addRow({
                    onPress: () => {
                        if (isMockFitnessKit(fitness)) {
                            console.debug(
                                `${
                                    fitness.name
                                } glucoseSamples: ${JSON.stringify(
                                    fitness.glucoseSamples,
                                    null,
                                    2
                                )}`
                            );
                        }
                    },
                })
                .addLabel({ title: fitness.name, flex: 1 })
                .addSwitchInput(fitness._debug$);
        }
    });
}
