import { User, lz } from '@byterium/glucose-diary-client';
import React from 'react';
import { ScrollView, StyleSheet, View } from 'react-native';
import { Button, Form } from 'react-native-form-model';
import { Banner, useTheme } from 'react-native-paper';

import AppBackground from '../../../components/AppBackground';
import CenteredContent from '../../../components/CenteredContent';
import { KeyboardAvoidingView } from '../../../components/KeyboardAvoidingView';
import { AppTheme, kMaxPageWidth } from '../../../const';
import { usePromise } from '../../../reactUtil';
import {
    LoginFormData,
    createFormLoginData,
    createLoginCodeForm,
} from '../../../services/forms/user/userForms';
import { LoginEmailCodePageOptions } from './loginEmailTypes';

export interface LoginEmailCodePageProps extends LoginEmailCodePageOptions {}

export default function LoginEmailCodePage(props: LoginEmailCodePageProps) {
    const {
        email,
        length: tokenLength,
        expiryInterval,
        navigation,
    } = {
        ...props,
        ...props.route.params,
    };

    const theme = useTheme() as AppTheme;

    const [action, setAction] = React.useState<Promise<any> | undefined>(
        undefined
    );
    const { loading = false, error } = usePromise(action);

    const data = React.useRef<LoginFormData>(
        createFormLoginData({ email })
    ).current;

    const form = React.useMemo(() => {
        const form = createLoginCodeForm({
            data,
            expiryInterval,
            tokenLength,
            theme,
            autoFocusToken: true,
        });
        form.validateAll({ focusOnInvalid: true });
        return form;
    }, [data, expiryInterval, theme, tokenLength]);

    const tryLogin = React.useCallback(() => {
        if (!form.validateAll({ focusOnInvalid: true })) {
            return;
        }
        setAction(
            User.login({
                email: data.email.value || '',
                token: data.token.value || '',
            })
        );
    }, [data.email.value, data.token.value, form]);

    React.useEffect(() => {
        form.onSubmit = tryLogin;
    }, [form, tryLogin]);

    return (
        <View style={styles.container}>
            <AppBackground />
            <KeyboardAvoidingView style={styles.container}>
                <ScrollView
                    style={styles.container}
                    contentContainerStyle={styles.scrollContent}
                >
                    <CenteredContent
                        maxWidth={kMaxPageWidth}
                        style={styles.content}
                    >
                        <Banner
                            visible={!!error}
                            actions={[
                                {
                                    label: lz('retry'),
                                    onPress: () => navigation.pop(),
                                },
                            ]}
                        >
                            {`${lz('somethingWentWrong')}: ${String(
                                error?.message || error || ''
                            )}`}
                        </Banner>

                        <Form form={form} style={styles.form} />

                        <Button
                            title={lz('login')}
                            icon={false}
                            disabled={loading}
                            loading={loading}
                            onPress={() => tryLogin()}
                            mode='contained'
                            style={styles.button}
                        />
                    </CenteredContent>
                </ScrollView>
            </KeyboardAvoidingView>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    scrollContent: {
        flexGrow: 1,
    },
    content: {
        margin: 12,
    },
    form: {
        marginVertical: 12,
    },
    section: {
        marginBottom: 10,
    },
    icon: {
        width: 24,
    },
    button: {
        marginTop: 8,
    },
});
