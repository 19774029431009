/* eslint-disable import/order */
import React from 'react';
import 'react-native-get-random-values';
import * as WebBrowser from 'expo-web-browser';

// Must import locale before other components
import './src/services/locale';

import * as SplashScreen from 'expo-splash-screen';
import { StatusBar } from 'expo-status-bar';
import {
    LayoutAnimation,
    Platform,
    UIManager,
    useColorScheme,
} from 'react-native';
import { Provider as PaperProvider } from 'react-native-paper';
import { SafeAreaProvider } from 'react-native-safe-area-context';

import AppDelegate from './src/AppDelegate';
import AppSnackbar from './src/components/AppSnackbar';
import CenteredContent from './src/components/CenteredContent';
import { darkTheme, kMaxPageWidth, lightTheme } from './src/const';
import Pages from './src/pages';
import LoadingPage from './src/pages/LoadingPage';
import { useLockBodyScroll } from './src/reactUtil';
import Updater from './src/services/Updater';
import UserSettings from './src/services/UserSettings';
import './src/services/forms/formSetup';
import {
    registerAppStateChanges,
    unregisterAppStateChanges,
} from './src/services/appState';
import RootBackground from './src/components/RootBackground';

import * as serviceWorkerRegistration from './src/serviceWorkerRegistration';

// Keep the splash screen visible during setup
SplashScreen.preventAutoHideAsync().catch(console.error);

try {
    WebBrowser.maybeCompleteAuthSession();
} catch (err) {
    console.error(err);
}

// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

if (Platform.OS === 'android') {
    if (UIManager.setLayoutAnimationEnabledExperimental) {
        UIManager.setLayoutAnimationEnabledExperimental(true);
    }
}

/** App root component. */
export default function App() {
    useLockBodyScroll();

    const [loadFinished, setLoadFinished] = React.useState(false);
    const [appReady, setAppReady] = React.useState(false);
    const [error, setError] = React.useState<Error | undefined>();
    const [retryAttempt, setRetryAttempt] = React.useState(0);

    React.useEffect(() => {
        async function prepare() {
            try {
                const loaded = await AppDelegate.shared().setup();
                await AppDelegate.shared().finishAllTasks();
                console.debug('App loaded successfully');
                LayoutAnimation.configureNext(
                    LayoutAnimation.Presets.easeInEaseOut
                );
                setAppReady(loaded);
            } catch (e: any) {
                LayoutAnimation.configureNext(
                    LayoutAnimation.Presets.easeInEaseOut
                );
                setError(e);
            } finally {
                await SplashScreen.hideAsync();
                setLoadFinished(true);
            }
        }

        prepare();
    }, [retryAttempt]);

    React.useEffect(() => {
        if (!loadFinished || error) {
            return;
        }
        registerAppStateChanges();
        Updater.enableAutoUpdateCheck();

        return () => unregisterAppStateChanges();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadFinished, error]);

    return (
        <ThemedApp
            loaded={appReady}
            error={error}
            onRetry={() => {
                LayoutAnimation.configureNext(
                    LayoutAnimation.Presets.easeInEaseOut
                );
                setError(undefined);
                setRetryAttempt(x => x + 1);
            }}
        />
    );
}

interface AppProps {
    onRetry: () => void;
    loaded?: boolean;
    error?: Error;
}

const ThemedApp = React.memo(({ onRetry, loaded, error }: AppProps) => {
    const colorSchemeSetting = UserSettings.useColorScheme();
    const colorSchemeSystem = useColorScheme();
    const colorScheme =
        colorSchemeSetting === 'auto'
            ? colorSchemeSystem || 'light'
            : colorSchemeSetting;
    const statusBarColorScheme = colorScheme === 'dark' ? 'light' : 'dark';
    const theme = colorScheme === 'dark' ? darkTheme : lightTheme;

    let content: React.ReactNode;
    if (error || !loaded) {
        content = <LoadingPage error={error} onRetry={onRetry} />;
    } else {
        content = <Pages />;
    }
    return (
        <PaperProvider theme={theme}>
            <StatusBar style={statusBarColorScheme} />
            <RootBackground color={theme.colors.background} />
            <SafeAreaProvider>
                {content}
                <CenteredContent maxWidth={kMaxPageWidth}>
                    <AppSnackbar />
                </CenteredContent>
            </SafeAreaProvider>
        </PaperProvider>
    );
});
