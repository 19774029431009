import { gql } from '@apollo/client';
import { AllRecords, AppDelegate } from '@byterium/glucose-diary-client';
import moment from 'moment';
import { FormModel } from 'react-native-form-model';
import { BehaviorSubject } from 'rxjs';

import { addErrorSnack, addSnack } from '../../../components/AppSnackbar';
import { AppTheme } from '../../../const';
import { DeveloperSettingsNavigationProp } from '../../../pages/Settings/developer/DeveloperSettingsTypes';
import { confirmation } from '../../alert';

export const ADD_DEMO_DATA = gql`
    mutation AddDemoData($days: Int, $utcOffset: Int) {
        replaceDemoData(days: $days, utcOffset: $utcOffset)
    }
`;

export const RESET_USER_DATA = gql`
    mutation ResetUserData {
        resetUserData
    }
`;

const app = AppDelegate.shared();

export function addDataManagementSectionToForm({
    form,
    navigation,
    theme,
}: {
    form: FormModel;
    navigation: DeveloperSettingsNavigationProp;
    theme: AppTheme;
}) {
    const demoDays = new BehaviorSubject(90);
    const activity = new BehaviorSubject(false);

    form.addSection({
        title: 'Data Management',
        footer: 'Adding demo data will delete all of your data and add the specified number of days of demo data to your account.',
    }).modify(section => {
        // Add Demo Data
        section
            .addRow()
            .addLabel({ title: 'Demo Data', flex: 1 })
            .addKeyboardInput({
                value: demoDays,
                type: 'integer',
                align: 'right',
                mode: 'contained',
            })
            .addButton({
                title: 'Add',
                loading: activity,
                onPress: () =>
                    (async () => {
                        const shouldAdd = await confirmation({
                            message: `You are about to delete all of your data and add ${demoDays.value} days of demo data to your account. Make sure you have disabled Apple Health sync on all devices before proceeding.`,
                        });
                        if (!shouldAdd) {
                            return;
                        }
                        activity.next(true);
                        let addedCount = 0;
                        try {
                            const res = await app.client.mutate({
                                mutation: ADD_DEMO_DATA,
                                variables: {
                                    days: demoDays.value,
                                    utcOffset: moment().utcOffset(),
                                },
                                fetchPolicy: 'no-cache',
                            });
                            addedCount = res.data?.replaceDemoData || 0;
                            await AllRecords.refetchAll();
                        } catch (err) {
                            console.error('Failed to add demo data', err);
                        } finally {
                            if (addedCount) {
                                addSnack({
                                    message: `Added ${addedCount} demo records`,
                                    action: {
                                        label: 'Home',
                                        onPress: () => navigation.popToTop(),
                                    },
                                });
                            } else {
                                addErrorSnack('Failed to add demo data');
                            }
                            activity.next(false);
                        }
                    })(),
            });

        // Reset Data
        section
            .addRow({
                onPress: () =>
                    (async () => {
                        const shouldReset = await confirmation({
                            message:
                                'Are you sure you want to delete all of your data?',
                        });
                        if (!shouldReset) {
                            return;
                        }
                        activity.next(true);
                        let deletedCount = 0;
                        try {
                            const res = await app.client.mutate({
                                mutation: RESET_USER_DATA,
                                fetchPolicy: 'no-cache',
                            });
                            deletedCount = res.data?.resetUserData || 0;
                            await AllRecords.refetchAll();
                        } catch (err) {
                            console.error('Failed to delete user data', err);
                        } finally {
                            if (deletedCount) {
                                addSnack({
                                    message: `Deleted ${deletedCount} records`,
                                    action: {
                                        label: 'Home',
                                        onPress: () => navigation.popToTop(),
                                    },
                                });
                            } else {
                                addErrorSnack('Failed to delete records');
                            }
                            activity.next(false);
                        }
                    })(),
            })
            .addLabel({
                title: 'Reset All Data',
                flex: 1,
                align: 'center',
                style: { colors: { label: theme.form.colors.input } },
            });
    });
}
