import {
    InsulinProducts,
    InsulinUnit,
    Schema,
    formatInsulinUnit,
    formatInsulinValue,
    isNormalInsulinValue,
    lz,
    parseInsulinValue,
} from '@byterium/glucose-diary-client';
import React from 'react';
import { ImageProps } from 'react-native';
import { InputFieldValueInfo } from 'react-native-form-model';

import { NotesIcon } from '../../../components/assets/commonAssets';
import { InsulinIcon } from '../../../components/assets/insulinAssets';
import { defaultSectionStyleWithValidation } from '../formUtil';
import {
    RecordFormData,
    getRecordFormValues,
    setRecordFormValues,
} from './recordFormData';
import { UpdateRecordFormOptions } from './recordFormTypes';
import { handleAbnormalValueIfNeeded } from './recordFormUtil';

export function Icon(props: Pick<ImageProps, 'style'>) {
    return <InsulinIcon {...props} />;
}

export function addInsulinSection({
    form,
    data,
    theme,
    styles,
    units: { insulinUnit },
    isNew,
    navigation,
}: UpdateRecordFormOptions) {
    form.addSection({
        style: defaultSectionStyleWithValidation(theme),
    }).modify(section => {
        section
            .addRow({
                onPress: isNew
                    ? () => {
                          if (!navigation) {
                              console.error(
                                  'No navigation property specified.'
                              );
                              return;
                          }
                          navigation.push('InsulinProductSearch', {});
                      }
                    : undefined,
            })
            .addCustom(() => <NotesIcon style={styles.icon} />)
            .setMargin(0)
            .addLabel(lz('medication'))
            .addInputLabel<Schema.InsulinProductFieldsFragment | undefined>({
                value: data.insulin.product,
                disabled: !isNew,
                parseInput: value => value,
                validation: value =>
                    value ? true : lz('mustSelectAMedication'),
                formatValue: product =>
                    product
                        ? InsulinProducts.formatProduct(product, {
                              concentration: true,
                          })
                        : '',
                placeholder: lz('choose'),
                align: 'right',
                flex: 1,
            });

        section
            .addRow()
            .addCustom(() => <InsulinIcon style={styles.icon} />)
            .setMargin(0)
            .addLabel({ title: lz('doseAmount'), flex: 1 })
            .addKeyboardInput<number | undefined>({
                value: data.insulin.doseAmount,
                type: 'unsignedFloat',
                disabled: !isNew,
                parseInput: x => parseInsulinValue(x, insulinUnit),
                validation: x =>
                    (x || 0) > 0 || lz('valueMustBeGT', { value: 0 }),
                formatValue: x => formatInsulinValue(x, insulinUnit),
                placeholder: formatInsulinValue(0, insulinUnit),
                flex: 1,
                align: 'right',
                mode: 'contained',
                autoFocus: isNew,
                selectTextOnFocus: true,
                onValueChange: info =>
                    _handleAbnormalValueIfNeeded({
                        ...info,
                        unit: insulinUnit,
                    }),
            })
            .setMargin(0)
            .addLabel({
                title: formatInsulinUnit(insulinUnit),
                color: theme.colors.textStronglyFaded,
            });

        section.footer = section.flattenedFormattedErrors$({
            editedOnly: true,
        });
    });
}

export function getInsulinFormValues(
    formData: RecordFormData
): Schema.AddInsulinDoseInput {
    return {
        ...getRecordFormValues(formData),
        doseAmount: formData.insulin.doseAmount.value || 0,
        productId: formData.insulin.product.value?.id || '',
    };
}

export function setInsulinFormValues(
    formData: RecordFormData,
    values: Schema.InsulinDoseFieldsFragment
) {
    setRecordFormValues(formData, values);
    formData.insulin.doseAmount.next(values.doseAmount);
    formData.insulin.product.next(values.product);
}

function _handleAbnormalValueIfNeeded({
    unit,
    ...options
}: InputFieldValueInfo<number | undefined> & { unit: InsulinUnit }) {
    handleAbnormalValueIfNeeded({
        ...options,
        isNormalValue: isNormalInsulinValue,
        displayValue: `${formatInsulinValue(
            options.value,
            unit
        )} ${formatInsulinUnit(unit)}`,
    });
}
