import {
    RecordType,
    formatCarbValue,
    formatEnergyValue,
    formatGlucoseValue,
    formatInsulinValue,
} from '@byterium/glucose-diary-client';
import {
    DateScale,
    dateUnitsWithDuration,
    floorDate,
    stepDateLinear,
} from '@byterium/librechart';
import moment, { Moment } from 'moment';
import { Animated, TextStyle } from 'react-native';
import { Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

import { AppTheme } from '../../const';
import { AnyDateUnit } from '../../dateFormat';
import { animatedObservable } from '../../reactUtil';
import { UserUnits } from '../../services/UserSettings';
import {
    DatePeriod,
    DateScaleInfo,
    RecordChartDataPoint,
    RecordChartLayoutOptions,
    RecordChartPeriodState,
    kBaseDateUnit,
    kBaseDateUnitMs,
    periods,
} from './chartConst';

export function getOriginDate() {
    return fixDateUTCOffset(moment()).startOf('year');
}

export const getMinorPeriod = (
    periodIndex: number,
    { majorInterval, minorCount, scale }: DateScaleInfo
): DatePeriod => {
    let maxMinorUnit: AnyDateUnit = periods[periodIndex - 1] || 'hour';
    if (maxMinorUnit === 'week') {
        maxMinorUnit = 'day';
    }
    if (
        !majorInterval ||
        !minorCount ||
        isNaN(majorInterval) ||
        isNaN(minorCount)
    ) {
        // No layout information yet
        return {
            unit: maxMinorUnit,
            value: 1,
        };
    }
    const [value, unit] = dateUnitsWithDuration(
        scale.minorTickScales[0]?.interval.value ||
            scale.tickScale.interval.value
    );
    return { value, unit };
};

export const createPeriodState = (
    periodIndex: number,
    dateScaleInfo: DateScaleInfo
): RecordChartPeriodState => {
    const minorPeriod = getMinorPeriod(periodIndex, dateScaleInfo);
    const minorPeriodDuration = moment.duration(
        minorPeriod.value,
        minorPeriod.unit
    );
    return {
        periodIndex,
        minorPeriod,
        minorPeriodDuration,
        minorPeriodMs: minorPeriodDuration.asMilliseconds(),
    };
};

export function getAverageY(points: RecordChartDataPoint[]): number {
    const c = points.length;
    if (c === 0) {
        return NaN;
    }
    return points.reduce((sum, p) => sum + p.y, 0) / c;
}
export function labelTextStyle({
    color,
    theme,
}: {
    color: string;
    theme: AppTheme;
}): TextStyle {
    return {
        color: color || undefined,
        fontWeight: 'bold',
        backgroundColor: theme.chart.backgroundColor + '80',
        paddingHorizontal: 5,
        paddingVertical: 3,
        borderRadius: theme.roundness,
    };
}

export function chartValueFormatter(
    recordType: RecordType,
    units: UserUnits
): (value: number) => string {
    switch (recordType) {
        case 'ActivitySession':
            return (value: number) =>
                formatEnergyValue(value, units.energyUnit);
        case 'GlucoseSample':
            return (value: number) =>
                formatGlucoseValue(value, units.glucoseUnit);
        case 'InsulinDose':
            return (value: number) =>
                formatInsulinValue(value, units.insulinUnit);
        case 'Meal':
            return (value: number) =>
                formatCarbValue(value, units.carbUnit, units.carbConfig);
    }
    throw new Error('Invalid sample type');
}

/**
 * Detects tops and bottoms in data.
 *
 * @param records
 */
export function detectPointTops(records: RecordChartDataPoint[]) {
    const len = records.length;
    if (len === 0) {
        return;
    } else if (len === 1) {
        records[0].isTop = true;
        return;
    }
    if (len > 2) {
        for (let i = 1; i < len - 1; i++) {
            const p0 = records[i - 1];
            const p1 = records[i];
            const p2 = records[i + 1];
            const t1 = moment.duration(p1.x.diff(p0.x)).as(kBaseDateUnit);
            if (t1 < 0) {
                throw new Error('Expected data to be in ascending date format');
            }
            const t2 = moment.duration(p2.x.diff(p1.x)).as(kBaseDateUnit);
            if (t2 < 0) {
                throw new Error('Expected data to be in ascending date format');
            }
            if (t1 !== 0 && t2 !== 0) {
                const v1 = (p1.y - p0.y) / t1;
                const v2 = (p2.y - p1.y) / t2;
                const a = v2 - v1;
                p1.isTop = a <= 0;
            } else {
                p1.isTop = p1.y >= p0.y;
            }
        }
    }
    records[0].isTop = records[0].y >= records[1].y;
    records[len - 1].isTop = records[len - 1].y >= records[len - 2].y;
}

/**
 * Determines whether to show labels on individual points
 * when the labels are positioned either above or below the
 * point.
 *
 * @param records
 */
export function updateVerticalLabelVisiblity(
    records: RecordChartDataPoint[],
    minDistance: number,
    scale: DateScale,
    topOnly: boolean
) {
    const len = records.length;
    if (len === 0) {
        return;
    } else if (len === 1) {
        records[0].showLabel = true;
        return;
    }

    let bestTop: RecordChartDataPoint | undefined;
    let bestTopX = 0;
    let bestBottom: RecordChartDataPoint | undefined;
    let bestBottomX = 0;
    for (const p of records) {
        p.showLabel = false;
        const x = scale.locationOfValue(p.x);
        if (topOnly || p.isTop) {
            if (!bestTop) {
                // Init top
                bestTop = p;
                bestTopX = x;
            } else if (x - bestTopX > minDistance) {
                // Distance far enough to show multiple top labels
                bestTop.showLabel = true;
                bestTop = p;
                bestTopX = x;
            } else if (p.y > bestTop.y) {
                // Found better top
                bestTop = p;
            }
        } else {
            if (!bestBottom) {
                // Init bottom
                bestBottom = p;
                bestBottomX = x;
            } else if (x - bestBottomX > minDistance) {
                // Distance far enough to show multiple bottom labels
                bestBottom.showLabel = true;
                bestBottom = p;
                bestBottomX = x;
            } else if (p.y < bestBottom.y) {
                // Found better bottom
                bestBottom = p;
            }
        }
    }
    if (bestTop) {
        bestTop.showLabel = true;
    }
    if (bestBottom) {
        bestBottom.showLabel = true;
    }
}

/**
 * Determines whether to show labels on individual points
 * when the labels are positioned to the right of the point
 *
 * @param records
 */
export function updateHorizontalLabelVisiblity(
    records: RecordChartDataPoint[],
    minDistance: number,
    scale: DateScale
) {
    const len = records.length;
    if (len === 0) {
        return;
    } else if (len === 1) {
        records[0].showLabel = true;
        return;
    }

    let bestPoint: RecordChartDataPoint | undefined;
    let bestPointX = 0;
    for (const p of records) {
        p.showLabel = false;
        const x = scale.locationOfValue(p.x);
        if (!bestPoint) {
            // Init point
            bestPoint = p;
            bestPointX = x;
        } else if (x - bestPointX > minDistance) {
            // Distance far enough to show multiple labels
            bestPoint.showLabel = true;
            bestPoint = p;
            bestPointX = x;
        } else {
            // This point covers the previous point
            bestPoint = p;
            bestPointX = x;
        }
    }
    if (bestPoint) {
        bestPoint.showLabel = true;
    }
}

/**
 * Collects ans sums or averages data (according to `mode`) in intervals
 * with the specified `period`.
 *
 * For `sum` mode, all points are tops as this is designed for bar charts.
 *
 * @param records Records in date ascending order.
 * @param period
 * @param mode
 * @returns A new collection of points.
 */
export function bucketPointData({
    records,
    period,
    xMode,
    yMode,
}: {
    records: RecordChartDataPoint[];
    period: DatePeriod;
    xMode: 'floor' | 'average';
    yMode: 'sum' | 'average';
}): RecordChartDataPoint[] {
    const isAverageX = xMode === 'average';
    const isAverageY = yMode === 'average';

    /** Used for x and y averages. */
    let bucketSize = 0;
    let bucketStartDate: Moment;
    let bucketEndDate: Moment;
    /** Used for x averages. */
    let bucketDateSum = 0;

    const getAveDate = () => {
        return moment((bucketDateSum / bucketSize) * kBaseDateUnitMs);
    };

    const getBucketAveEndDate = (startDate?: Moment) => {
        return (startDate || getAveDate()).add(period.value, period.unit);
    };

    const initBucketWithSideEffects = (p: RecordChartDataPoint) => {
        let x = p.x.clone();
        if (isAverageX) {
            bucketStartDate = x;
            bucketEndDate = getBucketAveEndDate(x.clone());
        } else {
            x = floorDate(x, period.value, period.unit);
            bucketStartDate = x;
            bucketEndDate = x.clone().add(period.value, period.unit);
            // Move bucket date to the middle
            x = stepDateLinear(x, period.value / 2, period.unit);
        }
        bucketSize = 1;
        bucketDateSum = p.x.valueOf() / kBaseDateUnitMs;
        return {
            id: bucketStartDate.toISOString(),
            x,
            y: p.y,
        };
    };

    const finishBucket = (p0: RecordChartDataPoint) => {
        if (isAverageX) {
            // Process average
            p0.x = getAveDate();
        }
        if (isAverageY) {
            // Process average
            p0.y /= bucketSize;
        }
    };

    const buckets = records.reduce<RecordChartDataPoint[]>((buckets, p) => {
        const c = buckets.length;
        if (c === 0) {
            // Start first bucket
            buckets.push(initBucketWithSideEffects(p));
            return buckets;
        }
        const p0 = buckets[c - 1];
        if (p.x.isSameOrAfter(bucketEndDate)) {
            // Next bucket
            finishBucket(p0);
            buckets.push(initBucketWithSideEffects(p));
            return buckets;
        } else {
            // Same bucket
            bucketSize += 1;
            bucketDateSum += p.x.valueOf() / kBaseDateUnitMs;
            if (isAverageX) {
                // Shift bucket end date
                bucketEndDate = getBucketAveEndDate();
            }
            p0.y += p.y;
            return buckets;
        }
    }, []);

    const len = buckets.length;
    if (len !== 0) {
        finishBucket(buckets[len - 1]);
    }
    return buckets;
}

export function rectDataTransForm(
    data: RecordChartDataPoint,
    minorPeriodMs: number,
    valueTransformer: RecordChartLayoutOptions['valueTransformer']
) {
    return {
        x: data.x.clone().subtract(minorPeriodMs * 0.4, 'ms'),
        y: 0,
        x2: data.x.clone().add(minorPeriodMs * 0.4, 'ms'),
        y2: valueTransformer(data.y),
    };
}

export function scaleViewToContent$(
    value: number,
    scale$: Animated.Value,
    hysteresisRatio: number
): Observable<number> {
    return animatedObservable(scale$).pipe(
        map(scale => value / Math.abs(scale)),
        distinctUntilChanged(
            (a, b) => Math.max(b / a - 1, a / b - 1) < hysteresisRatio
        )
    );
}

/**
 * Returns a new date with the UTC offset fixed,
 * i.e. removes DST rules.
 */
export function fixDateUTCOffset(date: Moment): Moment {
    date = date.clone();
    date.utcOffset(date.utcOffset());
    return date;
}
