import { lz } from '@byterium/glucose-diary-client';
import React from 'react';
import { ViewProps } from 'react-native';
import { Snackbar, Text, useTheme } from 'react-native-paper';
import { BehaviorSubject } from 'rxjs';

import { AppTheme, getInvertedTheme } from '../const';
import { useBehaviorSubject } from '../reactUtil';

const _stateNonce = new BehaviorSubject(0);
const _pendingItems: SnackbarItem[] = [];
let _currentItem: SnackbarItem | undefined;
let _snackbarCount = 0;

/**
 * Display a message on the bottom of the screen
 * in the form of a snackbar with an optional action.
 */
export const addSnack = (item: SnackbarItem | string) => {
    let normItem: SnackbarItem;
    if (typeof item === 'string') {
        normItem = { message: String(item) };
    } else {
        normItem = { ...item };
    }
    const len = _pendingItems.length;
    if (
        (len !== 0 && _pendingItems[len - 1].message === normItem.message) ||
        _currentItem?.message === normItem.message
    ) {
        // Filter repeated messages
        return;
    }
    _pendingItems.push(normItem);
    _stateNonce.next(_stateNonce.value + 1);
};

/**
 * Display an error message on the bottom of the screen
 * in the form of a snackbar with an optional action.
 */
export const addErrorSnack = (item: SnackbarItem | string) => {
    if (typeof item === 'string') {
        item = { message: item };
    }
    addSnack({
        ...item,
        type: 'error',
    });
};

export interface SnackbarItem {
    message: string;
    type?: 'info' | 'error';

    /**
     * The time, in milliseconds, until the message
     * is dismissed automatically.
     *
     * Set to a negative number to prevent automatic dismiss.
     */
    duration?: number;

    /**
     * Label and press callback for the action button. It should contain the following properties:
     * - `label` - Label of the action button
     * - `onPress` - Callback that is called when action button is pressed.
     */
    action?: {
        label?: string;
        accessibilityLabel?: string;
        onPress?: () => void;
    };
}

export interface AppSnackbarProps extends ViewProps {}

export default function AppSnackbar() {
    const theme = useTheme() as AppTheme;
    const invertedTheme = getInvertedTheme(theme);

    const [currentItem = { message: '' }, setCurrentItem] = React.useState<
        SnackbarItem | undefined
    >(undefined);

    const [snackbackVisible, setSnackbackVisible] = React.useState(false);
    const stateNonce = useBehaviorSubject(_stateNonce);

    React.useEffect(() => {
        if (!snackbackVisible) {
            // Get next item
            _currentItem = _pendingItems.shift();
            if (_currentItem) {
                setCurrentItem(_currentItem);
                setSnackbackVisible(true);
            }
        }
    }, [stateNonce, snackbackVisible]);

    React.useEffect(() => {
        _snackbarCount += 1;

        if (_snackbarCount > 1) {
            console.error(
                'There are multiple instances of AppSnackbar. Only one can be used at a time.'
            );
        }
        return () => {
            _snackbarCount -= 1;
        };
    }, []);

    const next = () => {
        setSnackbackVisible(false);
    };

    const { type = 'info' } = currentItem;
    let contentColor = invertedTheme.colors.text;
    switch (type) {
        case 'info':
            contentColor = invertedTheme.colors.text;
            break;
        case 'error':
            contentColor = theme.colors.onSurfaceError;
            break;
    }

    let { duration = 5000 } = currentItem;
    if (duration < 0) {
        duration = 1e10;
    }

    return (
        <Snackbar
            visible={snackbackVisible}
            action={{
                label: currentItem.action?.label || lz('dismiss'),
                accessibilityLabel:
                    currentItem.action?.accessibilityLabel ||
                    currentItem.action?.label ||
                    lz('dismiss'),
                onPress: () => {
                    next();
                    if (currentItem.action?.onPress) {
                        try {
                            currentItem.action.onPress();
                        } catch (err) {
                            console.error('Uncaught error: ' + err);
                        }
                    }
                },
            }}
            onDismiss={next}
            duration={duration}
            // theme={{
            //     colors: {
            //         primary: invertedTheme.form.colors.input,
            //         accent: invertedTheme.form.colors.input,
            //     },
            // }}
        >
            <Text
                style={{ color: contentColor }}
                onPressIn={undefined}
                onPressOut={undefined}
            >
                {currentItem.message || ''}
            </Text>
        </Snackbar>
    );
}
