import {
    AppDelegate,
    FitnessBase,
    FitnessSyncInterval,
    fitnessSyncIntervalMs,
    formatFitnessRecordSyncInterval,
    kZeroFitnessSyncInterval,
    lz,
} from '@byterium/glucose-diary-client';
import _ from 'lodash';
import React from 'react';
import { ImageStyle, StyleProp } from 'react-native';
import {
    FormModel,
    PartialFormStyle,
    SectionModelOptions,
} from 'react-native-form-model';
import { ActivityIndicator } from 'react-native-paper';
import { distinctUntilChanged, map } from 'rxjs/operators';

import { CloudIcon, ExitIcon } from '../../../components/assets/commonAssets';
import { GlucoseIcon } from '../../../components/assets/glucoseAssets';
import { MealIcon } from '../../../components/assets/mealAssets';
import { AppTheme } from '../../../const';
import { FitnessSettingsFormData } from '../../../pages/Settings/sync/syncSettingsData';
import { useBehaviorSubject } from '../../../reactUtil';
import { addDateRowsToFromSection } from '../dateForms';

export const kFitnessSyncIntervals: FitnessSyncInterval[] = [
    kZeroFitnessSyncInterval,
    { value: 2, unit: 'week' },
    { value: 3, unit: 'month' },
    { value: 6, unit: 'month' },
];

export const kDefaultFitnessSyncInterval: FitnessSyncInterval = {
    value: 2,
    unit: 'week',
};

if (!_.find(kFitnessSyncIntervals, kDefaultFitnessSyncInterval)) {
    throw new Error('Default interval not in list');
}

export function addFitnessSettingsSections(
    options: {
        fitness: FitnessBase;
        form: FormModel;
        data: FitnessSettingsFormData;
        theme: AppTheme;
        styles: { icon: StyleProp<ImageStyle> };
    } & Partial<SectionModelOptions>
) {
    addFitnessButtonSection(options);
    addFitnessPermissionSection(options);
    addFitnessImportSection(options);
    addFitnessExportSection(options);
}

export function addFitnessButtonSection({
    fitness,
    form,
    theme,
    styles,
    ...sectionOptions
}: {
    fitness: FitnessBase;
    form: FormModel;
    theme: AppTheme;
    styles: { icon: StyleProp<ImageStyle> };
} & Partial<SectionModelOptions>) {
    const buttonLabelStyle: PartialFormStyle = {
        colors: { label: theme.form.colors.input },
    };

    form.addSection({
        ...sectionOptions,
        style: { ...sectionOptions.style, sectionFooterAlign: 'center' },
        footer: lz('permissionsDescripton', {
            appName: AppDelegate.shared().name,
            fitnessName: fitness.name,
        }),
    })
        .addRow({ onPress: () => fitness.openSystemSettings() })
        .addFlex()
        .addLabel({
            title: lz('openTitle', { title: fitness.name }),
            style: buttonLabelStyle,
        })
        .setMargin(0)
        .addCustom(() => <ExitIcon style={styles.icon} />)
        .addFlex();
}

export function addFitnessPermissionSection({
    fitness,
    form,
    theme,
    styles,
    ...sectionOptions
}: {
    fitness: FitnessBase;
    form: FormModel;
    theme: AppTheme;
    styles: { icon: StyleProp<ImageStyle> };
} & Partial<SectionModelOptions>) {
    form.addSection({
        ...sectionOptions,
        style: { ...sectionOptions.style, sectionFooterAlign: 'center' },
        footer: [
            lz('syncRecordsDescripton', {
                appName: AppDelegate.shared().name,
                fitnessName: fitness.name,
            }),
            lz('syncMoreRecordsDescripton'),
        ].join('\n\n'),
    }).modify(section => {
        section
            .addRow()
            .addCustom(() => (
                <CloudIcon
                    done={!!useBehaviorSubject(fitness.sync$)}
                    style={styles.icon}
                />
            ))
            .setMargin(0)
            .addLabel(lz('sync'))
            .addOptionInput<FitnessSyncInterval | undefined>({
                value: fitness.sync$,
                mode: 'dialog',
                prompt: lz('sync'),
                possibleValues: _.sortBy(
                    _.uniqBy(
                        [fitness.sync$.value, ...kFitnessSyncIntervals],
                        fitnessSyncIntervalMs
                    ),
                    fitnessSyncIntervalMs
                ),
                serializer: fitnessSyncIntervalMs,
                formatValue: x => formatFitnessRecordSyncInterval(x),
                flex: 1,
                align: 'right',
            });
    });
}

export function addFitnessImportSection(
    options: {
        fitness: FitnessBase;
        form: FormModel;
        data: FitnessSettingsFormData;
        theme: AppTheme;
        styles: { icon: StyleProp<ImageStyle> };
    } & Partial<SectionModelOptions>
) {
    const { fitness, form, data, theme, styles, ...sectionOptions } = options;

    const buttonLabelStyle: PartialFormStyle = {
        colors: { label: theme.form.colors.input },
    };

    form.addSection({
        ...sectionOptions,
        style: { ...sectionOptions.style, sectionFooterAlign: 'center' },
        title: lz('importData'),
    }).modify(section => {
        addDateRowsToFromSection({ section, ...data.import, styles });

        // GlucoseSample
        const importingGlucoseSample$ = data.import.importing.pipe(
            map(x => x.GlucoseSample),
            distinctUntilChanged()
        );
        section
            .addRow({
                onPress: () =>
                    !data.import.importing.value.GlucoseSample &&
                    data.import.beginImport('GlucoseSample'),
            })
            .addCustom(() => <GlucoseIcon style={styles.icon} />)
            .setMargin(0)
            .addLabel({
                title: lz('bloodGlucose'),
                style: {
                    colors: { label: theme.colors.glucose },
                },
                flex: 1,
            })
            .addCustom({
                render: props => (
                    <ActivityIndicator {...props} size={24} animating />
                ),
                visible: importingGlucoseSample$,
            })
            .addLabel({
                title: lz('import'),
                style: buttonLabelStyle,
                align: 'right',
                visible: importingGlucoseSample$.pipe(map(x => !x)),
            });

        // Meal
        const importingMeal$ = data.import.importing.pipe(
            map(x => x.Meal),
            distinctUntilChanged()
        );
        section
            .addRow({
                onPress: () =>
                    !data.import.importing.value.Meal &&
                    data.import.beginImport('Meal'),
            })
            .addCustom(() => <MealIcon style={styles.icon} />)
            .setMargin(0)
            .addLabel({
                title: lz('carbohydrates'),
                style: {
                    colors: { label: theme.colors.meal },
                },
                flex: 1,
            })
            .addCustom({
                render: props => (
                    <ActivityIndicator {...props} size={24} animating />
                ),
                visible: importingMeal$,
            })
            .addLabel({
                title: lz('import'),
                style: buttonLabelStyle,
                align: 'right',
                visible: importingMeal$.pipe(map(x => !x)),
            });

        section.footer = section
            .flattenedFormattedErrors$({
                editedOnly: true,
            })
            .pipe(
                map(errorStr =>
                    [
                        lz('importFitnessButtonMessage', {
                            fitnessName: fitness.name,
                        }),
                        lz('importFitnessDataRequirementsMessage', {
                            appName: AppDelegate.shared().name,
                            fitnessName: fitness.name,
                        }),
                        errorStr,
                    ]
                        .filter(x => !!x)
                        .join('\n\n')
                )
            );
    });
}

export function addFitnessExportSection(
    options: {
        fitness: FitnessBase;
        form: FormModel;
        data: FitnessSettingsFormData;
        theme: AppTheme;
        styles: { icon: StyleProp<ImageStyle> };
    } & Partial<SectionModelOptions>
) {
    const { fitness, form, data, theme, styles, ...sectionOptions } = options;

    const buttonLabelStyle: PartialFormStyle = {
        colors: { label: theme.form.colors.input },
    };
    form.addSection({
        ...sectionOptions,
        style: { ...sectionOptions.style, sectionFooterAlign: 'center' },
        title: lz('exportData'),
    }).modify(section => {
        addDateRowsToFromSection({ section, ...data.export, styles });

        // GlucoseSample
        const exportingGlucoseSample$ = data.export.exporting.pipe(
            map(x => x.GlucoseSample),
            distinctUntilChanged()
        );
        section
            .addRow({
                onPress: () =>
                    !data.export.exporting.value.GlucoseSample &&
                    data.export.beginExport('GlucoseSample'),
            })
            .addCustom(() => <GlucoseIcon style={styles.icon} />)
            .setMargin(0)
            .addLabel({
                title: lz('bloodGlucose'),
                style: {
                    colors: { label: theme.colors.glucose },
                },
                flex: 1,
            })
            .addCustom({
                render: props => (
                    <ActivityIndicator {...props} size={24} animating />
                ),
                visible: exportingGlucoseSample$,
            })
            .addLabel({
                title: lz('export'),
                style: buttonLabelStyle,
                align: 'right',
                visible: exportingGlucoseSample$.pipe(map(x => !x)),
            });

        // Meal
        const exportingMeal$ = data.export.exporting.pipe(
            map(x => x.Meal),
            distinctUntilChanged()
        );
        section
            .addRow({
                onPress: () =>
                    !data.export.exporting.value.Meal &&
                    data.export.beginExport('Meal'),
            })
            .addCustom(() => <MealIcon style={styles.icon} />)
            .setMargin(0)
            .addLabel({
                title: lz('carbohydrates'),
                style: {
                    colors: { label: theme.colors.meal },
                },
                flex: 1,
            })
            .addCustom({
                render: props => (
                    <ActivityIndicator {...props} size={24} animating />
                ),
                visible: exportingMeal$,
            })
            .addLabel({
                title: lz('export'),
                style: buttonLabelStyle,
                align: 'right',
                visible: exportingMeal$.pipe(map(x => !x)),
            });

        section.footer = section
            .flattenedFormattedErrors$({
                editedOnly: true,
            })
            .pipe(
                map(errorStr =>
                    [
                        lz('exportFitnessButtonMessage', {
                            fitnessName: fitness.name,
                        }),
                        lz('exportFitnessDataRequirementsMessage', {
                            appName: AppDelegate.shared().name,
                            fitnessName: fitness.name,
                        }),
                        errorStr,
                    ]
                        .filter(x => !!x)
                        .join('\n\n')
                )
            );
    });
}
