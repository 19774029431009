import {
    EnergyUnit,
    Schema,
    formatEnergyUnit,
    formatEnergyValue,
    isNormalEnergyValue,
    lz,
    parseEnergyValue,
} from '@byterium/glucose-diary-client';
import React from 'react';
import { ImageProps } from 'react-native';
import { InputFieldValueInfo } from 'react-native-form-model';

import { ActivityIcon } from '../../../components/assets/activityAssets';
import { NotesIcon } from '../../../components/assets/commonAssets';
import { defaultSectionStyleWithValidation } from '../formUtil';
import {
    RecordFormData,
    getRecordFormValues,
    setRecordFormValues,
} from './recordFormData';
import { UpdateRecordFormOptions } from './recordFormTypes';
import { handleAbnormalValueIfNeeded } from './recordFormUtil';

export function Icon(props: Pick<ImageProps, 'style'>) {
    return <ActivityIcon {...props} />;
}

export function addActivitySection({
    form,
    data,
    theme,
    styles,
    units: { energyUnit },
    isNew,
}: UpdateRecordFormOptions) {
    form.addSection({
        style: defaultSectionStyleWithValidation(theme),
    }).modify(section => {
        section
            .addRow()
            .addCustom(() => <NotesIcon style={styles.icon} />)
            .setMargin(0)
            .addLabel(lz('activeEnergy'))
            .addKeyboardInput<number | undefined>({
                value: data.activity.energy,
                type: 'unsignedFloat',
                disabled: !isNew,
                parseInput: x => parseEnergyValue(x, energyUnit),
                validation: x =>
                    (x || 0) > 0 || lz('activeEnergyValueGT', { value: 0 }),
                formatValue: x => formatEnergyValue(x, energyUnit),
                placeholder: formatEnergyValue(0, energyUnit),
                flex: 1,
                align: 'right',
                mode: 'contained',
                autoFocus: isNew,
                selectTextOnFocus: true,
                onValueChange: info =>
                    _handleAbnormalValueIfNeeded({
                        ...info,
                        unit: energyUnit,
                    }),
            })
            .setMargin(0)
            .addLabel({
                title: formatEnergyUnit(energyUnit),
                color: theme.colors.textStronglyFaded,
            });
        section.footer = section.flattenedFormattedErrors$({
            editedOnly: true,
        });
    });

    form.addSection()
        .addRow()
        .addCustom(() => <ActivityIcon style={styles.icon} />)
        .setMargin(0)
        .addLabel(lz('description'))
        .addKeyboardInput({
            value: data.activity.description,
            disabled: !isNew,
            flex: 1,
            align: 'right',
            mode: 'contained',
        });
}

export function getActivityFormValues(
    formData: RecordFormData
): Schema.AddActivitySessionInput {
    return {
        ...getRecordFormValues(formData),
        energy: formData.activity.energy.value || 0,
        description: formData.activity.description.value,
    };
}

export function setActivityFormValues(
    formData: RecordFormData,
    values: Omit<Schema.ActivitySessionFieldsFragment, '__typename'>
) {
    setRecordFormValues(formData, values);
    formData.activity.energy.next(values.energy);
    formData.activity.description.next(values.description || '');
}

function _handleAbnormalValueIfNeeded({
    unit,
    ...options
}: InputFieldValueInfo<number | undefined> & { unit: EnergyUnit }) {
    handleAbnormalValueIfNeeded({
        ...options,
        isNormalValue: isNormalEnergyValue,
        displayValue: `${formatEnergyValue(
            options.value,
            unit
        )} ${formatEnergyUnit(unit)}`,
    });
}
