import {
    RecordType,
    formatRecordType,
    getRecordDataSource,
    getSharedFitnessKit,
    kFitnessKitTypes,
    kRecordTypes,
    lz,
} from '@byterium/glucose-diary-client';
import React from 'react';
import {
    FormModel,
    SectionModel,
    useBehaviorSubject,
} from 'react-native-form-model';
import { Colors } from 'react-native-paper';

import { FitnessKitLogo } from '../../../components/FitnessKitLogo';
import {
    CheckmarkIcon,
    ChevronIcon,
    Circle,
    CrossIcon,
    NotesIcon,
} from '../../../components/assets/commonAssets';
import { recordTypeColor } from '../../../components/assets/recordAssets';
import { navigate } from '../../../pages/navigate';
import { addDateSectionToFrom } from '../dateForms';
import * as Activity from './activityForms';
import * as Glucose from './glucoseForms';
import * as Insulin from './insulinForms';
import * as Meal from './mealForms';
import { CreateRecordFormOptions } from './recordFormTypes';

const kRecordTypeRowHeight = 58;
const kRecordTypeCircleDiameter = 36;

export function createRecordForm(options: CreateRecordFormOptions): FormModel {
    const { data, theme, recordType, styles, isNew, navigation } = options;
    const form = FormModel.create({ style: theme.form });
    const typeColor = recordTypeColor(recordType, theme);

    form.addSection()
        .addRow({
            style: {
                rowHeight: kRecordTypeRowHeight,
            },
        })
        .addCustom(() => {
            let icon: React.ReactNode = null;
            switch (recordType) {
                case 'ActivitySession':
                    icon = <Activity.Icon style={styles.icon} />;
                    break;
                case 'GlucoseSample':
                    icon = <Glucose.Icon style={styles.icon} />;
                    break;
                case 'InsulinDose':
                    icon = <Insulin.Icon style={styles.icon} />;
                    break;
                case 'Meal':
                    icon = <Meal.Icon style={styles.icon} />;
                    break;
            }
            return (
                <Circle
                    strokeColor={typeColor}
                    diameter={kRecordTypeCircleDiameter}
                >
                    {icon}
                </Circle>
            );
        })
        .setMargin(0)
        .addOptionInput<RecordType>({
            value: data.recordType,
            disabled: !isNew,
            mode: 'dropdown',
            possibleValues: [...kRecordTypes],
            formatValue: x => formatRecordType(x),
            style: {
                inputFontWeight: 'bold',
                colors: {
                    input: typeColor,
                    disabled: typeColor,
                },
            },
            flex: 1,
        });

    addDateSectionToFrom({
        form,
        ...data.common,
        disabled: !isNew,
        theme,
        styles,
    });

    switch (recordType) {
        case 'ActivitySession':
            Activity.addActivitySection({ ...options, form });
            break;

        case 'GlucoseSample':
            Glucose.addGlucoseSection({ ...options, form });
            break;

        case 'InsulinDose':
            Insulin.addInsulinSection({ ...options, form });
            break;

        case 'Meal':
            Meal.addMealSection({ ...options, form });
            break;
    }

    form.addSection()
        .addRow({ style: { marginTop: 0, marginBottom: 0 } })
        .setLine({ lineHeight: 50 })
        .addCustom(() => <NotesIcon style={styles.icon} />)
        .setMargin(0)
        .addLabel(lz('notes'))
        .addLine({
            lineHeight: 150,
            marginBottom: theme.form.marginBottom - 2,
        })
        .addKeyboardInput({
            value: data.common.notes,
            multiline: true,
            submitOnChangeValue: true,
            flex: 1,
            style: {
                colors: { input: theme.colors.text },
                fontSize: 14,
            },
            mode: 'contained',
        });

    let syncSection: SectionModel | undefined;
    const records = getRecordDataSource(recordType);
    if (!isNew && records.supportsFitnessSync()) {
        for (const fitnessType of kFitnessKitTypes) {
            const fitness = getSharedFitnessKit(fitnessType);
            if (!fitness.supported && !fitness._debug$.value) {
                continue;
            }
            if (!syncSection) {
                syncSection = form.addSection({ title: lz('cloudSync') });
            }
            const section = syncSection;
            section
                .addRow({
                    onPress: () =>
                        navigation
                            ? navigation.navigate('FitnessSettings', {
                                  fitnessType,
                              })
                            : navigate('FitnessSettings', {
                                  fitnessType,
                              }),
                })
                .addCustom(() => (
                    <FitnessKitLogo
                        fitnessType={fitnessType}
                        style={styles.icon}
                    />
                ))
                .setMargin(0)
                .addLabel({
                    title: fitness.name,
                    flex: 1,
                })
                .addCustom(() => {
                    const isSaved = useBehaviorSubject(
                        data.fitness[fitness.recordIdKey]
                    );
                    return isSaved ? (
                        <CheckmarkIcon
                            filled
                            color={Colors.green500}
                            style={styles.icon}
                        />
                    ) : (
                        <CrossIcon
                            filled
                            color={Colors.red500}
                            style={styles.icon}
                        />
                    );
                })
                .setMargin(0)
                .addCustom(() => <ChevronIcon />);
        }
    }

    return form;
}
