import React from 'react';
import { Animated } from 'react-native';

import { useImport } from '../../reactUtil';
import { PngAssetProps } from './assetConst';

export const AppIcon = React.memo<Animated.AnimatedProps<PngAssetProps>>(
    props => {
        const _AppIcon = useImport(AppIcon$).default || null;
        return _AppIcon ? (
            <Animated.Image {...props} source={_AppIcon} />
        ) : (
            <Animated.View {...props} />
        );
    }
);
const AppIcon$ = () => import('../../../assets/adaptive-icon.png');
