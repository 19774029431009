import { AppDelegate, lz } from '@byterium/glucose-diary-client';
import { BehaviorSubject } from 'rxjs';

import { addErrorSnack, addSnack } from '../../components/AppSnackbar';

export default abstract class UpdaterBase {
    readonly isUpdateAvailable$ = new BehaviorSubject(false);

    private _autoUpdateCheckEnabled = false;

    get isUpdateAvailable(): boolean {
        return this.isUpdateAvailable$.value;
    }

    setUpdateAvailable(flag: boolean) {
        if (flag === this.isUpdateAvailable$.value) {
            return;
        }
        this.isUpdateAvailable$.next(flag);
    }

    abstract get isSupported(): boolean;
    abstract get downloadRequired(): boolean;

    abstract _executeUpdateCheck(): Promise<void>;

    abstract downloadUpdate(): Promise<void>;

    abstract installUpdate(): Promise<void>;

    private _updateCheck$?: Promise<void>;

    onUpdateAvailable() {
        console.info('An new version is available');
        addSnack({
            message: lz('newAppVersionAvailableMessage'),
            action: {
                label: lz('update'),
                onPress: () =>
                    (async () => {
                        try {
                            await this.downloadUpdate();
                            try {
                                await this.installUpdate();
                            } catch (err) {
                                console.error(
                                    'Failed to install udpate: ' + err
                                );
                                addErrorSnack(lz('installUpdateFailedMessage'));
                            }
                        } catch (err) {
                            console.error('Failed to download udpate: ' + err);
                            addErrorSnack(lz('downloadUpdateFailedMessage'));
                        }
                    })(),
            },
        });
    }

    async checkForUpdate(options?: { ignoreErrors?: boolean }): Promise<void> {
        if (this.isUpdateAvailable) {
            return;
        }
        if (!this._updateCheck$) {
            let check = this._executeUpdateCheck();
            if (options?.ignoreErrors) {
                check = check.catch(() => {});
            }
            check = check.finally(() => {
                this._updateCheck$ = undefined;
                if (this.isUpdateAvailable) {
                    this.onUpdateAvailable();
                }
            });
            this._updateCheck$ = check;
        }
        return this._updateCheck$;
    }

    async isUpdateAvailableAsync(options?: {
        ignoreErrors?: boolean;
    }): Promise<boolean> {
        await this.checkForUpdate(options);
        return this.isUpdateAvailable;
    }

    /**
     * Checks for updates immedidately and each
     * time the app becomes active.
     *
     * @returns
     */
    enableAutoUpdateCheck() {
        if (this._autoUpdateCheckEnabled) {
            return;
        }
        this._autoUpdateCheckEnabled = true;

        if (AppDelegate.shared().isActive$.value) {
            this.checkForUpdate({ ignoreErrors: true });
        }

        AppDelegate.shared().addStateTask(
            ({ active }) =>
                active && this.checkForUpdate({ ignoreErrors: true })
        );
    }
}
