import {
    CarbConfig,
    CarbUnit,
    EnergyUnit,
    FitnessSyncInterval,
    GlucoseUnit,
    InsulinUnit,
    User,
    getCurrentLocale,
    isFitnessSyncInterval,
    kBaseGlucoseUnit,
    kBaseInsulinUnit,
    kDefaultCarbConfigs,
    kDefaultEnergyUnits,
    lz,
} from '@byterium/glucose-diary-client';
import DeepStorageAdapter, { IKeyStorage } from 'deep-storage-adapter';
import _ from 'lodash';
import { BehaviorSubject, Observable, Subscription, combineLatest } from 'rxjs';
import {
    debounceTime,
    distinctUntilChanged,
    filter,
    map,
    skip,
} from 'rxjs/operators';

import { addSnack } from '../components/AppSnackbar';
import { ColorScheme, kDefaultColorScheme } from '../const';
import { useBehaviorSubject, useObservable } from '../reactUtil';

const kDefaultUserSettingsStorageKey = '__settings';
const kSharedSettingsStorageKey = '__shared';
const kSaveDebounceInterval = 500;
const locale = getCurrentLocale({ basic: true }) || 'en';

export interface UserUnits {
    glucoseUnit: GlucoseUnit;
    insulinUnit: InsulinUnit;
    carbUnit: CarbUnit;
    carbConfig: CarbConfig;
    energyUnit: EnergyUnit;
}

export const kBaseUserUnits: UserUnits = {
    glucoseUnit: kBaseGlucoseUnit,
    insulinUnit: kBaseInsulinUnit,
    carbUnit: 'units',
    carbConfig: kDefaultCarbConfigs[locale],
    energyUnit: kDefaultEnergyUnits[locale],
};

export const kUserSettingDefaults = {
    syncHealthKit$: undefined as FitnessSyncInterval | undefined,
    syncGoogleFit$: undefined as FitnessSyncInterval | undefined,
    colorScheme$: kDefaultColorScheme,
    glucoseUnit$: kBaseUserUnits.glucoseUnit,
    insulinUnit$: kBaseUserUnits.insulinUnit,
    carbUnit$: kBaseUserUnits.carbUnit,
    carbsPerUnit$: kBaseUserUnits.carbConfig.carbsPerUnit,
    energyUnit$: kBaseUserUnits.energyUnit,
    glucoseTargetLowerLimit$: 5,
    glucoseTargetUpperLimit$: 9,

    _debugEmailLoginDryRun$: false,
    _debugHealthKit$: false,
    _debugGoogleFit$: false,
};

export type UserSettingsBaseType = typeof kUserSettingDefaults;

export type UserSettingKey = keyof UserSettingsBaseType;

export const kUserSettingsKeys = Object.keys(
    kUserSettingDefaults
) as readonly UserSettingKey[];

export const kSharedSettingsKeys: readonly UserSettingKey[] = [
    'colorScheme$',
    '_debugEmailLoginDryRun$',
    '_debugHealthKit$',
    '_debugGoogleFit$',
];

export type UserSettingsType = {
    [K in UserSettingKey]: BehaviorSubject<UserSettingsBaseType[K]>;
};

const kValueCustomizers: Partial<
    {
        [K in UserSettingKey]: (value: any) => UserSettingsBaseType[K];
    }
> = {
    syncHealthKit$: fitnessSyncIntervalLoader,
    syncGoogleFit$: fitnessSyncIntervalLoader,
};

function fitnessSyncIntervalLoader(value: any) {
    // Make sure we load an interval
    return isFitnessSyncInterval(value) ? value : undefined;
}

/**
 * Holds user settings.
 *
 * All user setting attribute names must end with a `$`.
 */
export class UserSettings implements UserSettingsType {
    // General
    saveDebounceInterval = kSaveDebounceInterval;

    // Syncronization

    /** Whether to automatically add and delete Health Kit records. */
    syncHealthKit$ = new BehaviorSubject(kUserSettingDefaults.syncHealthKit$);

    /** Whether to automatically add and delete Google Fit records. */
    syncGoogleFit$ = new BehaviorSubject(kUserSettingDefaults.syncGoogleFit$);

    /** User's theme color setting. */
    colorScheme$ = new BehaviorSubject<ColorScheme>(
        kUserSettingDefaults.colorScheme$
    );

    useColorScheme() {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        return useBehaviorSubject(this.colorScheme$);
    }

    // Units

    useUnits() {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        return useObservable(this.units$).value || kBaseUserUnits;
    }

    units$: Observable<UserUnits>;

    /** User's glucose unit. */
    glucoseUnit$ = new BehaviorSubject<GlucoseUnit>(
        kUserSettingDefaults.glucoseUnit$
    );

    /** User's insulin unit. */
    insulinUnit$ = new BehaviorSubject<InsulinUnit>(
        kUserSettingDefaults.insulinUnit$
    );

    /** User's carb unit. */
    carbUnit$ = new BehaviorSubject<CarbUnit>(kUserSettingDefaults.carbUnit$);

    /** User's carb config. */
    carbConfig$: Observable<CarbConfig>;

    /** See {@link CarbConfig.carbsPerUnit}. */
    carbsPerUnit$ = new BehaviorSubject(kUserSettingDefaults.carbsPerUnit$);

    /** User's energy unit. */
    energyUnit$ = new BehaviorSubject<EnergyUnit>(
        kUserSettingDefaults.energyUnit$
    );

    // Glucose

    /** Glucose target lower limit in mmol/L. */
    glucoseTargetLowerLimit$ = new BehaviorSubject(
        kUserSettingDefaults.glucoseTargetLowerLimit$
    );

    useGlucoseTargetLowerLimit() {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        return useBehaviorSubject(this.glucoseTargetLowerLimit$);
    }

    /** Glucose target lower limit in mmol/L. */
    glucoseTargetUpperLimit$ = new BehaviorSubject(
        kUserSettingDefaults.glucoseTargetUpperLimit$
    );

    useGlucoseTargetUpperLimit() {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        return useBehaviorSubject(this.glucoseTargetUpperLimit$);
    }

    // Debug

    _debugEmailLoginDryRun$ = new BehaviorSubject(
        kUserSettingDefaults._debugEmailLoginDryRun$
    );

    _debugHealthKit$ = new BehaviorSubject(
        kUserSettingDefaults._debugHealthKit$
    );

    _debugGoogleFit$ = new BehaviorSubject(
        kUserSettingDefaults._debugGoogleFit$
    );

    // Management

    private _storage?: DeepStorageAdapter;
    private _storageKey = kDefaultUserSettingsStorageKey;
    private _userMissingKey = '?';

    constructor() {
        this.carbConfig$ = this._createCarbConfig$();
        this.units$ = this._createUnits$();
    }

    configure(options: {
        store: IKeyStorage;
        keyPrefix?: string;
        userMissingKey?: string;
    }) {
        const {
            keyPrefix = kDefaultUserSettingsStorageKey,
            userMissingKey = '?',
            ...otherOptions
        } = options;
        this._storageKey = keyPrefix;
        this._storage = new DeepStorageAdapter(otherOptions);
        this._userMissingKey = userMissingKey;
    }

    close() {
        this.endSync();
    }

    private _isSyncing = false;
    private _syncSubs: Subscription[] = [];

    private _createUnits$(): Observable<UserUnits> {
        return combineLatest([
            this.glucoseUnit$,
            this.insulinUnit$,
            this.carbUnit$,
            this.carbConfig$,
            this.energyUnit$,
        ]).pipe(
            map(unitList => {
                const units: UserUnits = {
                    glucoseUnit: unitList[0],
                    insulinUnit: unitList[1],
                    carbUnit: unitList[2],
                    carbConfig: unitList[3],
                    energyUnit: unitList[4],
                };
                return units;
            })
        );
    }

    private _createCarbConfig$(): Observable<CarbConfig> {
        return combineLatest([this.carbsPerUnit$]).pipe(
            map(configList => {
                const carbConfig: CarbConfig = {
                    carbsPerUnit: configList[0],
                };
                return carbConfig;
            })
        );
    }

    beginSync() {
        if (this._isSyncing) {
            return;
        }
        this._isSyncing = true;

        this._syncSubs = kUserSettingsKeys.map(key => {
            const pipe = [
                skip(1),
                distinctUntilChanged(),
                filter(() => !this._loading),
            ];
            if (this.saveDebounceInterval) {
                pipe.push(debounceTime(this.saveDebounceInterval));
            }
            return (this as any)[key].pipe(...pipe).subscribe((value: any) => {
                if (!this._loading) {
                    this._saveValue(key, value);
                }
            });
        });
    }

    endSync() {
        if (!this._isSyncing) {
            return;
        }
        this._isSyncing = false;
        this._syncSubs.map(sub => sub.unsubscribe());
        this._syncSubs = [];
    }

    private _loading = false;

    async load() {
        await this.finishLoad();
        if (!this._load$) {
            this._load$ = this._load().finally(() => {
                this._load$ = undefined;
            });
        }
        await this.finishLoad();
    }

    async finishLoad() {
        if (this._load$) {
            await this._load$;
        }
    }

    private _load$?: Promise<void>;

    private async _load() {
        this._loading = true;
        const uid = this._userKey();
        console.debug(`loading user settings: ${uid}`);
        let data = await this._loadData();
        data = {
            ...kUserSettingDefaults,
            ...data?.[kSharedSettingsStorageKey],
            ...data?.[this._userKey()],
        };
        _.mapValues(data, (v, k: UserSettingKey) => {
            const attr = (this as any)[k];
            if (!(attr && attr instanceof BehaviorSubject)) {
                console.warn(`Ignoring invalid user setting in storage: ${k}`);
                return;
            }
            const customizer = kValueCustomizers[k];
            if (customizer) {
                v = customizer(v);
            }
            if (!_.isEqual(attr.value, v)) {
                console.debug(`loaded ${k}: ${JSON.stringify(v)}`);
                attr.next(v);
            }
        });
        console.debug(`loaded user settings: ${uid}`);
        this._loading = false;
    }

    private _userKey() {
        return User.current()?.id || this._userMissingKey;
    }

    private async _loadData() {
        if (!this._storage) {
            throw new Error('No storage. Did you run configure()?');
        }
        try {
            return await this._storage.getItem(this._storageKey);
        } catch (error) {
            console.error('Could not parse user settings data: ' + error);
        }
        return {};
    }

    private async _saveValue(key: UserSettingKey, value: any) {
        if (!this._storage) {
            throw new Error('No storage. Did you run configure()?');
        }
        try {
            console.debug(`saving ${key}: ${JSON.stringify(value)}`);
            const storageKey = kSharedSettingsKeys.includes(key)
                ? kSharedSettingsStorageKey
                : this._userKey();
            await this._storage.setItem(
                this._storageKey,
                { [storageKey]: { [key]: value } },
                { merge: true }
            );
            // addSnack(lz('dataUpdatedSuccessfully'));
        } catch (error) {
            console.error('Could not save user settings data: ' + error);
            addSnack(lz('dataUpdateFailedMessage'));
        }
    }

    async delete() {
        if (!this._storage) {
            throw new Error('No storage. Did you run configure()?');
        }
        try {
            console.debug(`deleting user settings`);
            const data = await this._storage.getItem(this._storageKey);
            delete data[this._userKey()];
            await this._storage.setItem(this._storageKey, data, {
                merge: false,
            });
            await this.load();
        } catch (error) {
            console.error('Could delete user settings data: ' + error);
            addSnack(lz('dataUpdateFailedMessage'));
        }
    }
}

const _sharedUserSettings = new UserSettings();

export default _sharedUserSettings;
