import {
    ActivitySessions,
    formatEnergyUnit,
    formatEnergyValue,
    lz,
    lzDate,
} from '@byterium/glucose-diary-client';
import React from 'react';

import { navigate } from '../../pages/navigate';
import UserSettings from '../../services/UserSettings';
import { ActivityIcon } from '../assets/activityAssets';
import RecordSummaryCell, {
    RecordSummaryBuildOptions,
    RecordSummaryCellProps,
} from '../records/RecordSummaryCell';

export const useActivitySessionSummaryCell = (
    options: RecordSummaryBuildOptions
): React.ReactNode => {
    const { record, error, loading } = ActivitySessions.useLatest();
    const { energyUnit } = UserSettings.useUnits();
    const props: Partial<RecordSummaryCellProps> = {};

    if (record) {
        props.date = lzDate(record.date);

        const valuesAndUnits: { value: string; unit: string }[] = [];
        valuesAndUnits.push({
            value: formatEnergyValue(record.energy, energyUnit),
            unit: formatEnergyUnit(energyUnit),
        });

        if (valuesAndUnits.length === 0) {
            valuesAndUnits.push({
                value: '',
                unit: '',
            });
        }

        props.value = valuesAndUnits[0].value;
        props.unit = valuesAndUnits[0].unit;

        if (valuesAndUnits.length > 1) {
            props.description = valuesAndUnits
                .slice(1)
                .map(x => x.value + ' ' + x.unit)
                .join('/n');
        }
    }

    return (
        <RecordSummaryCell
            {...options}
            {...props}
            title={lz('activity')}
            titleIcon={() => <ActivityIcon />}
            error={error ? error.message || String(error) : ''}
            placeholder={loading ? lz('loading') : lz('noData')}
            primaryColor={options.theme.colors.activity}
            onPress={() =>
                navigate('RecordSummary', { recordType: 'ActivitySession' })
            }
        />
    );
};
