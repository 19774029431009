import React from 'react';
import { StyleSheet } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import OverviewChart from '../components/OverviewChart';
import { HomeNavigationProp } from './navigate';

export interface OverviewChartPageProps {
    navigation: HomeNavigationProp;
}

export default function OverviewChartPage({
    navigation,
}: OverviewChartPageProps) {
    const insets = useSafeAreaInsets();

    React.useLayoutEffect(() => {
        navigation.setOptions({ headerShown: false });
    });

    return (
        <OverviewChart
            isFullscreen
            onFullscreenPress={() => navigation.pop()}
            style={[
                styles.chart,
                {
                    paddingTop: insets.top,
                    marginRight: insets.right,
                    marginLeft: insets.left,
                    paddingBottom: insets.bottom,
                },
            ]}
        />
    );
}

const styles = StyleSheet.create({
    chart: {
        flex: 1,
    },
});
