import {
    RecordDataPoint,
    ToNumberOptions,
    lzTime,
} from '@byterium/glucose-diary-client';
import React from 'react';
import { StyleSheet, View } from 'react-native';
import { Row, RowProps } from 'react-native-form-model';
import { ActivityIndicator, Text, useTheme } from 'react-native-paper';

import { AppTheme } from '../../const';
import { ChevronIcon } from '../assets/commonAssets';

export interface RecordListItemProps extends RowProps {
    dataPoint: RecordDataPoint;
    valueFormatter: (value: number, options?: ToNumberOptions) => string;
    units: string;
    description?: string;
    descriptionColor?: string;
    loading?: boolean;
}

export default function RecordListItem({
    dataPoint,
    valueFormatter,
    units,
    showDelete = false,
    description,
    descriptionColor,
    loading = false,
    onPress,
    ...rowProps
}: RecordListItemProps) {
    const theme = useTheme() as AppTheme;
    let accessory: RowProps['rightAccessory'] = undefined;
    if (showDelete) {
        // Handled by row
    } else if (loading) {
        accessory = props => <ActivityIndicator {...props} size={16} />;
    } else if (onPress) {
        accessory = props => <ChevronIcon {...props} size={16} />;
    }
    return (
        <Row
            {...rowProps}
            showDelete={showDelete}
            rightAccessory={accessory}
            onPress={onPress}
        >
            <View style={styles.container}>
                <View style={[styles.cell, styles.mainContent]}>
                    <Text
                        style={[styles.value, { color: theme.colors.text }]}
                        onPressIn={undefined}
                        onPressOut={undefined}
                    >
                        {valueFormatter(dataPoint.y)}
                        <Text
                            style={{
                                color: theme.colors.textStronglyFaded,
                            }}
                            onPressIn={undefined}
                            onPressOut={undefined}
                        >
                            {' ' + (units || '')}
                        </Text>
                    </Text>
                </View>
                {!!description && (
                    <View style={styles.cell}>
                        <Text
                            style={
                                descriptionColor
                                    ? { color: descriptionColor }
                                    : undefined
                            }
                            onPressIn={undefined}
                            onPressOut={undefined}
                        >
                            {description}
                        </Text>
                    </View>
                )}
                <View style={[styles.cell, styles.rightAlign]}>
                    <Text
                        style={[styles.date, { color: theme.colors.text }]}
                        onPressIn={undefined}
                        onPressOut={undefined}
                    >
                        {lzTime(dataPoint.x)}
                    </Text>
                </View>
            </View>
        </Row>
    );
}

const hPadding = 10;

const styles = StyleSheet.create({
    container: {
        flex: 1,
        marginRight: 8,
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    row: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    cell: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
    },
    mainContent: {
        flex: 1.2,
    },
    rightAlign: {
        justifyContent: 'flex-end',
    },
    value: {
        paddingRight: hPadding,
        fontWeight: 'bold',
        fontSize: 16,
    },
    date: {
        fontSize: 16,
        textAlign: 'right',
    },
});
