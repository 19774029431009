import {
    InsulinProducts,
    Schema,
    formatInsulinConcentration,
    formatInsulinProfileType,
} from '@byterium/glucose-diary-client';
import React from 'react';
import { StyleSheet, View } from 'react-native';
import { Row, RowProps } from 'react-native-form-model';
import { Caption, Text } from 'react-native-paper';

const kInsulinProfileTypeFormatOptions = { choice: true };

export interface InsulinProductSummaryRowProps extends RowProps {
    product: Schema.InsulinProductFieldsFragment;
}

export default function InsulinProductSummaryRow({
    product,
    style,
    ...props
}: InsulinProductSummaryRowProps) {
    return (
        <Row {...props} style={[styles.container, style]}>
            <Text onPressIn={undefined} onPressOut={undefined}>
                {InsulinProducts.formatProduct(product, {
                    concentration: false,
                })}
            </Text>
            <View style={styles.rightContainer}>
                {!!product.medication.clinicalName && (
                    <Caption style={styles.caption}>
                        {product.medication.clinicalName}
                    </Caption>
                )}
                <Caption style={styles.caption}>
                    {formatInsulinProfileType(
                        product.medication.profileType,
                        kInsulinProfileTypeFormatOptions
                    )}
                </Caption>
                {!!product.insulinConcentration && (
                    <Caption style={styles.caption}>
                        {formatInsulinConcentration(
                            product.insulinConcentration
                        )}
                    </Caption>
                )}
            </View>
        </Row>
    );
}

const styles = StyleSheet.create({
    container: {
        flexWrap: 'wrap',
    },
    caption: {
        lineHeight: 12,
        flexShrink: 1,
        textAlign: 'right',
    },
    leftText: {
        flexShrink: 1,
    },
    rightContainer: {
        flexShrink: 1,
        alignItems: 'flex-end',
    },
    rightText: {
        paddingLeft: 8,
        flexShrink: 1,
        textAlign: 'right',
    },
});
