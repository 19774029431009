import { RecordType } from '@byterium/glucose-diary-client';
import React from 'react';
import { StyleSheet, TouchableOpacity, ViewProps } from 'react-native';
import { ActivityIndicator } from 'react-native-paper';

import { navigate } from '../../pages/navigate';
import { SvgAssetProps } from '../assets/assetConst';
import { Circle } from '../assets/commonAssets';

export interface AddSampleItemProps extends ViewProps {
    icon: (props: SvgAssetProps) => React.ReactNode;
    recordType: RecordType;
    color: string;
    animating: boolean;
}

const AddSampleItem = React.memo(
    ({
        icon,
        recordType,
        color,
        style,
        animating,
        ...otherProps
    }: AddSampleItemProps) => {
        const onPress = () => {
            navigate('AddSample', { recordType });
        };

        const iconView = icon({
            style: styles.icon,
            color,
        });

        return (
            <TouchableOpacity
                {...otherProps}
                style={[styles.container, style]}
                onPress={onPress}
            >
                <Circle strokeColor={color} strokeWidth={2} diameter={60}>
                    <ActivityIndicator
                        animating={animating}
                        hidesWhenStopped
                        color={color}
                        style={styles.activity}
                        size={60}
                    />
                    {iconView}
                </Circle>
            </TouchableOpacity>
        );
    }
);

const styles = StyleSheet.create({
    container: {
        alignItems: 'stretch',
        width: 60,
        height: 60,
    },
    layerContainer: {
        flex: 1,
    },
    layer: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
    },
    activity: {
        position: 'absolute',
        left: 0,
        right: 0,
        padding: 2,
    },
    icon: {
        // flex: 1,
        padding: 2,
    },
});

export default AddSampleItem;
