import React from 'react';

import { BackgroundProps } from './RootBackground.types';

const RootBackground = ({ color }: BackgroundProps) => {
    React.useEffect(() => {
        document.body.style.backgroundColor = color;
    }, [color]);

    return null;
};

export default RootBackground;
