import { AuthProvider, lz } from '@byterium/glucose-diary-client';
import React from 'react';
import { ScrollView, StyleSheet, View } from 'react-native';
import { Button } from 'react-native-form-model';
import {
    ActivityIndicator,
    Banner,
    Modal,
    Portal,
    Text,
    useTheme,
} from 'react-native-paper';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import AppBackground from '../../components/AppBackground';
import AppFooter from '../../components/AppFooter';
import { AppName } from '../../components/AppName';
import CenteredContent from '../../components/CenteredContent';
import { AppIcon } from '../../components/assets/brandAssets';
import * as SignInButtons from '../../components/login/loginButtons';
import { AppTheme, kMaxPageWidth } from '../../const';
import { usePromise } from '../../reactUtil';
import {
    AppleAuthenticationCredential,
    signInWithApple,
} from '../../services/login/appleLogin';
import { isApple } from '../../services/login/appleLogin/isApple';
import {
    useAuthSessions,
    useWarmBrowser,
} from '../../services/login/loginUtil';
import { LoginMainPageOptions } from './loginTypes';

export interface LoginMainPageProps extends LoginMainPageOptions {}

/**
 * Handles external login and client login.
 *
 * When the user is logged in successfuly, the next screen
 * is displayed automatically. See AppDelegate.
 *
 * @param param0
 * @returns
 */
export default function LoginMainPage({ navigation }: LoginMainPageProps) {
    const theme = useTheme() as AppTheme;
    const insets = useSafeAreaInsets();
    useWarmBrowser();

    const [provider, setProvider] = React.useState<AuthProvider | undefined>();
    const sessions = useAuthSessions();
    const session = provider && sessions[provider];

    const tryLogin = (targetProvider: AuthProvider | 'email' | undefined) => {
        setAppleSignInAction(undefined);

        if (targetProvider === 'email') {
            targetProvider = undefined;
            navigation.push('LoginEmail', {});
        }

        if (provider !== targetProvider) {
            setProvider(targetProvider);
        }
        if (targetProvider) {
            sessions[targetProvider].promptAsync();
        }
    };

    const [appleSignInAction, setAppleSignInAction] = React.useState<
        Promise<AppleAuthenticationCredential | undefined> | undefined
    >();
    const {
        // If the promise succeeds, the next screen will
        // be presented automatically. See AppDelegate.
        error: appleAuthError,
        loading: loggingInWithApple,
    } = usePromise(appleSignInAction);

    const loading = session?.loading || loggingInWithApple || false;
    const error =
        session?.recallError ||
        (session?.response?.type === 'error' && session.response.error) ||
        appleAuthError ||
        undefined;

    const tryAppleLogin = () => {
        setAppleSignInAction(signInWithApple());
    };

    const appleButton = (
        <SignInButtons.AppleSignInButton
            key='apple'
            disabled={loading}
            onPress={() => tryLogin('apple')}
            onNativePress={() => tryAppleLogin()}
            style={styles.button}
        />
    );

    const googleButton = (
        <SignInButtons.GoogleSignInButton
            key='google'
            disabled={loading || !sessions.google.request}
            onPress={() => tryLogin('google')}
            style={styles.button}
        />
    );

    const vkButton = (
        <SignInButtons.VKontakteSignInButton
            key='vkontakte'
            disabled={loading || !sessions.vkontakte.request}
            onPress={() => tryLogin('vkontakte')}
            style={styles.button}
        />
    );

    const oauthButtons = [googleButton, vkButton];
    if (isApple) {
        // Prioritise apple login on apple
        oauthButtons.unshift(appleButton);
    } else {
        oauthButtons.push(appleButton);
    }

    return (
        <View style={styles.container}>
            <AppBackground style={{ backgroundColor: theme.colors.surface }} />
            <ScrollView
                style={styles.container}
                contentContainerStyle={[
                    styles.scrollContent,
                    {
                        paddingTop: insets.top,
                        paddingLeft: insets.left + kXPadding,
                        paddingRight: insets.right + kXPadding,
                        paddingBottom: insets.bottom,
                    },
                ]}
            >
                <CenteredContent
                    maxWidth={kMaxPageWidth}
                    style={styles.contentContainer}
                >
                    <AppIcon style={styles.appIcon} />
                    <AppName style={styles.appName} />

                    <Banner
                        visible={!!error}
                        actions={[
                            {
                                label: lz('retry'),
                                onPress: () => {
                                    if (appleSignInAction) {
                                        tryAppleLogin();
                                    } else {
                                        tryLogin(provider);
                                    }
                                },
                            },
                        ]}
                    >
                        {`${lz('somethingWentWrong')}: ${String(
                            error?.message || error || ''
                        )}`}
                    </Banner>

                    <View style={styles.buttonContainer}>
                        <Text
                            style={styles.welcomeMessageText}
                            onPressIn={undefined}
                            onPressOut={undefined}
                        >
                            {lz('alphaMessage')}
                        </Text>

                        {oauthButtons}
                        <SignInButtons.EmailSignInButton
                            key='email'
                            disabled={loading}
                            onPress={() => tryLogin('email')}
                            style={styles.button}
                        />
                    </View>
                </CenteredContent>
                <AppFooter showContactButton style={styles.footer} />
            </ScrollView>

            <Portal theme={theme}>
                <Modal
                    visible={loading}
                    onDismiss={() => session?.cancel()}
                    dismissable={false}
                    contentContainerStyle={[
                        styles.modal,
                        {
                            backgroundColor: theme.colors.surface,
                            borderRadius: theme.roundness,
                        },
                    ]}
                >
                    <ActivityIndicator
                        animating={loading || true}
                        style={styles.modalAnimation}
                    />
                    <Text
                        style={styles.modalText}
                        onPressIn={undefined}
                        onPressOut={undefined}
                    >
                        {session?.message || ''}
                    </Text>
                    <Button
                        title={lz('cancel')}
                        onPress={() => {
                            session?.cancel();
                            setAppleSignInAction(undefined);
                        }}
                        style={styles.modalCancelButton}
                    />
                </Modal>
            </Portal>
        </View>
    );
}

const kXPadding = 26;

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    scrollContent: {
        flexGrow: 1,
        flexDirection: 'column',
        paddingVertical: 16,
        alignItems: 'center',
    },
    contentContainer: {
        flex: 1,
        alignItems: 'center',
    },
    appIcon: {
        marginTop: 0,
        marginRight: -20,
        marginBottom: -20,
        marginLeft: -20,
        height: 200,
        width: 200,
        alignSelf: 'center',
    },
    appName: {
        textAlign: 'center',
        marginBottom: 24,
    },
    buttonContainer: {
        maxWidth: 400,
        alignContent: 'center',
    },
    footer: {
        margin: 16,
    },
    button: {
        marginTop: 12,
    },
    modal: {
        maxWidth: 200,
        alignSelf: 'center',
        alignItems: 'center',
        padding: 24,
        paddingTop: 32,
    },
    modalAnimation: {
        marginTop: 20,
    },
    modalText: {
        textAlign: 'center',
        paddingHorizontal: 20,
        marginTop: 20,
    },
    modalCancelButton: {
        marginTop: 12,
        height: 50,
    },
    welcomeMessageText: {
        marginVertical: 20,
        width: '100%',
        textAlign: 'center',
    },
});
