import { lz } from '@byterium/glucose-diary-client';
import { Alert, AlertButton, Platform } from 'react-native';

const kWebConfirmDelay = 100;

export interface AlertOptions {
    title?: string;
    message: string;
    confirmTitle?: string;
    destructive?: boolean;
    onDismiss?: () => void;
}

export interface ConfirmationOptions extends AlertOptions {
    cancelTitle?: string;
    onConfirm: () => void;
    onCancel?: () => void;
}

export function alertAsync({
    title = lz('alert'),
    confirmTitle = lz('dismiss'),
    ...options
}: AlertOptions) {
    _confirm({
        ...options,
        title,
        confirmTitle,
        cancelTitle: '',
        onConfirm: () => {},
    });
}

export function alertPromise(
    options: Omit<ConfirmationOptions, 'onConfirm' | 'onCancel'>
): Promise<void> {
    return new Promise<void>((resolve, reject) => {
        alertAsync({
            ...options,
            onDismiss: () => resolve(),
        });
    });
}

const _confirm = ({
    title = lz('confirmation'),
    message,
    confirmTitle = lz('confirm'),
    cancelTitle = lz('cancel'),
    destructive = false,
    onConfirm,
    onCancel,
    onDismiss,
}: ConfirmationOptions) => {
    const _onConfirm = () => {
        onConfirm();
        onDismiss?.();
    };

    const _onCancel = () => {
        onCancel?.();
        onDismiss?.();
    };

    if (Platform.OS === 'web') {
        if (confirm(message)) {
            setTimeout(_onConfirm, kWebConfirmDelay);
        } else if (onCancel || onDismiss) {
            setTimeout(_onCancel, kWebConfirmDelay);
        }
    } else {
        const buttons: AlertButton[] = [];
        if (cancelTitle) {
            buttons.push({
                text: cancelTitle,
                onPress: _onCancel,
                style: 'cancel',
            });
        }
        if (confirmTitle) {
            buttons.push({
                text: confirmTitle,
                style: destructive ? 'destructive' : 'default',
                onPress: _onConfirm,
            });
        }
        Alert.alert(title, message, buttons, {
            cancelable: true,
            onDismiss,
        });
    }
};

export { _confirm as confirm };

export function confirmation(
    options: Omit<ConfirmationOptions, 'onConfirm' | 'onCancel'>
): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
        _confirm({
            ...options,
            onConfirm: () => resolve(true),
            onCancel: () => resolve(false),
        });
    });
}
