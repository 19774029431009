import { ENV, lz } from '@byterium/glucose-diary-client';
import React from 'react';
import { ScrollView, StyleSheet, View } from 'react-native';
import { Form, FormModel } from 'react-native-form-model';
import { useTheme } from 'react-native-paper';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import AppBackground from '../../components/AppBackground';
import AppFooter from '../../components/AppFooter';
import CenteredContent from '../../components/CenteredContent';
import { KeyboardAvoidingView } from '../../components/KeyboardAvoidingView';
import {
    ChevronIcon,
    CloudIcon,
    ColorSchemeIcon,
    HammerIcon,
    UnitsIcon,
} from '../../components/assets/commonAssets';
import { GlucoseIcon } from '../../components/assets/glucoseAssets';
import UserSummaryRow from '../../components/settings/UserSummaryRow';
import {
    AppTheme,
    ColorScheme,
    formatColorScheme,
    kColorSchemes,
    kMaxPageWidth,
} from '../../const';
import UserSettings from '../../services/UserSettings';
import { HomeNavigationProp } from '../navigate';
import { SettingsPageOptions } from './settingsTypes';
import {
    addSyncSettingsSection,
    fitnessKitsToDisplay,
} from './sync/syncSettingsForm';

export interface SettingsPageProps
    extends SettingsPageOptions<HomeNavigationProp> {}

export default function SettingsPage({ navigation }: SettingsPageProps) {
    const theme = useTheme() as AppTheme;
    const insets = useSafeAreaInsets();

    const [form, setForm] = React.useState(() => FormModel.empty());

    React.useLayoutEffect(() => {
        const form = FormModel.create({ style: theme.form });

        form.addSection()
            .addRow()
            .addCustom(() => <ColorSchemeIcon style={styles.icon} />)
            .setMargin(0)
            .addLabel(lz('theme'))
            .addOptionInput<ColorScheme>({
                value: UserSettings.colorScheme$,
                mode: 'segmented',
                possibleValues: [...kColorSchemes],
                formatValue: x => formatColorScheme(x),
                style: {
                    fontSize: 14,
                },
                flex: 1,
                align: 'right',
            });

        form.addSection().modify(section => {
            section
                .addRow({ onPress: () => navigation.push('GlucoseSettings') })
                .addCustom(() => <GlucoseIcon style={styles.icon} />)
                .setMargin(0)
                .addLabel(lz('bloodGlucose'))
                .addFlex()
                .addCustom(() => <ChevronIcon />);

            section
                .addRow({ onPress: () => navigation.push('UnitSettings') })
                .addCustom(() => <UnitsIcon style={styles.icon} />)
                .setMargin(0)
                .addLabel(lz('units'))
                .addFlex()
                .addCustom(() => <ChevronIcon />);

            const fitnessKitCount = fitnessKitsToDisplay().length;
            if (fitnessKitCount === 1) {
                addSyncSettingsSection(form, {
                    title: lz('cloudSync'),
                    theme,
                    styles,
                    style: { sectionMargin: 12 },
                });
            } else if (fitnessKitCount > 1) {
                section
                    .addRow({ onPress: () => navigation.push('SyncSettings') })
                    .addCustom(() => <CloudIcon style={styles.icon} />)
                    .setMargin(0)
                    .addLabel(lz('cloudSync'))
                    .addFlex()
                    .addCustom(() => <ChevronIcon />);
            }
        });

        if (ENV.isDev) {
            form.addSection().modify(section => {
                section
                    .addRow({
                        onPress: () => navigation.push('DeveloperSettings'),
                    })
                    .addCustom(() => <HammerIcon style={styles.icon} />)
                    .setMargin(0)
                    .addLabel('Developer')
                    .addFlex()
                    .addCustom(() => <ChevronIcon />);
            });
        }

        setForm(form);
    }, [navigation, theme]);

    return (
        <View style={styles.container}>
            <AppBackground />
            <KeyboardAvoidingView style={styles.container}>
                <ScrollView
                    style={styles.container}
                    contentContainerStyle={[
                        styles.scrollContent,
                        {
                            paddingLeft: insets.left,
                            paddingRight: insets.right,
                            paddingBottom: insets.bottom,
                        },
                    ]}
                >
                    <CenteredContent
                        maxWidth={kMaxPageWidth}
                        style={styles.content}
                    >
                        <UserSummaryRow
                            isSingle
                            onPress={() => navigation.navigate('UserProfile')}
                        />

                        <Form form={form} style={styles.form} />
                    </CenteredContent>
                    <AppFooter showLogoutButton showContactButton />
                </ScrollView>
            </KeyboardAvoidingView>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    scrollContent: {
        flexGrow: 1,
    },
    content: {
        margin: 12,
        flexGrow: 1,
    },
    form: {
        marginVertical: 12,
    },
    icon: {
        width: 24,
    },
    button: {
        marginTop: 8,
    },
});
