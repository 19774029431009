import { Ionicons } from '@expo/vector-icons';
import React from 'react';
import { Image, StyleSheet, View } from 'react-native';
import { useTheme } from 'react-native-paper';

import { AppTheme } from '../../const';
import { useImport } from '../../reactUtil';
import { IconAssetProps, PngAssetProps } from './assetConst';

export const AppleLogo = ({
    color,
    size = 24,
    ...otherProps
}: IconAssetProps) => {
    const theme = useTheme() as AppTheme;
    return (
        <Ionicons
            name='logo-apple'
            direction='ltr'
            {...otherProps}
            size={size}
            color={color || theme.colors.text}
        />
    );
};

export const AppleHealthLogo = ({
    size = 24,
    style,
    ...props
}: PngAssetProps) => {
    const _AppleHealthLogo = useImport(AppleHealthLogo$).default || null;
    return _AppleHealthLogo ? (
        <Image
            {...props}
            source={_AppleHealthLogo}
            style={[styles.appleHealth, style, { width: size, height: size }]}
        />
    ) : (
        <View
            {...props}
            style={[styles.appleHealth, style, { width: size, height: size }]}
        />
    );
};
const AppleHealthLogo$ = () => import('../../../assets/social/applehealth.png');

export const GoogleIcon = React.memo<PngAssetProps>(({ style, ...props }) => {
    const _GoogleIcon = useImport(GoogleIcon$).default || null;
    return _GoogleIcon ? (
        <Image
            {...props}
            source={_GoogleIcon}
            style={[styles.socialIcon, style]}
        />
    ) : (
        <View {...props} style={[styles.socialIcon, style]} />
    );
});
const GoogleIcon$ = () => import('../../../assets/social/google.png');

export const GoogleFitLogo = React.memo<PngAssetProps>(
    ({ style, ...props }) => {
        const _GoogleFitLogo = useImport(GoogleFitLogo$).default || null;
        return _GoogleFitLogo ? (
            <Image
                {...props}
                source={_GoogleFitLogo}
                style={[styles.socialIcon, style]}
            />
        ) : (
            <View {...props} style={[styles.socialIcon, style]} />
        );
    }
);
const GoogleFitLogo$ = () => import('../../../assets/social/googlefit.png');

export const VKontakteIcon = React.memo<PngAssetProps>(
    ({ style, ...props }) => {
        const _VKontakteIcon = useImport(VKontakteIcon$).default || null;
        return _VKontakteIcon ? (
            <Image
                {...props}
                source={_VKontakteIcon}
                style={[styles.socialIcon, style]}
            />
        ) : (
            <View {...props} style={[styles.socialIcon, style]} />
        );
    }
);
const VKontakteIcon$ = () => import('../../../assets/social/vkontakte.png');

const styles = StyleSheet.create({
    socialIcon: {
        width: 24,
        height: 24,
    },
    appleHealth: {
        shadowColor: '#000000',
        shadowOpacity: 0.1,
        shadowRadius: 1.5,
        shadowOffset: { width: 0, height: 1 },
        overflow: 'visible',
    },
});
