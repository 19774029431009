import {
    FitnessKitType,
    GoogleFitBase,
    HealthKitBase,
    fitnessSyncIntervalMs,
    getSharedFitnessKit,
    kFitnessKitTypes,
    lz,
} from '@byterium/glucose-diary-client';
import React from 'react';
import { ImageStyle, Platform, StyleProp } from 'react-native';
import { FormModel, SectionModelOptions } from 'react-native-form-model';
import { map } from 'rxjs/operators';

import { FitnessKitLogo } from '../../../components/FitnessKitLogo';
import { ChevronIcon } from '../../../components/assets/commonAssets';
import { AppTheme } from '../../../const';
import { MaybeObservable } from '../../../reactUtil';
import { navigate } from '../../navigate';

export function addSyncSettingsSection(
    form: FormModel,
    {
        styles,
        theme,
        ...sectionOptions
    }: {
        theme: AppTheme;
        styles: { icon: StyleProp<ImageStyle> };
    } & Partial<SectionModelOptions>
) {
    return form.addSection(sectionOptions).modify(section => {
        for (const {
            type,
            fitness,
            force,
            canConfig,
        } of fitnessKitsToDisplay()) {
            const stateStr: MaybeObservable<string> = canConfig
                ? fitness.sync$.pipe(
                      map(sync =>
                          fitnessSyncIntervalMs(sync) &&
                          (force || fitness.isAvailable)
                              ? lz('enabled')
                              : lz('disabled')
                      )
                  )
                : lz('unavailable');

            const row = section
                .addRow({
                    onPress: canConfig
                        ? () =>
                              navigate('FitnessSettings', {
                                  fitnessType: fitness.type,
                              })
                        : undefined,
                })
                .addCustom(() => (
                    <FitnessKitLogo fitnessType={type} style={styles.icon} />
                ))
                .setMargin(0)
                .addLabel(fitness.name)
                .addLabel({
                    title: stateStr,
                    style: {
                        colors: {
                            label: canConfig
                                ? theme.form.colors.input
                                : theme.form.colors.disabled,
                        },
                    },
                    flex: 1,
                    align: 'right',
                });

            if (canConfig) {
                row.setMargin(0).addCustom(() => <ChevronIcon />);
            }
        }
    });
}

export function fitnessKitsToDisplay() {
    const fitnessKitInfos: {
        type: FitnessKitType;
        fitness: HealthKitBase | GoogleFitBase;
        force: boolean;
        canConfig: boolean;
    }[] = [];
    for (const type of kFitnessKitTypes) {
        const fitness = getSharedFitnessKit(type);

        const support = fitness.supported;
        const force = fitness._debug$.value;
        const canConfig = support || force;
        if (
            support ||
            force ||
            fitness.supportedPlatforms.includes(Platform.OS)
        ) {
            fitnessKitInfos.push({ type, fitness, force, canConfig });
        }
    }
    return fitnessKitInfos;
}
