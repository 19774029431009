import React from 'react';
import {
    StyleProp,
    StyleSheet,
    View,
    ViewProps,
    ViewStyle,
} from 'react-native';

export interface StackProps extends ViewProps {
    horizontal?: boolean;
    children?: React.ReactNode | React.ReactNode[];
    itemContainerStyle?: StyleProp<ViewStyle>;
}

export default function Stack({
    horizontal,
    children,
    style,
    itemContainerStyle,
    ...props
}: StackProps) {
    if (!children) {
        children = [];
    } else if (!(children instanceof Array)) {
        children = [children];
    }
    const itemContainers = (children as React.ReactNode[]).map((child, i) => (
        <View key={i} style={[styles.itemContainer, itemContainerStyle]}>
            {child}
        </View>
    ));
    return (
        <View
            {...props}
            style={[
                horizontal
                    ? styles.horizontalContainer
                    : styles.verticalContainer,
                style,
            ]}
        >
            {itemContainers}
        </View>
    );
}

const styles = StyleSheet.create({
    horizontalContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    verticalContainer: {
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    itemContainer: {
        margin: 4,
    },
});
