import { i18n as i18n0 } from '@byterium/glucose-diary-client';
import * as Localization from 'expo-localization';
import i18n from 'i18n-js';
import moment from 'moment';

// TODO: Handle locale change on Android [https://trello.com/c/BKSLqW7f]

function setupLocalization(lz: typeof i18n) {
    const locale = (
        Localization.locale ||
        lz.defaultLocale ||
        'en'
    ).toLowerCase();
    console.debug('locale: ' + locale);

    lz.locale = locale;

    // Set date locale
    moment.locale(locale);
}

// We setup upstream i18n and copy current i18n if needed.
setupLocalization(i18n0);

if (i18n0 !== i18n) {
    console.warn(
        'Upstream i18n not equal to current i18n. This is probably due to two different i18n packages being used by the app and the library. Will try to do a one time sync of translations and settings.'
    );
    Object.assign(i18n, i18n0);
}
