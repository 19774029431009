import { ENV } from '@byterium/glucose-diary-client';
import _ from 'lodash';
import React from 'react';
import { ScrollView, StyleSheet } from 'react-native';
import { Form, FormModel } from 'react-native-form-model';
import { useTheme } from 'react-native-paper';

import CenteredContent from '../../../components/CenteredContent';
import { KeyboardAvoidingView } from '../../../components/KeyboardAvoidingView';
import { AppTheme, kMaxPageWidth } from '../../../const';

export default function DeveloperEnvPage() {
    const theme = useTheme() as AppTheme;

    const form = React.useMemo(() => {
        const form = FormModel.create({ style: theme.form });
        const section = form.addSection();
        _.mapValues(ENV.toJSON(), (value, key) => {
            section
                .addRow()
                .addLabel({
                    title: key,
                    style: {
                        colors: { label: theme.colors.textSlightlyFaded },
                    },
                })
                .addFlex()
                .addLabel(String(value));
        });
        return form;
    }, [theme]);

    return (
        <KeyboardAvoidingView style={styles.container}>
            <ScrollView
                style={styles.container}
                contentContainerStyle={styles.scrollContent}
            >
                <CenteredContent
                    maxWidth={kMaxPageWidth}
                    style={styles.content}
                >
                    <Form form={form} style={styles.form} />
                </CenteredContent>
            </ScrollView>
        </KeyboardAvoidingView>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    scrollContent: {
        flexGrow: 1,
    },
    content: {
        margin: 12,
    },
    form: {
        marginVertical: 12,
    },
    section: {
        marginBottom: 10,
    },
    icon: {
        width: 24,
    },
});
