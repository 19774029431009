import { PartialFormStyle } from 'react-native-form-model';

import { AppTheme } from '../../const';

export function defaultSectionStyleWithValidation(
    theme: AppTheme
): PartialFormStyle {
    return {
        sectionFooterAlign: 'right',
        colors: { sectionFooter: theme.form.colors.error },
    };
}
