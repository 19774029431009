import {
    CarbConfig,
    CarbUnit,
    EnergyUnit,
    Schema,
    formatCarbUnit,
    formatCarbValue,
    formatEnergyUnit,
    formatEnergyValue,
    isNormalCarbValue,
    isNormalEnergyValue,
    lz,
    parseCarbValue,
    parseEnergyValue,
} from '@byterium/glucose-diary-client';
import React from 'react';
import { ImageProps } from 'react-native';
import { InputFieldValueInfo } from 'react-native-form-model';

import { NotesIcon } from '../../../components/assets/commonAssets';
import { MealIcon } from '../../../components/assets/mealAssets';
import { defaultSectionStyleWithValidation } from '../formUtil';
import {
    RecordFormData,
    getRecordFormValues,
    setRecordFormValues,
} from './recordFormData';
import { UpdateRecordFormOptions } from './recordFormTypes';
import { handleAbnormalValueIfNeeded } from './recordFormUtil';

export function Icon(props: Pick<ImageProps, 'style'>) {
    return <MealIcon {...props} />;
}

export function addMealSection({
    form,
    data,
    theme,
    styles,
    units: { carbUnit, carbConfig, energyUnit },
    isNew,
}: UpdateRecordFormOptions) {
    form.addSection({
        style: defaultSectionStyleWithValidation(theme),
    }).modify(section => {
        section
            .addRow()
            .addCustom(() => <NotesIcon style={styles.icon} />)
            .setMargin(0)
            .addLabel(lz('carbohydrates'))
            .addKeyboardInput<number | undefined>({
                value: data.meal.carbs,
                type: 'unsignedFloat',
                disabled: !isNew,
                parseInput: x => parseCarbValue(x, carbUnit, carbConfig),
                validation: x =>
                    (x || 0) > 0 || lz('carbValueGT', { value: 0 }),
                formatValue: x => formatCarbValue(x, carbUnit, carbConfig),
                placeholder: formatCarbValue(0, carbUnit, carbConfig),
                flex: 1,
                align: 'right',
                mode: 'contained',
                autoFocus: isNew,
                selectTextOnFocus: true,
                onValueChange: info =>
                    _handleAbnormalCarbValueIfNeeded({
                        ...info,
                        unit: carbUnit,
                        carbConfig,
                    }),
            })
            .setMargin(0)
            .addLabel({
                title: formatCarbUnit(carbUnit),
                color: theme.colors.textStronglyFaded,
            });

        section
            .addRow()
            .addCustom(() => <NotesIcon style={styles.icon} />)
            .setMargin(0)
            .addLabel(lz('energyContent'))
            .addKeyboardInput<number | undefined>({
                value: data.meal.energy,
                disabled: !isNew,
                type: 'unsignedFloat',
                optional: true,
                parseInput: x =>
                    x ? parseEnergyValue(x, energyUnit) : undefined,
                validation: x =>
                    typeof x === 'undefined' || x > 0
                        ? true
                        : lz('energyContentValueGT', { value: 0 }),
                formatValue: x => formatEnergyValue(x, energyUnit),
                placeholder: formatEnergyValue(0, energyUnit),
                flex: 1,
                align: 'right',
                mode: 'contained',
                selectTextOnFocus: true,
                onValueChange: info =>
                    _handleAbnormalEnergyValueIfNeeded({
                        ...info,
                        unit: energyUnit,
                    }),
            })
            .setMargin(0)
            .addLabel({
                title: formatEnergyUnit(energyUnit),
                color: theme.colors.textStronglyFaded,
            });

        section.footer = section.flattenedFormattedErrors$({
            editedOnly: true,
        });
    });

    // TODO: add glycemic and meal indexes. See [task](https://trello.com/c/w2SXPpwt)

    form.addSection()
        .addRow()
        .addCustom(() => <MealIcon style={styles.icon} />)
        .setMargin(0)
        .addLabel(lz('description'))
        .addKeyboardInput({
            value: data.meal.description,
            disabled: !isNew,
            flex: 1,
            align: 'right',
            mode: 'contained',
        });
}

export function getMealFormValues(
    formData: RecordFormData
): Schema.AddMealInput {
    return {
        ...getRecordFormValues(formData),
        energy: formData.meal.energy.value || undefined,
        carbs: formData.meal.carbs.value || undefined,
        glycemicIndex: formData.meal.glycemicIndex.value || undefined,
        insulinIndex: formData.meal.insulinIndex.value || undefined,
        description: formData.meal.description.value,
    };
}

export function setMealFormValues(
    formData: RecordFormData,
    values: Schema.MealFieldsFragment
) {
    setRecordFormValues(formData, values);
    formData.meal.energy.next(values.energy);
    formData.meal.carbs.next(values.carbs);
    formData.meal.glycemicIndex.next(values.glycemicIndex);
    formData.meal.insulinIndex.next(values.insulinIndex);
    formData.meal.description.next(values.description || '');
}

function _handleAbnormalCarbValueIfNeeded({
    unit,
    carbConfig,
    ...options
}: InputFieldValueInfo<number | undefined> & {
    unit: CarbUnit;
    carbConfig: CarbConfig;
}) {
    handleAbnormalValueIfNeeded({
        ...options,
        isNormalValue: isNormalCarbValue,
        displayValue: `${formatCarbValue(
            options.value,
            unit,
            carbConfig
        )} ${formatCarbUnit(unit)}`,
    });
}

function _handleAbnormalEnergyValueIfNeeded({
    unit,
    ...options
}: InputFieldValueInfo<number | undefined> & { unit: EnergyUnit }) {
    handleAbnormalValueIfNeeded({
        ...options,
        isNormalValue: isNormalEnergyValue,
        displayValue: `${formatEnergyValue(
            options.value,
            unit
        )} ${formatEnergyUnit(unit)}`,
    });
}
