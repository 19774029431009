export const kMimeTypeByFileType = {
    PNG: 'image/png',
    JPEG: 'image/jpeg',
    PDF: 'application/pdf',
};

export const kUTIByFileType = {
    PNG: 'public.png',
    JPEG: 'public.jpeg',
    PDF: 'com.adobe.pdf',
};

export type FileType = keyof typeof kMimeTypeByFileType;

export interface RefObjectLike {
    current: any;
}

export interface MediaShareOptions {
    /** Defaults to PNG. */
    fileType?: FileType;
    /** Defaults to `image.${fileType.toLowerCase()}`. */
    fileName?: string;
    /** Defaults to 0.9. */
    quality?: number;
    /** Share dialogue title. */
    title?: string;
    /** Share dialogue description. */
    text?: string;
}

const kMediaShareDefaults: Required<MediaShareOptions> = {
    title: '',
    text: '',
    fileType: 'PNG',
    fileName: '',
    quality: 0.9,
};

export const mediaShareOptions = (
    options: MediaShareOptions = {}
): Required<MediaShareOptions> => {
    const { fileType = kMediaShareDefaults.fileType } = options;
    return {
        ...kMediaShareDefaults,
        fileType,
        fileName: `image.${fileType.toLowerCase()}`,
        ...options,
    };
};
