import {
    ENV,
    getSharedFitnessKit,
    kFitnessKitTypes,
    lz,
} from '@byterium/glucose-diary-client';
import { StackNavigationOptions } from '@react-navigation/stack';
import React from 'react';

import { HomeStack } from '../../navigate';
import SyncSettingsPage from './SyncSettingsPage';
import FitnessSettingsPage from './fitness/FitnessSettingsPage';

const syncSettingsScreens = ({
    defaultScreenOptions,
}: {
    defaultScreenOptions?: StackNavigationOptions;
} = {}) => {
    return [
        <HomeStack.Screen
            key='SyncSettings'
            name='SyncSettings'
            component={SyncSettingsPage}
            options={{
                title: lz('cloudSync'),
                ...defaultScreenOptions,
            }}
        />,

        ENV.isDev ||
        !!kFitnessKitTypes
            .map(getSharedFitnessKit)
            .find(fitness => fitness.supported) ? (
            <HomeStack.Screen
                key='FitnessSettings'
                name='FitnessSettings'
                component={FitnessSettingsPage}
                options={defaultScreenOptions}
            />
        ) : null,
    ];
};

export default syncSettingsScreens;
