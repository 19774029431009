import {
    ActivitySessions,
    DataSourceManagedMutationOptions,
    GlucoseSamples,
    InsulinDoses,
    Meals,
    RecordType,
    Schema,
    lz,
} from '@byterium/glucose-diary-client';
import { HeaderBackButton } from '@react-navigation/stack';
import React, { useCallback } from 'react';
import { ScrollView, StyleSheet, View, ViewProps } from 'react-native';
import { Button, Form } from 'react-native-form-model';
import { useTheme } from 'react-native-paper';
import { HeaderButtons, Item } from 'react-navigation-header-buttons';

import AppBackground from '../../components/AppBackground';
import { addErrorSnack, addSnack } from '../../components/AppSnackbar';
import CenteredContent from '../../components/CenteredContent';
import { KeyboardAvoidingView } from '../../components/KeyboardAvoidingView';
import { AppTheme, kMaxPageWidth } from '../../const';
import { useObservable, usePromise } from '../../reactUtil';
import UserSettings from '../../services/UserSettings';
import * as RecordForms from '../../services/forms/records';
import { AddSampleNavigationProp, AddSampleRouteProp } from '../navigate';

const kHeaderButtonMargin = 12;

export interface AddSamplePageOptions {
    recordType: RecordType;
    formData?: RecordForms.RecordFormData;
    insulinProduct?: Schema.InsulinProductFieldsFragment;
}

export interface AddSamplePageProps extends AddSamplePageOptions, ViewProps {
    navigation: AddSampleNavigationProp;
    route: AddSampleRouteProp;
}

const AddSamplePage = React.memo(
    ({ navigation, route, ...props }: AddSamplePageProps) => {
        const theme = useTheme() as AppTheme;
        const units = UserSettings.useUnits();
        const {
            recordType: defaultRecordType,
            formData: formDataProp,
            insulinProduct,
        } = {
            ...props,
            ...route.params,
        };

        const formData = React.useMemo(
            () =>
                formDataProp ||
                RecordForms.createRecordFormData({
                    recordType: defaultRecordType,
                }),
            [defaultRecordType, formDataProp]
        );

        React.useEffect(() => {
            if (insulinProduct) {
                formData.insulin.product.next(insulinProduct);
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [insulinProduct?.id]);

        const { value: recordType = defaultRecordType } = useObservable(
            formData.recordType
        );

        const [commit, setCommit] = React.useState<Promise<any> | undefined>(
            undefined
        );
        const { loading: saving } = usePromise(commit);

        const form = React.useMemo(() => {
            const form = RecordForms.createRecordForm({
                data: formData,
                theme,
                recordType,
                styles,
                units,
                isNew: true,
                navigation,
            });
            form.validateAll({ focusOnInvalid: true });
            return form;
        }, [formData, navigation, recordType, theme, units]);

        const onDone = useCallback(() => {
            if (!form.validateAll({ focusOnInvalid: true })) {
                return;
            }

            let action: Promise<any> | undefined;
            const options: DataSourceManagedMutationOptions = {
                context: { formData },
            };
            switch (recordType) {
                case 'ActivitySession':
                    action = ActivitySessions.add(
                        RecordForms.Activity.getActivityFormValues(formData),
                        options
                    );
                    break;
                case 'GlucoseSample':
                    action = GlucoseSamples.add(
                        RecordForms.Glucose.getGlucoseFormValues(formData),
                        options
                    );
                    break;
                case 'InsulinDose':
                    action = InsulinDoses.add(
                        RecordForms.Insulin.getInsulinFormValues(formData),
                        options
                    );
                    break;
                case 'Meal':
                    action = Meals.add(
                        RecordForms.Meal.getMealFormValues(formData),
                        options
                    );
                    break;
            }

            if (action) {
                // Only exit after confirmation
                setCommit(action);
                action
                    .then(() => {
                        addSnack(lz('dataAddedSuccessfully'));
                        navigation.pop();
                    })
                    .catch(err => {
                        console.error('Failed to add record: ' + err);
                        addErrorSnack(lz('dataAddFailedMessage'));
                        // navigate('AddSample', { recordType, formData });
                    });
            } else {
                navigation.pop();
            }
        }, [form, formData, navigation, recordType]);

        React.useEffect(() => {
            form.onSubmit = onDone;
        }, [form, onDone]);

        React.useLayoutEffect(() => {
            const onCancel = () => {
                navigation.pop();
            };
            navigation.setOptions({
                headerLeft: props => (
                    <HeaderBackButton
                        {...props}
                        disabled={saving}
                        onPress={() => onCancel()}
                    />
                ),
                headerBackTitle: lz('cancel'),
                headerRight: props => (
                    <HeaderButtons>
                        <Item
                            title={lz('done')}
                            disabled={saving}
                            onPress={() => onDone()}
                        />
                    </HeaderButtons>
                ),
                headerRightContainerStyle: styles.headerButton,
            });
        }, [navigation, onDone, saving]);

        return (
            <View style={styles.container}>
                <AppBackground />
                <KeyboardAvoidingView style={styles.container}>
                    <ScrollView
                        style={styles.container}
                        contentContainerStyle={styles.scrollContent}
                    >
                        <CenteredContent
                            maxWidth={kMaxPageWidth}
                            style={styles.content}
                        >
                            <Form form={form} style={styles.form} />

                            <Button
                                title={lz('done')}
                                mode='contained'
                                loading={saving}
                                disabled={saving}
                                onPress={() => onDone()}
                            />
                        </CenteredContent>
                    </ScrollView>
                </KeyboardAvoidingView>
            </View>
        );
    }
);

const styles = StyleSheet.create({
    headerButton: {
        marginHorizontal: kHeaderButtonMargin,
    },
    container: {
        flex: 1,
    },
    scrollContent: {
        flexGrow: 1,
    },
    content: {
        marginHorizontal: 12,
        marginBottom: 12,
    },
    form: {
        marginVertical: 12,
    },
    icon: {
        width: 24,
    },
});

export default AddSamplePage;
