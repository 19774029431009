import { FitnessKitType } from '@byterium/glucose-diary-client';
import React from 'react';

import { PngAssetProps } from './assets/assetConst';
import { AppleHealthLogo, GoogleFitLogo } from './assets/socialAssets';

export interface FitnessKitLogoProps extends PngAssetProps {
    fitnessType: FitnessKitType;
}

export function FitnessKitLogo({ fitnessType, ...props }: FitnessKitLogoProps) {
    switch (fitnessType) {
        case 'HealthKit':
            return <AppleHealthLogo {...props} />;
        case 'GoogleFit':
            return <GoogleFitLogo {...props} />;
    }
    return null;
}
