import { User } from '@byterium/glucose-diary-client';
import React from 'react';
import { Image, StyleSheet } from 'react-native';
import { Text, useTheme } from 'react-native-paper';

import { AppTheme } from '../../const';
import { useObservable } from '../../reactUtil';
import { Circle, CircleProps } from '../assets/commonAssets';

const kDefaultDiameter = 50;

export interface UserAvatarProps
    extends Omit<CircleProps, 'strokeColor' | 'diameter'> {
    diameter?: number;
    strokeColor?: string;
}

export default function UserAvatar({
    diameter = kDefaultDiameter,
    strokeColor,
    ...otherProps
}: UserAvatarProps) {
    const theme = useTheme() as AppTheme;
    const innerDiameter = diameter - 4;
    strokeColor = strokeColor || theme.colors.accent;
    const textStyle = {
        color: strokeColor,
        fontSize: Math.floor(diameter * 0.4),
    };
    const [photoError, setPhotoError] = React.useState(false);
    const { value: userData } = useObservable(User.currentData$);

    React.useEffect(() => {
        setPhotoError(false);
    }, [userData?.photoUrl]);

    return (
        <Circle {...otherProps} diameter={diameter} strokeColor={strokeColor}>
            {userData?.photoUrl && !photoError ? (
                <Circle
                    diameter={innerDiameter}
                    strokeColor={theme.colors.surface}
                >
                    <Image
                        source={{ uri: userData.photoUrl }}
                        style={{ width: innerDiameter, height: innerDiameter }}
                        onError={() => setPhotoError(true)}
                        onLoad={() => setPhotoError(false)}
                    />
                </Circle>
            ) : (
                <Text
                    style={[styles.initials, textStyle]}
                    onPressIn={undefined}
                    onPressOut={undefined}
                >
                    {User.initials(userData)}
                </Text>
            )}
        </Circle>
    );
}

const styles = StyleSheet.create({
    initials: {},
});
