import React from 'react';
import { ScrollView, StyleSheet } from 'react-native';
import { Form, FormModel } from 'react-native-form-model';
import { useTheme } from 'react-native-paper';

import CenteredContent from '../../../components/CenteredContent';
import { KeyboardAvoidingView } from '../../../components/KeyboardAvoidingView';
import { AppTheme, kMaxPageWidth } from '../../../const';
import { addDeveloperSettingsSections } from '../../../services/forms/developer/developerSettingsForm';
import { DeveloperSettingsPageOptions } from './DeveloperSettingsTypes';

export interface DeveloperSettingsPageProps
    extends DeveloperSettingsPageOptions {}

export default function DeveloperSettingsPage({
    navigation,
}: DeveloperSettingsPageProps) {
    const theme = useTheme() as AppTheme;

    const form = React.useMemo(() => {
        const form = FormModel.create({ style: theme.form });
        addDeveloperSettingsSections(form, {
            navigation,
            theme,
            styles,
            style: { sectionMargin: 12 },
        });
        return form;
    }, [navigation, theme]);

    return (
        <KeyboardAvoidingView style={styles.container}>
            <ScrollView
                style={styles.container}
                contentContainerStyle={styles.scrollContent}
            >
                <CenteredContent
                    maxWidth={kMaxPageWidth}
                    style={styles.content}
                >
                    <Form form={form} style={styles.form} />
                </CenteredContent>
            </ScrollView>
        </KeyboardAvoidingView>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    scrollContent: {
        flexGrow: 1,
    },
    content: {
        margin: 12,
    },
    form: {
        marginVertical: 12,
    },
    section: {
        marginBottom: 10,
    },
    icon: {
        width: 24,
    },
});
