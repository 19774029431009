import { lz } from '@byterium/glucose-diary-client';
import React from 'react';
import { Platform, StyleSheet } from 'react-native';
import { Colors, useTheme } from 'react-native-paper';

import { AppTheme } from '../../../const';
import { TranslucentButton } from '../../TranslucentButton';
import { EmailIcon } from '../../assets/commonAssets';
import { GoogleIcon, VKontakteIcon } from '../../assets/socialAssets';
import { SignInButtonProps } from './loginButtonTypes';

const iconSize = 24;

export const VKontakteSignInButton: React.FC<SignInButtonProps> = ({
    title = lz('signInWithVKontakte'),
    icon = true,
    mode = 'contained',
    style,
    disabled,
    ...props
}) => {
    return (
        <TranslucentButton
            {...props}
            style={[styles.button, style]}
            title={title}
            numberOfLines={Platform.OS === 'ios' ? 1 : 0}
            icon={
                icon &&
                (props => (
                    <VKontakteIcon
                        width={iconSize}
                        height={iconSize}
                        style={{
                            opacity: disabled ? 0.5 : 1,
                            margin: 8,
                        }}
                    />
                ))
            }
        />
    );
};

export const GoogleSignInButton: React.FC<SignInButtonProps> = ({
    title = lz('signInWithGoogle'),
    icon = true,
    mode = 'contained',
    style,
    disabled,
    ...props
}) => {
    // See https://developers.google.com/identity/branding-guidelines
    return (
        <TranslucentButton
            {...props}
            style={[styles.button, style]}
            title={title}
            numberOfLines={Platform.OS === 'ios' ? 1 : 0}
            icon={
                icon &&
                (props => (
                    <GoogleIcon
                        {...props}
                        width={iconSize}
                        height={iconSize}
                        style={{
                            opacity: disabled ? 0.5 : 1,
                            margin: 8,
                        }}
                    />
                ))
            }
        />
    );
};

export const EmailSignInButton: React.FC<SignInButtonProps> = ({
    title = lz('signInWithEmail'),
    icon = true,
    style,
    ...props
}) => {
    const theme = useTheme() as AppTheme;
    return (
        <TranslucentButton
            {...props}
            style={[styles.button, style]}
            title={title}
            numberOfLines={Platform.OS === 'ios' ? 1 : 0}
            icon={
                icon &&
                (props => (
                    <EmailIcon
                        {...props}
                        color={theme.colors.textMoreSlightlyFaded}
                        style={styles.emailIcon}
                    />
                ))
            }
        />
    );
};

const styles = StyleSheet.create({
    button: {
        minHeight: 40,
    },
    shadow: {
        shadowColor: '#171717',
        shadowOffset: { width: 0, height: 2 },
        shadowRadius: 12,
        shadowOpacity: 0.1,
        overflow: 'visible',
    },
    lightText: {
        color: Colors.grey200,
    },
    darkText: {
        color: Colors.grey800,
    },
    emailIcon: {
        width: 24,
        height: 24,
        marginRight: 8,
    },
    emailText: {
        fontSize: 14,
    },
});
