import { RecordType } from '@byterium/glucose-diary-client';
import React from 'react';

import { AppTheme } from '../../const';
import { ActivityIcon } from './activityAssets';
import { AssetProps } from './assetConst';
import { GlucoseIcon } from './glucoseAssets';
import { InsulinIcon } from './insulinAssets';
import { MealIcon } from './mealAssets';

export interface RecordTypeIconProps extends AssetProps {
    recordType: RecordType;
}

export function RecordTypeIcon({ recordType, ...props }: RecordTypeIconProps) {
    switch (recordType) {
        case 'ActivitySession':
            return <ActivityIcon {...props} />;
        case 'GlucoseSample':
            return <GlucoseIcon {...props} />;
        case 'InsulinDose':
            return <InsulinIcon {...props} />;
        case 'Meal':
            return <MealIcon {...props} />;
    }
    throw new Error('Invalid sample type');
}

/**
 * Return the formatted sample type color.
 *
 * We use a function becuase we do not assume that
 * the user accessibility settings will stay constant.
 * @param recordType
 */
export function recordTypeColor(
    recordType: RecordType,
    theme: AppTheme
): string {
    switch (recordType) {
        case 'ActivitySession':
            return theme.colors.activity;
        case 'GlucoseSample':
            return theme.colors.glucose;
        case 'InsulinDose':
            return theme.colors.insulin;
        case 'Meal':
            return theme.colors.meal;
    }
    throw new Error('Invalid sample type');
}
