import { lz } from '@byterium/glucose-diary-client';
import React from 'react';
import { StyleSheet, ViewProps } from 'react-native';
import { Form, FormModel } from 'react-native-form-model';
import { Banner } from 'react-native-paper';

import { kMaxPageWidth } from '../../const';
import CenteredContent from '../CenteredContent';

export interface InsulinProductSearchBarProps extends ViewProps {
    form: FormModel;
    error?: Error;
    retry: () => void;
}

function InsulinProductSearchBar({
    form,
    error,
    retry,
    ...props
}: InsulinProductSearchBarProps) {
    return (
        <CenteredContent maxWidth={kMaxPageWidth} style={props}>
            <Form form={form} />

            <Banner
                style={styles.banner}
                visible={!!error}
                actions={[
                    {
                        label: lz('retry'),
                        onPress: retry,
                    },
                ]}
            >
                {`${lz('somethingWentWrong')}: ${String(
                    error?.message || error || ''
                )}`}
            </Banner>
        </CenteredContent>
    );
}

const styles = StyleSheet.create({
    banner: {
        marginTop: 8,
    },
});

export default React.memo(InsulinProductSearchBar);
