import {
    RecordDataPoint,
    Records,
    Schema,
    WatchPointsConnection,
} from '@byterium/glucose-diary-client';
import { DateScale } from '@byterium/librechart';
import moment from 'moment';
import { Observable, combineLatest } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

import {
    ChartMode,
    DatePeriod,
    RecordChartDataPoint,
    kDataDisplayDebounceInterval,
    kDefaultChartFetchQuery,
} from './chartConst';
import {
    bucketPointData,
    detectPointTops,
    updateHorizontalLabelVisiblity,
    updateVerticalLabelVisiblity,
} from './chartUtil';

export function chartFetchQuery(
    overrides?: Schema.FindRecordQuery
): Schema.FindRecordQuery {
    const startDate = moment().startOf('day');
    return {
        ...kDefaultChartFetchQuery,
        startDate,
        endDate: startDate.clone().add(1, 'day'),
        ...overrides,
    };
}

export function dataSourceRecords$(
    dataSource: Records<any>
): WatchPointsConnection {
    const res = dataSource.watchPoints(chartFetchQuery());
    return {
        ...res,
        points: res.points.pipe(debounceTime(kDataDisplayDebounceInterval)),
    };
}

export function mapRecordsToPoints({
    records$,
    minorPeriod$,
    mode,
}: {
    records$: Observable<RecordDataPoint[]>;
    minorPeriod$: Observable<DatePeriod>;
    mode: ChartMode;
}): Observable<RecordChartDataPoint[]> {
    const distinctMinorPeriod$ = minorPeriod$.pipe(distinctUntilChanged());
    switch (mode) {
        case 'line':
            return records$.pipe(
                map(points => {
                    detectPointTops(points);
                    return points;
                })
            );
        case 'bar':
        case 'scatter':
            return combineLatest([records$, distinctMinorPeriod$]).pipe(
                map(state => {
                    let [points, minorPeriod] = state;
                    // Bucket data
                    points = bucketPointData({
                        records: points,
                        period: minorPeriod,
                        yMode: 'sum',
                        xMode: mode === 'bar' ? 'floor' : 'average',
                    });
                    return points;
                })
            );
    }
}

export function mapPointsToLabelPoints({
    points$,
    minLabelViewDistanceX$,
    dateScale,
    mode,
}: {
    points$: Observable<RecordChartDataPoint[]>;
    minLabelViewDistanceX$: Observable<number>;
    dateScale: DateScale;
    mode: ChartMode;
}): Observable<RecordChartDataPoint[]> {
    return combineLatest([points$, minLabelViewDistanceX$]).pipe(
        map(state => {
            const [points, minLabelViewDistanceX] = state;
            switch (mode) {
                case 'line':
                case 'bar':
                    updateVerticalLabelVisiblity(
                        points,
                        minLabelViewDistanceX,
                        dateScale,
                        mode === 'bar'
                    );
                    break;
                case 'scatter':
                    updateHorizontalLabelVisiblity(
                        points,
                        minLabelViewDistanceX,
                        dateScale
                    );
                    break;
            }
            return points.filter(x => x.showLabel);
        })
    );
}
