import {
    Meals,
    formatCarbUnit,
    formatCarbValue,
    formatEnergyUnit,
    formatEnergyValue,
    lz,
    lzDate,
} from '@byterium/glucose-diary-client';
import React from 'react';

import { navigate } from '../../pages/navigate';
import UserSettings from '../../services/UserSettings';
import { MealIcon } from '../assets/mealAssets';
import RecordSummaryCell, {
    RecordSummaryBuildOptions,
    RecordSummaryCellProps,
} from '../records/RecordSummaryCell';

export const useMealSummaryCell = (
    options: RecordSummaryBuildOptions
): React.ReactNode => {
    const { record, error, loading } = Meals.useLatest();
    const { carbUnit, carbConfig, energyUnit } = UserSettings.useUnits();
    const props: Partial<RecordSummaryCellProps> = {};

    if (record) {
        props.date = lzDate(record.date);

        const valuesAndUnits: { value: string; unit: string }[] = [];
        if (record.carbs) {
            valuesAndUnits.push({
                value: formatCarbValue(record.carbs, carbUnit, carbConfig),
                unit: formatCarbUnit(carbUnit),
            });
        }

        if (record.energy) {
            valuesAndUnits.push({
                value: formatEnergyValue(record.energy, energyUnit),
                unit: formatEnergyUnit(energyUnit),
            });
        }

        if (valuesAndUnits.length === 0) {
            valuesAndUnits.push({
                value: '',
                unit: '',
            });
        }

        props.value = valuesAndUnits[0].value;
        props.unit = valuesAndUnits[0].unit;

        if (valuesAndUnits.length > 1) {
            props.description = valuesAndUnits
                .slice(1)
                .map(x => x.value + ' ' + x.unit)
                .join('/n');
        }
    }

    return (
        <RecordSummaryCell
            {...options}
            {...props}
            title={lz('carbohydrates')}
            titleIcon={() => <MealIcon />}
            error={error ? error.message || String(error) : ''}
            placeholder={loading ? lz('loading') : lz('noData')}
            primaryColor={options.theme.colors.meal}
            onPress={() => navigate('RecordSummary', { recordType: 'Meal' })}
        />
    );
};
