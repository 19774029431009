import { Schema, UserDocument, lz } from '@byterium/glucose-diary-client';
import React from 'react';
import {
    ActivityIndicator,
    ScrollView,
    StyleSheet,
    View,
    ViewProps,
} from 'react-native';
import { Button } from 'react-native-form-model';
import { Caption, Colors, Headline, Text, Title } from 'react-native-paper';

import { usePromise } from '../reactUtil';
import CenteredContent from './CenteredContent';

const kDefaultMaxWidth = 600;
const kFooterDoneButtonMinDocLength = 2000;

export interface DocumentViewerProps extends ViewProps {
    document: UserDocument | undefined;
    maxWidth?: number;
    onDismiss?: () => any;
}

export default function DocumentViewer({
    document,
    maxWidth = kDefaultMaxWidth,
    onDismiss,
    style,
    ...props
}: DocumentViewerProps) {
    const {
        value: content,
        error,
        loading,
    } = usePromise(() => document?.getContent(), [document?.hash]);

    const title =
        document?.type === Schema.DocumentType.TermsAndConditions
            ? lz('termsAndConditions')
            : '';

    const errorMsg =
        (typeof error === 'object' &&
            error instanceof Error &&
            error?.message) ||
        error ||
        '';

    return (
        <ScrollView
            {...props}
            style={[styles.container, style]}
            contentContainerStyle={[styles.scrollContent, { maxWidth }]}
        >
            <CenteredContent maxWidth={maxWidth} style={styles.content}>
                <View style={styles.flex} />

                <View>
                    {(() => {
                        if (loading) {
                            return (
                                <View>
                                    <ActivityIndicator size='large' animating />
                                    <Caption style={styles.message}>
                                        {lz('loadingPleaseWait', {
                                            default: 'Loading...',
                                        })}
                                    </Caption>
                                </View>
                            );
                        } else if (errorMsg) {
                            return (
                                <View>
                                    <Headline
                                        style={[
                                            styles.message,
                                            { color: Colors.red800 },
                                        ]}
                                    >
                                        {lz('somethingWentWrong')}
                                    </Headline>
                                    <Text
                                        style={styles.message}
                                        onPressIn={undefined}
                                        onPressOut={undefined}
                                    >
                                        {errorMsg}
                                    </Text>
                                </View>
                            );
                        } else {
                            return (
                                <View>
                                    <View style={styles.row}>
                                        <Title style={[styles.message]}>
                                            {title}
                                        </Title>
                                        {onDismiss && (
                                            <Button
                                                title={lz('close')}
                                                onPress={onDismiss}
                                                style={styles.button}
                                            />
                                        )}
                                    </View>
                                    <Text
                                        style={styles.message}
                                        onPressIn={undefined}
                                        onPressOut={undefined}
                                    >
                                        {content || ''}
                                    </Text>

                                    <View style={[styles.row, styles.footer]}>
                                        <View style={styles.flex} />
                                        {onDismiss &&
                                            (content?.length || 0) >
                                                kFooterDoneButtonMinDocLength && (
                                                <Button
                                                    title={lz('close')}
                                                    onPress={onDismiss}
                                                    style={styles.button}
                                                />
                                            )}
                                    </View>
                                </View>
                            );
                        }
                    })()}
                </View>

                <View style={styles.flex} />
            </CenteredContent>
        </ScrollView>
    );
}

const styles = StyleSheet.create({
    container: {
        // flex: 1,
    },
    scrollContent: {
        flexGrow: 1,
    },
    content: {
        flex: 1,
        // alignContent: 'stretch',
    },
    row: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    footer: {
        marginVertical: 8,
    },
    message: {
        paddingVertical: 10,
        paddingHorizontal: 20,
        textAlign: 'left',
    },
    button: {
        marginHorizontal: 8,
    },
    flex: {
        flex: 1,
    },
});
