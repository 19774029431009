import {
    OnboardActions,
    Schema,
    User,
    UserDocument,
    UserDocuments,
    lz,
} from '@byterium/glucose-diary-client';
import _ from 'lodash';
import React from 'react';
import { ScrollView, StyleSheet, View, ViewProps } from 'react-native';
import { Button, Form, FormModel } from 'react-native-form-model';
import { Banner, Headline, Modal, Portal, useTheme } from 'react-native-paper';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import AppBackground from '../components/AppBackground';
import AppFooter from '../components/AppFooter';
import CenteredContent from '../components/CenteredContent';
import DocumentViewer from '../components/DocumentViewer';
import { KeyboardAvoidingView } from '../components/KeyboardAvoidingView';
import TermsLabel from '../components/login/TermsLabel';
import { AppTheme, kMaxPageWidth } from '../const';
import { usePromise } from '../reactUtil';
import { composeSupportEmail } from '../services/email';
import { GoogleFit, HealthKit } from '../services/fitness';
import {
    addFitnessPermissionSection,
    kDefaultFitnessSyncInterval,
} from '../services/forms/sync/syncForms';
import {
    addDocumentSectionToForm,
    createDocumentData,
} from '../services/forms/user/userForms';
import { navigateReplace } from './navigate';

export interface OnboardPageProps extends ViewProps {}

export default function OnboardPage(props: OnboardPageProps) {
    const theme = useTheme() as AppTheme;
    const insets = useSafeAreaInsets();

    const [form, setForm] = React.useState(() => FormModel.empty());
    const [saveAction, setSaveAction] = React.useState<
        Promise<any> | undefined
    >(undefined);
    const { loading: saving = false, error: saveError } =
        usePromise(saveAction);

    const onboardActions = React.useMemo<OnboardActions>(
        () => User.current()?.getOnboardActions() || User.emptyOnboardActions(),
        []
    );
    const { data: docData, signDocuments } = React.useRef(
        createDocumentData(onboardActions.documentsToSign)
    ).current;

    const [viewingDoc, setViewingDoc] = React.useState<
        UserDocument | undefined
    >(undefined);

    React.useEffect(() => {
        const form = FormModel.empty();

        [
            {
                setup: onboardActions.setupHealthKit,
                fitness: HealthKit.shared(),
            },
            {
                setup: onboardActions.setupGoogleFit,
                fitness: GoogleFit.shared(),
            },
        ].forEach(({ setup, fitness }) => {
            if (setup) {
                // Enable by default
                fitness.sync$.next(kDefaultFitnessSyncInterval);
                addFitnessPermissionSection({
                    fitness,
                    form,
                    theme,
                    styles,
                    title: fitness.name,
                    style: { sectionTitleAlign: 'center' },
                });
            }
        });

        addDocumentSectionToForm({
            form,
            data: docData,
            theme,
            customTermsLabel: props => (
                <TermsLabel
                    {...props}
                    onPress={() => {
                        setViewingDoc(
                            UserDocuments.get(
                                Schema.DocumentType.TermsAndConditions
                            )
                        );
                    }}
                />
            ),
        });

        form.validateAll({ focusOnInvalid: true });
        setForm(form);
    }, [
        docData,
        onboardActions.setupGoogleFit,
        onboardActions.setupHealthKit,
        theme,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        ...Object.values(_.omit(onboardActions, 'documentsToSign')),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        onboardActions.documentsToSign.map(doc => String(doc.type)).join(','),
    ]);

    const error = saveError;

    const trySave = () => {
        if (!form.validateAll({ focusOnInvalid: true })) {
            return;
        }

        setSaveAction(
            User.current()
                ?.updateUser({
                    signedDocuments: signDocuments(),
                })
                .then(() => navigateReplace('Home')) ||
                Promise.reject(new Error('Missing user'))
        );
    };

    return (
        <View style={styles.container}>
            <AppBackground />
            <KeyboardAvoidingView style={styles.container}>
                <ScrollView
                    style={styles.container}
                    contentContainerStyle={[
                        styles.scrollContent,
                        {
                            paddingTop: insets.top,
                            paddingLeft: insets.left,
                            paddingRight: insets.right,
                            paddingBottom: insets.bottom,
                        },
                    ]}
                >
                    <CenteredContent
                        maxWidth={kMaxPageWidth}
                        style={styles.contentContainer}
                    >
                        <Banner
                            visible={!!error}
                            actions={[
                                {
                                    label: lz('retry'),
                                    onPress: () => trySave(),
                                },
                                {
                                    label: lz('contactUs'),
                                    onPress: () => composeSupportEmail(),
                                },
                            ]}
                        >
                            {`${lz('somethingWentWrong')}: ${String(
                                error?.message || error || ''
                            )}`}
                        </Banner>

                        <Headline style={styles.message}>
                            {lz('beforeWeContinueMessage')}
                        </Headline>

                        <Form form={form} style={styles.form} />

                        <Button
                            title={lz('proceed')}
                            disabled={saving}
                            loading={saving}
                            onPress={() => trySave()}
                            mode='contained'
                            style={styles.button}
                        />
                    </CenteredContent>
                    <AppFooter
                        showContactButton
                        showLogoutButton
                        showDeleteAccountButton={
                            onboardActions.documentsToSign.length !== 0
                        }
                        style={styles.footer}
                    />
                </ScrollView>

                <Portal theme={theme}>
                    <Modal
                        visible={!!viewingDoc}
                        onDismiss={() => setViewingDoc(undefined)}
                        contentContainerStyle={[
                            styles.modal,
                            {
                                backgroundColor: theme.colors.surface,
                                borderRadius: theme.roundness,
                            },
                        ]}
                    >
                        <DocumentViewer
                            document={viewingDoc}
                            onDismiss={() => setViewingDoc(undefined)}
                            // style={styles.content}
                        />
                    </Modal>
                </Portal>
            </KeyboardAvoidingView>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    scrollContent: {
        flexGrow: 1,
        flexDirection: 'column',
        alignItems: 'center',
    },
    contentContainer: {
        padding: 16,
        flexGrow: 1,
        alignItems: 'center',
    },
    form: {
        marginVertical: 12,
    },
    message: {
        paddingVertical: 10,
        paddingHorizontal: 20,
        textAlign: 'center',
    },
    button: {
        marginTop: 8,
    },
    logoutButton: {
        marginTop: 8,
    },
    modal: {
        margin: 12,
        flex: 1,
        alignSelf: 'center',
    },
    icon: {
        width: 24,
    },
    footer: {},
    flexGrow: {
        flexGrow: 1,
    },
});
