import React from 'react';
import { StyleSheet, View, ViewProps } from 'react-native';
import { Text, TouchableRipple, useTheme } from 'react-native-paper';

import { AppTheme } from '../../const';
import { ChevronIcon } from '../assets/commonAssets';

const kIconSizeAdd = 2;

export interface RecordSummaryBuildOptions
    extends ViewProps,
        Pick<RecordSummaryCellProps, 'placeholder' | 'onPress'> {
    theme: AppTheme;
}

export interface RecordSummaryCellProps extends ViewProps {
    title?: string;
    titleIcon?: string | (() => React.ReactNode);
    date?: string;
    value?: string;
    placeholder?: string;
    error?: string;
    unit?: string;
    description?: string;
    primaryColor?: string;
    onPress?: () => void;
}

interface RecordSummaryCellInternalProps extends RecordSummaryCellProps {}

const RecordSummaryCell: React.FC<RecordSummaryCellInternalProps> = ({
    title,
    titleIcon,
    date,
    value,
    placeholder,
    unit,
    description,
    primaryColor,
    onPress,
    ...otherProps
}) => {
    const theme = useTheme() as AppTheme;
    primaryColor = primaryColor || theme.colors.primary;

    const iconSize = styles.title.fontSize + kIconSizeAdd;

    let titleIconView: React.ReactNode = null;
    if (titleIcon) {
        if (typeof titleIcon === 'string') {
            titleIconView = <ChevronIcon size={iconSize} />;
        } else {
            titleIconView = titleIcon();
        }
    }

    const header = (
        <View style={styles.header}>
            {titleIconView}

            <View
                style={[
                    styles.headerRowContent,
                    { paddingLeft: titleIconView ? hPadding : 0 },
                ]}
            >
                <Text
                    selectable={false}
                    style={[styles.title, { color: primaryColor }]}
                    onPressIn={undefined}
                    onPressOut={undefined}
                >
                    {title || ''}
                </Text>
                <FlexibleSpace />
                <Text
                    selectable={false}
                    style={[
                        styles.date,
                        {
                            color: theme.colors.textSlightlyFaded,
                        },
                    ]}
                    onPressIn={undefined}
                    onPressOut={undefined}
                >
                    {date || ''}
                </Text>
            </View>

            {onPress && <ChevronIcon size={iconSize} style={styles.chevron} />}
        </View>
    );

    const body = (
        <View style={styles.body}>
            {value ? (
                <View style={styles.row}>
                    <Text
                        selectable={false}
                        style={[styles.value, { color: theme.colors.text }]}
                        onPressIn={undefined}
                        onPressOut={undefined}
                    >
                        {value || ''}
                    </Text>
                    <Text
                        selectable={false}
                        style={[
                            styles.unit,
                            { color: theme.colors.textStronglyFaded },
                        ]}
                        onPressIn={undefined}
                        onPressOut={undefined}
                    >
                        {unit || ''}
                    </Text>
                </View>
            ) : (
                <Text
                    selectable={false}
                    style={[
                        styles.placeholder,
                        { color: theme.colors.textStronglyFaded },
                    ]}
                    onPressIn={undefined}
                    onPressOut={undefined}
                >
                    {placeholder || ''}
                </Text>
            )}
            {!!description && (
                <Text
                    selectable={false}
                    style={styles.description}
                    onPressIn={undefined}
                    onPressOut={undefined}
                >
                    {description}
                </Text>
            )}
        </View>
    );

    const innerContainer = (
        <View style={{ flex: 1 }}>
            {header}
            {body}
        </View>
    );

    const containerStyle = [
        styles.container,
        {
            backgroundColor: theme.colors.surface,
            borderRadius: theme.roundness,
        },
        otherProps.style,
    ];

    if (onPress) {
        const rippleColor = theme.colors.primary + '60';
        return (
            <TouchableRipple
                onPress={() => onPress()}
                rippleColor={rippleColor}
                underlayColor={rippleColor}
                style={containerStyle}
            >
                {innerContainer}
            </TouchableRipple>
        );
    } else {
        return <View style={containerStyle}>{innerContainer}</View>;
    }
};

const FlexibleSpace = React.memo(() => <View style={styles.flex} />);

const hPadding = 10;
const vPadding = 10;

const styles = StyleSheet.create({
    container: {
        paddingHorizontal: 12,
        paddingVertical: vPadding,
        overflow: 'hidden',
    },
    header: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    headerRowContent: {
        flexShrink: 1,
        flexGrow: 1,
        flexDirection: 'row',
        alignItems: 'center',
        flexWrap: 'wrap',
    },
    row: {
        flexDirection: 'row',
        flexWrap: 'wrap',
    },
    title: {
        paddingRight: hPadding,
        fontSize: 14,
        fontWeight: 'bold',
    },
    date: {
        fontSize: 14,
    },
    chevron: {
        paddingLeft: hPadding,
    },
    body: {
        paddingTop: 8,
        flex: 1,
    },
    value: {
        paddingRight: hPadding,
        fontWeight: 'bold',
        fontSize: 22,
    },
    unit: {
        paddingRight: hPadding,
        fontSize: 22,
    },
    placeholder: {
        paddingRight: hPadding,
        fontSize: 14,
    },
    description: {
        paddingTop: 8,
        fontSize: 14,
    },
    flex: {
        flex: 1,
    },
});

export default RecordSummaryCell;
