import { lz } from '@byterium/glucose-diary-client';
import React from 'react';
import { StyleSheet } from 'react-native';
import { Button } from 'react-native-form-model';
import { Colors, useTheme } from 'react-native-paper';

import { AppTheme } from '../../../../const';
import { AppleLogo } from '../../../assets/socialAssets';
import { SignInButtonProps } from '../loginButtonTypes';

const DefaultAppleSignInButton: React.FC<SignInButtonProps> = ({
    title = lz('signInWithApple'),
    icon = true,
    mode = 'contained',
    style,
    disabled,
    ...props
}) => {
    const theme = useTheme() as AppTheme;
    return (
        <Button
            {...props}
            title={title}
            mode={mode}
            icon={
                icon &&
                (props => (
                    <AppleLogo
                        size={24}
                        style={{
                            opacity: disabled ? 0.5 : 1,
                            margin: 8,
                        }}
                        color={theme.dark ? Colors.black : Colors.white}
                    />
                ))
            }
            color={theme.dark ? Colors.white : Colors.black}
            style={[
                styles.button,
                {
                    borderRadius: theme.roundness,
                },
                style,
            ]}
        />
    );
};

export default DefaultAppleSignInButton;

const styles = StyleSheet.create({
    button: {
        minHeight: 40,
    },
});
