import { ENV } from '@byterium/glucose-diary-client';

import { unregister } from '../../serviceWorkerRegistration';
import * as Vercel from '../vercel';
import UpdaterBase from './UpdaterBase';

const kGitCommitHashStorageKey = 'git_commit_sha';

export default class WebUpdater extends UpdaterBase {
    get isSupported(): boolean {
        return !!ENV.VERCEL;
    }
    get downloadRequired(): boolean {
        return false;
    }

    async _executeUpdateCheck() {
        await this._saveCurrentVersionIfNeeded();
        const installedVersion = this._getInstalledVersion();
        if (!installedVersion) {
            throw new Error(
                'Unable to check for updates, installed version not set'
            );
        }

        const info = await Vercel.getServerInfo();
        const { VERCEL_GIT_COMMIT_SHA: currentVersion } = info.env || {};

        const isAvailable =
            currentVersion && currentVersion !== installedVersion;
        this.setUpdateAvailable(isAvailable);
    }

    private _getInstalledVersion() {
        return localStorage.getItem(kGitCommitHashStorageKey);
    }

    private async _saveCurrentVersionIfNeeded() {
        const currentHash = ENV.VERCEL_GIT_COMMIT_SHA;
        if (!currentHash) {
            return;
        }
        const storedHash = this._getInstalledVersion();
        if (storedHash !== currentHash) {
            // Save current hash
            localStorage.setItem(kGitCommitHashStorageKey, currentHash);
        }
    }

    async downloadUpdate() {}

    async installUpdate() {
        await unregister();
        window.location.reload();
    }
}
