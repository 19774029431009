import {
    GlucoseUnit,
    RecordType,
    convertGlucoseValue,
    formatGlucoseUnit,
    kBaseGlucoseUnit,
    lzn,
} from '@byterium/glucose-diary-client';
import React from 'react';
import { StyleSheet, View, ViewProps } from 'react-native';
import { useTheme } from 'react-native-paper';

import RecordChart from '../components/RecordChart';
import { AppTheme } from '../const';
import UserSettings from '../services/UserSettings';
import { ChartBaseOptions } from './RecordChart/chartConst';

/**
 * Converts glucose units if needed.
 * @param point
 */
const createValueTransformer = (unit: GlucoseUnit) => {
    return (value: number) =>
        convertGlucoseValue(value, kBaseGlucoseUnit, unit);
};

export interface OverviewChartProps extends ChartBaseOptions, ViewProps {}

export default function OverviewChart({
    isFullscreen,
    onFullscreenPress,
    ...props
}: OverviewChartProps) {
    const theme = useTheme() as AppTheme;
    const units = UserSettings.useUnits();
    const valueTransformer = createValueTransformer(units.glucoseUnit);
    const sharedProps = {
        recordType: 'GlucoseSample' as RecordType,
        valueTransformer,
        valueFormatter: lzn,
        units: formatGlucoseUnit(units.glucoseUnit),
    };
    return (
        <View {...props}>
            <RecordChart
                isOverview
                {...sharedProps}
                isFullscreen={isFullscreen}
                onFullscreenPress={onFullscreenPress}
                highColor={theme.colors.glucoseHighFaded}
                color={theme.colors.glucoseNormal}
                lowColor={theme.colors.glucoseLowFaded}
                highValue={valueTransformer(
                    UserSettings.glucoseTargetUpperLimit$.value
                )}
                lowValue={valueTransformer(
                    UserSettings.glucoseTargetLowerLimit$.value
                )}
                style={styles.chart}
            />
        </View>
    );
}

const styles = StyleSheet.create({
    chart: {
        flex: 1,
    },
});
