import {
    RecordModel,
    isActivitySession,
    isGlucoseSample,
    isInsulinDose,
    isMeal,
} from '@byterium/glucose-diary-client';

import * as Activity from './activityForms';
import * as Glucose from './glucoseForms';
import * as Insulin from './insulinForms';
import * as Meal from './mealForms';
import { RecordFormData, setRecordFormValues } from './recordFormData';

export function setCombinedRecordFormValues<T extends RecordModel>(
    formData: RecordFormData,
    values: T
) {
    setRecordFormValues(formData, values);
    if (isActivitySession(values)) {
        Activity.setActivityFormValues(formData, values);
    }
    if (isGlucoseSample(values)) {
        Glucose.setGlucoseFormValues(formData, values);
    }
    if (isInsulinDose(values)) {
        Insulin.setInsulinFormValues(formData, values);
    }
    if (isMeal(values)) {
        Meal.setMealFormValues(formData, values);
    }
    return formData;
}
