import {
    RecordDataPoint,
    getRecordDataSource,
    lz,
} from '@byterium/glucose-diary-client';
import _ from 'lodash';
import React, { useCallback } from 'react';
import {
    Platform,
    RefreshControl,
    SectionList,
    StyleSheet,
    TouchableOpacity,
    View,
    ViewProps,
} from 'react-native';
import { Button, Divider, Row } from 'react-native-form-model';
import { Text, useTheme } from 'react-native-paper';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { addErrorSnack, addSnack } from '../../components/AppSnackbar';
import CenteredContent from '../../components/CenteredContent';
import RefreshButton from '../../components/RefreshButton';
import Stack from '../../components/Stack';
import { EditIcon } from '../../components/assets/commonAssets';
import RecordListItem from '../../components/records/RecordListItem';
import { AppTheme, kMaxPageWidth } from '../../const';
import {
    RefreshConfig,
    useCustomNavigationHeaders,
    usePromise,
    useRefreshAction,
} from '../../reactUtil';
import UserSettings from '../../services/UserSettings';
import {
    RecordListNavigationProp,
    RecordListPageOptions,
    RecordListRouteProp,
} from './recordTypes';
import { getRecordDescriptionInterface, getValueFormatter } from './recordUtil';

const kFetchLimit = 20;
const kDefaultFetchQuery = {
    descending: true,
    limit: kFetchLimit,
    offset: 0,
};

const isNative = Platform.OS !== 'web';

export interface RecordListPageProps extends RecordListPageOptions {
    navigation: RecordListNavigationProp;
    route: RecordListRouteProp;
}

export default function RecordListPage(props: RecordListPageProps) {
    const theme = useTheme() as AppTheme;
    const insets = useSafeAreaInsets();
    const combinedProps = {
        ...props,
        ...props.route.params,
    };
    const { recordType, unit, navigation } = combinedProps;
    const units = UserSettings.useUnits();
    const valueFormatter = React.useMemo(
        () => getValueFormatter({ recordType, units }),
        [recordType, units]
    );
    const { getDescription, getDescriptionColor } = React.useMemo(
        () => getRecordDescriptionInterface({ recordType, theme }),
        [recordType, theme]
    );
    const dataSource = getRecordDataSource(recordType);

    const [editing, setEditing] = React.useState(false);

    const [extraDataNonce, setExtraDataNonce] = React.useState(0);

    const [confirmingDeleteId, setConfirmingDeleteId] = React.useState<
        string | undefined
    >();
    const deletingIds = React.useRef(new Set<string>()).current;
    const [detailDelete, setDetailDelete] = React.useState<
        Promise<void> | undefined
    >(undefined);
    const { loading: deleting, error: deleteError } = usePromise(detailDelete);

    React.useEffect(() => {
        if (!deleting && !deleteError) {
            // Deleted successfully
            deletingIds.clear();
        }
    }, [deleting, deleteError, deletingIds]);

    React.useEffect(() => {
        if (deleteError) {
            const deleteRecordsAgain = (ids: string[]) => {
                setDetailDelete(dataSource.delete({ ids }));
            };

            console.error('Failed to delete record(s): ' + deleteError);
            addErrorSnack({
                message: lz('somethingWentWrong'),
                action: {
                    label: lz('retry'),
                    onPress: () => deleteRecordsAgain([...deletingIds]),
                },
            });
        }
    }, [dataSource, deleteError, deletingIds]);

    const refreshConfig = React.useMemo(
        (): RefreshConfig<any> => ({
            refreshTask: () => dataSource.refetchAll(),
            onRefreshed: res =>
                addSnack({
                    message: res.error
                        ? lz('somethingWentWrong')
                        : lz('dataUpdatedSuccessfully'),
                    type: res.error ? 'error' : 'info',
                }),
            disabled: deleting,
        }),
        [dataSource, deleting]
    );

    const refreshAction = useRefreshAction(refreshConfig);

    const toggleEditing = () => {
        setEditing(editing => !editing);
        setConfirmingDeleteId(undefined);
    };

    useCustomNavigationHeaders(
        {
            navigation,
            headerRight: props => (
                <Stack style={styles.navHeaderRightContainer} horizontal>
                    {!isNative && (
                        <RefreshButton
                            {...refreshAction}
                            color={props.tintColor || theme.colors.accent}
                        />
                    )}
                    <TouchableOpacity
                        disabled={deleting}
                        onPress={() => toggleEditing()}
                    >
                        <EditIcon
                            filled={editing}
                            color={props.tintColor || theme.colors.accent}
                        />
                    </TouchableOpacity>
                </Stack>
            ),
        },
        [
            editing,
            deleting,
            theme.colors.accent,
            ...(isNative ? [] : [...Object.values(refreshAction)]),
        ]
    );

    const {
        points = [],
        loading: loadingList,
        fetchMore: _fetchMore,
    } = dataSource.usePoints({ ...kDefaultFetchQuery });
    const pointsByDay = _.groupBy(points, point =>
        point.x.clone().startOf('d').format('LL')
    );
    const sections = Object.keys(pointsByDay).map(day => ({
        title: day,
        data: pointsByDay[day],
    }));

    const nextFetchOffsetRef = React.useRef(kFetchLimit);
    if (points.length > nextFetchOffsetRef.current) {
        nextFetchOffsetRef.current = points.length;
    }

    const handleDeletePress = (id: string) => {
        if (confirmingDeleteId !== id) {
            setConfirmingDeleteId(id);
            return;
        }
        setConfirmingDeleteId(undefined);

        if (!id || deletingIds.has(id)) {
            return;
        }
        deletingIds.add(id);
        setDetailDelete(
            Promise.all([
                detailDelete || Promise.resolve(),
                dataSource.delete({ id }),
            ]).then(() => {})
        );
    };

    const openRecordDetail = React.useCallback(
        (recordId: string) => {
            navigation.navigate('RecordDetail', {
                ...props.route.params,
                recordType,
                recordId,
            });
        },
        [navigation, props.route.params, recordType]
    );

    React.useEffect(() => {
        setExtraDataNonce(x => x + 1);
    }, [deleting]);

    const hasMore = () => true;
    // const hasMore = () => points.length >= nextFetchOffsetRef.current;

    const fetchMore = useCallback(() => {
        if (!hasMore()) {
            // Nothing else to fetch
            return;
        }
        const query = {
            ...kDefaultFetchQuery,
            offset: nextFetchOffsetRef.current,
        };
        nextFetchOffsetRef.current += kFetchLimit;
        _fetchMore({ variables: query });
    }, [_fetchMore]);

    const refreshColorsAndroid = React.useMemo(
        () => [theme.colors.accent],
        [theme.colors.accent]
    );

    return (
        <View style={styles.container}>
            {/* TODO: Use RecyclerListView to disable sticky header support on web and add app background */}
            <SectionList<RecordDataPoint>
                sections={sections}
                extraData={extraDataNonce}
                onEndReached={Platform.OS !== 'web' ? fetchMore : undefined}
                onEndReachedThreshold={0.3}
                refreshControl={
                    isNative ? (
                        <RefreshControl
                            {...refreshAction}
                            {...refreshConfig}
                            tintColor={theme.colors.accent}
                            colors={refreshColorsAndroid}
                        />
                    ) : undefined
                }
                renderItem={({ item, index, section }) => {
                    const deletingItem = deleting && deletingIds.has(item.id);
                    return (
                        <CenteredContent maxWidth={kMaxPageWidth}>
                            <RecordListItem
                                key={item.id}
                                dataPoint={item}
                                valueFormatter={valueFormatter}
                                units={unit}
                                description={getDescription?.(item)}
                                descriptionColor={getDescriptionColor?.(item)}
                                loading={deletingItem}
                                onPress={
                                    !editing
                                        ? () => openRecordDetail(item.id)
                                        : undefined
                                }
                                showDelete={editing && !deletingItem}
                                onDelete={() => handleDeletePress(item.id)}
                                confirmingDelete={
                                    item.id === confirmingDeleteId
                                }
                                isTop={index === 0}
                                isBottom={index === section.data.length - 1}
                            />
                        </CenteredContent>
                    );
                }}
                renderSectionHeader={({ section: { title } }) => (
                    <CenteredContent maxWidth={kMaxPageWidth}>
                        <Text
                            key={title}
                            style={[
                                styles.header,
                                {
                                    color: theme.form.colors.sectionTitle,
                                    backgroundColor: theme.colors.background,
                                },
                            ]}
                            onPressIn={undefined}
                            onPressOut={undefined}
                        >
                            {title}
                        </Text>
                    </CenteredContent>
                )}
                keyExtractor={item => item.id}
                ItemSeparatorComponent={ListDivider}
                ListEmptyComponent={ListEmptyComponent}
                ListFooterComponent={React.useMemo(
                    () => (
                        <View style={styles.footer}>
                            {hasMore() ? (
                                <Button
                                    title={lz('loadMore')}
                                    mode='text'
                                    loading={loadingList}
                                    disabled={loadingList}
                                    onPress={fetchMore}
                                />
                            ) : null}
                        </View>
                    ),
                    // eslint-disable-next-line react-hooks/exhaustive-deps
                    [hasMore(), fetchMore, loadingList]
                )}
                stickySectionHeadersEnabled
                contentContainerStyle={[
                    styles.content,
                    {
                        paddingLeft: insets.left + xPadding,
                        paddingRight: insets.right + xPadding,
                        paddingBottom: insets.bottom,
                    },
                ]}
                style={styles.container}
                onScroll={
                    confirmingDeleteId
                        ? () => setConfirmingDeleteId(undefined)
                        : undefined
                }
            />
        </View>
    );
}

function ListDivider(props: ViewProps) {
    return (
        <CenteredContent {...props} maxWidth={kMaxPageWidth}>
            <Divider />
        </CenteredContent>
    );
}

const ListEmptyComponent = React.memo(() => (
    <CenteredContent maxWidth={kMaxPageWidth}>
        <Row isSingle style={styles.emptyFooter}>
            <Text onPressIn={undefined} onPressOut={undefined}>
                {lz('noData')}
            </Text>
        </Row>
    </CenteredContent>
));

const xPadding = 12;

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    content: {},
    navHeaderRightContainer: {
        paddingRight: 8,
    },
    header: {
        paddingLeft: xPadding,
        paddingRight: 43,
        paddingTop: 10,
        paddingBottom: 6,
        fontWeight: 'bold',
        fontSize: 16,
        textAlign: 'right',
    },
    headerRightContainer: {
        paddingRight: 8,
    },
    footer: {
        padding: 8,
        alignItems: 'center',
    },
    emptyFooter: {
        marginTop: 12,
    },
});
