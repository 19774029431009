import React from 'react';
import { StyleSheet } from 'react-native';
import { useTheme } from 'react-native-paper';
import Svg, { Path, SvgProps } from 'react-native-svg';

import { AppTheme } from '../const';

const kLightColors = ['#fdd4b9', '#febf94', '#fdaa70', '#fa944c', '#f57e23'];
const kDarkColors = ['#25274e', '#22416c', '#155d87', '#007a9e', '#0097b1'];

const AppBackground = React.memo(({ style, ...props }: SvgProps) => {
    const theme = useTheme() as AppTheme;
    const colors = theme.dark ? kDarkColors : kLightColors;
    return (
        <Svg
            {...props}
            style={[
                styles.container,
                { backgroundColor: theme.colors.background },
                style,
            ]}
            viewBox='0 0 900 600'
            width='100%'
            height='100%'
            preserveAspectRatio='xMaxYMid slice'
        >
            <Path
                d='M0 428L30 421C60 414 120 400 180 401.2C240 402.3 300 418.7 360 434.3C420 450 480 465 540 453.2C600 441.3 660 402.7 720 380.5C780 358.3 840 352.7 870 349.8L900 347L900 601L870 601C840 601 780 601 720 601C660 601 600 601 540 601C480 601 420 601 360 601C300 601 240 601 180 601C120 601 60 601 30 601L0 601Z'
                fill={colors[0]}
            />
            <Path
                d='M0 448L30 448C60 448 120 448 180 450C240 452 300 456 360 449.8C420 443.7 480 427.3 540 418.5C600 409.7 660 408.3 720 421.2C780 434 840 461 870 474.5L900 488L900 601L870 601C840 601 780 601 720 601C660 601 600 601 540 601C480 601 420 601 360 601C300 601 240 601 180 601C120 601 60 601 30 601L0 601Z'
                fill={colors[1]}
            />
            <Path
                d='M0 430L30 444.5C60 459 120 488 180 487.3C240 486.7 300 456.3 360 453.5C420 450.7 480 475.3 540 486.3C600 497.3 660 494.7 720 484.3C780 474 840 456 870 447L900 438L900 601L870 601C840 601 780 601 720 601C660 601 600 601 540 601C480 601 420 601 360 601C300 601 240 601 180 601C120 601 60 601 30 601L0 601Z'
                fill={colors[2]}
            />
            <Path
                d='M0 532L30 534C60 536 120 540 180 541.8C240 543.7 300 543.3 360 543.2C420 543 480 543 540 544.5C600 546 660 549 720 545C780 541 840 530 870 524.5L900 519L900 601L870 601C840 601 780 601 720 601C660 601 600 601 540 601C480 601 420 601 360 601C300 601 240 601 180 601C120 601 60 601 30 601L0 601Z'
                fill={colors[3]}
            />
            <Path
                d='M0 530L30 531.8C60 533.7 120 537.3 180 535.3C240 533.3 300 525.7 360 524.2C420 522.7 480 527.3 540 535.3C600 543.3 660 554.7 720 557.2C780 559.7 840 553.3 870 550.2L900 547L900 601L870 601C840 601 780 601 720 601C660 601 600 601 540 601C480 601 420 601 360 601C300 601 240 601 180 601C120 601 60 601 30 601L0 601Z'
                fill={colors[4]}
            />
        </Svg>
    );
});

const styles = StyleSheet.create({
    container: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        width: '100%',
        height: '100%',
    },
});

export default AppBackground;
