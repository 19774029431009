import {
    AppDelegate,
    RecordType,
    WatchPointsQueryResult,
    kRecordTypes,
    lz,
} from '@byterium/glucose-diary-client';
import { Chart, ChartLayout, DateScale } from '@byterium/librechart';
import { Ionicons } from '@expo/vector-icons';
import moment, { Moment } from 'moment';
import React from 'react';
import {
    Insets,
    StyleSheet,
    TouchableOpacity,
    View,
    ViewProps,
} from 'react-native';
import { Divider, SegmentedControl } from 'react-native-form-model';
import { ActivityIndicator, Portal, useTheme } from 'react-native-paper';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

import { AppTheme } from '../../const';
import { useObservable, usePromise } from '../../reactUtil';
import UserSettings from '../../services/UserSettings';
import { librechartToRxjsObservable } from '../../util';
import { ShareIcon } from '../assets/commonAssets';
import ChartShareModal from './ChartShareModal';
import {
    RecordChartViewOptions,
    kDataSources,
    kMinLabelXDistance,
    kMinLabelXDistanceHysteresisRatio,
    kMinSecondaryLabelXDistance,
} from './chartConst';
import {
    chartFetchQuery,
    dataSourceRecords$,
    mapPointsToLabelPoints,
    mapRecordsToPoints,
} from './chartData';
import { createChartLayout, updateChartDataLayout } from './chartLayout';
import { canShareChart, currentShare, shareChartLazy } from './chartShare';
import { getAverageY, scaleViewToContent$ } from './chartUtil';

export interface RecordChartProps extends RecordChartViewOptions, ViewProps {}

export interface RecordChartRef {
    layout: () => ChartLayout;
}

const RecordChart = React.memo(
    React.forwardRef<RecordChartRef, RecordChartProps>((props, ref) => {
        const {
            recordType,
            valueTransformer,
            mode = 'line',
            color,
            highColor,
            lowColor,
            highValue,
            lowValue,
            style,
            isOverview = false,
            isFullscreen = false,
            onFullscreenPress,
            ...otherProps
        } = props;
        const theme = useTheme() as AppTheme;
        const dataSource = kDataSources[recordType];
        const chartRef = React.useRef<Chart | null>(null);
        const dataAverages = React.useRef<{ [T in RecordType]: number }>({
            GlucoseSample: NaN,
            InsulinDose: NaN,
            Meal: NaN,
            ActivitySession: NaN,
        }).current;
        const units = UserSettings.useUnits();

        const {
            plot,
            layout,
            dataLayout,
            labelDataLayout,
            secondaryDataLayouts,
            periodState,
            minorPeriod$,
            dateScaleInfo,
            visibleDataRange$,
            updateVisibleRegion,
            updatePeriodState,
        } = React.useMemo(
            () => createChartLayout({ ...props, dataAverages, units, theme }),
            // eslint-disable-next-line react-hooks/exhaustive-deps
            []
        );

        React.useImperativeHandle(ref, () => ({
            layout: () => layout,
        }));

        React.useEffect(() => {
            const subs: Subscription[] = [];

            const dateScale = plot.xLayout.scale as DateScale;
            const { query, points: records$ } = dataSourceRecords$(dataSource);
            const points$ = mapRecordsToPoints({
                records$,
                minorPeriod$,
                mode,
            });
            const minMainLabelViewDistanceX$ = scaleViewToContent$(
                kMinLabelXDistance,
                plot.scale$.x,
                kMinLabelXDistanceHysteresisRatio
            );
            const labelPoints$ = mapPointsToLabelPoints({
                points$,
                minLabelViewDistanceX$: minMainLabelViewDistanceX$,
                dateScale,
                mode,
            });

            // Add main data
            subs.push(
                points$.subscribe(points => {
                    dataAverages[recordType] = getAverageY(points);
                    updateChartDataLayout(dataLayout, points);
                })
            );
            subs.push(
                labelPoints$.subscribe(points =>
                    updateChartDataLayout(labelDataLayout!, points)
                )
            );

            const subQueries: WatchPointsQueryResult[] = [];
            if (isOverview) {
                // Add overview data
                const minSubLabelViewDistanceX$ = scaleViewToContent$(
                    kMinSecondaryLabelXDistance,
                    plot.scale$.x,
                    kMinLabelXDistanceHysteresisRatio
                );

                kRecordTypes.forEach(recordType => {
                    const info = secondaryDataLayouts[recordType];
                    if (!info) {
                        return;
                    }

                    const { query: subQuery, points: subRecords$ } =
                        dataSourceRecords$(kDataSources[recordType]);
                    subQueries.push(subQuery);
                    const subPoints$ = mapRecordsToPoints({
                        records$: subRecords$,
                        minorPeriod$,
                        mode: 'scatter',
                    });
                    const subLabelPoints$ = mapPointsToLabelPoints({
                        points$: subPoints$,
                        minLabelViewDistanceX$: minSubLabelViewDistanceX$,
                        dateScale,
                        mode: 'scatter',
                    });

                    subs.push(
                        subPoints$.subscribe(points => {
                            dataAverages[recordType] = getAverageY(points);
                            updateChartDataLayout(
                                info!.valueDataLayout,
                                points
                            );
                        })
                    );
                    subs.push(
                        subLabelPoints$.subscribe(points =>
                            updateChartDataLayout(info!.labelDataLayout, points)
                        )
                    );
                });
            }

            // Listen to date scale
            const { layoutInfo: dateLayout } = plot.xLayout;
            const layoutUpdates = librechartToRxjsObservable(
                plot.xLayout.updates
            );
            subs.push(
                layoutUpdates.subscribe(() => {
                    dateScaleInfo.majorInterval =
                        dateLayout.containerLength / dateLayout.majorCount;
                    dateScaleInfo.minorCount = dateLayout.minorCount;
                    dateScaleInfo.recenteringOffset =
                        dateLayout.recenteringOffset;
                    updatePeriodState();
                })
            );

            // Fetch data on visible region change
            subs.push(
                visibleDataRange$
                    .pipe(distinctUntilChanged())
                    .subscribe(range => {
                        // InteractionManager.runAfterInteractions(() => fetchMore(range));
                        fetchMore(range);
                    })
            );

            // const fetchMore = _.debounce((range: [Moment, Moment]) => {
            const fetchMore = (range: [Moment, Moment]) => {
                const args = {
                    variables: chartFetchQuery({
                        startDate: range[0],
                        endDate: range[1],
                    }),
                };
                query.refetch(args.variables);
                for (const subQuery of subQueries) {
                    subQuery.refetch(args.variables);
                }
            };
            // }, kDataUpdateDebounceInterval);

            return () => {
                for (const sub of subs) {
                    sub.unsubscribe();
                }
            };
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [dataLayout, isOverview]);

        /** Must match `periods` */
        const periodLabels = [
            lz('daysShort', { defaultValue: 'D' }),
            lz('weeksShort', { defaultValue: 'W' }),
            lz('monthsShort', { defaultValue: 'M' }),
            lz('yearsShort', { defaultValue: 'Y' }),
        ];

        const setPeriodWithIndex = (index: number) => {
            if (index < 0) {
                return;
            }
            if (index === periodState.periodIndex) {
                // Return to current period
                updateVisibleRegion({ date: moment() });
                return;
            }

            periodState.periodIndex = index;
            // updatePeriodState();
            updateVisibleRegion({ snapToCurrentDate: true });
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
        const shareChart = React.useCallback(shareChartLazy({ chartRef }), [
            chartRef,
        ]);

        const { loading: isSharing } = usePromise(currentShare());
        const [shareModalVisible, setShareModalVisible] = React.useState(false);
        const { value: networkActivity } = useObservable(
            AppDelegate.shared().networkActivityDepth$.pipe(
                map(x => !!x),
                distinctUntilChanged(),
                debounceTime(500)
            )
        );

        return (
            <View
                {...otherProps}
                style={[
                    styles.container,
                    {
                        backgroundColor: theme.colors.surface,
                        borderRadius: theme.roundness,
                        borderColor: theme.colors.surface,
                    },
                    style,
                ]}
            >
                <View style={styles.headerContainer}>
                    <View style={styles.headerItemRow}>
                        {!!onFullscreenPress && (
                            <Ionicons
                                name={isFullscreen ? 'contract' : 'expand'}
                                size={20}
                                color={theme.form.colors.input}
                                onPress={() => onFullscreenPress()}
                                hitSlop={hitSlop}
                                style={styles.headerItem}
                            />
                        )}
                        <Ionicons
                            name='locate-outline'
                            size={20}
                            color={theme.form.colors.input}
                            onPress={() =>
                                setPeriodWithIndex(periodState.periodIndex)
                            }
                            hitSlop={hitSlop}
                            style={styles.headerItem}
                        />
                    </View>
                    <SegmentedControl
                        selectedIndex={periodState.periodIndex}
                        possibleValues={periodLabels}
                        onSelect={i => setPeriodWithIndex(i)}
                        style={styles.chartControl}
                    />
                    <View style={styles.shareButtonContainer}>
                        {canShareChart() ? (
                            isSharing ? (
                                <ActivityIndicator size={22} animating />
                            ) : (
                                <TouchableOpacity
                                    onPress={() => setShareModalVisible(true)}
                                    hitSlop={hitSlop}
                                    style={styles.shareButton}
                                >
                                    <ShareIcon
                                        size={22}
                                        color={theme.form.colors.input}
                                        style={styles.shareIcon}
                                    />
                                </TouchableOpacity>
                            )
                        ) : null}
                    </View>
                </View>
                <Divider />
                <View style={styles.flex}>
                    <Chart
                        ref={chartRef}
                        layout={layout}
                        style={styles.chart}
                    />
                    <ActivityIndicator
                        size={20}
                        animating={!!networkActivity}
                        style={styles.chartActivity}
                    />
                </View>
                <Portal>
                    <ChartShareModal
                        visible={shareModalVisible}
                        dismissable
                        onDismiss={() => setShareModalVisible(false)}
                        onShare={options => {
                            shareChart({ ...options, recordType, isOverview });
                            setShareModalVisible(false);
                        }}
                    />
                </Portal>
            </View>
        );
    })
);

// RecordChart.displayName = 'RecordChart';

const hitSlop: Insets = {
    top: 10,
    left: 10,
    right: 10,
    bottom: 10,
};

const styles = StyleSheet.create({
    container: {
        overflow: 'hidden',
        alignItems: 'stretch',
        borderWidth: 1,
        margin: -1,
    },
    headerContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingHorizontal: 6,
        paddingVertical: 8,
    },
    headerItemRow: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    headerItem: {
        padding: 8,
        marginVertical: -8,
    },
    chartControl: {
        marginHorizontal: 6,
        flexShrink: 1,
        flexWrap: 'wrap',
    },
    shareButtonContainer: {
        flexDirection: 'row',
        width: 72,
        height: 30,
        alignItems: 'center',
        justifyContent: 'flex-end',
    },
    shareButton: {
        width: 22,
        height: 22,
        padding: 8,
        marginVertical: -8,
        alignItems: 'center',
        justifyContent: 'center',
    },
    shareIcon: {
        width: 22,
        height: 22,
    },
    chart: {
        flex: 1,
        // height: 300,
        // width: 600,
        minHeight: 250,
    },
    chartActivity: {
        position: 'absolute',
        padding: 14,
    },
    flex: {
        flex: 1,
    },
});

export default React.memo(RecordChart);
