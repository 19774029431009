import { Ionicons } from '@expo/vector-icons';
import React from 'react';
import { FormAssets } from 'react-native-form-model';

import {
    CheckmarkIcon,
    CrossCircleIcon,
} from '../../components/assets/commonAssets';

FormAssets.shared = new FormAssets({
    ClearIcon: CrossCircleIcon,
    CheckmarkIcon,
    LeftArrowIcon: props => <Ionicons {...props} name='ios-arrow-back' />,
    RightArrowIcon: props => <Ionicons {...props} name='ios-arrow-forward' />,
});
