import React from 'react';
import { StyleSheet } from 'react-native';
import { Button, ButtonProps } from 'react-native-form-model';
import { useTheme } from 'react-native-paper';

import { AppTheme } from '../const';

export const TranslucentButton: React.FC<ButtonProps> = ({
    disabled,
    style,
    ...props
}) => {
    const theme = useTheme() as AppTheme;
    return (
        <Button
            {...props}
            disabled={disabled}
            mode='contained'
            style={[
                styles.button,
                styles.shadow,
                {
                    backgroundColor: theme.colors.background + '60',
                },
                style,
            ]}
            textStyle={{
                color: disabled
                    ? theme.colors.disabled
                    : theme.colors.textMoreSlightlyFaded,
            }}
        />
    );
};

const styles = StyleSheet.create({
    button: {
        minHeight: 40,
    },
    shadow: {
        shadowColor: '#171717',
        shadowOffset: { width: 0, height: 2 },
        shadowRadius: 12,
        shadowOpacity: 0.1,
        overflow: 'visible',
    },
});
