import {
    InsulinUnit,
    formatInsulinUnit,
    kInsulinUnits,
    lz,
} from '@byterium/glucose-diary-client';

import UserSettings from '../../UserSettings';
import { UnitSettingSectionOptions } from './unitSettingTypes';

export function addInsulinUnitRowsToSection({
    section,
}: UnitSettingSectionOptions) {
    section
        .addRow()
        .addLabel({ title: lz('insulin'), flex: 1 })
        .addOptionInput<InsulinUnit>({
            value: UserSettings.insulinUnit$,
            mode: 'segmented',
            possibleValues: [...kInsulinUnits],
            formatValue: x => formatInsulinUnit(x),
            style: {
                fontSize: 14,
            },
            flex: 1,
            align: 'right',
        });
}
