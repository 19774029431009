import { getSharedFitnessKit } from '@byterium/glucose-diary-client';
import React from 'react';
import { ScrollView, StyleSheet } from 'react-native';
import { Form, FormModel } from 'react-native-form-model';
import { useTheme } from 'react-native-paper';

import CenteredContent from '../../../../components/CenteredContent';
import { KeyboardAvoidingView } from '../../../../components/KeyboardAvoidingView';
import { AppTheme, kMaxPageWidth } from '../../../../const';
import { addFitnessSettingsSections } from '../../../../services/forms/sync/syncForms';
import { createFitnessSettingsData } from '../syncSettingsData';
import { FitnessSettingsPageOptions } from './fitnessSettingsTypes';

export interface FitnessSettingsPageProps extends FitnessSettingsPageOptions {}

export default function FitnessSettingsPage(props: FitnessSettingsPageProps) {
    const { fitnessType, navigation } = {
        ...props,
        ...props.route.params,
    };
    const theme = useTheme() as AppTheme;
    const fitness = getSharedFitnessKit(fitnessType);

    const data = React.useMemo(
        () => createFitnessSettingsData(fitness),
        [fitness]
    );

    const form = React.useMemo(() => {
        const form = FormModel.create({ style: theme.form });
        addFitnessSettingsSections({
            fitness,
            form,
            data,
            theme,
            styles,
            style: { sectionMargin: 12 },
        });
        return form;
    }, [data, fitness, theme]);

    React.useLayoutEffect(() => {
        navigation.setOptions({ title: fitness.name });
    }, [fitness.name, navigation]);

    return (
        <KeyboardAvoidingView style={styles.container}>
            <ScrollView
                style={styles.container}
                contentContainerStyle={styles.scrollContent}
            >
                <CenteredContent
                    maxWidth={kMaxPageWidth}
                    style={styles.content}
                >
                    <Form form={form} style={styles.form} />
                </CenteredContent>
            </ScrollView>
        </KeyboardAvoidingView>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    scrollContent: {
        flexGrow: 1,
    },
    content: {
        margin: 12,
    },
    form: {
        marginVertical: 12,
    },
    section: {
        marginBottom: 10,
    },
    icon: {
        width: 24,
    },
});
