import {
    Schema,
    User,
    UserDocument,
    UserDocuments,
    formatDiabetesType,
    lz,
    lzDuration,
} from '@byterium/glucose-diary-client';
import moment from 'moment';
import { ViewProps } from 'react-native';
import { FormError, FormModel, safeKeyList } from 'react-native-form-model';
import { BehaviorSubject } from 'rxjs';

import { AppTheme } from '../../../const';

export interface CredentialFormData
    extends Required<
        {
            [K in keyof Schema.UserCredentialsInput]: BehaviorSubject<
                Schema.UserCredentialsInput[K]
            >;
        }
    > {}

export interface UserDetailFormData
    extends Required<
        {
            [K in keyof Omit<
                Schema.UserProfileInput,
                'email'
            >]: BehaviorSubject<Schema.UserProfileInput[K]>;
        }
    > {
    email: BehaviorSubject<string>;
}

const kUserProfileInputKeys = safeKeyList<keyof Schema.UserProfileInput>({
    email: 1,
    displayName: 1,
    givenName: 1,
    middleName: 1,
    familyName: 1,
    diabetesType: 1,
    photoUrl: 1,
    signedDocuments: 1,
});

export interface SignupFormData
    extends CredentialFormData,
        UserDetailFormData {}

export interface CredentialSectionOptions {
    data: CredentialFormData;
    theme: AppTheme;
    expiryInterval?: number;
    tokenLength?: number;
    editable?: boolean;
    email?: boolean;
    token?: boolean;
    autoFocusEmail?: boolean;
    autoFocusToken?: boolean;
    returnTypeSubmit?: boolean;
}

export interface FormObservable {
    subscribe: () => FormSubscription;
}

export interface FormSubscription {
    unsubscribe: () => void;
}

export interface LoginFormData extends CredentialFormData {}

export function createLoginForm(
    options: Omit<CredentialSectionOptions, 'email'> & {
        data: LoginFormData;
    }
) {
    const form = FormModel.create();

    // Credentials
    addCredentialSectionToForm({
        email: true,
        token: false,
        ...options,
        form,
        returnTypeSubmit: true,
    });

    return form;
}

export function createLoginCodeForm(
    options: Omit<CredentialSectionOptions, 'email'> & {
        data: LoginFormData;
    }
) {
    const form = FormModel.create();

    // Credentials
    addCredentialSectionToForm({
        email: false,
        token: true,
        ...options,
        form,
        returnTypeSubmit: true,
    });

    return form;
}

export function createSignupForm(
    options: Omit<CredentialSectionOptions, 'login'> & { data: SignupFormData }
): FormModel {
    const form = FormModel.create();

    // Credentials
    addCredentialSectionToForm({
        email: true,
        token: false,
        ...options,
        form,
    });

    // Details
    addUserDetailsToForm({ ...options, form });

    return form;
}

export function createFormSignupData(
    defaults: Partial<
        Schema.UserCredentialsInput & Schema.UserProfileInput
    > = {}
): SignupFormData {
    return {
        email: new BehaviorSubject<string>(
            User.isEmail(defaults.email) ? defaults.email || '' : ''
        ),
        token: new BehaviorSubject<string>(defaults.token || ''),

        displayName: new BehaviorSubject<string | undefined>(
            defaults.displayName
        ),
        givenName: new BehaviorSubject<string | undefined>(defaults.givenName),
        middleName: new BehaviorSubject<string | undefined>(
            defaults.middleName
        ),
        familyName: new BehaviorSubject<string | undefined>(
            defaults.familyName
        ),
        diabetesType: new BehaviorSubject<Schema.DiabetesType | undefined>(
            defaults.diabetesType
        ),
        photoUrl: new BehaviorSubject<string | undefined>(defaults.photoUrl),
        signedDocuments: new BehaviorSubject<
            Schema.SignedDocumentInput[] | undefined
        >(defaults.signedDocuments),
    };
}

export function getFormProfileValues(
    data: UserDetailFormData
): Schema.UserProfileInput {
    const profile: Schema.UserProfileInput = {
        email: data.email.value,
    };
    for (const key of kUserProfileInputKeys) {
        const value$ = data[key];
        if (value$) {
            profile[key] = value$.value as any;
        }
    }
    return profile;
}

export function createDocumentData(documentsToSign: UserDocument[]) {
    const data = documentsToSign.map(document => ({
        document,
        accepted: new BehaviorSubject<boolean | undefined>(undefined),
    }));

    return {
        data,
        signDocuments: () =>
            data
                .map(({ document, accepted }) => {
                    if (accepted.value) {
                        return UserDocuments.signDocument(document);
                    }
                    return undefined;
                })
                .filter(x => !!x) as Schema.SignedDocumentInput[],
    };
}

export function createFormLoginData(
    defaults: Partial<
        Schema.UserCredentialsInput & Schema.UserProfileInput
    > = {}
): LoginFormData {
    return createFormSignupData(defaults);
}

export function addCredentialSectionToForm({
    form,
    data,
    theme,
    expiryInterval,
    editable = true,
    email = false,
    token = false,
    autoFocusEmail = false,
    autoFocusToken = false,
    returnTypeSubmit = false,
}: CredentialSectionOptions & { form: FormModel }) {
    // Place token instruction at the top to allow
    // space for notifications at the top of the screen

    form.addSection({
        style: { sectionFooterAlign: 'center' },
        footer: editable
            ? lz(
                  !token
                      ? 'sendOneTimePasscodeEmailInstructions'
                      : 'inputOneTimePasscodeEmailInstructions',
                  {
                      email: data.email.value || '',
                      expiryInterval: expiryInterval
                          ? lzDuration(moment.duration(expiryInterval), {
                                relative: true,
                            })
                          : undefined,
                  }
              )
            : undefined,
    });

    form.addSection({
        style: {
            colors: { sectionFooter: theme.form.colors.error },
        },
    }).modify(section => {
        if (email) {
            section.addRow().addKeyboardInput({
                value: data.email,
                key: 'email',
                type: 'email',
                textContentType: 'emailAddress',
                returnKeyType: returnTypeSubmit ? 'send' : 'next',
                placeholder: lz('email'),
                disabled: !editable,
                autoFocus: autoFocusEmail && editable,
                validation: input =>
                    User.isEmail(input)
                        ? { valid: true }
                        : {
                              valid: false,
                              error: new FormError.FormValidationError(
                                  lz('invalidEmailFormat')
                              ),
                          },
                flex: 1,
            });
        }
        if (token) {
            section.addRow().addKeyboardInput({
                value: data.token,
                key: 'token',
                type: 'integer',
                textContentType: 'oneTimeCode',
                returnKeyType: returnTypeSubmit ? 'send' : 'next',
                placeholder: lz('passcode'),
                disabled: !editable,
                autoFocus: autoFocusToken && editable,
                parseInput: String,
                validation: input =>
                    User.isOneTimePasscode(input)
                        ? { valid: true }
                        : {
                              valid: false,
                              error: new FormError.FormValidationError(
                                  lz('invalidPasscode')
                              ),
                          },
                flex: 1,
            });
        }
        section.footer = section.flattenedFormattedErrors$({
            editedOnly: true,
        });
    });
}

export function addUserDetailsToForm({
    form,
    data,
    theme,
    editable = true,
}: {
    form: FormModel;
    data: UserDetailFormData;
    theme: AppTheme;
    editable?: boolean;
}) {
    // Name
    form.addSection().modify(section => {
        section
            .addRow()
            .addKeyboardInput({
                value: data.givenName,
                key: 'givenName',
                textContentType: 'givenName',
                disabled: !editable,
                placeholder: lz('givenName'),
                returnKeyType: 'next',
                flex: 1,
            })
            .addKeyboardInput({
                value: data.middleName,
                key: 'middleName',
                textContentType: 'middleName',
                disabled: !editable,
                placeholder: editable ? lz('middleName') : '',
                flex: 1,
                align: 'right',
                returnKeyType: 'next',
            });
        section.addRow().addKeyboardInput({
            value: data.familyName,
            key: 'familyName',
            textContentType: 'familyName',
            disabled: !editable,
            placeholder: lz('familyName'),
            returnKeyType: 'next',
            flex: 1,
        });
    });

    // Diabetes
    form.addSection().modify(section => {
        section
            .addRow()
            .addLabel({ title: lz('diabetesType'), flex: 1 })
            .addOptionInput<Schema.DiabetesType | undefined>({
                value: data.diabetesType,
                key: 'diabetesType',
                mode: 'segmented',
                possibleValues: [
                    Schema.DiabetesType.None,
                    Schema.DiabetesType.I,
                    Schema.DiabetesType.Ii,
                ],
                disabled: !editable,
                formatValue: x => formatDiabetesType(x),
                optional: true,
                style: {
                    fontSize: 14,
                },
                align: 'right',
            });
    });
}

export function addDocumentSectionToForm({
    form,
    data,
    theme,
    customTermsLabel,
}: {
    form: FormModel;
    data: ReturnType<typeof createDocumentData>['data'];
    theme: AppTheme;
    customTermsLabel: (props: ViewProps) => React.ReactNode;
}) {
    if (!data?.length) {
        return;
    }

    for (const rowData of data) {
        switch (rowData.document.type) {
            case Schema.DocumentType.TermsAndConditions:
                form.addSection({
                    title: lz('termsAndConditions'),
                    style: {
                        sectionTitleAlign: 'center',
                        colors: {
                            sectionFooter: theme.form.colors.error,
                        },
                    },
                }).modify(section => {
                    // T&C
                    if (rowData.document.modified) {
                        section.addRow().addLabel({
                            title: lz('termsOfUseChangedMessage'),
                            flex: 1,
                            style: {
                                fontSize: 14,
                            },
                        });
                    }
                    const row = section.addRow();
                    if (customTermsLabel) {
                        row.addCustom({
                            render: customTermsLabel,
                            flex: 1,
                        });
                    } else {
                        row.addLabel({
                            title: lz('IAgreeToTerms'),
                            flex: 1,
                            style: {
                                fontSize: 14,
                            },
                        });
                    }

                    row.addOptionInput<boolean | undefined>({
                        value: rowData.accepted,
                        mode: 'segmented',
                        possibleValues: [true, false],
                        optional: true,
                        clearButtonMode: 'never',
                        formatValue: x => (x ? lz('accept') : lz('decline')),
                        validation: accepted =>
                            accepted || lz('mustAgreeToTerms'),
                        style: {
                            fontSize: 14,
                        },
                        align: 'right',
                        flex: 1,
                    });

                    section.footer = section.flattenedFormattedErrors$({
                        editedOnly: true,
                    });
                });
        }
    }
}
