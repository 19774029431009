import {
    Schema,
    User,
    UserDocument,
    UserDocuments,
    lz,
} from '@byterium/glucose-diary-client';
import React from 'react';
import { ScrollView, StyleSheet, View } from 'react-native';
import { Button, Form } from 'react-native-form-model';
import { Banner, Modal, Portal, useTheme } from 'react-native-paper';

import AppBackground from '../../../components/AppBackground';
import CenteredContent from '../../../components/CenteredContent';
import DocumentViewer from '../../../components/DocumentViewer';
import { KeyboardAvoidingView } from '../../../components/KeyboardAvoidingView';
import TermsLabel from '../../../components/login/TermsLabel';
import { AppTheme, kMaxPageWidth } from '../../../const';
import { usePromise } from '../../../reactUtil';
import UserSettings from '../../../services/UserSettings';
import {
    addDocumentSectionToForm,
    createDocumentData,
    createFormSignupData,
    createSignupForm,
    getFormProfileValues,
} from '../../../services/forms/user/userForms';
import { SignupEmailPageOptions } from './loginEmailTypes';

export interface SignupEmailPageProps extends SignupEmailPageOptions {}

export default function SignupEmailPage(props: SignupEmailPageProps) {
    const { email: emailProp, navigation } = {
        ...props,
        ...props.route.params,
    };

    const theme = useTheme() as AppTheme;

    const [action, setAction] = React.useState<Promise<any> | undefined>(
        undefined
    );
    const { loading = false, error } = usePromise(action);

    const data = React.useRef(
        createFormSignupData({ email: emailProp })
    ).current;
    const { data: docData, signDocuments } = React.useRef(
        createDocumentData(User.getOnboardActions().documentsToSign)
    ).current;

    const [viewingDoc, setViewingDoc] = React.useState<
        UserDocument | undefined
    >(undefined);

    const form = React.useMemo(() => {
        const autoFocusEmail = !emailProp;

        const form = createSignupForm({
            data,
            theme,
            email: true,
            autoFocusEmail,
            autoFocusToken: !autoFocusEmail,
        });

        addDocumentSectionToForm({
            form,
            data: docData,
            theme,
            customTermsLabel: () => (
                <TermsLabel
                    onPress={() => {
                        setViewingDoc(
                            UserDocuments.get(
                                Schema.DocumentType.TermsAndConditions
                            )
                        );
                    }}
                />
            ),
        });

        form.validateAll({ focusOnInvalid: true });

        return form;
    }, [data, docData, emailProp, theme]);

    const trySignup = React.useCallback(() => {
        if (!form.validateAll({ focusOnInvalid: true })) {
            return;
        }
        setAction(
            (async () => {
                const email = data.email.value || '';
                await User.signup({
                    ...getFormProfileValues(data),
                    email,
                    signedDocuments: signDocuments(),
                });
                const tokenHint = await User.sendOneTimePasscode({
                    email,
                    dryRun: UserSettings._debugEmailLoginDryRun$.value,
                });
                navigation.push('LoginEmailCode', { email, ...tokenHint });
            })()
        );
    }, [data, form, navigation, signDocuments]);

    React.useEffect(() => {
        form.onSubmit = trySignup;
    }, [form, trySignup]);

    return (
        <View style={styles.container}>
            <AppBackground />
            <KeyboardAvoidingView style={styles.container}>
                <ScrollView
                    style={styles.container}
                    contentContainerStyle={styles.scrollContent}
                >
                    <CenteredContent
                        maxWidth={kMaxPageWidth}
                        style={styles.content}
                    >
                        <Banner
                            visible={!!error}
                            actions={[
                                {
                                    label: lz('retry'),
                                    onPress: () => trySignup(),
                                },
                            ]}
                        >
                            {`${lz('somethingWentWrong')}: ${String(
                                error?.message || error || ''
                            )}`}
                        </Banner>

                        <Form form={form} style={styles.form} />

                        <Button
                            title={lz('signup')}
                            disabled={loading}
                            loading={loading}
                            onPress={() => trySignup()}
                            mode='contained'
                            style={styles.button}
                        />
                        <Button
                            title={
                                lz('alreadyHaveAccount') +
                                '\n' +
                                lz('loginHere')
                            }
                            disabled={loading}
                            onPress={() =>
                                navigation.replace('LoginEmail', {
                                    email: data.email.value,
                                })
                            }
                            style={styles.button}
                        />
                    </CenteredContent>
                </ScrollView>

                <Portal theme={theme}>
                    <Modal
                        visible={!!viewingDoc}
                        onDismiss={() => setViewingDoc(undefined)}
                        contentContainerStyle={[
                            styles.modal,
                            {
                                backgroundColor: theme.colors.surface,
                                borderRadius: theme.roundness,
                            },
                        ]}
                    >
                        <DocumentViewer
                            document={viewingDoc}
                            onDismiss={() => setViewingDoc(undefined)}
                        />
                    </Modal>
                </Portal>
            </KeyboardAvoidingView>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    scrollContent: {
        flexGrow: 1,
    },
    content: {
        margin: 12,
    },
    form: {
        marginVertical: 12,
    },
    section: {
        marginBottom: 10,
    },
    icon: {
        width: 24,
    },
    button: {
        marginTop: 8,
    },
    modal: {
        margin: 12,
        flex: 1,
        alignSelf: 'center',
    },
});
