import {
    InsulinProducts,
    Schema,
    formatInsulinProfileType,
    lz,
} from '@byterium/glucose-diary-client';
import { RouteProp } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import React, { useCallback } from 'react';
import { FlatList, Platform, StyleSheet, View, ViewProps } from 'react-native';
import { Button, Divider, FormModel, Row } from 'react-native-form-model';
import { ActivityIndicator, Caption, useTheme } from 'react-native-paper';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { BehaviorSubject } from 'rxjs';
import { skip } from 'rxjs/operators';

import CenteredContent from '../../components/CenteredContent';
import { KeyboardAvoidingView } from '../../components/KeyboardAvoidingView';
import { SearchIcon } from '../../components/assets/commonAssets';
import InsulinProductSearchBar from '../../components/insulin/InsulinProductSearchBar';
import InsulinProductSummaryRow from '../../components/insulin/InsulinProductSummaryRow';
import { AppTheme, kMaxPageWidth } from '../../const';
import { useBehaviorSubject, useDebounce } from '../../reactUtil';
import { composeSupportEmail } from '../../services/email';
import { HomeStackList } from '../navigate';

const kSearchDebounceInterval = 500;

const kFetchLimit = 100;
const kDefaultFetchQuery = {
    limit: kFetchLimit,
};

const baseProductFilter = (
    product: Schema.InsulinProductFieldsFragment
): boolean => !product.hidden;

const filterProducts = (
    searchText: string,
    products: Schema.InsulinProductFieldsFragment[]
): Schema.InsulinProductFieldsFragment[] => {
    searchText = (searchText || '').trim().toLocaleLowerCase();
    if (searchText.length === 0) {
        return products.filter(baseProductFilter);
    } else if (searchText.length === 1) {
        return products.filter(
            product =>
                baseProductFilter(product) &&
                ((product.brandName || '')
                    .toLocaleLowerCase()
                    .startsWith(searchText) ||
                    product.medication.clinicalName
                        ?.toLocaleLowerCase()
                        .startsWith(searchText))
        );
    } else {
        return products.filter(
            product =>
                baseProductFilter(product) &&
                (InsulinProducts.formatProduct(product, {
                    concentration: false,
                })
                    .toLocaleLowerCase()
                    .includes(searchText) ||
                    product.medication.clinicalName
                        ?.toLocaleLowerCase()
                        .includes(searchText) ||
                    formatInsulinProfileType(product.medication.profileType)
                        ?.toLocaleLowerCase()
                        .includes(searchText))
        );
    }
};

export type InsulinProductSearchNavigationProp = StackNavigationProp<
    HomeStackList,
    'InsulinProductSearch'
>;

interface InsulinProductSearchPageProps {
    navigation: InsulinProductSearchNavigationProp;
    route: InsulinProductSearchRouteProp;
}

type InsulinProductSearchRouteProp = RouteProp<
    HomeStackList,
    'InsulinProductSearch'
>;

const InsulinProductSearchPage: React.FC<InsulinProductSearchPageProps> = ({
    navigation,
    route,
}) => {
    const theme = useTheme() as AppTheme;
    const insets = useSafeAreaInsets();

    const searchText$ = React.useRef(new BehaviorSubject('')).current;
    const {
        records: products = [],
        loading,
        error,
        refetch,
        fetchMore: _fetchMore,
    } = InsulinProducts.useFindAll({ ...kDefaultFetchQuery });

    const productsRef = React.useRef(products);
    productsRef.current = products;

    const loading$ = React.useRef(new BehaviorSubject(loading)).current;

    const nextFetchOffsetRef = React.useRef(kFetchLimit);
    if (products.length > nextFetchOffsetRef.current) {
        nextFetchOffsetRef.current = products.length;
    }

    const [matchingProducts, setMatchingProducts] = React.useState<
        Schema.InsulinProductFieldsFragment[]
    >([]);

    const isMountedRef = React.useRef(true);
    React.useEffect(() => {
        return () => {
            isMountedRef.current = false;
        };
    }, []);

    const updateResults = useDebounce(
        () => {
            if (!isMountedRef.current) {
                return;
            }
            const newMatchingProducts = filterProducts(
                searchText$.value,
                productsRef.current
            );
            setMatchingProducts(newMatchingProducts);
        },
        kSearchDebounceInterval,
        []
    );

    const chooseProduct = (product: Schema.InsulinProductFieldsFragment) => {
        // @ts-ignore: merge option not handled
        navigation.navigate({
            name: 'AddSample',
            params: { insulinProduct: product },
            merge: true,
        });
    };

    React.useEffect(
        () => {
            loading$.next(loading);
            if (!loading) {
                updateResults();
            }
        },
        // Update results when loaded
        [loading, loading$, updateResults]
    );

    const form = React.useMemo(() => {
        const form = FormModel.create({ style: theme.form });

        form.addSection()
            .addRow()
            .addCustom(() => {
                // eslint-disable-next-line react-hooks/rules-of-hooks
                const loading = useBehaviorSubject(loading$);
                return loading ? (
                    <ActivityIndicator hidesWhenStopped animating={loading} />
                ) : (
                    <SearchIcon size={32} />
                );
            })
            .setMargin(0)
            .addKeyboardInput({
                key: 'searchText',
                value: searchText$,
                submitOnChangeValue: true,
                autoFocus: true,
                placeholder: lz('search'),
                flex: 1,
            });

        return form;
    }, [loading$, searchText$, theme.form]);

    React.useEffect(() => {
        const sub = searchText$.pipe(skip(1)).subscribe(updateResults);

        return () => {
            sub.unsubscribe();
        };
    }, [searchText$, updateResults]);

    const hasMore = () =>
        productsRef.current.length >= nextFetchOffsetRef.current;

    const fetchMore = useCallback(() => {
        if (!hasMore()) {
            // Nothing else to fetch
            return;
        }
        const query = {
            ...kDefaultFetchQuery,
            offset: nextFetchOffsetRef.current,
        };
        nextFetchOffsetRef.current += kFetchLimit;
        _fetchMore({ variables: query });
        loading$.next(true);
    }, [_fetchMore, loading$]);

    return (
        <KeyboardAvoidingView style={styles.container}>
            <FlatList<Schema.InsulinProductFieldsFragment>
                data={matchingProducts}
                keyExtractor={item => item.id}
                onEndReachedThreshold={0.3}
                onEndReached={Platform.OS !== 'web' ? fetchMore : undefined}
                keyboardDismissMode='on-drag'
                renderItem={({ item: product, index }) => (
                    <CenteredContent
                        maxWidth={kMaxPageWidth}
                        style={styles.content}
                    >
                        <InsulinProductSummaryRow
                            product={product}
                            key={product.id}
                            style={styles.row}
                            isTop={index === 0}
                            isBottom={index === matchingProducts.length - 1}
                            onPress={() => chooseProduct(product)}
                        />
                    </CenteredContent>
                )}
                ListEmptyComponent={() => (
                    <CenteredContent
                        maxWidth={kMaxPageWidth}
                        style={styles.content}
                    >
                        <Row isSingle style={styles.row}>
                            <Caption style={styles.centerText}>
                                {lz('noSearchResultsMessage')}
                            </Caption>
                        </Row>
                    </CenteredContent>
                )}
                style={styles.container}
                contentContainerStyle={[
                    styles.listContent,
                    {
                        paddingLeft: insets.left + xPadding,
                        paddingRight: insets.right + xPadding,
                        paddingBottom: insets.bottom,
                    },
                ]}
                ItemSeparatorComponent={props => (
                    <CenteredContent {...props} maxWidth={kMaxPageWidth}>
                        <Divider />
                    </CenteredContent>
                )}
                ListHeaderComponent={React.useMemo(
                    () => (
                        <InsulinProductSearchBar
                            key='header'
                            form={form}
                            error={error}
                            retry={() => refetch()}
                        />
                    ),
                    [form, error, refetch]
                )}
                ListFooterComponent={React.useMemo(
                    () => (
                        <DefaultFooter
                            loadMore={hasMore() ? fetchMore : undefined}
                            loading={loading}
                        />
                    ),
                    [fetchMore, loading]
                )}
            />
        </KeyboardAvoidingView>
    );
};

interface DefaultFooterProps extends ViewProps {
    loadMore?: () => void;
    loading?: boolean;
}

function DefaultFooter({
    loadMore,
    loading,
    style,
    ...props
}: DefaultFooterProps) {
    const theme = useTheme() as AppTheme;
    return (
        <View {...props} style={[styles.footerStyle, style]}>
            {loadMore ? (
                <Button
                    style={styles.loadMoreButton}
                    title={lz('loadMore')}
                    mode='text'
                    loading={loading}
                    disabled={loading}
                    onPress={loadMore}
                />
            ) : null}

            <Caption style={styles.footerTextStyle}>
                {lz('missingDataMessage') + ' '}
                <Caption
                    onPress={() => composeSupportEmail()}
                    style={[
                        styles.linkText,
                        { color: theme.form.colors.input },
                    ]}
                >
                    {lz('letUsKnow')}
                </Caption>
            </Caption>
        </View>
    );
}

const xPadding = 12;

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    content: {
        flex: 1,
    },
    listContent: {
        flexGrow: 1,
        paddingTop: 8,
    },
    row: {
        minHeight: 50,
    },
    centerText: {
        width: '100%',
        textAlign: 'center',
    },
    linkText: {
        textDecorationLine: 'underline',
    },
    noResultsContainer: {
        alignItems: 'stretch',
    },
    footerStyle: {
        padding: 8,
        alignItems: 'center',
    },
    footerTextStyle: {
        textAlign: 'center',
    },
    loadMoreButton: {
        height: 50,
        marginVertical: 8,
    },
});

export default InsulinProductSearchPage;
