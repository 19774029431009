import { Ionicons } from '@expo/vector-icons';
import React from 'react';
import {
    Platform,
    StyleProp,
    StyleSheet,
    View,
    ViewProps,
    ViewStyle,
} from 'react-native';
import { Colors, useTheme } from 'react-native-paper';
import { IconProps } from 'react-native-paper/lib/typescript/components/MaterialCommunityIcon';

import { AppTheme } from '../../const';
import { iconStyles } from './assetConst';

export const AddIcon = ({
    color,
    size = 24,
    style,
    circle = false,
    filled = false,
    ...otherProps
}: Omit<IconProps, 'name' | 'color' | 'direction' | 'size'> & {
    color?: string;
    style?: StyleProp<ViewStyle>;
    size?: number;
    circle?: boolean;
    filled?: boolean;
}) => {
    const theme = useTheme() as AppTheme;
    return (
        <View {...otherProps} style={[iconStyles.iconContainer, style]}>
            <Ionicons
                name={
                    filled
                        ? 'add-circle'
                        : circle
                        ? 'add-circle-outline'
                        : 'add'
                }
                direction='ltr'
                size={size}
                color={color || theme.colors.text}
            />
        </View>
    );
};

export interface CircleProps extends ViewProps {
    diameter: number;
    strokeColor: string;
    strokeWidth?: number;
    children?: React.ReactNode;
}

/** Returns a circle. */
export const Circle = React.memo(
    ({
        diameter,
        strokeColor = Colors.grey500,
        strokeWidth = 2,
        children,
        style,
        ...otherProps
    }: CircleProps) => {
        return (
            <View
                {...otherProps}
                style={[
                    style,
                    {
                        width: diameter,
                        height: diameter,
                        borderRadius: diameter / 2,
                        borderColor: strokeColor,
                        borderWidth: strokeWidth,
                        overflow: 'hidden',
                    },
                ]}
            >
                {children && <View style={styles.circleLayer}>{children}</View>}
            </View>
        );
    }
);

export const ChevronIcon = ({
    color,
    size = 16,
    ...otherProps
}: Omit<IconProps, 'name' | 'color' | 'direction' | 'size'> & {
    color?: string;
    style?: StyleProp<ViewStyle>;
    size?: number;
}) => {
    const theme = useTheme() as AppTheme;
    return (
        <Ionicons
            name='ios-chevron-forward'
            direction='ltr'
            {...otherProps}
            size={size}
            color={color || theme.colors.primary}
        />
    );
};

export const CloudIcon = ({
    color,
    done = false,
    size = 24,
    style,
    ...otherProps
}: Omit<IconProps, 'name' | 'color' | 'direction' | 'size'> & {
    color?: string;
    done?: boolean;
    style?: StyleProp<ViewStyle>;
    size?: number;
}) => {
    const theme = useTheme() as AppTheme;
    return (
        <View {...otherProps} style={[iconStyles.iconContainer, style]}>
            <Ionicons
                name={done ? 'cloud-done-outline' : 'cloud-outline'}
                direction='ltr'
                size={size}
                color={color || theme.colors.text}
            />
        </View>
    );
};

export const CloudUploadIcon = ({
    color,
    size = 24,
    style,
    ...otherProps
}: Omit<IconProps, 'name' | 'color' | 'direction' | 'size'> & {
    color?: string;
    style?: StyleProp<ViewStyle>;
    size?: number;
}) => {
    const theme = useTheme() as AppTheme;
    return (
        <View {...otherProps} style={[iconStyles.iconContainer, style]}>
            <Ionicons
                name='cloud-upload-outline'
                direction='ltr'
                size={size}
                color={color || theme.colors.text}
            />
        </View>
    );
};

export const CloudDownloadIcon = ({
    color,
    size = 24,
    style,
    ...otherProps
}: Omit<IconProps, 'name' | 'color' | 'direction' | 'size'> & {
    color?: string;
    style?: StyleProp<ViewStyle>;
    size?: number;
}) => {
    const theme = useTheme() as AppTheme;
    return (
        <View {...otherProps} style={[iconStyles.iconContainer, style]}>
            <Ionicons
                name='cloud-download-outline'
                direction='ltr'
                size={size}
                color={color || theme.colors.text}
            />
        </View>
    );
};

export const CrossCircleIcon = ({
    color,
    size,
    style,
    ...otherProps
}: Omit<IconProps, 'name' | 'color' | 'direction' | 'size'> & {
    color?: string;
    style?: StyleProp<ViewStyle>;
    size?: number;
}) => {
    const theme = useTheme() as AppTheme;
    return (
        <View {...otherProps} style={[iconStyles.iconContainer, style]}>
            <Ionicons
                name='close-circle-outline'
                direction='ltr'
                size={size || 19}
                color={color || theme.colors.text}
            />
        </View>
    );
};

export const CalendarIcon = ({
    color,
    size = 22,
    style,
    ...otherProps
}: Omit<IconProps, 'name' | 'color' | 'direction' | 'size'> & {
    color?: string;
    style?: StyleProp<ViewStyle>;
    size?: number;
}) => {
    const theme = useTheme() as AppTheme;
    return (
        <View {...otherProps} style={[iconStyles.iconContainer, style]}>
            <Ionicons
                name='calendar-outline'
                direction='ltr'
                size={size}
                color={color || theme.colors.text}
            />
        </View>
    );
};

export const CheckmarkIcon = ({
    color,
    size = 22,
    style,
    circle = false,
    filled = false,
    ...otherProps
}: Omit<IconProps, 'name' | 'color' | 'direction' | 'size'> & {
    color?: string;
    style?: StyleProp<ViewStyle>;
    size?: number;
    circle?: boolean;
    filled?: boolean;
}) => {
    const theme = useTheme() as AppTheme;
    return (
        <View {...otherProps} style={[iconStyles.iconContainer, style]}>
            <Ionicons
                name={
                    filled
                        ? 'checkmark-circle'
                        : circle
                        ? 'checkmark-circle-outline'
                        : 'checkmark'
                }
                direction='ltr'
                size={size}
                color={color || theme.colors.text}
            />
        </View>
    );
};

export const ClockIcon = ({
    color,
    size,
    style,
    ...otherProps
}: Omit<IconProps, 'name' | 'color' | 'direction' | 'size'> & {
    color?: string;
    style?: StyleProp<ViewStyle>;
    size?: number;
}) => {
    const theme = useTheme() as AppTheme;
    return (
        <View {...otherProps} style={[iconStyles.iconContainer, style]}>
            <Ionicons
                name='time-outline'
                direction='ltr'
                size={size || 22}
                color={color || theme.colors.text}
            />
        </View>
    );
};

export const CrossIcon = ({
    color,
    size = 22,
    style,
    circle = false,
    filled = false,
    ...otherProps
}: Omit<IconProps, 'name' | 'color' | 'direction' | 'size'> & {
    color?: string;
    style?: StyleProp<ViewStyle>;
    size?: number;
    circle?: boolean;
    filled?: boolean;
}) => {
    const theme = useTheme() as AppTheme;
    return (
        <View {...otherProps} style={[iconStyles.iconContainer, style]}>
            <Ionicons
                name={
                    filled
                        ? 'close-circle'
                        : circle
                        ? 'close-circle-outline'
                        : 'close'
                }
                direction='ltr'
                size={size}
                color={color || theme.colors.text}
            />
        </View>
    );
};

export const ColorSchemeIcon = ({
    color,
    size = 22,
    style,
    ...otherProps
}: Omit<IconProps, 'name' | 'color' | 'direction' | 'size'> & {
    color?: string;
    style?: StyleProp<ViewStyle>;
    size?: number;
}) => {
    const theme = useTheme() as AppTheme;
    return (
        <View {...otherProps} style={[iconStyles.iconContainer, style]}>
            <Ionicons
                name='sunny'
                direction='ltr'
                size={size}
                color={color || theme.colors.text}
            />
        </View>
    );
};

export const ChartIcon = ({
    color,
    size,
    style,
    ...otherProps
}: Omit<IconProps, 'name' | 'color' | 'direction' | 'size'> & {
    color?: string;
    style?: StyleProp<ViewStyle>;
    size?: number;
}) => {
    const theme = useTheme() as AppTheme;
    return (
        <View {...otherProps} style={[iconStyles.iconContainer, style]}>
            <Ionicons
                name='analytics'
                direction='ltr'
                size={size || 22}
                color={color || theme.colors.text}
            />
        </View>
    );
};

export const EditIcon = ({
    color,
    size = 24,
    filled = false,
    style,
    ...otherProps
}: Omit<IconProps, 'name' | 'color' | 'direction' | 'size'> & {
    color?: string;
    filled?: boolean;
    style?: StyleProp<ViewStyle>;
    size?: number;
}) => {
    const theme = useTheme() as AppTheme;
    return (
        <View {...otherProps} style={[iconStyles.iconContainer, style]}>
            <Ionicons
                name={filled ? 'create' : 'create-outline'}
                direction='ltr'
                size={size}
                color={color || theme.colors.text}
            />
        </View>
    );
};

export const ExitIcon = ({
    color,
    size = 24,
    style,
    ...otherProps
}: Omit<IconProps, 'name' | 'color' | 'direction' | 'size'> & {
    color?: string;
    style?: StyleProp<ViewStyle>;
    size?: number;
}) => {
    const theme = useTheme() as AppTheme;
    return (
        <View {...otherProps} style={[iconStyles.iconContainer, style]}>
            <Ionicons
                name='exit-outline'
                direction='ltr'
                size={size}
                color={color || theme.colors.primary}
            />
        </View>
    );
};

export const EmailIcon = ({
    color,
    size,
    style,
    ...otherProps
}: Omit<IconProps, 'name' | 'color' | 'direction' | 'size'> & {
    color?: string;
    style?: StyleProp<ViewStyle>;
    size?: number;
}) => {
    const theme = useTheme() as AppTheme;
    return (
        <View {...otherProps} style={[iconStyles.iconContainer, style]}>
            <Ionicons
                name='mail-outline'
                direction='ltr'
                size={size || 22}
                color={color || theme.colors.text}
            />
        </View>
    );
};

export const HammerIcon = ({
    color,
    size,
    style,
    ...otherProps
}: Omit<IconProps, 'name' | 'color' | 'direction' | 'size'> & {
    color?: string;
    style?: StyleProp<ViewStyle>;
    size?: number;
}) => {
    const theme = useTheme() as AppTheme;
    return (
        <View {...otherProps} style={[iconStyles.iconContainer, style]}>
            <Ionicons
                name='hammer-outline'
                direction='ltr'
                size={size || 24}
                color={color || theme.colors.text}
            />
        </View>
    );
};

export const HourGlassIcon = ({
    color,
    size,
    style,
    ...otherProps
}: Omit<IconProps, 'name' | 'color' | 'direction' | 'size'> & {
    color?: string;
    style?: StyleProp<ViewStyle>;
    size?: number;
}) => {
    const theme = useTheme() as AppTheme;
    return (
        <View {...otherProps} style={[iconStyles.iconContainer, style]}>
            <Ionicons
                name='hourglass-outline'
                direction='ltr'
                size={size || 24}
                color={color || theme.colors.text}
            />
        </View>
    );
};

export const NotesIcon = ({
    color,
    size,
    style,
    ...otherProps
}: Omit<IconProps, 'name' | 'color' | 'direction' | 'size'> & {
    color?: string;
    style?: StyleProp<ViewStyle>;
    size?: number;
}) => {
    const theme = useTheme() as AppTheme;
    return (
        <View {...otherProps} style={[iconStyles.iconContainer, style]}>
            <Ionicons
                name='document-text-outline'
                direction='ltr'
                size={size || 22}
                color={color || theme.colors.text}
            />
        </View>
    );
};

export const RefreshIcon = ({
    color,
    size = 22,
    style,
    ...otherProps
}: Omit<IconProps, 'name' | 'color' | 'direction'> & {
    color?: string;
    style?: StyleProp<ViewStyle>;
    size?: number;
}) => {
    const theme = useTheme() as AppTheme;
    return (
        <View
            {...otherProps}
            style={[
                iconStyles.iconContainer,
                style,
                { width: size, height: size },
            ]}
        >
            <Ionicons
                name='sync'
                direction='ltr'
                size={size}
                color={color || theme.colors.text}
            />
        </View>
    );
};

export const SearchIcon = ({
    color,
    ...otherProps
}: Omit<IconProps, 'name' | 'color' | 'direction'> & {
    color?: string;
    style?: StyleProp<ViewStyle>;
}) => {
    const theme = useTheme() as AppTheme;
    return (
        <Ionicons
            name='search-circle'
            direction='ltr'
            {...otherProps}
            color={color || theme.colors.primary}
        />
    );
};

export const SettingsIcon = ({
    color,
    size,
    style,
    ...otherProps
}: Omit<IconProps, 'name' | 'color' | 'direction' | 'size'> & {
    color?: string;
    style?: StyleProp<ViewStyle>;
    size?: number;
}) => {
    const theme = useTheme() as AppTheme;
    return (
        <View {...otherProps} style={[iconStyles.iconContainer, style]}>
            <Ionicons
                name='cog-outline'
                direction='ltr'
                size={size || 22}
                color={color || theme.colors.text}
            />
        </View>
    );
};

export const ShareIcon = ({
    color,
    size,
    style,
    ...otherProps
}: Omit<IconProps, 'name' | 'color' | 'direction' | 'size'> & {
    color?: string;
    style?: StyleProp<ViewStyle>;
    size?: number;
}) => {
    const theme = useTheme() as AppTheme;
    return (
        <View {...otherProps} style={[iconStyles.iconContainer, style]}>
            <Ionicons
                name={
                    Platform.OS === 'ios'
                        ? 'share-outline'
                        : 'share-social-outline'
                }
                direction='ltr'
                size={size || 22}
                color={color || theme.colors.text}
            />
        </View>
    );
};

export const TimerIcon = ({
    color,
    size,
    style,
    ...otherProps
}: Omit<IconProps, 'name' | 'color' | 'direction' | 'size'> & {
    color?: string;
    style?: StyleProp<ViewStyle>;
    size?: number;
}) => {
    const theme = useTheme() as AppTheme;
    return (
        <View {...otherProps} style={[iconStyles.iconContainer, style]}>
            <Ionicons
                name='stopwatch'
                direction='ltr'
                size={size || 22}
                color={color || theme.colors.text}
            />
        </View>
    );
};

export const QuestionMarkIcon = ({
    color,
    size = 22,
    style,
    circle = false,
    filled = false,
    ...otherProps
}: Omit<IconProps, 'name' | 'color' | 'direction' | 'size'> & {
    color?: string;
    style?: StyleProp<ViewStyle>;
    size?: number;
    circle?: boolean;
    filled?: boolean;
}) => {
    const theme = useTheme() as AppTheme;
    return (
        <View {...otherProps} style={[iconStyles.iconContainer, style]}>
            <Ionicons
                name={
                    filled
                        ? 'help-circle'
                        : circle
                        ? 'help-circle-outline'
                        : 'help'
                }
                direction='ltr'
                size={size}
                color={color || theme.colors.text}
            />
        </View>
    );
};

export const UnitsIcon = ({
    color,
    size,
    style,
    ...otherProps
}: Omit<IconProps, 'name' | 'color' | 'direction' | 'size'> & {
    color?: string;
    style?: StyleProp<ViewStyle>;
    size?: number;
}) => {
    const theme = useTheme() as AppTheme;
    return (
        <View {...otherProps} style={[iconStyles.iconContainer, style]}>
            <Ionicons
                name='globe-outline'
                direction='ltr'
                size={size || 22}
                color={color || theme.colors.text}
            />
        </View>
    );
};

const styles = StyleSheet.create({
    circleLayer: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
    },
});
