import { useHeaderHeight } from '@react-navigation/stack';
import React from 'react';
import {
    Platform,
    KeyboardAvoidingView as _KeyboardAvoidingView,
    KeyboardAvoidingViewProps as _KeyboardAvoidingViewProps,
} from 'react-native';

export interface KeyboardAvoidingViewProps extends _KeyboardAvoidingViewProps {
    children: React.ReactNode | React.ReactNode[];
}

export function KeyboardAvoidingView({
    children,
    ...props
}: KeyboardAvoidingViewProps) {
    const autoKeyboardHeight = useHeaderHeight();
    const {
        behavior = Platform.OS === 'ios' ? 'padding' : 'height',
        keyboardVerticalOffset = autoKeyboardHeight,
    } = props;
    return (
        <_KeyboardAvoidingView
            {...props}
            behavior={behavior}
            keyboardVerticalOffset={keyboardVerticalOffset}
        >
            {children}
        </_KeyboardAvoidingView>
    );
}
