import React from 'react';
import { Animated, StyleSheet, TextProps } from 'react-native';

const fontSize = 24;

export interface AppNameProps extends Animated.AnimatedProps<TextProps> {}

export function AppName({ style, ...props }: AppNameProps) {
    return (
        <Animated.Text
            {...props}
            style={[styles.text, style]}
            numberOfLines={1}
            adjustsFontSizeToFit
            minimumFontScale={0.6}
        >
            <Animated.Text
                adjustsFontSizeToFit
                numberOfLines={1}
                minimumFontScale={0.6}
                style={styles.gmate}
            >
                Gmate
            </Animated.Text>
            <Animated.Text
                adjustsFontSizeToFit
                numberOfLines={1}
                minimumFontScale={0.6}
                style={styles.connect}
            >
                {' '}
                Connect
            </Animated.Text>
        </Animated.Text>
    );
}

const styles = StyleSheet.create({
    text: {
        fontSize,
    },
    gmate: {
        color: '#fc7b36',
        fontWeight: 'bold',
    },
    connect: {
        color: '#0bbfdf',
    },
});
