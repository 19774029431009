import { AllRecords, lz } from '@byterium/glucose-diary-client';
import React from 'react';
import {
    Platform,
    RefreshControl,
    ScrollView,
    StyleSheet,
    TouchableOpacity,
    View,
} from 'react-native';
import { useTheme } from 'react-native-paper';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import AddSampleRow from '../components/AddSampleRow';
import AppBackground from '../components/AppBackground';
import { AppName } from '../components/AppName';
import { addSnack } from '../components/AppSnackbar';
import CenteredContent from '../components/CenteredContent';
import OverviewButtonRow from '../components/OverviewButtonRow';
import OverviewChart from '../components/OverviewChart';
import RefreshButton from '../components/RefreshButton';
import Stack from '../components/Stack';
import { useActivitySessionSummaryCell } from '../components/activity/ActivitySessionSummaryCell';
import { useGlucoseSampleSummaryCell } from '../components/glucose/GlucoseSampleSummaryCell';
import { useInsulinDoseSummaryCell } from '../components/insulin/InsulinDoseSummaryCell';
import { useMealSummaryCell } from '../components/meal/MealSummaryCell';
import UserAvatar from '../components/settings/UserAvatar';
import { AppTheme, kMaxPageWidth } from '../const';
import {
    RefreshConfig,
    useAppMode,
    useCustomNavigationHeaders,
    useInFocus,
    useRefreshAction,
} from '../reactUtil';
import { HomeNavigationProp } from './navigate';

export interface HomePageProps {
    navigation: HomeNavigationProp;
}

const isNative = Platform.OS !== 'web';

const refreshConfig: RefreshConfig<any> = {
    refreshTask: () => AllRecords.refetchAll(),
    onRefreshed: res =>
        addSnack({
            message: res.error
                ? lz('somethingWentWrong')
                : lz('dataUpdatedSuccessfully'),
            type: res.error ? 'error' : 'info',
        }),
};

const HomePage: React.FC<HomePageProps> = ({ navigation }) => {
    const theme = useTheme() as AppTheme;
    const insets = useSafeAreaInsets();
    const [isFullscreenChart, setFullscreenChart] = React.useState(false);

    const preferredAppMode = useAppMode({ overview: true });
    const [appMode, setAppMode] = React.useState(preferredAppMode);
    const isDesktop = appMode === 'desktop';
    const isInFocus = useInFocus(navigation);

    React.useEffect(() => {
        // Prevent changing app modes while in fullscreen
        if (!isFullscreenChart && appMode !== preferredAppMode) {
            setAppMode(preferredAppMode);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [preferredAppMode, isFullscreenChart]);

    const refreshAction = useRefreshAction(refreshConfig);

    useCustomNavigationHeaders(
        {
            navigation,
            headerTitle: props => <AppName {...props} />,
            headerRight: props => (
                <Stack {...props} horizontal>
                    {!isNative && (
                        <RefreshButton
                            {...refreshAction}
                            color={props.tintColor || theme.colors.accent}
                        />
                    )}
                    <TouchableOpacity
                        onPress={() => navigation.push('Settings')}
                        style={styles.settings}
                    >
                        <UserAvatar diameter={32} />
                    </TouchableOpacity>
                </Stack>
            ),
        },
        isNative ? [] : [...Object.values(refreshAction)]
    );

    React.useEffect(() => {
        navigation.setOptions({ headerShown: !isFullscreenChart });
    }, [isFullscreenChart, navigation]);

    const refreshColorsAndroid = React.useMemo(
        () => [theme.colors.accent],
        [theme.colors.accent]
    );

    return (
        <View style={styles.rootContainer}>
            {!isFullscreenChart && <AppBackground />}
            <ScrollView
                style={[
                    styles.scrollContainer,
                    isFullscreenChart ? styles.hidden : undefined,
                ]}
                contentContainerStyle={styles.scrollContent}
                refreshControl={
                    isNative ? (
                        <RefreshControl
                            {...refreshAction}
                            {...refreshConfig}
                            tintColor={theme.colors.accent}
                            colors={refreshColorsAndroid}
                        />
                    ) : undefined
                }
            >
                <CenteredContent
                    maxWidth={kMaxPageWidth}
                    style={[
                        styles.content,
                        {
                            paddingLeft: insets.left,
                            paddingRight: !isDesktop ? insets.right : 0,
                            paddingBottom: insets.bottom,
                        },
                    ]}
                >
                    <AddSampleRow style={styles.row} />

                    {!isDesktop && (
                        <OverviewButtonRow
                            isSingle
                            onPress={() => navigation.push('OverviewChart')}
                            style={styles.row}
                        />
                    )}

                    {/* <Text
                    style={[
                        styles.sectionTitle,
                        { color: theme.colors.textSlightlyFaded },
                    ]}
                >
                    {lz('favourites')}
                </Text> */}
                    {useGlucoseSampleSummaryCell({
                        theme,
                        style: styles.row,
                    })}
                    {useInsulinDoseSummaryCell({
                        theme,
                        style: styles.row,
                    })}
                    {useMealSummaryCell({
                        theme,
                        style: styles.row,
                    })}
                    {useActivitySessionSummaryCell({
                        theme,
                        style: styles.row,
                    })}
                </CenteredContent>
            </ScrollView>
            {(isDesktop || isFullscreenChart) && isInFocus && (
                <OverviewChart
                    isFullscreen={isFullscreenChart}
                    onFullscreenPress={() =>
                        setFullscreenChart(!isFullscreenChart)
                    }
                    style={[
                        styles.desktopChart,
                        isFullscreenChart
                            ? {
                                  marginTop: insets.top,
                                  marginRight: insets.right,
                                  marginLeft: insets.left,
                                  marginBottom: insets.bottom,
                              }
                            : {
                                  marginRight: kMargin + insets.right,
                                  marginBottom: kMargin + insets.bottom,
                              },
                    ]}
                />
            )}
        </View>
    );
};

const kMargin = 12;

const styles = StyleSheet.create({
    settings: {
        padding: 4,
        marginRight: 10,
    },
    rootContainer: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'center',
    },
    container: {
        flex: 1,
        minWidth: 200,
    },
    scrollContainer: {
        flex: 1,
        minWidth: 200,
        maxWidth: kMaxPageWidth,
    },
    scrollContent: {
        flexGrow: 1,
    },
    content: {
        margin: kMargin,
    },
    sectionTitle: {
        marginTop: 8,
        marginBottom: 8,
        marginHorizontal: 10,
        fontSize: 16,
        fontWeight: 'bold',
    },
    row: {
        marginBottom: 8,
    },
    desktopChart: {
        flex: 1.5,
        marginTop: kMargin,
    },
    hidden: {
        position: 'absolute',
        display: 'none',
    },
});

export default HomePage;
