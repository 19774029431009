import {
    ImageProps,
    ImageStyle,
    StyleProp,
    StyleSheet,
    ViewStyle,
} from 'react-native';
import { IconProps } from 'react-native-paper/lib/typescript/components/MaterialCommunityIcon';
import { SvgProps } from 'react-native-svg';

export interface AssetProps extends Omit<ImageProps, 'source'> {}

export interface IconAssetProps
    extends Omit<IconProps, 'name' | 'color' | 'direction' | 'size'> {
    color?: string;
    style?: StyleProp<ViewStyle>;
    size?: number;
}

export interface PngAssetProps extends AssetProps {
    size?: number;
    color?: string;
}

export interface SvgAssetProps
    extends Omit<SvgProps, 'color' | 'width' | 'height'>,
        AssetProps {
    color?: string;
    width?: number;
    height?: number;
    style?: StyleProp<ImageStyle>;
}

export const iconStyles = StyleSheet.create({
    iconContainer: {
        alignItems: 'center',
        justifyContent: 'center',
        aspectRatio: 1.0,
    },
    iconSvg: {
        padding: 2,
    },
});
