import {
    CarbUnit,
    formatCarbUnit,
    kBaseCarbUnit,
    kCarbUnits,
    lz,
    lzn,
} from '@byterium/glucose-diary-client';

import UserSettings from '../../UserSettings';
import { UnitSettingSectionOptions } from './unitSettingTypes';

export function addCarbUnitRowsToSection({
    section,
    theme,
}: UnitSettingSectionOptions) {
    section
        .addRow()
        .addLabel({ title: lz('carbohydrates'), flex: 1 })
        .addOptionInput<CarbUnit>({
            value: UserSettings.carbUnit$,
            mode: 'segmented',
            possibleValues: [...kCarbUnits],
            formatValue: x => formatCarbUnit(x),
            style: {
                fontSize: 14,
            },
            flex: 1,
            align: 'right',
        });

    section
        .addRow()
        .addLabel({ title: lz('carbCountingBasis'), flex: 1 })
        .addKeyboardInput<number>({
            value: UserSettings.carbsPerUnit$,
            validation: x => (x || 0) > 0 || lz('valueMustBeGT', { value: 0 }),
            type: 'unsignedFloat',
            placeholder: lzn(0),
            align: 'right',
            mode: 'contained',
        })
        .setMargin(0)
        .addLabel({
            title: formatCarbUnit(kBaseCarbUnit),
            color: theme.colors.textStronglyFaded,
        });
}
