import {
    Schema,
    UserDocument,
    Util,
    getCurrentLocale,
} from '@byterium/glucose-diary-client';
import moment from 'moment';

import { kDocumentHashAlgorithm } from '../../const';

export type UserDocumentLocale = 'en' | 'ru';

interface UserDocumentByKey {
    termsAndConditions: UserDocument;
}

const termsAndConditions = {
    en: () => import('./termsAndConditions/terms-en'),
    ru: () => import('./termsAndConditions/terms-ru'),
};

export const documentsByLocale: {
    [L in UserDocumentLocale]: UserDocumentByKey;
} = {
    en: {
        termsAndConditions: {
            type: Schema.DocumentType.TermsAndConditions,
            date: moment.utc('2021-03-19').toDate(),
            hashAlgorithm: kDocumentHashAlgorithm,
            hash: '9eca65ade15aeee8b708e495b1185a21',
            getContent: () => termsAndConditions.en().then(pkg => pkg.content),
            required: true,
        },
    },
    ru: {
        termsAndConditions: {
            type: Schema.DocumentType.TermsAndConditions,
            date: moment.utc('2021-03-19').toDate(),
            hashAlgorithm: kDocumentHashAlgorithm,
            hash: 'f0fe81e022bc04949d8933a01fe00021',
            getContent: () => termsAndConditions.ru().then(pkg => pkg.content),
            required: true,
        },
    },
};

export const kUserDocumentLocales =
    Util.safeKeyList<UserDocumentLocale>(documentsByLocale);

export function getUserDocuments(options?: {
    locale: UserDocumentLocale;
}): UserDocument[] {
    let locale = options?.locale || getCurrentLocale({ basic: true });
    if (!isUserDocumentLocale(locale)) {
        locale = 'en';
    }
    return Object.values(documentsByLocale[locale as UserDocumentLocale]);
}

export function isUserDocumentLocale(
    locale: string | UserDocumentLocale
): locale is UserDocumentLocale {
    return kUserDocumentLocales.includes(locale as any);
}

export function normalizeDocumentContent(content: string): string {
    // Trim all lines and remove empty lines
    return content
        .split('\n')
        .map(line => line.trim())
        .filter(line => !!line)
        .join('\n');
}
