import {
    GlucoseUnit,
    Schema,
    formatGlucoseUnit,
    formatGlucoseValue,
    formatMealFlag,
    isNormalGlucoseValue,
    lz,
    parseGlucoseValue,
} from '@byterium/glucose-diary-client';
import React from 'react';
import { ImageProps } from 'react-native';
import { InputFieldValueInfo } from 'react-native-form-model';

import { GlucoseIcon } from '../../../components/assets/glucoseAssets';
import { MealIcon } from '../../../components/assets/mealAssets';
import { defaultSectionStyleWithValidation } from '../formUtil';
import {
    RecordFormData,
    getRecordFormValues,
    setRecordFormValues,
} from './recordFormData';
import { UpdateRecordFormOptions } from './recordFormTypes';
import { handleAbnormalValueIfNeeded } from './recordFormUtil';

export function Icon(props: Pick<ImageProps, 'style'>) {
    return <GlucoseIcon {...props} />;
}

export function addGlucoseSection({
    form,
    data,
    theme,
    styles,
    units: { glucoseUnit },
    isNew,
}: UpdateRecordFormOptions) {
    const mealTimeRow = form
        .addSection()
        .addRow()
        .addCustom(() => <MealIcon style={styles.icon} />)
        .setMargin(0)
        .addLabel(lz('mealTime'));

    if (isNew) {
        mealTimeRow.addOptionInput<Schema.MealFlag | undefined>({
            value: data.glucose.mealFlag,
            mode: 'segmented',
            disabled: !isNew,
            possibleValues: [Schema.MealFlag.Premeal, Schema.MealFlag.Postmeal],
            formatValue: x => formatMealFlag(x),
            optional: true,
            style: {
                fontSize: 14,
            },
            align: 'right',
        });
    } else {
        mealTimeRow.addLabel({
            title: formatMealFlag(data.glucose.mealFlag.value),
            style: {
                colors: { label: theme.form.colors.disabled },
            },
            align: 'right',
            flex: 1,
        });
    }

    form.addSection({
        style: defaultSectionStyleWithValidation(theme),
    }).modify(section => {
        section
            .addRow()
            .addCustom(() => <GlucoseIcon style={styles.icon} />)
            .setMargin(0)
            .addLabel({ title: lz('bloodGlucose'), flex: 1 })
            .addKeyboardInput<number | undefined>({
                value: data.glucose.glucoseLevel,
                type: 'unsignedFloat',
                disabled: !isNew,
                parseInput: x => parseGlucoseValue(x, glucoseUnit),
                validation: x =>
                    (x || 0) > 0 || lz('valueMustBeGT', { value: 0 }),
                formatValue: x => formatGlucoseValue(x, glucoseUnit),
                placeholder: formatGlucoseValue(0, glucoseUnit),
                flex: 1,
                align: 'right',
                mode: 'contained',
                autoFocus: isNew,
                selectTextOnFocus: true,
                onValueChange: info =>
                    _handleAbnormalValueIfNeeded({
                        ...info,
                        unit: glucoseUnit,
                    }),
            })
            .setMargin(0)
            .addLabel({
                title: formatGlucoseUnit(glucoseUnit),
                color: theme.colors.textStronglyFaded,
            });
        section.footer = section.flattenedFormattedErrors$({
            editedOnly: true,
        });
    });
}

export function getGlucoseFormValues(
    formData: RecordFormData
): Schema.AddGlucoseSampleInput {
    return {
        ...getRecordFormValues(formData),
        glucoseLevel: formData.glucose.glucoseLevel.value || 0,
        mealFlag: formData.glucose.mealFlag.value,
    };
}

export function setGlucoseFormValues(
    formData: RecordFormData,
    values: Schema.GlucoseSampleFieldsFragment
) {
    setRecordFormValues(formData, values);
    formData.glucose.glucoseLevel.next(values.glucoseLevel);
    formData.glucose.mealFlag.next(values.mealFlag);
}

function _handleAbnormalValueIfNeeded({
    unit,
    ...options
}: InputFieldValueInfo<number | undefined> & { unit: GlucoseUnit }) {
    handleAbnormalValueIfNeeded({
        ...options,
        isNormalValue: isNormalGlucoseValue,
        displayValue: `${formatGlucoseValue(
            options.value,
            unit
        )} ${formatGlucoseUnit(unit)}`,
    });
}
