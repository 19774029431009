import React from 'react';
import { ScrollView, StyleSheet, View } from 'react-native';
import { Form, FormModel } from 'react-native-form-model';
import { useTheme } from 'react-native-paper';

import AppBackground from '../../../components/AppBackground';
import CenteredContent from '../../../components/CenteredContent';
import { KeyboardAvoidingView } from '../../../components/KeyboardAvoidingView';
import { AppTheme, kMaxPageWidth } from '../../../const';
import { addSyncSettingsSection } from './syncSettingsForm';
import { SyncSettingsPageOptions } from './syncSettingsTypes';

export interface SyncSettingsPageProps extends SyncSettingsPageOptions {}

export default function SyncSettingsPage({
    navigation,
}: SyncSettingsPageProps) {
    const theme = useTheme() as AppTheme;

    const form = React.useMemo(() => {
        const form = FormModel.create({ style: theme.form });
        addSyncSettingsSection(form, {
            theme,
            styles,
            style: { sectionMargin: 12 },
        });
        return form;
    }, [theme]);

    return (
        <View style={styles.container}>
            <AppBackground />
            <KeyboardAvoidingView style={styles.container}>
                <ScrollView
                    style={styles.container}
                    contentContainerStyle={styles.scrollContent}
                >
                    <CenteredContent
                        maxWidth={kMaxPageWidth}
                        style={styles.content}
                    >
                        <Form form={form} style={styles.form} />
                    </CenteredContent>
                </ScrollView>
            </KeyboardAvoidingView>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    scrollContent: {
        flexGrow: 1,
    },
    content: {
        margin: 12,
    },
    form: {
        marginVertical: 12,
    },
    section: {
        marginBottom: 10,
    },
    icon: {
        width: 24,
    },
});
