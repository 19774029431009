import {
    RecordModel,
    getRecordDataSource,
    lz,
} from '@byterium/glucose-diary-client';
import React, { useCallback } from 'react';
import { ScrollView, StyleSheet, View } from 'react-native';
import { Button, Form } from 'react-native-form-model';
import { ActivityIndicator, Banner, useTheme } from 'react-native-paper';
import { BehaviorSubject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import AppBackground from '../../components/AppBackground';
import { addErrorSnack } from '../../components/AppSnackbar';
import CenteredContent from '../../components/CenteredContent';
import { KeyboardAvoidingView } from '../../components/KeyboardAvoidingView';
import { AppTheme, kMaxPageWidth } from '../../const';
import { usePromise } from '../../reactUtil';
import UserSettings from '../../services/UserSettings';
import { confirm } from '../../services/alert';
import * as RecordForms from '../../services/forms/records';
import { setCombinedRecordFormValues } from '../../services/forms/records';
import {
    RecordDetailNavigationProp,
    RecordDetailPageOptions,
    RecordDetailRouteProp,
} from './recordTypes';

const kNotesAutoSaveDebounce = 3000;

export interface RecordDetailPageProps extends RecordDetailPageOptions {
    navigation: RecordDetailNavigationProp;
    route: RecordDetailRouteProp;
}

export default function RecordDetailPage(props: RecordDetailPageProps) {
    const combinedProps = {
        ...props,
        ...props.route.params,
    };
    const { recordId, recordType } = combinedProps;
    const dataSource = getRecordDataSource(recordType);

    const [attempt, setAttempt] = React.useState(0);

    const recordRequest = React.useMemo<Promise<RecordModel | undefined>>(
        () => dataSource.get(recordId),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [dataSource, recordId, attempt]
    );

    const { value: record, loading, error } = usePromise(recordRequest);
    const dataView = record ? (
        <RecordDetailDataPage {...combinedProps} record={record} />
    ) : (
        <View>
            <Banner
                visible={!!error}
                actions={[
                    {
                        label: lz('retry'),
                        onPress: () => setAttempt(attempt => attempt + 1),
                    },
                ]}
            >
                {`${lz('somethingWentWrong')}: ${String(
                    error?.message || error || ''
                )}`}
            </Banner>
            <ActivityIndicator
                size='large'
                animating={loading}
                style={styles.activity}
            />
        </View>
    );

    return (
        <View style={styles.container}>
            <AppBackground />
            {dataView}
        </View>
    );
}

interface RecordDetailDataPageProps extends RecordDetailPageProps {
    record: RecordModel;
}

function RecordDetailDataPage({
    record,
    recordType,
    navigation,
}: RecordDetailDataPageProps) {
    const dataSource = getRecordDataSource(recordType);

    const theme = useTheme() as AppTheme;
    const units = UserSettings.useUnits();

    const [deleteAction, setDeleteAction] = React.useState<
        Promise<void> | undefined
    >(undefined);
    const {
        loading: deleting,
        error: deleteError,
        complete: deleteComplete,
    } = usePromise(deleteAction);

    const data = React.useMemo(() => {
        const data = RecordForms.createRecordFormData({ recordType });
        setCombinedRecordFormValues(data, record);
        return data;
    }, [record, recordType]);

    const savedNotes = React.useRef(
        new BehaviorSubject(data.common.notes.value)
    ).current;
    const savingNotes = React.useRef(new BehaviorSubject(false)).current;

    const form = React.useMemo(() => {
        return RecordForms.createRecordForm({
            data,
            theme,
            recordType,
            styles,
            units,
            isNew: false,
        });
    }, [data, recordType, theme, units]);

    React.useEffect(() => {
        const saveNotes = (notes: string) => {
            if (notes === savedNotes.value) {
                return Promise.resolve(record);
            }

            console.debug('saving notes...');
            savedNotes.next(notes);
            savingNotes.next(true);
            return dataSource
                .updateOne({
                    id: record.id,
                    notes,
                })
                .finally(() => savingNotes.next(false));
        };

        // Save notes periodically
        const sub = data.common.notes
            .pipe(debounceTime(kNotesAutoSaveDebounce))
            .subscribe(notes => saveNotes(notes));

        // Save notes on exit
        return () => {
            sub.unsubscribe();
            saveNotes(data.common.notes.value);
        };
    }, [data.common.notes, dataSource, record, savedNotes, savingNotes]);

    const handleDeletePress = useCallback(() => {
        confirm({
            title: lz('confirmation'),
            message: lz('deleteRecordConfimationMessage'),
            confirmTitle: lz('confirm'),
            onConfirm: () =>
                setDeleteAction(dataSource.delete({ id: record.id })),
        });
    }, [dataSource, record.id]);

    React.useEffect(() => {
        if (deleteError) {
            console.error('Failed to delete record: ' + deleteError);
            addErrorSnack({
                message: lz('somethingWentWrong'),
                action: {
                    label: lz('retry'),
                    onPress: () => handleDeletePress(),
                },
            });
        } else if (deleteComplete) {
            navigation.pop();
        }
    }, [deleteError, deleteComplete, handleDeletePress, navigation]);

    return (
        <KeyboardAvoidingView style={styles.container}>
            <ScrollView
                style={styles.container}
                contentContainerStyle={styles.scrollContent}
            >
                <CenteredContent
                    maxWidth={kMaxPageWidth}
                    style={styles.content}
                >
                    <Form form={form} style={styles.form} />

                    <Button
                        title={lz('deleteRecord')}
                        mode='contained'
                        loading={deleting}
                        disabled={deleting}
                        onPress={() => handleDeletePress()}
                        color={theme.form.colors.destructive}
                    />
                </CenteredContent>
            </ScrollView>
        </KeyboardAvoidingView>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    scrollContent: {
        flexGrow: 1,
    },
    content: {
        margin: 12,
    },
    form: {
        marginVertical: 12,
    },
    icon: {
        width: 24,
    },
    activity: {
        margin: 32,
    },
});
