import {
    FitnessBase,
    RecordType,
    getRecordDataSource,
    isFitnessRecordType,
    kRecordTypes,
    lz,
} from '@byterium/glucose-diary-client';
import _ from 'lodash';
import moment from 'moment';
import { FormUtil } from 'react-native-form-model';
import { BehaviorSubject } from 'rxjs';

import { alertAsync } from '../../../services/alert';

export function createFitnessSettingsData(fitness: FitnessBase) {
    return {
        import: (() => {
            const { day, time } = FormUtil.mutableDateTime$(
                moment().startOf('year')
            );
            const importing = new BehaviorSubject(
                _.zipObject(
                    kRecordTypes,
                    kRecordTypes.map(x => false)
                )
            );
            return {
                day,
                time,
                beginImport: (type: RecordType) => {
                    const records = getRecordDataSource(type);
                    console.debug(
                        `Importing ${records.typename} from ${fitness.name}...`
                    );
                    importing.next({ ...importing.value, [type]: true });
                    return records
                        .importFromFitnessKit(fitness, {
                            startDate: FormUtil.joinDateTime(
                                day.value,
                                time.value
                            ),
                        })
                        .then(res => {
                            console.debug(
                                `Imported ${records.typename} from ${fitness.name}: added ${res.added.length}, skipped: ${res.skipped}, failed: ${res.failed}`
                            );
                            let title = '';
                            const messages: string[] = [];
                            if (res.failed) {
                                title = lz('importFailed');
                                if (res.added.length) {
                                    messages.push(
                                        lz('importFitnessSuccessMessage', {
                                            count: res.added.length,
                                            fitnessName: fitness.name,
                                        })
                                    );
                                }
                                messages.push(
                                    [
                                        lz('importFitnessFailureMessage', {
                                            count: res.failed,
                                            fitnessName: fitness.name,
                                        }),
                                        lz('tryAgainMessage'),
                                        lz('contactUsIfIssuePersistsMessage'),
                                    ].join(' ')
                                );
                            } else {
                                title = lz('importSuccessful');
                                messages.push(
                                    res.added.length
                                        ? lz('importFitnessSuccessMessage', {
                                              count: res.added.length,
                                              fitnessName: fitness.name,
                                          })
                                        : lz('importFitnessEmptyMessage', {
                                              fitnessName: fitness.name,
                                          })
                                );
                            }
                            alertAsync({
                                title,
                                message: messages.join('\n\n'),
                            });
                            return res;
                        })
                        .catch(err => {
                            console.error(
                                `Failed to import from ${fitness.name}: ${err}`
                            );
                            alertAsync({
                                title: lz('importFailed'),
                                message: [
                                    lz('importFitnessErrorMessage', {
                                        fitnessName: fitness.name,
                                    }),
                                    lz('contactUsIfIssuePersistsMessage'),
                                ].join(' '),
                            });
                        })
                        .finally(() =>
                            importing.next({
                                ...importing.value,
                                [type]: false,
                            })
                        );
                },
                importing,
            };
        })(),
        export: (() => {
            const { day, time } = FormUtil.mutableDateTime$(
                moment().startOf('year')
            );
            const exporting = new BehaviorSubject(
                _.zipObject(
                    kRecordTypes,
                    kRecordTypes.map(x => false)
                )
            );
            return {
                day,
                time,
                beginExport: (type: RecordType) => {
                    if (!isFitnessRecordType(type)) {
                        throw new Error('Invalid fitness record type');
                    }
                    const records = getRecordDataSource(type);
                    console.debug(
                        `Exporting ${records.typename} to ${fitness.name}...`
                    );
                    exporting.next({ ...exporting.value, [type]: true });
                    return records
                        .exportRangeToFitnessKitSafe(fitness, {
                            startDate: FormUtil.joinDateTime(
                                day.value,
                                time.value
                            ),
                        })
                        .then(res => {
                            console.debug(
                                `Exported ${records.typename} to ${fitness.name}: added ${res.added}, skipped: ${res.skipped}, failed: ${res.failed}`
                            );
                            let title = '';
                            const messages: string[] = [];
                            if (res.failed) {
                                title = lz('exportFailed');
                                if (res.added) {
                                    messages.push(
                                        lz('exportFitnessSuccessMessage', {
                                            count: res.added,
                                            fitnessName: fitness.name,
                                        })
                                    );
                                }
                                messages.push(
                                    [
                                        lz('exportFitnessFailureMessage', {
                                            count: res.failed,
                                            fitnessName: fitness.name,
                                        }),
                                        lz('tryAgainMessage'),
                                        lz('contactUsIfIssuePersistsMessage'),
                                    ].join(' ')
                                );
                            } else {
                                title = lz('exportSuccessful');
                                messages.push(
                                    res.added
                                        ? lz('exportFitnessSuccessMessage', {
                                              count: res.added,
                                              fitnessName: fitness.name,
                                          })
                                        : lz('exportFitnessEmptyMessage', {
                                              fitnessName: fitness.name,
                                          })
                                );
                            }
                            alertAsync({
                                title,
                                message: messages.join('\n\n'),
                            });
                            return res;
                        })
                        .catch(err => {
                            console.error(
                                `Failed to export to ${fitness.name}: ${err}`
                            );
                            alertAsync({
                                title: lz('exportFailed'),
                                message: [
                                    lz('exportFitnessErrorMessage', {
                                        fitnessName: fitness.name,
                                    }),
                                    lz('contactUsIfIssuePersistsMessage'),
                                ].join(' '),
                            });
                        })
                        .finally(() =>
                            exporting.next({
                                ...exporting.value,
                                [type]: false,
                            })
                        );
                },
                exporting,
            };
        })(),
    };
}

export type FitnessSettingsFormData = ReturnType<
    typeof createFitnessSettingsData
>;
