import { detect } from 'detect-browser';
import { saveAs } from 'file-saver';
import html2canvas, { Options as Html2CanvasOptions } from 'html2canvas';
import ReactDOM from 'react-dom';

import {
    MediaShareOptions,
    RefObjectLike,
    kMimeTypeByFileType,
    mediaShareOptions,
} from './mediaShareTypes';

const browser = detect();

export function isSupported() {
    // TODO: Find a more robust way of capturing a view
    return false;
}

export async function saveViewImage(
    ref: RefObjectLike,
    options: MediaShareOptions = {}
) {
    throw new Error('not implemented');
}

export async function shareView(
    ref: RefObjectLike,
    {
        fileType = 'PNG',
        fileName,
        quality = 0.9,
        title,
        text,
    }: MediaShareOptions = {}
): Promise<boolean> {
    const options = {
        fileType,
        fileName,
        quality,
        title,
        text,
    };
    const blob = await toBlob(ref, options);
    let success = false;
    if (blob) {
        success = await shareBlob(blob, options);
    }
    return success;
}

async function toCanvas(
    ref: RefObjectLike,
    options?: Partial<Html2CanvasOptions>
) {
    if (!ref.current) {
        throw new Error('Invalid RefObject');
    }

    const element = ReactDOM.findDOMNode(ref.current);
    if (!element || element instanceof Text) {
        throw new Error('Invalid DOM element');
    }
    return await html2canvas(element as HTMLElement, {
        scrollY: -window.scrollY,
        useCORS: true,
        logging: false,
        ...html2CanvasDefaults,
        ...options,
    });
}

async function toBlob(
    ref: RefObjectLike,
    options: MediaShareOptions = {}
): Promise<Blob | null> {
    const { fileType, quality } = mediaShareOptions(options);
    const mimeType = kMimeTypeByFileType[fileType];
    const canvas = await toCanvas(ref);
    if (!canvas) {
        return null;
    }
    return await new Promise((resolve, reject) => {
        canvas.toBlob(
            blob => {
                if (blob) {
                    resolve(blob);
                } else {
                    reject(new Error('Canvas to Blob failed'));
                }
            },
            mimeType,
            quality
        );
    });
}

async function shareBlob(
    blob: Blob,
    options: MediaShareOptions = {}
): Promise<boolean> {
    const data = await toSharableBlobData(blob, options);
    if (data) {
        // Try to share using Web Share API
        try {
            await navigator.share(data);
            return true;
        } catch (error) {
            if (!(error instanceof TypeError)) {
                throw error;
            }
            // Else: Probably not supported
        } finally {
            data.url && URL.revokeObjectURL(data.url);
        }
    }
    if (blob) {
        // Try to save blob
        try {
            const { fileName } = mediaShareOptions(options);
            saveAs(blob, fileName);
            return true;
        } catch (error) {
            if (!(error instanceof TypeError)) {
                throw error;
            }
            // Else: Probably not supported
        }
    }
    return false;
}

/**
 * Returns Web Share API compatible data.
 * @param url
 * @param options
 */
async function toSharableBlobData(
    blob: Blob,
    options: MediaShareOptions = {}
): Promise<any | undefined> {
    if (!blob) {
        throw new Error('Invalid blob');
    }

    if (!navigator?.share) {
        return undefined;
    }

    const nav = navigator as any;
    if (!nav.canShare) {
        // Cannot share with Web Share API
        return undefined;
    }

    const data: any = {
        title: options.title,
        text: options.text,
    };

    if (File) {
        // Try with files
        const { fileName } = mediaShareOptions(options);
        const file = new File([blob], fileName, { type: blob.type });
        data.files = [file];
        if (nav.canShare(data)) {
            return data;
        }
        delete data.files;
    }

    // Try with blob
    data.blob = blob;
    if (nav.canShare(data)) {
        return data;
    }
    delete data.blob;

    // Try with url
    data.url = URL.createObjectURL(blob);
    if (nav.canShare(data)) {
        return data;
    }
    URL.revokeObjectURL(data.url);
    delete data.url;

    // Cannot share with Web Share API
    return undefined;
}

const html2CanvasDefaults: Partial<Html2CanvasOptions> =
    browser?.name === 'chrome'
        ? {
              onclone: element => {
                  // See workaround: https://github.com/niklasvh/html2canvas/issues/1578#issuecomment-412796286
                  const svgElements = element.body.getElementsByTagName('svg');
                  Array.from(svgElements).forEach(svgElement => {
                      const bBox = svgElement.getBBox();
                      // const bBox = svgElement.getBBox({
                      //     clipped: false,
                      //     fill: false,
                      //     markers: false,
                      //     stroke: false,
                      // });
                      bBox.width &&
                          svgElement.setAttribute('width', String(bBox.width));
                      bBox.height &&
                          svgElement.setAttribute(
                              'height',
                              String(bBox.height)
                          );
                  });
              },
          }
        : {};
