import { lz } from '@byterium/glucose-diary-client';
import React from 'react';
import { StyleSheet, View } from 'react-native';
import { Row, RowProps } from 'react-native-form-model';
import { Text } from 'react-native-paper';

import { ChartIcon, ChevronIcon } from './assets/commonAssets';

export interface OverviewButtonRowProps extends RowProps {}

export default function OverviewButtonRow({
    style,
    ...props
}: OverviewButtonRowProps) {
    return (
        <Row {...props} style={[styles.container, style]}>
            <View style={styles.innerLeftContainer}>
                <ChartIcon />
                <Text
                    style={styles.text}
                    onPressIn={undefined}
                    onPressOut={undefined}
                >
                    {lz('overview')}
                </Text>
            </View>
            <ChevronIcon style={styles.chevron} />
        </Row>
    );
}

const styles = StyleSheet.create({
    container: {
        minHeight: 50,
    },
    innerLeftContainer: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    text: {
        fontWeight: 'bold',
        padding: 12,
    },
    chevron: {
        paddingLeft: 12,
    },
});
