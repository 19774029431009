import {
    GlucoseSamples,
    formatGlucoseUnit,
    formatGlucoseValue,
    formatMealFlag,
    lz,
    lzDate,
} from '@byterium/glucose-diary-client';
import React from 'react';

import { navigate } from '../../pages/navigate';
import UserSettings from '../../services/UserSettings';
import { GlucoseIcon } from '../assets/glucoseAssets';
import RecordSummaryCell, {
    RecordSummaryBuildOptions,
    RecordSummaryCellProps,
} from '../records/RecordSummaryCell';

export const useGlucoseSampleSummaryCell = (
    options: RecordSummaryBuildOptions
): React.ReactNode => {
    const { record, error, loading } = GlucoseSamples.useLatest();
    const { glucoseUnit } = UserSettings.useUnits();
    const props: Partial<RecordSummaryCellProps> = {};

    if (record) {
        props.value = formatGlucoseValue(record.glucoseLevel, glucoseUnit);
        props.unit = formatGlucoseUnit(glucoseUnit);
        props.date = lzDate(record.date);

        if (record.mealFlag) {
            props.description = formatMealFlag(record.mealFlag);
        }
    }

    // TODO: Add to description a note about normal range
    return (
        <RecordSummaryCell
            {...options}
            {...props}
            title={lz('bloodGlucose')}
            titleIcon={() => <GlucoseIcon />}
            error={error ? error.message || String(error) : ''}
            placeholder={loading ? lz('loading') : lz('noData')}
            primaryColor={options.theme.colors.glucose}
            onPress={() =>
                navigate('RecordSummary', { recordType: 'GlucoseSample' })
            }
        />
    );
};
