import {
    EnergyUnit,
    formatEnergyUnit,
    kEnergyUnits,
    lz,
} from '@byterium/glucose-diary-client';

import UserSettings from '../../UserSettings';
import { UnitSettingSectionOptions } from './unitSettingTypes';

export function addEnergyUnitRowsToSection({
    section,
}: UnitSettingSectionOptions) {
    section
        .addRow()
        .addLabel({ title: lz('energy') })
        .addOptionInput<EnergyUnit>({
            value: UserSettings.energyUnit$,
            mode: 'segmented',
            possibleValues: [...kEnergyUnits],
            formatValue: x => formatEnergyUnit(x),
            style: {
                fontSize: 14,
            },
            flex: 1,
            align: 'right',
        });
}
