import { lz } from '@byterium/glucose-diary-client';
import { Duration, Moment } from 'moment';
import React from 'react';
import { ImageStyle, StyleProp } from 'react-native';
import {
    FormModel,
    SectionModel,
    SectionModelOptions,
} from 'react-native-form-model';
import { BehaviorSubject } from 'rxjs';

import { CalendarIcon, ClockIcon } from '../../components/assets/commonAssets';
import { AppTheme } from '../../const';
import { defaultSectionStyleWithValidation } from './formUtil';

export interface DateFormSectionOptions extends Partial<SectionModelOptions> {
    day: BehaviorSubject<Moment>;
    time: BehaviorSubject<Duration>;
    disabled?: boolean;
    styles: { icon: StyleProp<ImageStyle> };
}

export function addDateSectionToFrom({
    form,
    day,
    time,
    disabled = false,
    theme,
    styles,
    ...options
}: {
    form: FormModel;
    theme: AppTheme;
} & DateFormSectionOptions) {
    form.addSection({
        style: defaultSectionStyleWithValidation(theme),
        ...options,
    }).modify(section => {
        addDateRowsToFromSection({ section, day, time, disabled, styles });
        section.footer = section.flattenedFormattedErrors$({
            editedOnly: true,
        });
    });
}

export function addDateRowsToFromSection({
    section,
    day,
    time,
    disabled = false,
    styles,
}: {
    section: SectionModel;
} & DateFormSectionOptions) {
    section
        .addRow()
        .addCustom(() => <CalendarIcon style={styles.icon} />)
        .setMargin(0)
        .addLabel(lz('date'))
        .addFlex()
        .addDateInput({
            value: day,
            disabled,
            futureDisabled: true,
            align: 'right',
        });
    section
        .addRow()
        .addCustom(() => <ClockIcon style={styles.icon} />)
        .setMargin(0)
        .addLabel(lz('time'))
        .addFlex()
        .addTimeInput({
            value: time,
            disabled,
            align: 'right',
            mode: 'contained',
            futureDisabled: true,
            selectTextOnFocus: true,
            refDay: day,
        });
}
