import { lz } from '@byterium/glucose-diary-client';
import { InputFieldValueInfo } from 'react-native-form-model';

import { confirm } from '../../alert';

export function handleAbnormalValueIfNeeded({
    value,
    isNormalValue,
    displayValue,
    field,
}: InputFieldValueInfo<number | undefined> & {
    isNormalValue: (value: number) => boolean;
    displayValue: string;
}) {
    if (
        typeof value !== 'number' ||
        !field.isValueValid(value) ||
        isNormalValue(value)
    ) {
        return;
    }
    confirm({
        title: lz('confirmation'),
        message: lz('valueEntryConfirmMessage', {
            value: displayValue || String(value),
        }),
        onCancel: () => {
            field.resetInput();
            field.viewRef?.current?.focus();
        },
        onConfirm: () => {},
    });
}
