import {
    AppDelegate,
    ENV,
    User,
    UserDocuments,
    lz,
} from '@byterium/glucose-diary-client';
import Constants from 'expo-constants';
import { Platform } from 'react-native';
import { Subscription } from 'rxjs';

import { addErrorSnack } from './components/AppSnackbar';
import UserSettings from './services/UserSettings';
import { getUserDocuments } from './services/documents';
import { bindFitnessToApp } from './services/fitness';
import { getAccessTokenManagement } from './services/secret';
import * as Vercel from './services/vercel';
import { findURL } from './services/webUtil';

const app = AppDelegate.shared();
let appErrorSub: Subscription | undefined;

// Subscribe to app errors
app.addSetupTask(() => {
    appErrorSub = app.errors$.subscribe(
        error => error && addErrorSnack(error.message || String(error.message))
    );
});
app.addTeardownTask(() => appErrorSub?.unsubscribe());

// Read package
try {
    // This is generally available on native and in node
    const pkg = require('../package.json');
    ENV.addSource({ EXPO_APP_VERSION: pkg.version }, { prioritize: true });
} catch (err) {}

// Add expo manifest env if available
Constants.manifest?.env && ENV.addSource(Constants.manifest.env);
Constants.manifest?.extra && ENV.addSource(Constants.manifest.extra);

app.appleBundleID = Constants.manifest?.ios?.bundleIdentifier;
app.androidBundleID = Constants.manifest?.android?.package;
switch (Platform.OS) {
    case 'ios':
        app.bundleID = app.appleBundleID;
        break;

    case 'android':
        app.bundleID = app.androidBundleID;
        break;
}

// Load and apply environment variables
app.addSetupTask(Vercel.loadVercelEnv);

// Choose server URI
app.addSetupTask(() => {
    app.chooseServerURI = uris => {
        if (Platform.OS === 'web') {
            const loc = new URL(window.location.href);
            return findURL(uris, { url: loc }) || uris[0];
        } else {
            switch (ENV.EXPO_LOCALE) {
                case 'ru':
                    return findURL(uris, { tld: 'ru' }) || uris[0];
                default:
                    return uris[0];
            }
        }
    };
});

// Validate platform settings
app.addSetupTask(() => {
    if (Platform.OS === 'web') {
        try {
            if (!window.sessionStorage || !window.localStorage) {
                throw new Error('No storage');
            }
        } catch (err) {
            throw new Error(lz('webStorageDisabledMessage'));
        }
    }
});

// Init UserSettings
app.addSetupTask(async () => {
    const { default: AsyncStorage } = await import(
        '@react-native-async-storage/async-storage'
    );
    UserSettings.configure({ store: AsyncStorage });

    // Sync settings
    UserSettings.beginSync();
    app.addTeardownTask(() => UserSettings.endSync());
});

// Load user settings
app.addUserTask(() => UserSettings.load());

// Load document meta
app.addSetupTask(() => {
    UserDocuments.all = getUserDocuments();
});

// ALL ENV SHOULD BE LOADED HERE
app.addSetupTask(() => {
    if (ENV.isDev) {
        console.debug(
            '[DEV] process.env: ' + JSON.stringify(global.process?.env, null, 2)
        );
        console.debug(
            '[DEV] manifest: ' + JSON.stringify(Constants.manifest, null, 2)
        );
        console.debug('[DEV] ENV:' + JSON.stringify(ENV.toJSON(), null, 2));
    }
});

// Use cookies or connect secure storage if needed
app.addSetupTask(async () => {
    if (!app.serverURI) {
        throw new Error('Server URI not available');
    }

    app.useCookies = false;
    if (Platform.OS === 'web') {
        const { shouldUseCookie } = await import('./services/cookie');
        app.useCookies = shouldUseCookie({ serverURI: app.serverURI });
    }

    console.debug('using cookies: ' + app.useCookies);
    if (!app.useCookies) {
        const accessTokenManagement = await getAccessTokenManagement();
        User.delegate = {
            ...accessTokenManagement,
        };
    }
});

// Setup fitness
bindFitnessToApp(app);

// Setup Apollo client here
app.addSetupClientTask();

// Navigate to login screen if logged out
const navigate$ = import('./pages/navigate');
let wasLoggedIn = false;
app.addUserTask(async () => {
    const { navigateReplace } = await navigate$;
    const user = User.current();
    if (!user && wasLoggedIn) {
        // Logged out, navigate to login page
        console.info('Logged out');
        navigateReplace('LoginMain');
    } else if (user && !wasLoggedIn) {
        // Logged in, navigate to home
        console.info('Logged in');
        await UserSettings.finishLoad();
        if (!user.needsOnboarding()) {
            console.info('Showing home page');
            navigateReplace('Home');
        } else {
            console.info('Onboarding');
            navigateReplace('Onboard');
        }
    }
    wasLoggedIn = !!user;
});

// Restore login
app.addSetupTask(() => User.recall());

export default AppDelegate;
