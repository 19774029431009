import { Util } from '@byterium/glucose-diary-client';
import { Observable as LCObservable } from '@byterium/librechart';
import { Observable, Subject } from 'rxjs';
import { finalize } from 'rxjs/operators';

// /**
//  * Iterates over all time spans in a given interval
//  * by yielding all start dates. To get the end dates
//  * use `date.endOf(unit)`.
//  */
// export function * iterateTimeSpans(
//     startDate: Moment,
//     endDate: Moment,
//     unit: moment.unitOfTime.StartOf,
//     options = {
//         stride: 0,
//     },
// ): Generator<Moment> {
//     unit = moment.normalizeUnits(unit as any) as moment.unitOfTime.StartOf;
//     if (!unit) {
//         throw new Error('Invalid unit');
//     }
//     let date = startDate.startOf(unit);
//     while (date.isBefore(endDate)) {
//         yield date.clone();
//         date = date.add(1 + options.stride, unit as any);
//     }
// }

/**
 * Resolve after `ms` interval.
 * @param ms
 * @param passthrough
 */
export function delay<T = any>(
    ms: number,
    passthrough?: T
): Promise<T | undefined> {
    let timer: any;
    return new Promise<T | undefined>((resolve, reject) => {
        timer = setTimeout(() => resolve(passthrough), ms);
    }).finally(() => {
        timer && clearTimeout(timer);
    });
}
export const findURLMatchingTLD = (uris: URL[], loc: URL): URL | undefined => {
    const isIP = Util.isIP(loc);
    let myTLD = loc.hostname;
    if (!isIP) {
        myTLD = myTLD
            .split('.')
            .filter(x => !!x)
            .pop()!;
    }
    for (const uri of uris) {
        let tld = uri.hostname;
        if (!isIP) {
            tld = tld
                .split('.')
                .filter(x => !!x)
                .pop()!;
        }
        if (tld === myTLD) {
            return uri;
        }
    }
    return undefined;
};

export function librechartToRxjsObservable<T = any>(
    lcObservable: LCObservable.IObservable<T>
): Observable<T> {
    // TODO: Only add listener after subscription. See [task](https://trello.com/c/zt7mL5Nh)
    const subject = new Subject<T>();
    const sub = lcObservable.addObserver(x => subject.next(x));
    return subject.pipe(finalize(() => sub.cancel()));
}

export function escapeHtml(unsafe: string): string {
    return unsafe
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;')
        .replace(/'/g, '&#039;');
}
