import { AppDelegate } from '@byterium/glucose-diary-client';

import { AppleAuthenticationCredential } from './appleLoginTypes';

export function useIsAppleLoginPopupAvailable() {
    return false;
}

export async function signInWithApple(): Promise<
    AppleAuthenticationCredential | undefined
> {
    throw new Error('Not supported');
}

export async function bindAppleLoginToApp(app: AppDelegate) {}
