import { lz } from '@byterium/glucose-diary-client';
import React from 'react';
import { ButtonProps } from 'react-native-form-model';
import { useTheme } from 'react-native-paper';

import { AppTheme } from '../const';
import { composeSupportEmail } from '../services/email';
import { TranslucentButton } from './TranslucentButton';
import { EmailIcon } from './assets/commonAssets';

export function ContactUsButton(props: Omit<ButtonProps, 'title' | 'onPress'>) {
    const theme = useTheme() as AppTheme;
    return (
        <TranslucentButton
            {...props}
            title={lz('contactUs')}
            onPress={() => composeSupportEmail()}
            icon={props => (
                <EmailIcon
                    {...props}
                    style={{ paddingHorizontal: 4 }}
                    color={theme.colors.textMoreSlightlyFaded}
                />
            )}
        />
    );
}
