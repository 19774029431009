import { IKeyStorage } from '@byterium/glucose-diary-client';
import { Platform } from 'react-native';

import { kDebugHostnames } from '../const';

const SecureStore = async (): Promise<IKeyStorage> => {
    const store = await import('expo-secure-store');
    return {
        setItem: store.setItemAsync,
        getItem: store.getItemAsync,
        removeItem: store.deleteItemAsync,
    };
};

const WebStore = async (): Promise<IKeyStorage> => {
    if (kDebugHostnames.includes(window.location.hostname)) {
        console.warn('[DEV] Secure store not available, using local storage.');
        return localStorage;
    } else {
        // TODO: When using session storage, fix security issue:
        // https://humanwhocodes.com/blog/2009/07/21/introduction-to-sessionstorage/
        // See [task](https://trello.com/c/2xCh3foG)
        console.warn('Secure store not available, using session storage.');
        return sessionStorage;
    }
};

export const createSecretStore = Platform.OS === 'web' ? WebStore : SecureStore;

const accessTokenKey = 'sid';

export const getAccessTokenManagement = async () => {
    const store = await createSecretStore();
    return {
        getAccessToken: async () => {
            return await store.getItem(accessTokenKey);
        },
        setAccessToken: async (token: string | undefined) => {
            if (token) {
                return await store.setItem(accessTokenKey, token);
            } else {
                return await store.removeItem(accessTokenKey);
            }
        },
    };
};
