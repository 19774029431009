import {
    FitnessRecordIdKey,
    RecordType,
    Schema,
    getAllSharedFitnessKits,
} from '@byterium/glucose-diary-client';
import { zipObject } from 'lodash';
import moment from 'moment';
import { FormUtil } from 'react-native-form-model';
import { BehaviorSubject } from 'rxjs';

export type RecordFormData = ReturnType<typeof createRecordFormData>;

export type FormRecordValues = Pick<
    Schema.Record,
    Extract<keyof Schema.AddGlucoseSampleInput, keyof Omit<Schema.Record, 'id'>>
> & { id?: string };

export const createRecordFormData = (config: { recordType: RecordType }) => {
    const { day, time } = FormUtil.mutableDateTime$(moment());
    // We must use all shared fitness kits (disabled or not), to avoid
    // undefined errors.
    const fitnessKits = getAllSharedFitnessKits();
    return {
        recordType: new BehaviorSubject(config.recordType),
        common: {
            id: undefined as string | undefined,
            day,
            time,
            get date() {
                return FormUtil.joinDateTime(day.value, time.value);
            },
            notes: new BehaviorSubject(''),
        },
        activity: {
            energy: new BehaviorSubject<number | undefined>(undefined),
            description: new BehaviorSubject(''),
        },
        glucose: {
            mealFlag: new BehaviorSubject<Schema.MealFlag | undefined>(
                undefined
            ),
            glucoseLevel: new BehaviorSubject<number | undefined>(undefined),
        },
        insulin: {
            profileType: new BehaviorSubject<Schema.InsulinProfileType>(
                Schema.InsulinProfileType.Intermediate
            ),
            product: new BehaviorSubject<
                Schema.InsulinProductFieldsFragment | undefined
            >(undefined),
            doseAmount: new BehaviorSubject<number | undefined>(undefined),
        },
        meal: {
            carbs: new BehaviorSubject<number | undefined>(undefined),
            energy: new BehaviorSubject<number | undefined>(undefined),
            glycemicIndex: new BehaviorSubject<number | undefined>(undefined),
            insulinIndex: new BehaviorSubject<number | undefined>(undefined),
            description: new BehaviorSubject(''),
        },
        fitness: {
            ...zipObject(
                fitnessKits.map(fitness => fitness.recordIdKey),
                fitnessKits.map(
                    () => new BehaviorSubject<string | undefined>(undefined)
                )
            ),
        } as { [K in FitnessRecordIdKey]: BehaviorSubject<string | undefined> },
    };
};

export function getRecordFormValues(
    formData: RecordFormData
): FormRecordValues {
    const values: FormRecordValues = {
        id: formData.common.id,
        date: formData.common.date,
        notes: formData.common.notes.value,
        utcOffset: moment().utcOffset(),
    };

    for (const fitnessKit of getAllSharedFitnessKits()) {
        values[fitnessKit.recordIdKey] =
            formData.fitness[fitnessKit.recordIdKey].value;
    }

    return values;
}

export function setRecordFormValues(
    formData: RecordFormData,
    values: FormRecordValues
) {
    const { day, time } = FormUtil.splitDateTime(values.date || moment());
    formData.common.id = values.id;
    formData.common.day.next(day);
    formData.common.time.next(time);
    formData.common.notes.next(values.notes || '');

    for (const fitnessKit of getAllSharedFitnessKits()) {
        formData.fitness[fitnessKit.recordIdKey].next(
            values[fitnessKit.recordIdKey]
        );
    }
}
