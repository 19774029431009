import React from 'react';
import { Platform, StyleSheet, View, ViewProps } from 'react-native';
import { Caption } from 'react-native-paper';

import { ContactUsButton } from './ContactUsButton';
import UpdateAppButton from './UpdateAppButton';
import VersionInfo from './VersionInfo';
import { DeleteAccountButton } from './login/DeleteAccountButton';
import { LogoutButton } from './login/LogoutButton';

const isWeb = Platform.OS === 'web';

export interface AppFooterProps extends ViewProps {
    showContactButton?: boolean;
    showLogoutButton?: boolean;
    showDeleteAccountButton?: boolean;
}

const AppFooter = React.memo(
    ({
        showContactButton,
        showLogoutButton,
        showDeleteAccountButton,
        style,
        ...props
    }: AppFooterProps) => {
        const buttons: React.ReactNode[] = [];
        if (showLogoutButton) {
            buttons.push(<LogoutButton key='logout' style={styles.button} />);
        }
        if (showDeleteAccountButton) {
            buttons.push(
                <DeleteAccountButton
                    key='deleteAccount'
                    style={styles.button}
                />
            );
        }
        if (showContactButton) {
            buttons.push(
                <ContactUsButton key='contactUs' style={styles.button} />
            );
        }

        // Update button is only displayed on web
        const showUpdateButton = isWeb;
        return (
            <View {...props} style={[styles.container, style]}>
                {buttons.length !== 0 && (
                    <View style={styles.buttonContainer}>{buttons}</View>
                )}
                <Caption style={styles.versionRow}>
                    <VersionInfo style={styles.text} />

                    {showUpdateButton && ' • '}
                    {showUpdateButton && <UpdateAppButton />}
                </Caption>
            </View>
        );
    }
);

const styles = StyleSheet.create({
    container: {
        alignItems: 'center',
        paddingHorizontal: 16,
        paddingBottom: 8,
    },
    buttonContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: 12,
    },
    button: {
        marginHorizontal: 4,
        marginVertical: 4,
        height: 40,
    },
    versionRow: {
        textAlign: 'center',
    },
    text: {
        textAlign: 'center',
    },
});

export default AppFooter;
