import { RecordType, lz } from '@byterium/glucose-diary-client';
import React from 'react';
import {
    StyleProp,
    StyleSheet,
    View,
    ViewProps,
    ViewStyle,
} from 'react-native';
import { Button, Divider } from 'react-native-form-model';
import { ScrollView } from 'react-native-gesture-handler';
import { Modal, Title, useTheme } from 'react-native-paper';

import { AppTheme } from '../../const';

const kMaxDialogHeight = 500;

export interface ChartShareOptions {
    mode: 'image' | 'other';
    recordType: RecordType;
    isOverview: boolean;
}

export interface ChartShareModalProps extends ViewProps {
    visible: boolean;
    onDismiss: () => void;
    dismissable?: boolean;
    contentContainerStyle?: StyleProp<ViewStyle>;
    onShare: (options: Pick<ChartShareOptions, 'mode'>) => void;
}

export default function ChartShareModal({
    contentContainerStyle,
    onShare,
    onDismiss,
    ...props
}: ChartShareModalProps) {
    const theme = useTheme() as AppTheme;
    const [height, setHeight] = React.useState<number>(0);
    const modalStyle: ViewStyle | undefined = height
        ? {
              height: Math.min(kMaxDialogHeight, height),
          }
        : { opacity: 0 };
    return (
        <Modal
            {...props}
            onDismiss={onDismiss}
            contentContainerStyle={[
                styles.modal,
                {
                    backgroundColor: theme.colors.surface,
                    borderRadius: theme.roundness,
                },
                modalStyle,
                contentContainerStyle,
            ]}
        >
            <ScrollView alwaysBounceVertical={false}>
                <View
                    onLayout={event => {
                        const newHeight = event.nativeEvent.layout.height;
                        if (height !== newHeight) {
                            setHeight(newHeight);
                        }
                    }}
                >
                    <Title style={styles.title}>{lz('shareChart')}</Title>
                    <Divider />
                    <Button
                        title={lz('saveImage')}
                        onPress={() => onShare({ mode: 'image' })}
                    />
                    <Divider />
                    <Button
                        title={lz('moreOptions')}
                        onPress={() => onShare({ mode: 'other' })}
                    />
                    <Divider />
                    <Button title={lz('cancel')} onPress={() => onDismiss()} />
                </View>
            </ScrollView>
        </Modal>
    );
}

const styles = StyleSheet.create({
    modal: {
        maxWidth: 400,
        maxHeight: kMaxDialogHeight,
        alignSelf: 'center',
        overflow: 'hidden',
    },
    title: {
        paddingHorizontal: 20,
        paddingVertical: 8,
        textAlign: 'center',
    },
});
