import { User, lz } from '@byterium/glucose-diary-client';
import React from 'react';
import { Button, ButtonProps } from 'react-native-form-model';
import { useTheme } from 'react-native-paper';

import { AppTheme } from '../../const';
import { alertAsync, confirmation } from '../../services/alert';

export function DeleteAccountButton(
    props: Omit<ButtonProps, 'title' | 'onPress'>
) {
    const theme = useTheme() as AppTheme;

    const confirmAndDelete = async () => {
        let confirmed = await confirmation({
            message: lz('deleteAccountConfirmationMessage1'),
            confirmTitle: lz('delete'),
            destructive: true,
        });
        if (!confirmed) {
            return;
        }
        confirmed = await confirmation({
            message: lz('deleteAccountConfirmationMessage2'),
            confirmTitle: lz('IUnderstand'),
            destructive: true,
        });
        if (!confirmed) {
            return;
        }
        await User.delete();
        alertAsync({
            title: lz('accountDeleted'),
            message: lz('accountDeletedMessage'),
        });
    };

    return (
        <Button
            mode='contained'
            color={theme.form.colors.destructive}
            {...props}
            title={lz('deleteAccount')}
            onPress={() => confirmAndDelete()}
        />
    );
}
