import { lz } from '@byterium/glucose-diary-client';
import { StackNavigationOptions } from '@react-navigation/stack';
import React from 'react';

import { HomeStack } from '../navigate';
import UserProfilePage from './UserProfilePage';

const userProfileScreens = ({
    defaultScreenOptions,
}: {
    defaultScreenOptions?: StackNavigationOptions;
} = {}) => {
    return [
        <HomeStack.Screen
            key='UserProfile'
            name='UserProfile'
            component={UserProfilePage}
            options={{
                title: lz('profile'),
                ...defaultScreenOptions,
            }}
        />,
    ];
};

export default userProfileScreens;
