import {
    FitnessSyncInterval,
    User,
    kZeroFitnessSyncInterval,
} from '@byterium/glucose-diary-client';
import { BehaviorSubject } from 'rxjs';

import UserSettings from '../../UserSettings';
import GoogleFit from './GoogleFit';

export async function configureGoogleFitSafe(user: User) {
    await UserSettings.finishLoad();
    if (GoogleFit.shared().supported || UserSettings._debugGoogleFit$.value) {
        user.syncGoogleFit$ = UserSettings.syncGoogleFit$;
    } else {
        user.syncGoogleFit$ = new BehaviorSubject<
            FitnessSyncInterval | undefined
        >(kZeroFitnessSyncInterval);
    }
    GoogleFit.shared().sync$ = user.syncGoogleFit$;
    GoogleFit.shared()._debug$ = UserSettings._debugGoogleFit$;
}

export async function initGoogleFitSafe() {
    if (!GoogleFit.shared().supported) {
        return;
    }
    if (
        GoogleFit.shared().isInitialized ||
        !UserSettings.syncGoogleFit$.value
    ) {
        return;
    }
    try {
        console.debug('Initializing Google Fit...');
        await GoogleFit.shared().init();
        console.debug('Google Fit ready');
    } catch (err: any) {
        console.error('Unable to initialize Google Fit: ' + err.message);
    }
}
