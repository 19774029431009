import {
    FitnessSyncInterval,
    User,
    kZeroFitnessSyncInterval,
} from '@byterium/glucose-diary-client';
import { BehaviorSubject } from 'rxjs';

import UserSettings from '../../UserSettings';
import HealthKit from './HealthKit';

export async function configureHealthKitSafe(user: User) {
    await UserSettings.finishLoad();
    if (HealthKit.shared().supported || UserSettings._debugHealthKit$.value) {
        user.syncHealthKit$ = UserSettings.syncHealthKit$;
    } else {
        user.syncHealthKit$ = new BehaviorSubject<
            FitnessSyncInterval | undefined
        >(kZeroFitnessSyncInterval);
    }
    HealthKit.shared().sync$ = user.syncHealthKit$;
    HealthKit.shared()._debug$ = UserSettings._debugHealthKit$;
}

export async function initHealthKitSafe() {
    if (!HealthKit.shared().supported) {
        return;
    }
    if (
        HealthKit.shared().isInitialized ||
        !UserSettings.syncHealthKit$.value
    ) {
        return;
    }
    try {
        console.debug('Initializing Health Kit...');
        await HealthKit.shared().init();
        console.debug('Health Kit ready');
    } catch (err: any) {
        console.error('Unable to initialize Health Kit: ' + err.message);
    }
}
