import {
    RecordType,
    ToNumberOptions,
    getRecordDataSource,
    lz,
    lzDate,
    lzDuration,
} from '@byterium/glucose-diary-client';
import { Duration } from 'moment';
import React from 'react';
import { StyleSheet, View } from 'react-native';
import { Divider, Row, RowProps } from 'react-native-form-model';
import { ActivityIndicator, Button, Text, useTheme } from 'react-native-paper';

import { AppTheme } from '../../const';
import { useCurrentDateInterval } from '../../reactUtil';

export interface RecordListSummaryProps extends RowProps {
    recordType: RecordType;
    valueFormatter: (value: number, options?: ToNumberOptions) => string;
    unit?: string;
    title?: string;
    showLatest?: boolean;
    showAverage?: Duration;
    onAllDataPress: () => any;
}

export default function RecordListSummary({
    recordType,
    valueFormatter,
    unit: units,
    title,
    showLatest = true,
    showAverage,
    onAllDataPress,
    ...otherProps
}: RecordListSummaryProps) {
    const theme = useTheme() as AppTheme;
    const fadedTextStyle = [
        styles.text,
        { color: theme.colors.textSlightlyFaded },
    ];
    const fadedValueStyle = [
        styles.value,
        { color: theme.colors.textSlightlyFaded },
    ];
    const dataSource = getRecordDataSource(recordType);

    // Latest record data
    const { record: latestRecord = null, loading: loadingLatest = false } =
        showLatest ? dataSource.useLatest() : {};
    const latestPoint = latestRecord
        ? dataSource.convertRecordToPoint(latestRecord)
        : null;

    // Average data
    const { average = NaN, loading: loadingAverage = false } = showAverage
        ? // eslint-disable-next-line react-hooks/rules-of-hooks
          dataSource.useAverage(useCurrentDateInterval(showAverage))
        : {};

    const loading = loadingLatest || loadingAverage;

    return (
        <Row isSingle {...otherProps}>
            <View style={styles.innerContainer}>
                <View style={styles.flexRow}>
                    <View style={styles.innerRow}>
                        <Text
                            style={styles.header}
                            onPressIn={undefined}
                            onPressOut={undefined}
                        >
                            {String(title || '')}
                        </Text>
                        <ActivityIndicator
                            hidesWhenStopped
                            animating={loading}
                            style={styles.activity}
                        />
                    </View>
                    <Text
                        style={fadedTextStyle}
                        onPressIn={undefined}
                        onPressOut={undefined}
                    >
                        {String(units || '')}
                    </Text>
                </View>
                {showLatest && (
                    <View style={styles.flexRow}>
                        <Text
                            style={styles.text}
                            onPressIn={undefined}
                            onPressOut={undefined}
                        >{`${lz('latest')}: `}</Text>
                        <Text
                            style={[styles.shrink, fadedTextStyle]}
                            onPressIn={undefined}
                            onPressOut={undefined}
                        >
                            {lzDate(latestRecord?.date)}
                        </Text>
                        <FlexibleSpace />
                        <Text
                            style={styles.value}
                            onPressIn={undefined}
                            onPressOut={undefined}
                        >
                            {latestPoint ? valueFormatter(latestPoint.y) : ''}
                        </Text>
                    </View>
                )}
                {showAverage && (
                    <View style={styles.flexRow}>
                        <Text
                            style={styles.text}
                            onPressIn={undefined}
                            onPressOut={undefined}
                        >{`${lz('average')}: `}</Text>
                        <Text
                            style={[styles.shrink, fadedTextStyle]}
                            onPressIn={undefined}
                            onPressOut={undefined}
                        >
                            {lzDuration(showAverage)}
                        </Text>
                        <FlexibleSpace />
                        <Text
                            style={
                                isNaN(average)
                                    ? fadedTextStyle
                                    : fadedValueStyle
                            }
                            onPressIn={undefined}
                            onPressOut={undefined}
                        >
                            {valueFormatter(average, {
                                nanString: lz('noData'),
                            })}
                        </Text>
                    </View>
                )}
                <Divider style={styles.divider} />
                <Button mode='text' uppercase={false} onPress={onAllDataPress}>
                    {lz('allData')}
                </Button>
            </View>
        </Row>
    );
}

const FlexibleSpace = React.memo(() => <View style={styles.flex} />);

const styles = StyleSheet.create({
    container: {},
    innerContainer: {
        flex: 1,
    },
    flexRow: {
        flex: 1,
        paddingVertical: 3,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    innerRow: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    divider: {
        marginVertical: 3,
    },
    header: {
        fontWeight: 'bold',
        fontSize: 14,
    },
    text: {
        fontSize: 14,
    },
    value: {
        fontSize: 18,
        fontWeight: 'bold',
        marginLeft: 8,
    },
    activity: {
        marginHorizontal: 8,
    },
    flex: {
        flex: 1,
    },
    shrink: {
        flexShrink: 1,
    },
});
