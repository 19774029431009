import { Ionicons } from '@expo/vector-icons';
import React from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';
import { useTheme } from 'react-native-paper';
import { IconProps } from 'react-native-paper/lib/typescript/components/MaterialCommunityIcon';

import { AppTheme } from '../../const';
import { iconStyles } from './assetConst';

export const InsulinIcon = ({
    color,
    size,
    style,
    ...otherProps
}: Omit<IconProps, 'name' | 'color' | 'direction' | 'size'> & {
    color?: string;
    style?: StyleProp<ViewStyle>;
    size?: number;
}) => {
    const theme = useTheme() as AppTheme;
    return (
        <View {...otherProps} style={[iconStyles.iconContainer, style]}>
            <Ionicons
                name='medkit'
                direction='ltr'
                size={size || 19}
                color={color || theme.colors.insulin}
            />
        </View>
    );
};
