import { lz } from '@byterium/glucose-diary-client';
import { StackNavigationOptions } from '@react-navigation/stack';
import React from 'react';

import { HomeStack } from '../../navigate';
import LoginEmailCodePage from './LoginEmailCodePage';
import LoginEmailPage from './LoginEmailPage';
import SignupEmailPage from './SignupEmailPage';

const loginEmailScreens = ({
    defaultScreenOptions,
}: {
    defaultScreenOptions?: StackNavigationOptions;
} = {}) => {
    return [
        <HomeStack.Screen
            key='LoginEmail'
            name='LoginEmail'
            component={LoginEmailPage}
            options={{
                title: lz('signInWithEmail'),
                ...defaultScreenOptions,
                headerTitleAlign: 'center',
            }}
        />,
        <HomeStack.Screen
            key='LoginEmailCode'
            name='LoginEmailCode'
            component={LoginEmailCodePage}
            options={{
                title: lz('signInWithEmail'),
                ...defaultScreenOptions,
                headerTitleAlign: 'center',
            }}
        />,
        <HomeStack.Screen
            key='SignupEmail'
            name='SignupEmail'
            component={SignupEmailPage}
            options={{
                title: lz('signup'),
                ...defaultScreenOptions,
                headerTitleAlign: 'center',
            }}
        />,
    ];
};

export default loginEmailScreens;
