import { lz } from '@byterium/glucose-diary-client';
import Constants from 'expo-constants';
import React from 'react';
import { Platform, StyleSheet } from 'react-native';
import { Button } from 'react-native-form-model';
import { Colors, useTheme } from 'react-native-paper';

import { AppTheme } from '../../../../const';
import { useIsAppleLoginPopupAvailable } from '../../../../services/login/appleLogin';
import { AppleLogo } from '../../../assets/socialAssets';
import { SignInButtonProps } from '../loginButtonTypes';
import NativeAppleSignInButton from './NativeAppleSignInButton';

const isSimulator = Platform.OS === 'ios' && Constants && !Constants.isDevice;

export interface AppleSignInButtonProps extends SignInButtonProps {
    onNativePress?: () => void;
}

export const AppleSignInButton: React.FC<AppleSignInButtonProps> = ({
    title = lz('signInWithApple'),
    icon = true,
    mode = 'contained',
    style,
    disabled,
    onPress,
    onNativePress,
    ...props
}) => {
    const theme = useTheme() as AppTheme;

    if (isSimulator) {
        // Sign in with Apple does not work on iOS simulator
        disabled = true;
    }

    return useIsAppleLoginPopupAvailable() ? (
        <NativeAppleSignInButton
            {...props}
            disabled={disabled}
            onPress={onNativePress}
        />
    ) : (
        <Button
            {...props}
            onPress={onPress}
            title={title}
            mode={mode}
            icon={
                icon &&
                (props => (
                    <AppleLogo
                        size={24}
                        style={{
                            opacity: disabled ? 0.5 : 1,
                            margin: 8,
                        }}
                        color={theme.dark ? Colors.black : Colors.white}
                    />
                ))
            }
            color={theme.dark ? Colors.white : Colors.black}
            style={[
                styles.button,
                {
                    borderRadius: theme.roundness,
                },
                style,
            ]}
        />
    );
};

const styles = StyleSheet.create({
    button: {
        minHeight: 40,
    },
});
