import { ENV, User, lz } from '@byterium/glucose-diary-client';
import { detect } from 'detect-browser';
import * as MailComposer from 'expo-mail-composer';
import { pick } from 'lodash';
import { Platform } from 'react-native';

import { escapeHtml } from '../util';

const isWeb = Platform.OS === 'web';
const platform = isWeb ? detect() : pick(Platform, ['OS', 'Version']);
const isHtmlSupported = Platform.OS === 'ios';

const getDiagnosticData = () => ({
    userId: User.current()?.id,
    app: ENV.EXPO_APP_NAME,
    version: ENV.EXPO_APP_VERSION,
    platform,
});

const plainTextDivider = '_'.repeat(28);

const createEmailBody = () =>
    !isHtmlSupported
        ? `\n\n\n\n${plainTextDivider}\n\n${lz(
              'writeAboveThisLine'
          )}\n${plainTextDivider}\n${stringifyForPlainText(
              getDiagnosticData()
          )}`
        : `
<!--- ${lz('writeAboveThisLine')} --->
<!DOCTYPE html>
<html lang="en" xmlns="https://www.w3.org/1999/xhtml" xmlns:o="urn:schemas-microsoft-com:office:office">
<head>
    <meta charset="utf-8">
    <meta name="viewport" content="width=device-width,initial-scale=1">
    <meta name="x-apple-disable-message-reformatting">
    <title></title>
    <!--[if mso]>
    <style>
        table {border-collapse:collapse;border-spacing:0;border:none;margin:0;}
        div, td {padding:0;}
        div {margin:0 !important;}
    </style>
    <noscript>
        <xml>
            <o:OfficeDocumentSettings>
            <o:PixelsPerInch>96</o:PixelsPerInch>
            </o:OfficeDocumentSettings>
        </xml>
    </noscript>
    <![endif]-->
    <style>
        table, td, div, h1, p {
            font-family: Helvetica, Arial, sans-serif;
        }
        .hidden {
            display: none;
            position: absolute;
            height: 0;
        }
    </style>
</head>
<body style="margin:0;padding:0;word-spacing:normal;">
    <br/><br/><br/><br/><br/><br/>
    <div role="article" aria-roledescription="email" lang="en" style="text-size-adjust:100%;-webkit-text-size-adjust:100%;-ms-text-size-adjust:100%;">
        <table role="presentation" style="width:100%;border:none;border-spacing:0;">
            <tr>
                <td align="center" style="padding:0;">
                    <div style="width:100%;height:1px;background-color:#606060" />
                    ${lz('writeAboveThisLine')}
                    <div id="diagnostics" class="hidden">
                        ${stringifyForHtml(getDiagnosticData())}
                    </div>
                    <div style="width:100%;height:1px;background-color:#606060" />
                </td>
            </tr>
        </table>
    </div>
</body>
</html>
`;

export async function composeSupportEmail() {
    return composeEmail({
        recipients: ENV.EXPO_SUPPORT_EMAIL
            ? [ENV.EXPO_SUPPORT_EMAIL]
            : undefined,
        subject: lz('feedbackAndSupport'),
        body: createEmailBody(),
        isHtml: isHtmlSupported,
    });
}

export async function composeEmail(options: MailComposer.MailComposerOptions) {
    return MailComposer.composeAsync(options);
}

function stringifyForHtml(obj: any): string {
    return escapeHtml(stringifyForPlainText(obj));
}

function stringifyForPlainText(obj: any): string {
    return JSON.stringify(obj, null, 2)
        .replace(/"/g, '')
        .replace(/\{/g, '')
        .replace(/\}/g, '');
}
