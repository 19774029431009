import { User, lz } from '@byterium/glucose-diary-client';
import React from 'react';
import { ButtonProps } from 'react-native-form-model';
import { useTheme } from 'react-native-paper';

import { AppTheme } from '../../const';
import { confirmation } from '../../services/alert';
import { TranslucentButton } from '../TranslucentButton';
import { ExitIcon } from '../assets/commonAssets';

export function LogoutButton(props: Omit<ButtonProps, 'title' | 'onPress'>) {
    const theme = useTheme() as AppTheme;

    const confirmAndLogout = async () => {
        const confirmed = await confirmation({
            message: lz('logoutConfirmationMessage'),
            confirmTitle: lz('logout'),
            destructive: true,
        });
        if (confirmed) {
            await User.logout();
        }
    };

    return (
        <TranslucentButton
            {...props}
            title={lz('logout')}
            onPress={() => confirmAndLogout()}
            icon={props => (
                <ExitIcon
                    {...props}
                    color={theme.colors.textMoreSlightlyFaded}
                />
            )}
        />
    );
}
