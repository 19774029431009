import { AppDelegate } from '@byterium/glucose-diary-client';
import { AppState, AppStateStatus } from 'react-native';

let registered = false;

/**
 * App state change callbacks.
 * Only registered when the app is loaded successfuly.
 */
const handleAppStateChange = (state: AppStateStatus) => {
    const app = AppDelegate.shared();
    switch (state) {
        case 'active':
            app.isActive$.next(true);
            break;
        case 'unknown':
            console.warn('Unknown app state');
            break;
        default:
            app.isActive$.next(false);
            break;
    }
};

export function registerAppStateChanges() {
    if (registered) {
        return;
    }
    registered = true;

    AppState.addEventListener('change', handleAppStateChange);
}

export function unregisterAppStateChanges() {
    if (!registered) {
        return;
    }
    registered = false;

    AppState.removeEventListener('change', handleAppStateChange);
}
