import {
    AppDelegate,
    FitnessBase,
    User,
    getAllSharedFitnessKits,
    getRecordDataSources,
    lz,
} from '@byterium/glucose-diary-client';
import moment from 'moment';
import { debounceTime, skip } from 'rxjs/operators';

import { addErrorSnack } from '../../components/AppSnackbar';
import { delay } from '../../util';
import * as GoogleFit from './GoogleFit';
import * as HealthKit from './HealthKit';

const KFitnessImportDebounceInterval = 1000;

export interface FitnessImportOptions {
    silenceErrors?: boolean;
}

export async function bindFitnessToApp(app: AppDelegate) {
    // Configure when user is logged in:
    app.addUserTask(async () => {
        const user = User.current();
        if (user) {
            await configureFitnessSafe(user);
        }
    });

    // Init when user is logged in and passes all onboard actions:
    app.addReadyTask(initFitnessSafe);

    // Note that at this stage we do not know if the user
    // has given permission to sync.
    getAllSharedFitnessKits().forEach(fitness => {
        app.addSetupTask(async () => await fitness.setup());

        // Sync when user is logged in and passes all onboard actions:
        app.addReadyTask(async () => await syncFitnessKitSafe(fitness));

        // Import from fitness kit when app becomes active:
        fitness.bindToAppState();
        app.addStateTask(async ({ active }) => {
            if (!active) {
                return;
            }
            await delay(200);
            await importFromFitnessKitSafe(fitness, {
                silenceErrors: true,
            });
        });

        // Import from fitness kit when sync settings change:
        const syncSub = fitness.sync$
            .pipe(skip(1), debounceTime(KFitnessImportDebounceInterval))
            .subscribe(() => importFromFitnessKitSafe(fitness));

        app.addTeardownTask(async () => {
            syncSub.unsubscribe();
            await fitness.close();
        });
    });
}

/**
 * Consifgure fitness kits with the user's permissions.
 * @param user
 * @returns
 */
export async function configureFitnessSafe(user: User) {
    return await Promise.all([
        HealthKit.configureHealthKitSafe(user),
        GoogleFit.configureGoogleFitSafe(user),
    ]);
}

export async function initFitnessSafe() {
    return await Promise.all([
        HealthKit.initHealthKitSafe(),
        GoogleFit.initGoogleFitSafe(),
    ]);
}

async function syncFitnessKitSafe(fitness: FitnessBase) {
    await importFromFitnessKitSafe(fitness);
    await exportToFitnessKitSafe(fitness);
}

async function exportToFitnessKitSafe(fitness: FitnessBase) {
    if (!fitness.isSyncEnabled || !User.current()) {
        return;
    }
    const startDate = moment().subtract(fitness.syncDuration);
    await Promise.all(
        getRecordDataSources()
            .filter(records => records.supportsFitnessSync())
            .map(records =>
                records.exportRangeToFitnessKitSafe(fitness, {
                    startDate,
                })
            )
    );
}

async function importFromFitnessKitSafe(
    fitness: FitnessBase,
    options?: FitnessImportOptions
) {
    if (!fitness.isSyncEnabled || !User.current()) {
        return;
    }
    const startDate = moment().subtract(fitness.syncDuration);
    try {
        await Promise.all(
            getRecordDataSources()
                .filter(records => records.supportsFitnessSync())
                .map(records =>
                    records.importFromFitnessKit(fitness, {
                        startDate,
                    })
                )
        );
    } catch (err) {
        console.error(`Failed to import from ${fitness.name}: ${err}`);
        if (!options?.silenceErrors) {
            addErrorSnack(
                lz('importFitnessErrorMessage', { fitnessName: fitness.name })
            );
        }
    }
}
