import { User, lz } from '@byterium/glucose-diary-client';
import React from 'react';
import { ScrollView, StyleSheet, View } from 'react-native';
import { Button, Form, FormModel } from 'react-native-form-model';
import { Banner, useTheme } from 'react-native-paper';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import AppBackground from '../../components/AppBackground';
import AppFooter from '../../components/AppFooter';
import { addSnack } from '../../components/AppSnackbar';
import CenteredContent from '../../components/CenteredContent';
import { KeyboardAvoidingView } from '../../components/KeyboardAvoidingView';
import { AppTheme, kMaxPageWidth } from '../../const';
import { usePromise } from '../../reactUtil';
import {
    addCredentialSectionToForm,
    addUserDetailsToForm,
    createFormSignupData,
    getFormProfileValues,
} from '../../services/forms/user/userForms';
import { UserProfilePageOptions } from './userProfileTypes';

export interface UserProfilePageProps extends UserProfilePageOptions {}

export default function UserProfilePage({ navigation }: UserProfilePageProps) {
    const theme = useTheme() as AppTheme;
    const insets = useSafeAreaInsets();

    const data = React.useRef(
        createFormSignupData(User.current()?.data)
    ).current;

    const [action, setAction] = React.useState<Promise<any> | undefined>(
        User.finishOperations()
    );
    const { loading = false, error } = usePromise(action);

    const form = React.useMemo(() => {
        const form = FormModel.create({ style: theme.form });

        addCredentialSectionToForm({
            form,
            data,
            theme,
            editable: false,
            email: true,
            token: false,
        });

        addUserDetailsToForm({ form, data, theme, editable: true });

        return form;
    }, [data, theme]);

    const saveChanges = React.useCallback(() => {
        if (!form.validateAll({ focusOnInvalid: true })) {
            return;
        }
        const action =
            User.current()?.updateUser(getFormProfileValues(data)) ||
            Promise.reject(new Error('Missing user'));
        action.then(() =>
            addSnack({
                message: lz('dataUpdatedSuccessfully'),
                action: {
                    label: lz('home'),
                    onPress: () => navigation.popToTop(),
                },
            })
        );
        setAction(action);
    }, [data, form, navigation]);

    React.useEffect(() => {
        form.onSubmit = saveChanges;
    }, [form, saveChanges]);

    return (
        <View style={styles.container}>
            <AppBackground />
            <KeyboardAvoidingView style={styles.container}>
                <ScrollView
                    style={styles.container}
                    contentContainerStyle={[
                        styles.scrollContent,
                        {
                            paddingLeft: insets.left,
                            paddingRight: insets.right,
                            paddingBottom: insets.bottom,
                        },
                    ]}
                >
                    <CenteredContent
                        maxWidth={kMaxPageWidth}
                        style={styles.content}
                    >
                        <Banner
                            visible={!!error}
                            actions={[
                                {
                                    label: lz('retry'),
                                    onPress: () => saveChanges(),
                                },
                            ]}
                        >
                            {`${lz('somethingWentWrong')}: ${String(
                                error?.message || error || ''
                            )}`}
                        </Banner>

                        <Form form={form} style={styles.form} />

                        <Button
                            title={lz('saveChanges')}
                            mode='contained'
                            disabled={loading}
                            onPress={() => saveChanges()}
                        />
                    </CenteredContent>
                    <AppFooter showLogoutButton showDeleteAccountButton />
                </ScrollView>
            </KeyboardAvoidingView>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    scrollContent: {
        flexGrow: 1,
    },
    content: {
        flexGrow: 1,
        paddingHorizontal: 12,
        paddingBottom: 12,
    },
    form: {
        marginBottom: 10,
    },
    section: {
        marginBottom: 10,
    },
    icon: {
        width: 24,
    },
    button: {
        marginTop: 8,
    },
    flexGrow: {
        flexGrow: 1,
    },
});
