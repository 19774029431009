import { lz } from '@byterium/glucose-diary-client';
import { StackNavigationOptions } from '@react-navigation/stack';
import React from 'react';

import { HomeStack } from '../navigate';
import InsulinProductSearchPage from './InsulinProductSearchPage';

const insulinScreens = ({
    defaultScreenOptions,
}: {
    defaultScreenOptions?: StackNavigationOptions;
} = {}) => {
    return [
        <HomeStack.Screen
            key='InsulinProductSearch'
            name='InsulinProductSearch'
            component={InsulinProductSearchPage}
            options={{
                title: lz('chooseAMedication'),
                ...defaultScreenOptions,
            }}
        />,
    ];
};

export default insulinScreens;
