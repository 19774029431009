import {
    ActivitySessions,
    GlucoseSamples,
    InsulinDoses,
    Meals,
    lz,
} from '@byterium/glucose-diary-client';
import React from 'react';
import { ScrollView, StyleSheet, View, ViewProps } from 'react-native';
import { Text, withTheme } from 'react-native-paper';

import { AppTheme } from '../../const';
import { useObservable } from '../../reactUtil';
import { ActivityIcon } from '../assets/activityAssets';
import { GlucoseIcon } from '../assets/glucoseAssets';
import { InsulinIcon } from '../assets/insulinAssets';
import { MealIcon } from '../assets/mealAssets';
import AddSampleItem from './AddSampleItem';

export interface AddSampleRowProps extends ViewProps {
    theme: AppTheme;
}

const AddSampleRow = React.memo(
    ({ theme, style, ...otherProps }: AddSampleRowProps) => {
        const { value: pendingGlucoseAdds = [] } = useObservable(() =>
            GlucoseSamples.pendingMutations$({ type: 'add' })
        );
        const { value: pendingInsulinAdds = [] } = useObservable(() =>
            InsulinDoses.pendingMutations$({ type: 'add' })
        );
        const { value: pendingMealAdds = [] } = useObservable(() =>
            Meals.pendingMutations$({ type: 'add' })
        );
        const { value: pendingActivityAdds = [] } = useObservable(() =>
            ActivitySessions.pendingMutations$({ type: 'add' })
        );
        return (
            <View
                {...otherProps}
                style={[
                    styles.container,
                    {
                        backgroundColor: theme.colors.surface,
                        borderRadius: theme.roundness,
                    },
                    style,
                ]}
            >
                <View style={styles.header}>
                    <Text
                        style={[
                            styles.title,
                            { color: theme.colors.textStronglyFaded },
                        ]}
                        onPressIn={undefined}
                        onPressOut={undefined}
                    >
                        {lz('add')}
                    </Text>
                </View>
                <ScrollView
                    {...otherProps}
                    horizontal
                    contentContainerStyle={styles.itemContainer}
                    alwaysBounceHorizontal={false}
                >
                    <AddSampleItem
                        recordType='GlucoseSample'
                        icon={props => <GlucoseIcon {...props} />}
                        animating={pendingGlucoseAdds.length !== 0}
                        color={theme.colors.glucose}
                        style={styles.item}
                    />
                    <AddSampleItem
                        recordType='InsulinDose'
                        icon={props => <InsulinIcon {...props} />}
                        animating={pendingInsulinAdds.length !== 0}
                        color={theme.colors.insulin}
                        style={styles.item}
                    />
                    <AddSampleItem
                        recordType='Meal'
                        icon={props => <MealIcon {...props} />}
                        animating={pendingMealAdds.length !== 0}
                        color={theme.colors.meal}
                        style={styles.item}
                    />
                    <AddSampleItem
                        recordType='ActivitySession'
                        icon={props => <ActivityIcon {...props} />}
                        animating={pendingActivityAdds.length !== 0}
                        color={theme.colors.activity}
                        style={styles.item}
                    />
                </ScrollView>
            </View>
        );
    }
);

const styles = StyleSheet.create({
    container: {},
    header: {
        marginTop: 8,
        marginHorizontal: 14,
    },
    title: {
        fontSize: 14,
        fontWeight: 'bold',
    },
    itemContainer: {
        flexDirection: 'row',
        flexGrow: 1,
    },
    item: {
        // flex: 1,
        marginLeft: 10,
        marginTop: 8,
        marginBottom: 10,
    },
    itemRight: {
        marginRight: 10,
    },
});

export default withTheme(AddSampleRow);
