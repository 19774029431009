import {
    AuthProvider,
    ENV,
    EnvKey,
    User,
    kAuthProviders,
    lz,
} from '@byterium/glucose-diary-client';
import {
    AuthRequestConfig,
    AuthRequestPromptOptions,
    ResponseType,
    makeRedirectUri,
    useAuthRequest,
} from 'expo-auth-session';
import Constants from 'expo-constants';
import { coolDownAsync, warmUpAsync } from 'expo-web-browser';
import _ from 'lodash';
import React from 'react';
import { Platform } from 'react-native';

import { usePromise } from '../../reactUtil';

const kProviderClientIdEnvKeys: { [K in AuthProvider]?: EnvKey } = {};

console.debug('[DEV] Redirect URI: ' + createRedirectURI());

export interface AuthSessionConfig extends Partial<AuthRequestConfig> {
    provider: AuthProvider;
}

export function useAuthSessions() {
    const sessionList = kAuthProviders.map(provider =>
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useAuthSession({ provider })
    );
    return _.zipObject(kAuthProviders, sessionList) as {
        [K in AuthProvider]: ReturnType<typeof useAuthSession>;
    };
}

export function useAuthSession(config: AuthSessionConfig) {
    const { provider, ...otherConfig } = config;
    let clientId = '';
    const clientIdEnvKey = kProviderClientIdEnvKeys[provider];
    if (clientIdEnvKey) {
        clientId = ENV.get(clientIdEnvKey) || '';
    }

    const [promptCounter, setPromptCounter] = React.useState(0);
    const [responseCounter, setResponseCounter] = React.useState(0);
    const [message, setMessage] = React.useState('');

    const [request, response, _promptAsync] = useAuthRequest(
        {
            responseType: ResponseType.Token,
            clientId,
            redirectUri: createRedirectURI(),
            ...otherConfig,
        },
        User.authDiscoveryDocument(provider)
    );
    const responseRef = React.useRef(response);

    const [recallAction, setRecallAction] = React.useState<
        Promise<User | undefined> | undefined
    >(undefined);
    const { error: recallError, loading: recalling = false } =
        usePromise(recallAction);

    React.useEffect(() => {
        if (response) {
            responseRef.current = response;
            setResponseCounter(promptCounter);

            if (response.type === 'success') {
                if (response.authentication?.accessToken) {
                    console.debug('Received access token. Getting user...');
                    setMessage(lz('retrievingLoginInfoMessage'));
                    setRecallAction(
                        User.loginWithAccessToken(
                            response.authentication.accessToken
                        )
                    );
                } else {
                    setRecallAction(
                        Promise.reject(new Error('No access token'))
                    );
                }
            } else if (response.type === 'error') {
                console.error(
                    'Auth error: ' + (response.error?.message || response.error)
                );
                setRecallAction(
                    Promise.reject(response.error || new Error('Login failed'))
                );
            } else {
                setRecallAction(Promise.resolve(undefined));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [response]);

    // TODO: Customise promptAsync with linking based on platform. See [task](https://trello.com/c/VcewpR9R)
    const promptAsync = (options?: AuthRequestPromptOptions | undefined) => {
        setPromptCounter(c => c + 1);
        setRecallAction(undefined);
        setMessage(lz('authenticatingMessage'));
        return _promptAsync(options);
    };

    const cancel = () => {
        // This request was cancelled
        // TODO: Do this in a less hacky way. See [task](https://trello.com/c/qpOC9QEf)
        __forceResetWebLogin();
    };

    const loading = responseCounter < promptCounter || recalling;

    return {
        request,
        response,
        promptAsync,
        loading,
        message,
        recallError,
        cancel,
    };
}

export function createRedirectURI() {
    return makeRedirectUri({
        scheme: Platform.OS !== 'web' ? Constants.manifest?.scheme : undefined,
    });
}

/**
 * On Android, warm up the web browser before it's used.
 * This allows the browser app to pre-initialize itself in the background.
 */
export function useWarmBrowser() {
    React.useEffect(() => {
        if (Platform.OS === 'android') {
            warmUpAsync();
            return () => {
                coolDownAsync();
            };
        }
    }, []);
}

/**
 * A hack on web to clear the auth redirect handle
 * to be able to restart a broken auth session.
 */
function __forceResetWebLogin() {
    if (Platform.OS === 'web') {
        const redirectUrl = localStorage.getItem(
            'ExpoWebBrowserRedirectHandle'
        );
        if (redirectUrl) {
            try {
                localStorage.removeItem('ExpoWebBrowserRedirectHandle');
                localStorage.removeItem(
                    `ExpoWebBrowser_RedirectUrl_${redirectUrl}`
                );
            } catch (err: any) {
                console.error(
                    'Error clearing AuthSession state: ' + err.message
                );
            }
        }
        window.location.reload();
    }
}
