import { Util, lz } from '@byterium/glucose-diary-client';
import {
    ChartTheme,
    kDefaultDarkChartTheme,
    kDefaultLightChartTheme,
} from '@byterium/librechart';
import _ from 'lodash';
import {
    kDefaultDarkFormColors,
    kDefaultFormStyle,
    kDefaultLightFormColors,
} from 'react-native-form-model';
import { Colors, DarkTheme, DefaultTheme } from 'react-native-paper';

export const kDocumentHashAlgorithm = 'md5';

export const kAppleHealth = 'Apple Health';

export const kDebugHostnames = ['localhost', '127.0.0.1'];

export type ColorScheme = 'light' | 'dark' | 'auto';
export const kColorSchemes = Util.safeKeyList<ColorScheme>({
    light: 1,
    dark: 1,
    auto: 1,
});
export const kDefaultColorScheme: ColorScheme = 'auto';

export function formatColorScheme(colorScheme?: ColorScheme): string {
    if (!colorScheme) {
        return lz('undefined');
    }
    switch (colorScheme) {
        case 'light':
            return lz('lightThemeType');
        case 'dark':
            return lz('darkThemeType');
        case 'auto':
            return lz('systemThemeType');
    }
    throw new Error('Invalid color scheme');
}

export const THEME_COLOR_PRIMARY_LIGHT = '#7073AD';
export const THEME_COLOR_ACCENT_LIGHT = '#fa8d23';

export const THEME_COLOR_PRIMARY_DARK = '#fa8d23';
export const THEME_COLOR_ACCENT_DARK = '#0097b1';

// export const THEME_COLOR_PRIMARY = '#F57E23';
export const THEME_COLOR_PRIMARY_GRAD = '#F37121';
export const THEME_COLOR_MEDIUM = '#FDC489';
export const THEME_COLOR_LIGHT = '#FDD4B9';
// export const THEME_COLOR_ACCENT = '#7073AD';

declare global {
    namespace ReactNativePaper {
        interface ThemeColors {
            onSurfaceError: string;
            activity: string;
            glucose: string;
            glucoseHigh: string;
            glucoseHighFaded: string;
            glucoseNormal: string;
            glucoseLow: string;
            glucoseLowFaded: string;
            insulin: string;
            meal: string;
            premeal: string;
            postmeal: string;
            textMoreSlightlyFaded: string;
            textSlightlyFaded: string;
            textStronglyFaded: string;
            formHeader: string;
        }

        interface AppTheme extends PaperThemeWithForm {
            colors: ThemeColors;
            chart: Required<ChartTheme>;
            colorScheme: 'light' | 'dark';
        }
    }
}

export interface AppTheme extends ReactNativePaper.AppTheme {}

export const kMaxPageWidth = 420;

const roundness = 8;

export const baseTheme: Partial<AppTheme> = {
    roundness,
    colors: {
        // chart: {
        //     valueColor: THEME_COLOR_PRIMARY,
        // } as DateChartStyleOptions,
        activity: Colors.green500,
        glucose: Colors.pinkA400,
        glucoseHigh: Colors.red500,
        glucoseHighFaded: Colors.red500 + (125).toString(16),
        glucoseNormal: Colors.indigo500,
        glucoseLow: Colors.blue500,
        glucoseLowFaded: Colors.blue500 + (125).toString(16),

        insulin: Colors.cyan500,
        meal: Colors.orange500,

        premeal: Colors.blue500,
        postmeal: Colors.red500,
    } as AppTheme['colors'],
    form: {
        ...kDefaultFormStyle,
        roundness,
    },
};

export const lightTheme: AppTheme = _.merge(DefaultTheme, baseTheme, {
    colorScheme: 'light',
    dark: false,
    colors: {
        /** Bradn colors */
        primary: THEME_COLOR_PRIMARY_LIGHT,
        accent: THEME_COLOR_ACCENT_LIGHT,

        notification: THEME_COLOR_PRIMARY_LIGHT,

        /** Background color for pages, such as lists. */
        background: Colors.grey100,
        /** Background color for elements containing content, such as cards. */
        surface: Colors.white,

        onBackground: Colors.grey900,
        onSurface: Colors.grey800,
        onSurfaceError: Colors.red200,

        error: kDefaultLightFormColors.error,

        // /** Color for disabled elements. */
        // disabled: THEME_COLOR_LIGHT,
        // /** Color for placeholder text, such as input placeholder. */
        placeholder: Colors.grey400,
        // /** Color for backdrops of various components such as modals. */
        // backdrop:
        // chart: {
        //     disabledValueColor: Colors.grey200,
        //     gridColor: Colors.grey300,
        //     axisColor: Colors.grey300,
        //     labelColor: Colors.grey500,
        //     selectionColor: Colors.grey400,
        // } as DateChartStyleOptions,
        textMoreSlightlyFaded: Colors.grey800,
        textSlightlyFaded: Colors.grey500,
        textStronglyFaded: Colors.grey400,
        formHeader: Colors.grey300,
    },
    form: {
        colors: {
            ...kDefaultLightFormColors,
            containedTextBackground: Colors.grey100,
            containedTextBorder: Colors.grey200,
            input: THEME_COLOR_PRIMARY_LIGHT,
            buttonBackground: THEME_COLOR_PRIMARY_LIGHT,
        },
    },
    chart: kDefaultLightChartTheme,
});

export const darkTheme: AppTheme = _.merge(DarkTheme, baseTheme, {
    colorScheme: 'dark',
    dark: true,
    colors: {
        /** Invert for dark mode */
        primary: THEME_COLOR_PRIMARY_DARK,
        accent: THEME_COLOR_ACCENT_DARK,

        notification: THEME_COLOR_PRIMARY_DARK,

        /** Background color for pages, such as lists. */
        background: Colors.black,
        /** Background color for elements containing content, such as cards. */
        surface: Colors.grey900,

        onBackground: Colors.grey200,
        onSurface: Colors.grey100,
        onSurfaceError: Colors.red800,

        error: Colors.red600,

        placeholder: Colors.grey600,
        // chart: {
        //     disabledValueColor: Colors.grey800,
        //     gridColor: Colors.grey700,
        //     axisColor: Colors.grey700,
        //     labelColor: Colors.grey500,
        //     selectionColor: Colors.grey600,
        // } as DateChartStyleOptions,
        textMoreSlightlyFaded: Colors.grey200,
        textSlightlyFaded: Colors.grey500,
        textStronglyFaded: Colors.grey600,
        formHeader: Colors.grey700,
    },
    form: {
        colors: {
            ...kDefaultDarkFormColors,
            containedTextBackground: Colors.black,
            containedTextBorder: Colors.black,
            input: THEME_COLOR_PRIMARY_DARK,
            buttonBackground: THEME_COLOR_PRIMARY_DARK,
            error: Colors.red600,
            destructive: Colors.red600,
        },
    },
    chart: kDefaultDarkChartTheme,
});

export function getInvertedTheme(theme: AppTheme) {
    switch (theme.colorScheme) {
        case 'light':
            return darkTheme;
        case 'dark':
            return lightTheme;
        default:
            throw new Error('Invalid theme');
    }
}

// console.log('Light theme: ' + JSON.stringify(lightTheme, null, 2));
// console.log('Dark theme: ' + JSON.stringify(darkTheme, null, 2));
