import { RouteProp } from '@react-navigation/native';
import {
    StackNavigationProp,
    createStackNavigator,
} from '@react-navigation/stack';
import React from 'react';
import { StyleProp, TextStyle } from 'react-native';

import { AddSamplePageOptions } from './AddSamplePage/AddSamplePage';
import { InsulinStackList } from './Insulin/insulinNavigation';
import { SettingsStackList } from './Settings/settingsTypes';
import { LoginStackList } from './login/loginTypes';
import { RecordsStackList } from './records/recordTypes';
import { UserProfileStackList } from './userProfile/userProfileTypes';

// Drawer

export type RootDrawerList = {
    Home: undefined;
};

// Home Stack

export type HomeStackList = {
    Onboard: undefined;
    Home: undefined;
    AddSample: AddSamplePageOptions;
    OverviewChart: undefined;
} & InsulinStackList &
    RecordsStackList &
    UserProfileStackList &
    SettingsStackList &
    LoginStackList;

export type NavigationStackName = keyof HomeStackList;

// OnboardPage

export type OnboardNavigationProp = StackNavigationProp<
    HomeStackList,
    'Onboard'
>;
export type OnboardRouteProp = RouteProp<HomeStackList, 'Onboard'>;

// Home

export type HomeNavigationProp = StackNavigationProp<HomeStackList, 'Home'>;

// AddSample

export type AddSampleNavigationProp = StackNavigationProp<
    HomeStackList,
    'AddSample'
>;

export type AddSampleRouteProp = RouteProp<HomeStackList, 'AddSample'>;

// OverviewChart

export type OverviewChartNavigationProp = StackNavigationProp<
    HomeStackList,
    'OverviewChart'
>;

export type OverviewChartRouteProp = RouteProp<HomeStackList, 'OverviewChart'>;

// Navigation utilities

export const navigationRef =
    React.createRef<StackNavigationProp<HomeStackList>>();

/** Navigate to the previous page. */
export const popNavigation = () => {
    navigationRef.current?.pop();
};

/** Navigate to a page. */
export const navigate = <
    T extends keyof HomeStackList,
    P extends HomeStackList[T]
>(
    name: keyof HomeStackList,
    params?: P
) => {
    navigationRef.current?.navigate(name, params);
};

/** Replace navigation stack with a page. */
export const navigateReplace = <
    T extends keyof HomeStackList,
    P extends HomeStackList[T]
>(
    name: NavigationStackName,
    params?: P
) => {
    navigationRef.current?.reset({
        index: 0,
        routes: [{ name, params }],
    });
};

export const HomeStack = createStackNavigator<HomeStackList>();
// export const ShopStack = createStackNavigator<ShopStackParamList>();

export function getHeaderTitleStyle(): StyleProp<TextStyle> {
    return {
        // alignSelf: 'center'
    };
}
