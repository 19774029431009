import { Util } from '@byterium/glucose-diary-client';

/**
 * Returns the first matching URL with the same TLD as
 * the specified URL.
 * @param urls
 * @param options
 * @returns
 */
export const findURL = (
    urls: URL[],
    options: { tld?: string; url?: URL }
): URL | undefined => {
    const { url } = options;
    const isIP = url ? Util.isIP(url) : false;
    let targetTld = url ? url.hostname : options?.tld;
    if (!targetTld) {
        throw new Error('Need to specify either a url or tld');
    }
    if (!isIP) {
        targetTld = targetTld
            .split('.')
            .filter(x => !!x)
            .pop()!;
    }
    for (const uri of urls) {
        let tld = uri.hostname;
        if (!isIP) {
            tld = tld
                .split('.')
                .filter(x => !!x)
                .pop()!;
        }
        if (tld === targetTld) {
            return uri;
        }
    }
    return undefined;
};
