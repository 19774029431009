import { StackNavigationOptions } from '@react-navigation/stack';
import React from 'react';

import { HomeStack } from '../../navigate';
import DeveloperEnvPage from './DeveloperEnvPage';
import DeveloperSettingsPage from './DeveloperSettingsPage';

const developerSettingsScreens = ({
    defaultScreenOptions,
}: {
    defaultScreenOptions?: StackNavigationOptions;
} = {}) => {
    return [
        <HomeStack.Screen
            key='DeveloperSettings'
            name='DeveloperSettings'
            component={DeveloperSettingsPage}
            options={{
                title: 'Developer',
                ...defaultScreenOptions,
            }}
        />,

        <HomeStack.Screen
            key='DeveloperEnv'
            name='DeveloperEnv'
            component={DeveloperEnvPage}
            options={{
                title: 'Env',
                ...defaultScreenOptions,
            }}
        />,
    ];
};

export default developerSettingsScreens;
