import { RecordType } from '@byterium/glucose-diary-client';
import React from 'react';
import { ViewProps } from 'react-native';

import { navigate } from '../../pages/navigate';
import GlucoseRangeWidget from '../glucose/GlucoseRangeWidget';

export interface RecordWidgetsProps extends ViewProps {
    recordType: RecordType;
}

export default function RecordWidgets({
    recordType,
    ...props
}: RecordWidgetsProps) {
    switch (recordType) {
        case 'GlucoseSample':
            return (
                <GlucoseRangeWidget
                    {...props}
                    onPressChange={() => navigate('GlucoseSettings')}
                />
            );
        default:
            return null;
    }
}
