import { StackNavigationOptions } from '@react-navigation/stack';
import React from 'react';

import { HomeStack } from '../navigate';
import LoginMainPage from './LoginMainPage';
import loginEmailScreens from './email/loginEmailScreens';

const loginScreens = ({
    defaultScreenOptions,
}: {
    defaultScreenOptions?: StackNavigationOptions;
} = {}) => {
    return [
        <HomeStack.Screen
            key='LoginMain'
            name='LoginMain'
            component={LoginMainPage}
            options={{
                ...defaultScreenOptions,
                headerShown: false,
                title: '',
            }}
        />,

        ...loginEmailScreens({ defaultScreenOptions }),
    ];
};

export default loginScreens;
