import { lz } from '@byterium/glucose-diary-client';
import { StackNavigationOptions } from '@react-navigation/stack';
import React from 'react';

import { allowDevMenu } from '../../services/env';
import { HomeStack } from '../navigate';
import SettingsPage from './SettingsPage';
import developerSettingsScreens from './developer/DeveloperSettingsScreens';
import GlucoseSettingsPage from './glucose/GlucoseSettingsPage';
import syncSettingsScreens from './sync/syncSettingsScreens';
import UnitSettingsPage from './units/UnitSettingsPage';

const settingsScreens = ({
    defaultScreenOptions,
}: {
    defaultScreenOptions?: StackNavigationOptions;
} = {}) => {
    return [
        <HomeStack.Screen
            key='Settings'
            name='Settings'
            component={SettingsPage}
            options={{
                title: lz('settings'),
                ...defaultScreenOptions,
            }}
        />,

        <HomeStack.Screen
            key='GlucoseSettings'
            name='GlucoseSettings'
            component={GlucoseSettingsPage}
            options={{
                title: lz('bloodGlucose'),
                ...defaultScreenOptions,
            }}
        />,
        <HomeStack.Screen
            key='UnitSettings'
            name='UnitSettings'
            component={UnitSettingsPage}
            options={{
                title: lz('units'),
                ...defaultScreenOptions,
            }}
        />,

        ...syncSettingsScreens({ defaultScreenOptions }),

        ...(allowDevMenu()
            ? developerSettingsScreens({ defaultScreenOptions })
            : []),
    ];
};

export default settingsScreens;
