import { User, lz } from '@byterium/glucose-diary-client';
import React from 'react';
import { ScrollView, StyleSheet, View } from 'react-native';
import { Button, Form } from 'react-native-form-model';
import { Banner, useTheme } from 'react-native-paper';

import AppBackground from '../../../components/AppBackground';
import CenteredContent from '../../../components/CenteredContent';
import { KeyboardAvoidingView } from '../../../components/KeyboardAvoidingView';
import { AppTheme, kMaxPageWidth } from '../../../const';
import { usePromise } from '../../../reactUtil';
import UserSettings from '../../../services/UserSettings';
import {
    LoginFormData,
    createFormLoginData,
    createLoginForm,
} from '../../../services/forms/user/userForms';
import { LoginEmailPageOptions } from './loginEmailTypes';

export interface LoginEmailPageProps extends LoginEmailPageOptions {}

export default function LoginEmailPage(props: LoginEmailPageProps) {
    const { email: emailProp, navigation } = {
        ...props,
        ...props.route.params,
    };

    const theme = useTheme() as AppTheme;

    const [action, setAction] = React.useState<Promise<any> | undefined>(
        undefined
    );
    const { loading = false, error } = usePromise(action);

    const data = React.useRef<LoginFormData>(
        createFormLoginData({ email: emailProp })
    ).current;

    const form = React.useMemo(() => {
        const form = createLoginForm({ data, theme, autoFocusEmail: true });
        form.validateAll({ focusOnInvalid: true });
        return form;
    }, [data, theme]);

    const trySendOneTimePasscode = React.useCallback(() => {
        if (!form.validateAll({ focusOnInvalid: true })) {
            return;
        }
        const email = data.email.value || '';
        setAction(
            User.sendOneTimePasscode({
                email,
                dryRun: UserSettings._debugEmailLoginDryRun$.value,
            }).then(tokenHint =>
                navigation.push('LoginEmailCode', { email, ...tokenHint })
            )
        );
    }, [data.email.value, form, navigation]);

    React.useEffect(() => {
        form.onSubmit = trySendOneTimePasscode;
    }, [form, trySendOneTimePasscode]);

    return (
        <View style={styles.container}>
            <AppBackground />
            <KeyboardAvoidingView style={styles.container}>
                <ScrollView
                    style={styles.container}
                    contentContainerStyle={styles.scrollContent}
                >
                    <CenteredContent
                        maxWidth={kMaxPageWidth}
                        style={styles.content}
                    >
                        <Banner
                            visible={!!error}
                            actions={[
                                {
                                    label: lz('retry'),
                                    onPress: () => trySendOneTimePasscode(),
                                },
                            ]}
                        >
                            {`${lz('somethingWentWrong')}: ${String(
                                error?.message || error || ''
                            )}`}
                        </Banner>

                        <Form form={form} style={styles.form} />

                        <Button
                            title={lz('sendOneTimePasscode')}
                            icon={false}
                            disabled={loading}
                            loading={loading}
                            onPress={() => trySendOneTimePasscode()}
                            mode='contained'
                            style={styles.button}
                        />
                        <Button
                            title={
                                lz('dontHaveAnAccount') +
                                '\n' +
                                lz('createAnAccountHere')
                            }
                            icon={false}
                            disabled={loading}
                            onPress={() =>
                                navigation.navigate('SignupEmail', {
                                    email: data.email.value,
                                })
                            }
                            style={styles.button}
                        />
                    </CenteredContent>
                </ScrollView>
            </KeyboardAvoidingView>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    scrollContent: {
        flexGrow: 1,
    },
    content: {
        margin: 12,
    },
    form: {
        marginVertical: 12,
    },
    section: {
        marginBottom: 10,
    },
    icon: {
        width: 24,
    },
    button: {
        marginTop: 8,
    },
});
