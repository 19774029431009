import {
    ActivitySessions,
    GlucoseSamples,
    InsulinDoses,
    Meals,
    RecordDataPoint,
    RecordType,
    Records,
    Schema,
} from '@byterium/glucose-diary-client';
import {
    ChartLayout,
    DataSource,
    DateScale,
    DateUnit,
    LabelDataSource,
    LineDataSource,
    PlotLayout,
} from '@byterium/librechart';
import _ from 'lodash';
import moment, { Duration, Moment } from 'moment';
import { BehaviorSubject, Observable } from 'rxjs';

import { AppTheme } from '../../const';
import { AnyDateUnit } from '../../dateFormat';
import { UserUnits } from '../../services/UserSettings';

export type ChartMode = 'line' | 'bar' | 'scatter';

export const kDefaultChartFetchLimit = 1000;
export const kDefaultChartFetchQuery: Schema.FindRecordQuery = {
    limit: kDefaultChartFetchLimit,
    descending: false,
};

export const kBaseDateUnit = 'day';
export const kBaseDateUnitMs = moment
    .duration(1, kBaseDateUnit)
    .asMilliseconds();

export const kMainLabelOffset = 12;

export const kSecondaryPointRadiusBase = 8;
export const kSecondaryAxisLabelWidth = 30;

export const kMinLabelXDistance = 60;
export const kMinSecondaryLabelXDistance = 60;
export const kMinLabelXDistanceHysteresisRatio = 0.2;
export const kSecondaryValueLabelOffset = 12;

export const kDataUpdateDebounceInterval = 1000;
export const kDataDisplayDebounceInterval = 200;

export const kMajorXGridLineDistanceMin = 80;

export const kBottomAxisHeight = 40;
export const kRightMainAxisThickness = 35;
export const kRightSecondaryAxisThickness = 40;

/** Must match `periodLabels` */
export const periods: AnyDateUnit[] = ['day', 'week', 'month', 'year'];

export const kBarCornerRadius = 4;

export interface RecordChartDataPoint extends RecordDataPoint {
    isTop?: boolean;
    showLabel?: boolean;
}

export type ChartDataByRecordType<U> = { [T in RecordType]: U };

export const kDataSources: ChartDataByRecordType<Records<any>> = {
    ActivitySession: ActivitySessions,
    GlucoseSample: GlucoseSamples,
    InsulinDose: InsulinDoses,
    Meal: Meals,
};

export const kSecondaryDataSourcePosition: ChartDataByRecordType<number> = {
    GlucoseSample: NaN,
    InsulinDose: 3,
    Meal: 2,
    ActivitySession: 1,
};

export const kRecordTypeAtY = _.invert(kSecondaryDataSourcePosition) as {
    [y: string]: RecordType;
};

export interface DateScaleInfo {
    majorInterval: number;
    minorCount: number;
    scale: DateScale;
    /**
     * The distance (in content coordinates) to offset the
     * viewport to preserve the current visible content
     * after update.
     **/
    recenteringOffset: number;
}

export interface DatePeriod {
    value: number;
    unit: DateUnit;
}

export interface SecondaryDataSourceOptions {
    recordType: RecordType;
    dataAverages: ChartDataByRecordType<number>;
    units: UserUnits;
    theme: AppTheme;
}

export interface SecondaryDataSourceInfo {
    valueDataLayout: LineDataSource<RecordChartDataPoint, Moment, number>;
    labelDataLayout: LabelDataSource<RecordChartDataPoint, Moment, number>;
}

export interface UpdateChartScrollOptions {
    date?: Moment;
    snapToCurrentDate?: boolean;
}

export interface RecordChartLayout {
    plot: PlotLayout<Moment, number, Duration, number>;
    layout: ChartLayout;
    dataLayout: DataSource<RecordChartDataPoint, Moment, number>;
    labelDataLayout: LabelDataSource<RecordChartDataPoint, Moment, number>;
    secondaryDataLayouts: { [T in RecordType]?: SecondaryDataSourceInfo };
    periodState: RecordChartPeriodState;
    minorPeriod$: BehaviorSubject<DatePeriod>;
    dateScaleInfo: DateScaleInfo;
    visibleDataRange$: Observable<[Moment, Moment]>;
    updateVisibleRegion: (options?: UpdateChartScrollOptions) => void;
    updatePeriodState: () => void;
}

export interface RecordChartPeriodState {
    periodIndex: number;
    minorPeriod: DatePeriod;
    minorPeriodDuration: Duration;
    minorPeriodMs: number;
}

export interface ChartBaseOptions {
    isFullscreen?: boolean;
    onFullscreenPress?: () => any;
}

export interface RecordChartBaseOptions extends ChartBaseOptions {
    recordType: RecordType;
    valueTransformer: (value: number) => number;
    mode?: ChartMode;
    color: string;
    highColor?: string;
    lowColor?: string;
    highValue?: number;
    lowValue?: number;
    isOverview?: boolean;
}

export interface RecordChartViewOptions extends RecordChartBaseOptions {
    valueFormatter?: (value: number) => string;
}

export interface RecordChartLayoutOptions extends RecordChartBaseOptions {
    dataAverages: { [T in RecordType]: number };
    units: UserUnits;
    theme: AppTheme;
}
