import { ENV } from '@byterium/glucose-diary-client';
import { Platform } from 'react-native';

import { VercelServerInfo } from '../apiSchema';

let vercelLoaded = false;
let vercelFailed = false;

/** Load env from Vercel. */
export const getServerInfo = async (): Promise<VercelServerInfo> => {
    if (!isVercel()) {
        throw new Error('Not in Vercel context');
    }
    const res = await fetch('/api/get-info');
    if (!res.ok) {
        throw new Error('Fetch failed with code: ' + res.status);
    }
    let info: VercelServerInfo | undefined;
    try {
        info = await res.clone().json();
    } catch (err: any) {
        throw new Error('JSON parse error: ' + err.message);
    }
    if (!info || info.error) {
        throw new Error(info?.error || 'unexpected result');
    }
    return info;
};

/** Load env from Vercel. */
export const loadVercelEnv = async () => {
    if (isVercel() && !vercelLoaded) {
        try {
            console.debug('Fetching server info from Vercel...');
            const info = await getServerInfo();
            if (!info.env) {
                throw new Error(info.error || 'unexpected result');
            }
            ENV.addSource(info.env);
            ENV.isDev &&
                console.debug(
                    '[DEV] Vercel env: ' + JSON.stringify(info.env, null, 2)
                );
            vercelLoaded = true;
        } catch (err: any) {
            console.error('Vercel error: ' + err.message);
            vercelFailed = true;
        }
    }
};

/** Guess whether this script is executed inside Vercel. */
const isVercel = () => {
    if (Platform.OS !== 'web') {
        return false;
    }
    if (vercelFailed) {
        return false;
    }
    return vercelLoaded || !global.process?.env.EXPO_SERVER_URI_BASE;
};
