import { lz } from '@byterium/glucose-diary-client';
import React from 'react';
import { StyleSheet, TextProps } from 'react-native';
import { Text, useTheme } from 'react-native-paper';

import { AppTheme } from '../../const';

export interface TermsLabelProps extends TextProps {
    onPress: () => any;
}

export default function TermsLabel({ onPress, ...props }: TermsLabelProps) {
    const theme = useTheme() as AppTheme;
    const title = lz('IAgreeToTerms');
    const linkTitle = lz('IAgreeToTermsLink');
    const linkTitleIndex = title.indexOf(linkTitle);
    const titleBeforeLink = title.substring(0, linkTitleIndex);
    const titleAfterLink = title.substring(linkTitleIndex + linkTitle.length);
    return (
        <Text onPressIn={undefined} onPressOut={undefined} {...props}>
            <Text onPressIn={undefined} onPressOut={undefined}>
                {titleBeforeLink || ''}
            </Text>
            <Text
                onPress={onPress}
                style={[
                    styles.link,
                    {
                        color: theme.form.colors.input || theme.colors.primary,
                    },
                ]}
                onPressIn={undefined}
                onPressOut={undefined}
            >
                {linkTitle || ''}
            </Text>
            <Text onPressIn={undefined} onPressOut={undefined}>
                {titleAfterLink || ''}
            </Text>
        </Text>
    );
}

const styles = StyleSheet.create({
    link: {
        textDecorationLine: 'underline',
    },
});
