import { HealthKitBase, HealthKitMock } from '@byterium/glucose-diary-client';

const fitnessKit = new HealthKitMock();
HealthKitBase.setShared(fitnessKit);

// fitnessKit.supported = true;
// fitnessKit.glucoseSamples = [
//     {
//         glucoseLevel: 1.4,
//         date: moment().startOf('day').add(6, 'hours'),
//         mealFlag: Schema.MealFlag.Premeal,
//         sourceId: 'test',
//     },
// ];

export default HealthKitMock;
