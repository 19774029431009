import { lz } from '@byterium/glucose-diary-client';
import { Chart } from '@byterium/librechart';
import * as IntentLauncher from 'expo-intent-launcher';
import { ActivityAction } from 'expo-intent-launcher';
import * as Linking from 'expo-linking';
import React from 'react';
import { Platform } from 'react-native';

import * as MediaShare from '../../services/MediaShare';
import { addErrorSnack, addSnack } from '../AppSnackbar';
import { ChartShareOptions } from './ChartShareModal';

let share$: Promise<void> | undefined;

export function canShareChart() {
    return MediaShare.isSupported();
}

export const currentShare = () => {
    return share$;
};

export const finishChartShare = async () => {
    if (share$) {
        return await share$;
    }
};

export const shareChartLazy = ({
    chartRef,
}: {
    chartRef: React.MutableRefObject<Chart | null>;
}) => {
    if (!canShareChart()) {
        return () => console.error('Chart sharing not supported');
    }
    return (options: ChartShareOptions) => {
        share$ =
            share$ ||
            (async () => {
                // Start sharing
                console.debug('Begin sharing chart with options:', options);
                const shareOptions: MediaShare.MediaShareOptions = {
                    fileType: 'PNG',
                    fileName: createFileName(options),
                    title: lz('shareChart'),
                };
                let success = false;
                switch (options.mode) {
                    case 'image':
                        success = await MediaShare.saveViewImage(
                            chartRef,
                            shareOptions
                        );
                        if (success) {
                            addSnack({
                                message: lz('chartSavedToPhotos'),
                                action: {
                                    label: lz('open'),
                                    accessibilityLabel: lz('open'),
                                    onPress: openPhotos,
                                },
                            });
                        }
                        break;
                    case 'other':
                        success = await MediaShare.shareView(
                            chartRef,
                            shareOptions
                        );
                        break;
                    default:
                        break;
                }
                if (!success) {
                    console.error('Unable to share chart');
                    addErrorSnack(lz('shareChartFailedMessage'));
                }
            })()
                .catch(err => {
                    console.error('Unable to share chart: ' + err);
                    addErrorSnack(lz('shareChartFailedMessage'));
                })
                .finally(() => {
                    // Allow sharing again
                    console.debug('End sharing chart');
                    share$ = undefined;
                });
        return share$!;
    };
};

function createFileName(options: ChartShareOptions): string {
    // TODO: Return a localised file name when expo-file-system supports it
    // const formattedRecordType = options.isOverview
    //     ? lz('overview')
    //     : formatRecordType(options.recordType);
    // return `${lz('chart')} - ${formattedRecordType}`;
    return 'Chart';
}

// function sanitizeFileName(fileName: string): string {
//     fileName = sanitize(fileName);
//     fileName = fileName.replace(/[\[\]]/g, '');
//     fileName = fileName.trim();
//     return fileName;
// }

function openPhotos() {
    switch (Platform.OS) {
        case 'ios':
            Linking.openURL('photos-redirect://');
            break;
        case 'android':
            // Linking.openURL("content://media/internal/images/media");
            IntentLauncher.startActivityAsync(
                'android.intent.action.MAIN' as ActivityAction,
                {
                    category: 'android.intent.category.APP_GALLERY',
                }
            );
            break;
    }
}
