import { User } from '@byterium/glucose-diary-client';
import React from 'react';
import { StyleSheet, View } from 'react-native';
import { Row, RowProps } from 'react-native-form-model';
import { Text } from 'react-native-paper';

import { useObservable } from '../../reactUtil';
import { ChevronIcon } from '../assets/commonAssets';
import UserAvatar from './UserAvatar';

export interface UserSummaryRowProps extends RowProps {}

export default function UserSummaryRow({ ...otherProps }: UserSummaryRowProps) {
    const { value: userData } = useObservable(User.currentData$);
    const {
        givenName = '',
        middleName = '',
        familyName = '',
        email = '',
    } = userData || {};
    const name = [givenName, middleName, familyName]
        .filter(x => !!x)
        .map(x => x.trim())
        .filter(x => !!x)
        .join(' ');
    const details = [name, email].filter(x => !!x);

    return (
        <Row {...otherProps} contentContainerStyle={styles.row}>
            <UserAvatar style={styles.avatar} />
            <View style={styles.detailContainer}>
                {details.map((detail, i) => (
                    <Text
                        key={i}
                        style={[
                            styles.detail,
                            i === 0 ? styles.mainDetail : {},
                        ]}
                        onPressIn={undefined}
                        onPressOut={undefined}
                    >
                        {detail}
                    </Text>
                ))}
            </View>
            <ChevronIcon />
        </Row>
    );
}

const styles = StyleSheet.create({
    row: {
        justifyContent: 'flex-start',
    },
    avatar: {
        marginVertical: 12,
    },
    detailContainer: {
        flex: 1,
        marginHorizontal: 12,
        justifyContent: 'center',
    },
    detail: {
        fontSize: 16,
        marginVertical: 2,
    },
    mainDetail: {
        fontWeight: 'bold',
    },
});
