import {
    GlucoseUnit,
    convertGlucoseValue,
    formatGlucoseUnit,
    formatGlucoseValue,
    glucoseUnitMap,
    kBaseGlucoseUnit,
    kGlucoseUnits,
    lz,
} from '@byterium/glucose-diary-client';
import React, { useMemo } from 'react';
import { ScrollView, StyleSheet, View } from 'react-native';
import { Form, FormModel } from 'react-native-form-model';
import { useTheme } from 'react-native-paper';
import { BehaviorSubject, Subscription } from 'rxjs';

import AppBackground from '../../../components/AppBackground';
import CenteredContent from '../../../components/CenteredContent';
import { KeyboardAvoidingView } from '../../../components/KeyboardAvoidingView';
import { AppTheme, kMaxPageWidth } from '../../../const';
import UserSettings from '../../../services/UserSettings';
import { GlucoseSettingsPageOptions } from './glucoseSettingsTypes';

export interface GlucoseSettingsPageProps extends GlucoseSettingsPageOptions {}

export default function GlucoseSettingsPage({
    navigation,
}: GlucoseSettingsPageProps) {
    const theme = useTheme() as AppTheme;

    const [form, setForm] = React.useState(() => FormModel.empty());
    const { glucoseUnit } = UserSettings.useUnits();

    const formData = useMemo(
        () => ({
            byUnit: glucoseUnitMap(unit => ({
                upperTargetLimit: new BehaviorSubject(
                    convertGlucoseValue(
                        UserSettings.glucoseTargetUpperLimit$.value,
                        kBaseGlucoseUnit,
                        unit
                    )
                ),
                lowerTargetLimit: new BehaviorSubject(
                    convertGlucoseValue(
                        UserSettings.glucoseTargetLowerLimit$.value,
                        kBaseGlucoseUnit,
                        unit
                    )
                ),
            })),
        }),
        []
    );

    React.useLayoutEffect(() => {
        const form = FormModel.create({ style: theme.form });
        const valuePlaceholder = formatGlucoseValue(0, glucoseUnit);

        form.addSection().modify(section => {
            section.setTitle(lz('targetLimits'));
            section
                .addRow()
                .addLabel({ title: lz('upperTargetLimit'), flex: 2 })
                .addKeyboardInput<number>({
                    value: formData.byUnit[glucoseUnit].upperTargetLimit,
                    type: 'unsignedFloat',
                    placeholder: valuePlaceholder,
                    flex: 1,
                    align: 'right',
                    mode: 'contained',
                })
                .setMargin(0)
                .addLabel({
                    title: formatGlucoseUnit(glucoseUnit),
                    color: theme.colors.textStronglyFaded,
                });
            section
                .addRow()
                .addLabel({ title: lz('lowerTargetLimit'), flex: 2 })
                .addKeyboardInput<number>({
                    value: formData.byUnit[glucoseUnit].lowerTargetLimit,
                    type: 'unsignedFloat',
                    placeholder: valuePlaceholder,
                    flex: 1,
                    align: 'right',
                    mode: 'contained',
                })
                .setMargin(0)
                .addLabel({
                    title: formatGlucoseUnit(glucoseUnit),
                    color: theme.colors.textStronglyFaded,
                });
            section.setFooter(lz('changeGlucoseLimitsConsultDoctor'));
        });

        form.addSection()
            .addRow()
            .addLabel({ title: lz('unitOfMeasurement'), flex: 1 })
            .addOptionInput<GlucoseUnit>({
                value: UserSettings.glucoseUnit$,
                mode: 'segmented',
                possibleValues: [...kGlucoseUnits],
                formatValue: x => formatGlucoseUnit(x),
                style: {
                    fontSize: 14,
                },
                flex: 1,
                align: 'right',
            });

        setForm(form);
    }, [theme, glucoseUnit, formData.byUnit]);

    React.useEffect(() => {
        const subs: Subscription[] = [];

        kGlucoseUnits.map(unit => {
            const data = formData.byUnit[unit];
            subs.push(
                data.upperTargetLimit.subscribe(value => {
                    const baseValue = convertGlucoseValue(
                        value,
                        unit,
                        kBaseGlucoseUnit
                    );
                    // Set new value to user settings
                    if (
                        baseValue !==
                        UserSettings.glucoseTargetUpperLimit$.value
                    ) {
                        UserSettings.glucoseTargetUpperLimit$.next(baseValue);
                    }
                    // Set new value to other units (which are hidden)
                    for (const otherUnit of kGlucoseUnits) {
                        if (otherUnit === unit) {
                            continue;
                        }
                        const otherData = formData.byUnit[otherUnit];
                        const otherValue = convertGlucoseValue(
                            value,
                            unit,
                            otherUnit
                        );
                        if (otherValue !== otherData.upperTargetLimit.value) {
                            otherData.upperTargetLimit.next(otherValue);
                        }
                    }
                })
            );
            subs.push(
                data.lowerTargetLimit.subscribe(value => {
                    const baseValue = convertGlucoseValue(
                        value,
                        unit,
                        kBaseGlucoseUnit
                    );
                    // Set new value to user settings
                    if (
                        baseValue !==
                        UserSettings.glucoseTargetLowerLimit$.value
                    ) {
                        UserSettings.glucoseTargetLowerLimit$.next(baseValue);
                    }
                    // Set new value to other units (which are hidden)
                    for (const otherUnit of kGlucoseUnits) {
                        if (otherUnit === unit) {
                            continue;
                        }
                        const otherData = formData.byUnit[otherUnit];
                        const otherValue = convertGlucoseValue(
                            value,
                            unit,
                            otherUnit
                        );
                        if (otherValue !== otherData.lowerTargetLimit.value) {
                            otherData.lowerTargetLimit.next(otherValue);
                        }
                    }
                })
            );
        });

        return () => {
            for (const sub of subs) {
                sub.unsubscribe();
            }
        };
    }, [formData.byUnit]);

    return (
        <View style={styles.container}>
            <AppBackground />
            <KeyboardAvoidingView style={styles.container}>
                <ScrollView
                    style={styles.container}
                    contentContainerStyle={styles.scrollContent}
                >
                    <CenteredContent
                        maxWidth={kMaxPageWidth}
                        style={styles.content}
                    >
                        <Form form={form} style={styles.form} />
                    </CenteredContent>
                </ScrollView>
            </KeyboardAvoidingView>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    scrollContent: {
        flexGrow: 1,
    },
    content: {
        margin: 12,
    },
    form: {
        marginVertical: 12,
    },
    section: {
        marginBottom: 10,
    },
    icon: {
        width: 24,
    },
});
