import React from 'react';
import { StyleSheet, View, ViewProps } from 'react-native';

export interface CenteredContentProps extends ViewProps {
    maxWidth?: number;
    children: React.ReactNode | React.ReactNode[];
}

const CenteredContent = React.memo(
    ({ maxWidth = 320, children, style, ...props }: CenteredContentProps) => {
        return (
            <View {...props} style={[styles.outerContainer, style]}>
                <View
                    style={
                        maxWidth
                            ? [styles.innerContainer, { flexBasis: maxWidth }]
                            : styles.disabledInnerContainer
                    }
                >
                    {children}
                </View>
            </View>
        );
    }
);

export default CenteredContent;

const styles = StyleSheet.create({
    outerContainer: {
        flexDirection: 'row',
        justifyContent: 'center',
    },
    innerContainer: {
        flexShrink: 1,
    },
    disabledInnerContainer: {
        flex: 1,
    },
});
