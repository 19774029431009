import {
    RecordModel,
    RecordType,
    Schema,
    ToNumberOptions,
    convertCarbValue,
    convertEnergyValue,
    convertGlucoseValue,
    convertInsulinValue,
    formatCarbUnit,
    formatCarbValue,
    formatEnergyUnit,
    formatEnergyValue,
    formatGlucoseUnit,
    formatGlucoseValue,
    formatInsulinUnit,
    formatInsulinValue,
    formatMealFlag,
    kBaseCarbUnit,
    kBaseEnergyUnit,
    kBaseGlucoseUnit,
    kBaseInsulinUnit,
} from '@byterium/glucose-diary-client';

import { RecordChartProps } from '../../components/RecordChart';
import { AppTheme } from '../../const';
import UserSettings, { UserUnits } from '../../services/UserSettings';
import {
    RecordPageBaseOptions,
    RecordSummaryPageExtraProps,
} from './recordTypes';

export function getRecordSummaryInterface({
    recordType,
    units,
    theme,
}: {
    recordType: RecordType;
    units: UserUnits;
    theme: AppTheme;
}): RecordPageBaseOptions & RecordSummaryPageExtraProps {
    let unit: string;
    let showAverage = false;
    let partialChartProps: Partial<RecordChartProps> = {};
    const valueFormatter = getValueFormatter({ recordType, units });
    const valueTransformer = getValueTransformer({ recordType, units });

    switch (recordType) {
        case 'ActivitySession':
            unit = formatEnergyUnit(units.energyUnit);

            partialChartProps = {
                ...partialChartProps,
                mode: 'bar',
                color: theme.colors.activity,
            };
            break;
        case 'GlucoseSample': {
            unit = formatGlucoseUnit(units.glucoseUnit);
            showAverage = true;

            partialChartProps = {
                ...partialChartProps,
                mode: 'line',
                highColor: theme.colors.glucoseHighFaded,
                color: theme.colors.glucoseNormal,
                lowColor: theme.colors.glucoseLowFaded,
                highValue: valueTransformer(
                    UserSettings.glucoseTargetUpperLimit$.value
                ),
                lowValue: valueTransformer(
                    UserSettings.glucoseTargetLowerLimit$.value
                ),
            };
            break;
        }
        case 'InsulinDose':
            unit = formatInsulinUnit(units.insulinUnit);

            partialChartProps = {
                ...partialChartProps,
                mode: 'bar',
                color: theme.colors.insulin,
            };
            break;
        case 'Meal':
            unit = formatCarbUnit(units.carbUnit);

            partialChartProps = {
                ...partialChartProps,
                mode: 'bar',
                color: theme.colors.meal,
            };
            break;
        default:
            throw new Error('Unknown record type: ' + recordType);
    }

    const chartProps: RecordChartProps = {
        color: '',
        ...partialChartProps,
        recordType,
        valueTransformer,
        valueFormatter,
    };

    return {
        unit,
        showAverage,
        chartProps,
        recordType,
    };
}

export const getValueTransformer = ({
    recordType,
    units,
}: {
    recordType: RecordType;
    units: UserUnits;
}): ((value: number) => number) => {
    switch (recordType) {
        case 'ActivitySession':
            return (value: number) =>
                convertEnergyValue(value, kBaseEnergyUnit, units.energyUnit);
        case 'GlucoseSample':
            return (value: number) =>
                convertGlucoseValue(value, kBaseGlucoseUnit, units.glucoseUnit);
        case 'InsulinDose':
            return (value: number) =>
                convertInsulinValue(value, kBaseInsulinUnit, units.insulinUnit);
        case 'Meal':
            return (value: number) =>
                convertCarbValue(
                    value,
                    kBaseCarbUnit,
                    units.carbUnit,
                    units.carbConfig
                );
        default:
            throw new Error('Unknown record type: ' + recordType);
    }
};

export const getValueFormatter = ({
    recordType,
    units,
}: {
    recordType: RecordType;
    units: UserUnits;
}): ((value: number, options?: ToNumberOptions) => string) => {
    switch (recordType) {
        case 'ActivitySession':
            return (value: number, options?: ToNumberOptions) =>
                formatEnergyValue(value, units.energyUnit, options);
        case 'GlucoseSample':
            return (value: number, options?: ToNumberOptions) =>
                formatGlucoseValue(value, units.glucoseUnit, options);
        case 'InsulinDose':
            return (value: number, options?: ToNumberOptions) =>
                formatInsulinValue(value, units.insulinUnit, options);
        case 'Meal':
            return (value: number, options?: ToNumberOptions) =>
                formatCarbValue(
                    value,
                    units.carbUnit,
                    units.carbConfig,
                    options
                );
        default:
            throw new Error('Unknown record type: ' + recordType);
    }
};

export interface RecordDescriptionInterface<T extends RecordModel = any> {
    getDescription?: (record: T) => string | undefined;
    getDescriptionColor?: (record: T) => string | undefined;
}

export const getRecordDescriptionInterface = <T extends RecordModel = any>({
    recordType,
    theme,
}: {
    recordType: RecordType;
    theme: AppTheme;
}): RecordDescriptionInterface<T> => {
    switch (recordType) {
        case 'ActivitySession':
            return {};
        case 'GlucoseSample':
            return {
                getDescription: (record: T) => {
                    const { mealFlag } =
                        record as unknown as Schema.GlucosePointFieldsFragment;
                    return mealFlag ? formatMealFlag(mealFlag) : '';
                },
                getDescriptionColor: (record: T) => {
                    const { mealFlag } =
                        record as unknown as Schema.GlucosePointFieldsFragment;
                    switch (mealFlag) {
                        case Schema.MealFlag.Premeal:
                            return theme.colors.premeal;
                        case Schema.MealFlag.Postmeal:
                            return theme.colors.postmeal;
                        default:
                            return undefined;
                    }
                },
            };
        case 'InsulinDose':
            return {};
        case 'Meal':
            return {};
        default:
            throw new Error('Unknown record type: ' + recordType);
    }
};
