import {
    RecordType,
    formatCarbUnit,
    formatEnergyUnit,
    formatGlucoseUnit,
    formatInsulinUnit,
} from '@byterium/glucose-diary-client';
import { ITickVector } from '@byterium/librechart';
import React from 'react';
import { Animated, StyleSheet, TextProps, View } from 'react-native';
import { Text } from 'react-native-paper';

import { AppTheme } from '../../const';
import { UserUnits } from '../../services/UserSettings';
import { Circle } from '../assets/commonAssets';
import { RecordTypeIcon, recordTypeColor } from '../assets/recordAssets';
import { kRecordTypeAtY, kSecondaryAxisLabelWidth } from './chartConst';

export interface SecondaryChartAxisLabelProps
    extends Animated.AnimatedProps<TextProps> {
    mainRecordType: RecordType;
    tick: ITickVector<number>;
    units: UserUnits;
    theme: AppTheme;
    isCompact?: boolean;
}

export default function SecondaryChartAxisLabel({
    mainRecordType,
    tick,
    units,
    theme,
    style,
    isCompact = false,
    ...props
}: SecondaryChartAxisLabelProps) {
    const recordType = kRecordTypeAtY[tick.value];
    if (!recordType || recordType === mainRecordType) {
        return null;
    }
    const color = recordTypeColor(recordType, theme);
    let unit = '';
    switch (recordType) {
        case 'ActivitySession':
            unit = formatEnergyUnit(units.energyUnit);
            break;
        case 'GlucoseSample':
            unit = formatGlucoseUnit(units.glucoseUnit);
            break;
        case 'InsulinDose':
            unit = formatInsulinUnit(units.insulinUnit);
            break;
        case 'Meal':
            unit = formatCarbUnit(units.carbUnit);
            break;
    }

    const textBaseStyle = { color };
    const textExtraStyle = unit.length > 3 ? styles.smallFont : null;

    return (
        <Animated.View {...props} style={[styles.container, style]}>
            {isCompact ? (
                <Text
                    style={[textBaseStyle, styles.compactText, textExtraStyle]}
                    onPressIn={undefined}
                    onPressOut={undefined}
                >
                    {unit}
                </Text>
            ) : (
                <View style={styles.innerContainer}>
                    <Text
                        style={[
                            textBaseStyle,
                            styles.hiddenText,
                            textExtraStyle,
                        ]}
                        onPressIn={undefined}
                        onPressOut={undefined}
                    >
                        {unit}
                    </Text>
                    <Circle
                        diameter={kSecondaryAxisLabelWidth}
                        strokeColor={color}
                        strokeWidth={0}
                    >
                        <RecordTypeIcon recordType={recordType} />
                    </Circle>
                    <Text
                        style={[
                            textBaseStyle,
                            styles.regularText,
                            textExtraStyle,
                        ]}
                        onPressIn={undefined}
                        onPressOut={undefined}
                    >
                        {unit}
                    </Text>
                </View>
            )}
        </Animated.View>
    );
}

const styles = StyleSheet.create({
    container: {
        height: '100%',
    },
    innerContainer: {
        flex: 1,
    },
    compactText: {
        width: kSecondaryAxisLabelWidth,
        textAlign: 'left',
    },
    hiddenText: {
        width: kSecondaryAxisLabelWidth,
        color: 'transparent',
    },
    regularText: {
        width: kSecondaryAxisLabelWidth,
        textAlign: 'center',
        overflow: 'hidden',
    },
    smallFont: {
        fontSize: 12,
    },
});
