/* eslint-disable react-hooks/rules-of-hooks */
import { lz } from '@byterium/glucose-diary-client';
import React from 'react';
import { Platform, ViewProps } from 'react-native';
import { Caption, useTheme } from 'react-native-paper';

import { AppTheme } from '../const';
import Updater from '../services/Updater';
import { delay } from '../util';
import { addErrorSnack, addSnack } from './AppSnackbar';

const isWeb = Platform.OS === 'web';

interface UpdateAppButtonProps extends ViewProps {
    cooldown?: number;
}

const UpdateAppButton: React.FC<UpdateAppButtonProps> = ({
    cooldown = 1000,
    style,
    ...props
}) => {
    const theme = useTheme() as AppTheme;

    if (!isWeb) {
        return null;
    }

    const didInitRef = React.useRef(false);
    const [phase, setPhase] = React.useState<'check' | 'load' | 'apply'>(
        Updater.isUpdateAvailable
            ? Updater.downloadRequired
                ? 'load'
                : 'apply'
            : 'check'
    );
    const [loading, setLoading] = React.useState(false);
    const [coolingDown, setCoolingDown] = React.useState(false);
    const disabled = loading || coolingDown || !Updater.isSupported;

    React.useEffect(() => {
        if (didInitRef.current && !loading && phase === 'check') {
            setCoolingDown(true);
            delay(cooldown).then(() => setCoolingDown(false));
        }
        didInitRef.current = true;
        return () => {
            setCoolingDown(false);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading]);

    const phaseActionLabel = () => {
        switch (phase) {
            case 'check':
                return lz('checkForUpdates');
            case 'load':
                return lz('downloadUpdate');
            case 'apply':
                return lz('installUpdate');
        }
    };

    const startPhase = async () => {
        if (disabled) {
            return;
        }
        setLoading(true);
        try {
            switch (phase) {
                case 'check': {
                    const hasUpdate = await Updater.isUpdateAvailableAsync();
                    if (hasUpdate) {
                        setPhase(Updater.downloadRequired ? 'load' : 'apply');
                    } else {
                        addSnack(lz('noUpdatesAvailableMessage'));
                    }
                    break;
                }
                case 'load':
                    await Updater.downloadUpdate();
                    setPhase('apply');
                    break;
                case 'apply':
                    await Updater.installUpdate();
                    setPhase('check');
                    break;
            }
        } catch (error) {
            console.error(`Update error (${phase}): ${error}`);
            switch (phase) {
                case 'check':
                    addErrorSnack(lz('checkForUpdatesFailedMessage'));
                    break;
                case 'load':
                    addErrorSnack(lz('downloadUpdateFailedMessage'));
                    break;
                case 'apply':
                    addErrorSnack(lz('installUpdateFailedMessage'));
                    break;
            }
            setPhase('check');
        }
        setLoading(false);
    };

    return (
        <Caption
            {...props}
            style={[!disabled && { color: theme.form.colors.input }, style]}
            onPress={() => startPhase()}
        >
            {loading ? lz('loading') : phaseActionLabel()}
        </Caption>
    );
};

export default UpdateAppButton;
