import {
    formatRecordType,
    getRecordDataSource,
    lz,
} from '@byterium/glucose-diary-client';
import moment from 'moment';
import React from 'react';
import {
    LayoutAnimation,
    Platform,
    RefreshControl,
    ScrollView,
    StyleSheet,
    TouchableOpacity,
    View,
} from 'react-native';
import { useTheme } from 'react-native-paper';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { debounceTime } from 'rxjs/operators';

import AppBackground from '../../components/AppBackground';
import { addSnack } from '../../components/AppSnackbar';
import CenteredContent from '../../components/CenteredContent';
import RecordChart, { RecordChartRef } from '../../components/RecordChart';
import RefreshButton from '../../components/RefreshButton';
import Stack from '../../components/Stack';
import { AddIcon } from '../../components/assets/commonAssets';
import RecordListSummary from '../../components/records/RecordListSummary';
import RecordWidgets from '../../components/records/RecordWidgets';
import { AppTheme, kMaxPageWidth } from '../../const';
import { RefreshConfig, useAppMode, useRefreshAction } from '../../reactUtil';
import UserSettings from '../../services/UserSettings';
import { navigate } from '../navigate';
import {
    RecordSummaryNavigationProp,
    RecordSummaryPageOptions,
    RecordSummaryRouteProp,
} from './recordTypes';
import { getRecordSummaryInterface, getValueFormatter } from './recordUtil';

const kSectionPadding = 8;

const isNative = Platform.OS !== 'web';

export interface RecordSummaryPageProps extends RecordSummaryPageOptions {
    navigation: RecordSummaryNavigationProp;
    route: RecordSummaryRouteProp;
}

const RecordSummaryPage: React.FC<RecordSummaryPageProps> = props => {
    const { recordType, navigation } = {
        ...props,
        ...props.route.params,
    };
    if (!recordType) {
        throw new Error('Missing record type');
    }
    const theme = useTheme() as AppTheme;
    const insets = useSafeAreaInsets();
    const [isFullscreenChart, setFullscreenChart] = React.useState(false);
    const isFullscreenChartRef = React.useRef(isFullscreenChart);
    isFullscreenChartRef.current = isFullscreenChart;
    const units = UserSettings.useUnits();
    const dataSource = getRecordDataSource(recordType);
    const recordsRef = React.useRef(dataSource);
    recordsRef.current = dataSource;
    const chartRef = React.useRef<RecordChartRef>(null);
    const [isPanningChart, setPanningChart] = React.useState(false);

    const preferredAppMode = useAppMode();
    const [appMode, setAppMode] = React.useState(preferredAppMode);
    const isDesktop = appMode === 'desktop';

    React.useEffect(() => {
        // Prevent changing app modes while in fullscreen
        if (!isFullscreenChart && appMode !== preferredAppMode) {
            setAppMode(preferredAppMode);
        }
    }, [appMode, preferredAppMode, isFullscreenChart]);

    const { showAverage, chartProps, ...sharedProps } = React.useMemo(
        () => getRecordSummaryInterface({ recordType, units, theme }),
        [recordType, units, theme]
    );
    const valueFormatter = React.useMemo(
        () => getValueFormatter({ recordType, units }),
        [recordType, units]
    );

    const refreshConfig = React.useMemo(
        (): RefreshConfig<any> => ({
            refreshTask: () => dataSource.refetchAll(),
            onRefreshed: res =>
                addSnack({
                    message: res.error
                        ? lz('somethingWentWrong')
                        : lz('dataUpdatedSuccessfully'),
                    type: res.error ? 'error' : 'info',
                }),
        }),
        [dataSource]
    );

    const refreshAction = useRefreshAction(refreshConfig);

    React.useLayoutEffect(() => {
        navigation.setOptions({
            headerTitle: formatRecordType(recordType),
            headerRight: props => (
                <Stack horizontal style={styles.stackStyle}>
                    {!isNative && (
                        <RefreshButton
                            {...refreshAction}
                            color={props.tintColor || theme.colors.accent}
                        />
                    )}
                    <TouchableOpacity
                        onPress={() => navigate('AddSample', { recordType })}
                    >
                        <AddIcon
                            color={props.tintColor || theme.colors.accent}
                        />
                    </TouchableOpacity>
                </Stack>
            ),
        });
    }, [
        navigation,
        recordType,
        refreshAction,
        theme.colors.accent,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        ...(isNative ? [] : [...Object.values(refreshAction)]),
    ]);

    React.useEffect(() => {
        navigation.setOptions({ headerShown: !isFullscreenChart });
    }, [navigation, isFullscreenChart]);

    React.useEffect(() => {
        const sub = chartRef.current
            ?.layout()
            .plots[0]?.panStarted$.pipe(debounceTime(50))
            .subscribe(panning => setPanningChart(panning));
        if (!sub && isPanningChart) {
            setPanningChart(false);
        }
        return () => sub?.unsubscribe();
    }, [isPanningChart]);

    const onFullscreenPress = React.useCallback(() => {
        LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
        setFullscreenChart(!isFullscreenChartRef.current);
    }, []);

    const chart = (
        <RecordChart
            {...chartProps}
            ref={chartRef}
            isFullscreen={isFullscreenChart}
            onFullscreenPress={onFullscreenPress}
            style={
                isDesktop
                    ? [
                          styles.desktopChart,
                          isFullscreenChart
                              ? {
                                    marginTop: insets.top,
                                    marginRight: insets.right,
                                    marginLeft: insets.left,
                                    marginBottom: insets.bottom,
                                }
                              : {
                                    marginRight: kMargin + insets.right,
                                    marginBottom: kMargin + insets.bottom,
                                },
                      ]
                    : isFullscreenChart
                    ? styles.flex
                    : styles.miniChart
            }
        />
    );

    const refreshColorsAndroid = React.useMemo(
        () => [theme.colors.accent],
        [theme.colors.accent]
    );

    return (
        <View style={styles.rootContainer}>
            {!isFullscreenChart && <AppBackground />}
            <ScrollView
                style={[
                    styles.scrollContainer,
                    isFullscreenChart && styles.scrollContainerFullscreen,
                    isFullscreenChart && isDesktop ? styles.hidden : undefined,
                ]}
                contentContainerStyle={styles.scrollContent}
                scrollEnabled={!isPanningChart && !isFullscreenChart}
                refreshControl={
                    isNative ? (
                        <RefreshControl
                            {...refreshAction}
                            {...refreshConfig}
                            tintColor={theme.colors.accent}
                            colors={refreshColorsAndroid}
                        />
                    ) : undefined
                }
            >
                <CenteredContent
                    maxWidth={isFullscreenChart ? 0 : kMaxPageWidth}
                    style={[
                        isFullscreenChart
                            ? styles.fullscreenContent
                            : styles.content,
                        {
                            paddingLeft: insets.left,
                            paddingBottom: insets.bottom,
                        },
                        isFullscreenChart
                            ? {
                                  paddingTop: insets.top,
                                  paddingRight: insets.right,
                              }
                            : {},
                    ]}
                >
                    {!isDesktop && chart}
                    {(isDesktop || !isFullscreenChart) && (
                        <View>
                            <RecordListSummary
                                {...sharedProps}
                                title={lz('summary')}
                                onAllDataPress={() =>
                                    navigate('RecordList', sharedProps)
                                }
                                valueFormatter={valueFormatter}
                                showLatest
                                showAverage={
                                    showAverage
                                        ? moment.duration(7, 'days')
                                        : undefined
                                }
                                style={styles.section}
                            />
                            <RecordWidgets
                                recordType={recordType}
                                style={styles.section}
                            />
                        </View>
                    )}
                </CenteredContent>
            </ScrollView>
            {isDesktop && chart}
        </View>
    );
};

const kMargin = 12;

const styles = StyleSheet.create({
    rootContainer: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'center',
    },
    container: {
        flex: 1,
        minWidth: 200,
    },
    scrollContainer: {
        flex: 1,
        minWidth: 200,
        maxWidth: kMaxPageWidth,
    },
    scrollContainerFullscreen: {
        maxWidth: undefined,
    },
    scrollContent: {
        flexGrow: 1,
    },
    content: {
        marginHorizontal: kMargin,
        marginBottom: kMargin,
        marginTop: kMargin - kSectionPadding,
        flexGrow: 1,
    },
    fullscreenContent: {
        flexGrow: 1,
    },
    stackStyle: {
        marginRight: 12,
    },
    miniChart: {
        marginTop: kSectionPadding,
    },
    desktopChart: {
        flex: 1.5,
        marginTop: kMargin,
    },
    fullscreenChart: {
        width: '100%',
        height: '100%',
    },
    section: {
        marginTop: kSectionPadding,
    },
    flex: {
        flex: 1,
    },
    hidden: {
        position: 'absolute',
        display: 'none',
    },
});

export default RecordSummaryPage;
