import React from 'react';
import { ScrollView, StyleSheet, View } from 'react-native';
import { Form, FormModel } from 'react-native-form-model';
import { useTheme } from 'react-native-paper';

import AppBackground from '../../../components/AppBackground';
import CenteredContent from '../../../components/CenteredContent';
import { KeyboardAvoidingView } from '../../../components/KeyboardAvoidingView';
import { AppTheme, kMaxPageWidth } from '../../../const';
import { addUnitSectionToForm } from '../../../services/forms/settings/unitSettingForms';
import { UnitSettingsPageOptions } from './unitSettingsTypes';

export interface UnitSettingsPageProps extends UnitSettingsPageOptions {}

export default function UnitSettingsPage({
    navigation,
}: UnitSettingsPageProps) {
    const theme = useTheme() as AppTheme;

    const [form, setForm] = React.useState(() => FormModel.empty());

    React.useLayoutEffect(() => {
        const form = FormModel.create({ style: theme.form });

        addUnitSectionToForm({ form, theme });

        setForm(form);
    }, [theme]);

    return (
        <View style={styles.container}>
            <AppBackground />
            <KeyboardAvoidingView style={styles.container}>
                <ScrollView
                    style={styles.container}
                    contentContainerStyle={styles.scrollContent}
                >
                    <CenteredContent
                        maxWidth={kMaxPageWidth}
                        style={styles.content}
                    >
                        <Form form={form} style={styles.form} />
                    </CenteredContent>
                </ScrollView>
            </KeyboardAvoidingView>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    scrollContent: {
        flexGrow: 1,
    },
    content: {
        margin: 12,
    },
    form: {
        marginVertical: 12,
    },
    section: {
        marginBottom: 10,
    },
    icon: {
        width: 24,
    },
});
